import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { identityServerAngularConfig } from '@env/environment';

import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { LoaderService } from './../../core/loader.service';
import { Router } from '@angular/router';
import { AuthServiceCleaner } from './../../shared/services/auth.service.cleaner';

@Component({
  selector: 'app-reset-login',
  templateUrl: './reset-login.component.html',
  styleUrls: ['./reset-login.component.sass'],
})
export class ResetLoginComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private authClearService: AuthServiceCleaner,
    private router: Router
  ) {}

  ngOnInit() {
    this.loaderService.showMain = false;
    this.authService.getCurrentUser().subscribe({
      next: (user) => {
        if (user) {
          this.authClearService.SetLogoutEvent();
        } else {
          this.authService.removeIndexedDbItem().then(() => {
            window.localStorage.clear();
            this.authService.isSetLoggedOut = true;
            this.router.navigate(['/login']);
          });
        }
      },
      error: (e) => {
        console.log(e);
        window.localStorage.clear();
        this.loaderService.showMain = false;
        window.location.href = '/';
      },
    });
  }
}
