<div
  [attr.id]="id"
  [ngClass]="{'p-carousel p-component  ui-carousel ':true, 'p-carousel-vertical': isVertical(), 'p-carousel-horizontal': !isVertical()}"
  [ngStyle]="style"
  [class]="styleClass"
>
  <!-- <div class="p-carousel-header" *ngIf="headerFacet || headerTemplate">
        <ng-content select="p-header"></ng-content>
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div> -->
  <div [class]="contentClass" [ngClass]="'p-carousel-content ui-carousel-viewport-wrapper'">
    <div class="p-carousel-container">
      <button
        type="button"
        [ngClass]="{'p-carousel-prev p-link':true, 'p-disabled': isBackwardNavDisabled()}"
        [disabled]="isBackwardNavDisabled()"
        (click)="navBackward($event)"
        pRipple
      >
        <span
          [ngClass]="{'p-carousel-prev-icon pi': true, 'pi-chevron-left': !isVertical(), 'pi-chevron-up': isVertical()}"
        ></span>
      </button>
      <div class="p-carousel-items-content" [ngStyle]="{'height': isVertical() ? verticalViewPortHeight : 'auto'}">
        <div
          #itemsContainer
          class="p-carousel-items-container"
          (transitionend)="onTransitionEnd()"
          (touchend)="onTouchEnd($event)"
          (touchstart)="onTouchStart($event)"
          (touchmove)="onTouchMove($event)"
        >
          <div
            *ngFor="let item of clonedItemsForStarting; let index = index"
            [ngClass]="{'p-carousel-item p-carousel-item-cloned': true,
                        'p-carousel-item-active': (totalShiftedItems * -1) === (value.length),
                        'p-carousel-item-start': 0 === index,
                        'p-carousel-item-end': (clonedItemsForStarting.length - 1) === index}"
          >
            <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
          </div>
          <div
            *ngFor="let item of value; let index = index"
            [ngClass]="{'p-carousel-item': true,
                        'p-carousel-item-active': (firstIndex() <= index && lastIndex() >= index),
                        'p-carousel-item-start': firstIndex() === index,
                        'p-carousel-item-end': lastIndex() === index}"
          >
            <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
          </div>
          <div
            *ngFor="let item of clonedItemsForFinishing; let index = index"
            [ngClass]="{'p-carousel-item p-carousel-item-cloned': true,
                        'p-carousel-item-active': ((totalShiftedItems *-1) === numVisible),
                        'p-carousel-item-start': 0 === index,
                        'p-carousel-item-end': (clonedItemsForFinishing.length - 1) === index}"
          >
            <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
          </div>
        </div>
      </div>
      <button
        type="button"
        [ngClass]="{'p-carousel-next p-link': true, 'p-disabled': isForwardNavDisabled()}"
        [disabled]="isForwardNavDisabled()"
        (click)="navForward($event)"
        pRipple
      >
        <span
          [ngClass]="{'p-carousel-prev-icon pi': true, 'pi-chevron-right': !isVertical(), 'pi-chevron-down': isVertical()}"
        ></span>
      </button>
    </div>
    <ul [ngClass]="'p-carousel-indicators p-reset'" [class]="indicatorsContentClass">
      <li
        *ngFor="let totalDot of totalDotsArray(); let i = index"
        [ngClass]="{'p-carousel-indicator':true,'p-highlight': _page === i}"
      >
        <button type="button" class="p-link" (click)="onDotClick($event, i)"></button>
      </li>
    </ul>
  </div>
  <div class="p-carousel-footer" *ngIf="footerFacet || footerTemplate">
    <ng-content select="p-footer"></ng-content>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </div>
</div>
