<div class="login-page">
  <div class="col-left" *ngIf="imagePath" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
  <div class="col-left" *ngIf="imagePath == undefined">
    <div class="swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="https://reflecx.io/accounts/desktop/images/slide-1.jpg" />
          <div class="slider-caption">
            <p>Pursue the perfect<br />customer experience.</p>
          </div>
        </div>
        <div class="swiper-slide">
          <img src="https://reflecx.io/accounts/desktop/images/slide-2.jpg" />
          <div class="slider-caption">
            <p>Listen, aggregate and<br />act across every channel.</p>
          </div>
        </div>
        <div class="swiper-slide">
          <img src="https://reflecx.io/accounts/desktop/images/slide-3.jpg" />
          <div class="slider-caption">
            <p>Say hello to RefleCX. Everything<br />you need for proactive CX.</p>
          </div>
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
  <div class="col-right">
    <form name="authForm" novalidate style="height: 100%">
      <div class="login-form">
        <div class="form">
          <div class="form__inner">
            <div class="form__header">
              <div class="login-logo">
                <img src="./assets/styles/portal/build/assets/images/reflecx-logo.svg" alt="" />
              </div>

              <h4>Thank you for Signing Up</h4>
              <p *ngIf="isEmailSent" class="small-text">
                An email has been sent to you, please copy the code and paste below:
              </p>
            </div>

            <fieldset class="form__group" [ngClass]="{ 'error--field': isError }">
              <label class="form__label">ENTER CONFIRMATION CODE</label>
              <div class="form__filed">
                <input
                  class="form__input"
                  type="code"
                  name="code"
                  [(ngModel)]="code"
                  [disabled]="!isEmailSent"
                  required
                />
                <div class="field__error" *ngIf="isError">
                  <p>
                    {{ 'Code is required.' }}
                  </p>
                </div>
              </div>
            </fieldset>

            <fieldset class="form__group" *ngIf="isEmailSent">
              <a class="dont-get-email" (click)="sendCodeEmail()">Don't get email, send again?</a>
            </fieldset>
            <fieldset class="form__group login--actions">
              <button class="login-btn" (click)="submitCode(code)">Confirm Account</button>
            </fieldset>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
