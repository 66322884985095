import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ContentChildren, ElementRef, forwardRef, NgModule, QueryList, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';
import { RfButtonModule } from '../button/button';
import { RfPrimeTemplate, RfSharedModule } from '../common/shared';
import { InputTextModule } from '../inputtext/inputtext';

export const AUTOCOMPLETE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutoComplete),
  multi: true,
};

@Component({
  selector: 'rf-autoComplete',
  templateUrl: './autocomplete.html',
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}')),
    ]),
  ],
  host: {
    '[class.ui-inputwrapper-filled]': 'filled',
    '[class.ui-inputwrapper-focus]': 'focus && !disabled',
  },
  providers: [AUTOCOMPLETE_VALUE_ACCESSOR],
})
export class RfAutoCompleteComponent extends AutoComplete {
  @ViewChild('in') inputEL: ElementRef;
  @ViewChild('multiIn') multiInputEL: ElementRef;
  @ViewChild('multiContainer') multiContainerEL: ElementRef;
  @ViewChild('ddBtn') dropdownButton: ElementRef;
  @ContentChildren(RfPrimeTemplate) templates: QueryList<any>;
}

@NgModule({
  imports: [CommonModule, InputTextModule, RfButtonModule, RfSharedModule],
  exports: [RfAutoCompleteComponent, RfSharedModule],
  declarations: [RfAutoCompleteComponent],
})
export class RfAutoCompleteModule {}
