import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DataTableHeaderComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'rgrid-header',
  templateUrl: './rheader.component.html',
  styleUrls: ['./rheader.component.scss'],
  host: {
    class: 'datatable-header',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RHeaderComponent extends DataTableHeaderComponent implements OnInit {
  @Input() longestContent: any;
  constructor(@Inject(ChangeDetectorRef) ref: ChangeDetectorRef) {
    super(ref);
    Object.assign(this, this);
  }
  ngOnInit() {}
}
