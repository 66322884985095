<div class="error-wrapper">
  <div class="error">
    <div class="logo__error">
      <img src="./assets/styles/portal/build/assets/images/reflecx-logo.svg" alt="" />
    </div>

    <div class="error__inner">
      <i class="fal fa-exclamation-circle"></i>
      <h1>Oops! Something went wrong.</h1>
      <p>This page couldn't load correctly. Please try again.</p>
      <p>Cette page n'a pas pu être chargée correctement. Veuillez réessayer.</p>
      <p><a (click)="toHome()" class="error-btn">Click Here to Try Again</a></p>
    </div>
  </div>
</div>
