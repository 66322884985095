<div
  [ngClass]="{'ui-button-text-only': (!onIcon && !offIcon),
                'ui-button-text-icon-left': (onIcon && offIcon && hasOnLabel && hasOffLabel && iconPos === 'left'),
                'ui-button-text-icon-right': (onIcon && offIcon && hasOnLabel && hasOffLabel && iconPos === 'right'),'ui-button-icon-only': (onIcon && offIcon && !hasOnLabel && !hasOffLabel),
                'ui-state-active': checked,'ui-state-focus':true, 'ui-state-disabled':disabled}"
  [ngStyle]="style"
  [class]="styleClass"
  (click)="toggle($event)"
  (keydown.enter)="toggle($event)"
>
  <div class="ui-helper-hidden-accessible">
    <input
      #checkbox
      type="checkbox"
      [attr.id]="inputId"
      [checked]="checked"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [attr.tabindex]="tabindex"
    />
  </div>
  <span
    *ngIf="onIcon||offIcon"
    class="ui-button-icon-left"
    [class]="checked ? this.onIcon : this.offIcon"
    [ngClass]="{'ui-button-icon-left': (iconPos === 'left'),
            'ui-button-icon-right': (iconPos === 'right')}"
  ></span>
  <span class="">{{checked ? hasOnLabel ? onLabel : 'ui-btn' : hasOffLabel ? offLabel : 'ui-btn'}}</span>
</div>
