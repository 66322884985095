import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DataTableFooterComponent } from '@swimlane/ngx-datatable';
import { FilerService } from '../../filter.service';

@Component({
  selector: 'rdatatable-footer',
  templateUrl: './rfooter.component.html',
  styleUrls: ['./rfooter.component.scss'],
  host: {
    class: 'datatable-footer',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RFooterComponent extends DataTableFooterComponent {
  constructor(public perPageService: FilerService) {
    super();
  }

  onPerPageChange(e: any) {
    this.perPageService.passValue(e.target.value);
  }
}
