import { EditableRow } from 'primeng/table';
import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[cvpEditableRow]',
})
export class CVPEditableRow extends EditableRow {
  @Input('cvpEditableRow') data: any;
  @Input() pEditableRowDisabled: boolean;
  constructor(public el: ElementRef) {
    super(el);
  }
  isEnabled() {
    return this.pEditableRowDisabled !== true;
  }
}
