import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  NgZone,
  Renderer2,
  OnInit,
  OnDestroy,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { ScrollerComponent } from '@swimlane/ngx-datatable';
import { RDataTableBodyComponent } from './body.component';

@Component({
  selector: 'rdatatable-scroller',
  templateUrl: './scrollbar.html',
  host: {
    class: 'datatable-scroll',
  },
})
export class RScrollerComponent extends ScrollerComponent implements OnChanges {
  @Input() longestContent: any;
  @ViewChild('scrollBar') scrollBar: any;
  constructor(
    public el: ElementRef,
    public bodyComponent: RDataTableBodyComponent,
    public _ngZone: NgZone,
    element: ElementRef,
    public _renderer: Renderer2
  ) {
    super(_ngZone, element, _renderer);
  }
  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (this.scrollBar && this.scrollBar.moveBar) {
        this.scrollBar.moveBar();
      }
    }, 0);
  }

  // onScrolled(event: MouseEvent): void {
  //   const dom: Element = <Element>event.target;
  //   requestAnimationFrame(() => {
  //     this.scrollYPos = dom.scrollTop;
  //     this.scrollXPos = dom.scrollLeft;
  //     this.updateOffset();
  //   });

  // requestAnimationFrame(() => {
  //   this.scrollYPos = dom.scrollTop;
  //   this.scrollXPos = dom.scrollLeft;
  //   this.updateOffset();
  // });

  // }
}
