import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DropdownModule } from 'primeng/dropdown';
import { SharedModule } from '../../shared/shared.module';
@NgModule({
  imports: [CommonModule, DropdownModule, SharedModule.forRoot()],
  declarations: [BarChartComponent, LineChartComponent, PieChartComponent],
  exports: [BarChartComponent, LineChartComponent, PieChartComponent],
})
export class ReportChartModule {}
