import { Action } from '@ngrx/store';

export enum ActionTypes {
  ChangeCacheState = '[ChangeCacheState Component] ChangeCacheState',
}

export class ChangeCacheState implements Action {
  readonly type = ActionTypes.ChangeCacheState;
  constructor(public payload: { cache: any }) {}
}
