<div class="swiper mySwiper">
    <p-carousel
      [showNavigators]="false"
      [showIndicators]="true"
      [numVisible]="1" 
      [numScroll]="1" 
      [autoplayInterval]="7000" 
      [circular]="true" 
      [value]="authService.sliderMeta">
      <ng-template let-slider pTemplate="item">
          <div class="swiper-slide">
            <img [src]="slider.img" />
            <div class="slider-caption">
              <p [innerHTML]="slider.description"></p>
            </div>
          </div>
      </ng-template>
    </p-carousel>
</div> 