import {
  Component,
  Input,
  HostBinding,
  ElementRef,
  Output,
  KeyValueDiffers,
  KeyValueDiffer,
  EventEmitter,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  DoCheck,
  SkipSelf,
} from '@angular/core';
import { DataTableBodyRowComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'rdatatable-body-row',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      *ngFor="let colGroup of _columnsByPin; let i = index; trackBy: trackByGroups"
      class="datatable-row-{{ colGroup.type }} datatable-row-group"
      [ngStyle]="_groupStyles[colGroup.type]"
    >
      <rdatatable-body-cell
        *ngFor="let column of colGroup.columns; let ii = index; trackBy: columnTrackingFn"
        tabindex="-1"
        [row]="row"
        [group]="group"
        [expanded]="expanded"
        [isSelected]="isSelected"
        [longestContent]="longestContent"
        [rowIndex]="rowIndex"
        [column]="column"
        [rowHeight]="rowHeight"
        [displayCheck]="displayCheck"
        [treeStatus]="treeStatus"
        (activate)="onActivate($event, ii)"
        (treeAction)="onTreeAction()"
      >
      </rdatatable-body-cell>
    </div>
  `,
})
export class RDataTableBodyRowComponent extends DataTableBodyRowComponent {
  @Input() longestContent: any;

  @HostBinding('style.width')
  get columnsTotalWidths(): string {
    return 'auto';
  }
}
