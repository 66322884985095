import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CssLoaderService {
  cssRoute = '';
  cssObservable: Subject<string> = new Subject<string>();

  constructor() {}

  public getCssObservable() {
    return this.cssObservable.asObservable();
  }

  public getCss() {
    return this.cssRoute;
  }

  public setCss(css: string) {
    this.cssRoute = css;
    this.cssObservable.next(css);
  }
}
