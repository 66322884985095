<span
  [ngClass]="{'ui-calendar':true, 'ui-calendar-w-btn': showIcon,
  'ui-calendar-timeonly': timeOnly}"
  [ngStyle]="style"
  #container
  [class]="styleClass"
  *ngIf="!primeCalendar"
>
  <ng-template [ngIf]="!inline">
    <div class="date-range-field" click-outside (clickOutside)="calendarMenu($event)">
      <i class="pi pi-calendar"></i>

      <ng-container
        *ngIf="customRange && (customRange.label == 'Custom Range' || customRange.label == 'gamme personnalisée')"
      >
        <div class="date-range-label" #ref (click)="onInputClick()" id="custom_range">
          <span *ngIf="!customResult"> <b>{{defaultSelectedDate.label}}</b> - {{defaultSelectedDate.value}} </span>
          <span *ngIf="customResult"> <b>{{customRange.label}}</b> - {{customResult || inputFieldValue}} </span>
        </div>
      </ng-container>
      <div
        class="action-menu-node"
        *ngIf="customRange && customRange.label == 'Custom Range'"
        #ref
        (click)="onInputClick();"
      >
        <i class="pi pi-angle-down"></i>
      </div>

      <div
        class="date-range-label"
        (click)="activeCalendar=!activeCalendar;"
        *ngIf="!customRange && defaultSelectedDate.label !== 'Custom Range'"
      >
        <b>{{defaultSelectedDate.label}}</b> - {{defaultSelectedDate.value}}
      </div>
      <div
        class="action-menu-node"
        *ngIf="!customRange &&
        defaultSelectedDate.label !== 'Custom Range'"
      >
        <i (click)="activeCalendar=!activeCalendar;" class="pi pi-angle-down"></i>
        <div class="action-menu active" *ngIf="activeCalendar">
          <ul>
            <li *ngFor="let date of dateDropDown; let i= index" [class.active]="selectedIndex == i">
              <a href="javascript:void(0);" (click)="selectCustomDate(date,i)">{{date.label}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
  <div
    [class]="panelStyleClass"
    class="abc"
    #contentWrapper
    style="margin-top: 25px"
    [ngClass]="{'ui-datepicker ui-widget ui-widget-content ui-helper-clearfix
    ui-corner-all': true, 'ui-datepicker-inline':inline,'ui-shadow':!inline,
    'ui-state-disabled':disabled,'ui-datepicker-timeonly':timeOnly,'ui-datepicker-multiple-month':
    this.numberOfMonths> 1, 'ui-datepicker-monthpicker': (view === 'month'),
    'ui-datepicker-touch-ui': touchUI}"
    (click)="onDatePickerClick($event)"
    [@.disabled]="inline === true"
    *ngIf="inline || overlayVisible"
  >
    <div class="ui-datepicker-title calendar-dropdown" *ngIf="navigatorForMultipleMonth">
      <select class="ui-datepicker-month" (change)="navigatorForMonth($event.target.value)">
        <option
          [value]="i"
          *ngFor="let monthName of navigationObj.monthNames;let i= index"
          [selected]="navigationObj.selectedFirstMonth === monthName"
        >
          {{monthName}}
        </option>
      </select>
      <select class="ui-datepicker-year zYear" (change)="navigatorForYear($event.target.value)">
        <option [value]="year" *ngFor="let year of yearOptions" [selected]="year === currentYear">{{year}}</option>
      </select>
    </div>
    <ng-container *ngIf="!timeOnly">
      <div class="ui-datepicker-range">
        <ul>
          <li
            *ngFor="let date of dateDropDown; let i= index"
            [class.active]="selectedIndex == i"
            (click)="selectCustomDate(date,i)"
          >
            <a>{{date.label}}</a>
          </li>
        </ul>
      </div>
      <div
        class="ui-datepicker-group ui-widget-content"
        *ngFor="let month of
        months; let i= index;"
      >
        <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
          <ng-content select="rf-header"></ng-content>
          <a class="ui-datepicker-prev ui-corner-all" tabindex="0" (click)="navigationBackward($event)" *ngIf="i === 0">
            <span class="pi pi-chevron-left"></span>
          </a>
          <a
            class="ui-datepicker-next ui-corner-all"
            tabindex="0"
            (click)="navigationForward($event)"
            *ngIf="numberOfMonths === 1 ? true : (i === numberOfMonths -1)"
          >
            <span class="pi pi-chevron-right"></span>
          </a>
          <div class="ui-datepicker-title">
            <!-- <span class="ui-datepicker-month" *ngIf="!monthNavigator && (view
              !== 'month')">{{locale.monthNames[month.month]}}</span> -->
            <span class="p-datepicker-month ui-datepicker-month" *ngIf="!monthNavigator && (view !== 'month')"
              >{{getTranslation('monthNames')[month.month]}}</span
            >
            <select
              class="ui-datepicker-month"
              *ngIf="monthNavigator && (view !== 'month') && numberOfMonths ===
              1"
              (change)="onMonthDropdownChange($event.target.value)"
            >
              <option
                [value]="i"
                *ngFor="let monthName of locale.monthNames;let i= index"
                [selected]="i === month.month"
              >
                {{monthName}}
              </option>
            </select>
            <select
              class="ui-datepicker-year zYear"
              *ngIf="yearNavigator && numberOfMonths === 1"
              (change)="onYearDropdownChange($event.target.value)"
            >
              <option [value]="year" *ngFor="let year of yearOptions" [selected]="year === currentYear">
                {{year}}
              </option>
            </select>
            <span class="ui-datepicker-year" *ngIf="!yearNavigator"
              >{{view === 'month' ? currentYear : month.year}}</span
            >
          </div>
        </div>
        <div class="ui-datepicker-calendar-container" *ngIf="view ==='date'">
          <table class="ui-datepicker-calendar">
            <thead>
              <tr>
                <th
                  scope="col"
                  *ngFor="let weekDay of weekDays;let begin=
                  first; let end= last"
                >
                  <span>{{weekDay}}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let week of month.dates">
                <td
                  *ngFor="let date of week"
                  [ngClass]="{'ui-datepicker-other-month': date.otherMonth,
                  'ui-datepicker-current-day':isSelected(date),'ui-datepicker-today':date.today}"
                >
                  {{previousDate(date)}}
                  <ng-container
                    *ngIf="date.otherMonth ? showOtherMonths :
                    true"
                  >
                    <a
                      class="ui-state-default"
                      *ngIf="date.selectable"
                      [ngClass]="{'ui-state-active':isSelected(date),
                      'ui-state-highlight':date.today}"
                      (click)="onDateSelect($event,date)"
                      draggable="false"
                    >
                      <ng-container *ngIf="!dateTemplate">
                        <span
                          [ngStyle]="{backgroundColor: (date.range == true) ?
                          '#8dcdff' : 'inherit'}"
                          style="border-radius: 0%; padding: 0.25em; width: 1.75em; height: 1.75em; display: block"
                          >{{date.day}}</span
                        >
                      </ng-container>
                      <ng-container
                        *ngTemplateOutlet="dateTemplate; context:
                        {$implicit: date}"
                      >
                      </ng-container>
                    </a>
                    <span class="ui-state-default ui-state-disabled" *ngIf="!date.selectable"> {{date.day}} </span>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="date-range-time">
          <select (change)="selectDropDown('hour',$event.target.value,i)">
            <option *ngFor="let h of hour" [value]="h">{{h}}</option>
          </select>
          :
          <select (change)="selectDropDown('minute',$event.target.value,i)">
            <option *ngFor="let m of minutes" [value]="m">{{m}}</option>
          </select>
          <select (change)="selectDropDown('zone',$event.target.value,i)">
            <option value="am">AM</option>
            <option value="pm">PM</option>
          </select>
        </div> -->
      </div>
      <div class="ui-monthpicker" *ngIf="view === 'month'">
        <a
          tabindex="0"
          *ngFor="let m of monthPickerValues(); let i= index"
          (click)="onMonthSelect($event, i)"
          class="ui-monthpicker-month"
          [ngClass]="{'ui-state-active': isMonthSelected(i)}"
        >
          {{m}}
        </a>
      </div>
      <div class="ui-datepicker-footer">
        <button type="button" class="btn" (click)="cancel()">Cancel</button>
        <button type="button" class="btn" [disabled]="!inputFieldValue" (click)="apply()">Apply</button>
      </div>
    </ng-container>
    <div class="ui-timepicker ui-widget-header ui-corner-all" *ngIf="showTime||timeOnly">
      <div class="ui-hour-picker">
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{'display': currentHour <10 ? 'inline': 'none'}">0</span><span>{{currentHour}}</span>
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-separator">
        <a tabindex="0">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{timeSeparator}}</span>
        <a tabindex="0">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-minute-picker">
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{'display': currentMinute <10 ? 'inline': 'none'}">0</span><span>{{currentMinute}}</span>
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-separator" *ngIf="showSeconds">
        <a tabindex="0">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{timeSeparator}}</span>
        <a tabindex="0">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-second-picker" *ngIf="showSeconds">
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{'display': currentSecond <10 ? 'inline': 'none'}">0</span><span>{{currentSecond}}</span>
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-ampm-picker" *ngIf="hourFormat=='12'">
        <a tabindex="0" (click)="toggleAMPM($event)">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{pm ? 'PM' : 'AM'}}</span>
        <a tabindex="0" (click)="toggleAMPM($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
    </div>

    <div class="ui-datepicker-buttonbar ui-widget-header" *ngIf="showButtonBar">
      <div class="ui-g">
        <div class="ui-g-6">
          <button
            type="button"
            [label]="_locale.today"
            (click)="onTodayButtonClick($event)"
            rfButton
            [ngClass]="[todayButtonStyleClass]"
          ></button>
        </div>
        <div class="ui-g-6">
          <button
            type="button"
            [label]="_locale.clear"
            (click)="onClearButtonClick($event)"
            rfButton
            [ngClass]="[clearButtonStyleClass]"
          ></button>
        </div>
      </div>
    </div>
    <ng-content select="rf-footer"></ng-content>
  </div>
</span>

<span
  [ngClass]="{'ui-calendar':true, 'ui-calendar-w-btn': showIcon,
  'ui-calendar-timeonly': timeOnly}"
  [ngStyle]="style"
  [class]="styleClass"
  *ngIf="primeCalendar"
>
  <ng-template [ngIf]="!inline">
    <div>
      <input
        #inputfield
        type="text"
        [attr.id]="inputId"
        [attr.name]="name"
        [attr.required]="required"
        [value]="inputFieldValue"
        (focus)="onInputFocus($event)"
        (keydown)="onInputKeydown($event)"
        (click)="onInputClick()"
        (blur)="onInputBlur($event)"
        [readonly]="readonlyInput"
        (input)="onUserInput($event)"
        [ngStyle]="inputStyle"
        [class]="inputStyleClass"
        [placeholder]="placeholder||''"
        [disabled]="disabled"
        [attr.tabindex]="tabindex"
        [ngClass]="'ui-inputtext ui-widget ui-state-default ui-corner-all'"
        autocomplete="off"
      /><button
        type="button"
        [icon]="icon"
        rfButton
        *ngIf="showIcon"
        (click)="onButtonClick($event,inputfield)"
        class="ui-datepicker-trigger ui-calendar-button"
        [ngClass]="{'ui-state-disabled':disabled}"
        [disabled]="disabled"
        tabindex="-1"
      ></button>
    </div>
  </ng-template>

  <div
    [class]="panelStyleClass"
    style="margin-top: 25px"
    #contentWrapper
    [ngStyle]="panelStyle"
    [ngClass]="{'ui-datepicker ui-widget ui-widget-content ui-helper-clearfix
    ui-corner-all': true, 'ui-datepicker-inline':inline,'ui-shadow':!inline,
    'ui-state-disabled':disabled,'ui-datepicker-timeonly':timeOnly,'ui-datepicker-multiple-month':
    this.numberOfMonths> 1, 'ui-datepicker-monthpicker': (view === 'month'),
    'ui-datepicker-touch-ui': touchUI}"
    (click)="onDatePickerClick($event)"
    [@overlayAnimation]="touchUI ? {value: 'visibleTouchUI', params:
    {showTransitionParams: showTransitionOptions, hideTransitionParams:
    hideTransitionOptions}}:
    {value: 'visible', params: {showTransitionParams: showTransitionOptions,
    hideTransitionParams: hideTransitionOptions}}"
    [@.disabled]="inline === true"
    (@overlayAnimation.start)="onOverlayAnimationStart($event)"
    *ngIf="inline || overlayVisible"
  >
    <ng-container *ngIf="!timeOnly">
      <div
        class="ui-datepicker-group ui-widget-content"
        *ngFor="let month of
        months; let i= index;"
      >
        <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
          <ng-content select="rf-header"></ng-content>
          <a class="ui-datepicker-prev ui-corner-all" tabindex="0" (click)="navBackward($event)" *ngIf="i === 0">
            <span class="pi pi-chevron-left"></span>
          </a>
          <a
            class="ui-datepicker-next ui-corner-all"
            tabindex="0"
            (click)="navForward($event)"
            *ngIf="numberOfMonths === 1 ? true : (i === numberOfMonths -1)"
          >
            <span class="pi pi-chevron-right"></span>
          </a>
          <div class="ui-datepicker-title">
            <span
              class="ui-datepicker-month"
              *ngIf="!monthNavigator && (view
              !== 'month')"
              >{{locale.monthNames[month.month]}}</span
            >
            <select
              class="ui-datepicker-month"
              *ngIf="monthNavigator && (view !== 'month') && numberOfMonths ===
              1"
              (change)="onMonthDropdownChange($event.target.value)"
            >
              <option
                [value]="i"
                *ngFor="let monthName of locale.monthNames;let i= index"
                [selected]="i === month.month"
              >
                {{monthName}}
              </option>
            </select>
            <select
              class="ui-datepicker-year zYear1"
              *ngIf="yearNavigator && numberOfMonths === 1"
              (change)="onYearDropdownChange($event.target.value)"
            >
              <option [value]="year" *ngFor="let year of yearOptions" [selected]="year === currentYear">
                {{year}}
              </option>
            </select>
            <span class="ui-datepicker-year" *ngIf="!yearNavigator"
              >{{view === 'month' ? currentYear : month.year}}</span
            >
          </div>
        </div>
        <div class="ui-datepicker-calendar-container" *ngIf="view ==='date'">
          <table class="ui-datepicker-calendar">
            <thead>
              <tr>
                <th
                  scope="col"
                  *ngFor="let weekDay of weekDays;let begin=
                  first; let end= last"
                >
                  <span>{{weekDay}}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let week of month.dates">
                <td
                  *ngFor="let date of week"
                  [ngClass]="{'ui-datepicker-other-month': date.otherMonth,
                  'ui-datepicker-current-day':isSelected(date),'ui-datepicker-today':date.today}"
                >
                  <ng-container
                    *ngIf="date.otherMonth ? showOtherMonths :
                    true"
                  >
                    <a
                      class="ui-state-default"
                      *ngIf="date.selectable"
                      [ngClass]="{'ui-state-active':isSelected(date),
                      'ui-state-highlight':date.today}"
                      (click)="onDateSelect($event,date)"
                      draggable="false"
                    >
                      <ng-container *ngIf="!dateTemplate"> {{date.day}} </ng-container>
                      <ng-container
                        *ngTemplateOutlet="dateTemplate; context:
                        {$implicit: date}"
                      >
                      </ng-container>
                    </a>
                    <span class="ui-state-default ui-state-disabled" *ngIf="!date.selectable"> {{date.day}} </span>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="ui-monthpicker" *ngIf="view === 'month'">
        <a
          tabindex="0"
          *ngFor="let m of monthPickerValues(); let i= index"
          (click)="onMonthSelect($event, i)"
          class="ui-monthpicker-month"
          [ngClass]="{'ui-state-active': isMonthSelected(i)}"
        >
          {{m}}
        </a>
      </div>
    </ng-container>
    <div class="ui-timepicker ui-widget-header ui-corner-all" *ngIf="showTime||timeOnly">
      <div class="ui-hour-picker">
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{'display': currentHour <10 ? 'inline': 'none'}">0</span><span>{{currentHour}}</span>
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-separator">
        <a tabindex="0">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{timeSeparator}}</span>
        <a tabindex="0">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-minute-picker">
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{'display': currentMinute <10 ? 'inline': 'none'}">0</span><span>{{currentMinute}}</span>
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-separator" *ngIf="showSeconds">
        <a tabindex="0">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{timeSeparator}}</span>
        <a tabindex="0">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-second-picker" *ngIf="showSeconds">
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{'display': currentSecond <10 ? 'inline': 'none'}">0</span><span>{{currentSecond}}</span>
        <a
          tabindex="0"
          (mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
        >
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="ui-ampm-picker" *ngIf="hourFormat=='12'">
        <a tabindex="0" (click)="toggleAMPM($event)">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{pm ? 'PM' : 'AM'}}</span>
        <a tabindex="0" (click)="toggleAMPM($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
    </div>

    <div class="ui-datepicker-buttonbar ui-widget-header" *ngIf="showButtonBar">
      <div class="ui-g">
        <div class="ui-g-6">
          <button
            type="button"
            [label]="_locale.today"
            (click)="onTodayButtonClick($event)"
            rfButton
            [ngClass]="[todayButtonStyleClass]"
          ></button>
        </div>
        <div class="ui-g-6">
          <button
            type="button"
            [label]="_locale.clear"
            (click)="onClearButtonClick($event)"
            rfButton
            [ngClass]="[clearButtonStyleClass]"
          ></button>
        </div>
      </div>
    </div>
    <ng-content select="rf-footer"></ng-content>
  </div>
</span>
