import { Injectable, NgModule } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { includes } from 'lodash';
import { TranslationDataValues, Data } from './types/translationtypes/translationdata';
import { Store } from '@ngrx/store';
import { CVPActions } from '@gstate/actions';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { Router } from '@angular/router';
import { MyStartupService } from '@app/app.service';
import { DecimalPipe, DatePipe } from '@angular/common';
import { environment } from '@env/environment';
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
  return s;
}
// @NgModule()
@Injectable()
export class I18nService {
  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */

  set language(language: string) {
    if (this.supportedLanguages) {
      language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
      let isSupportedLanguage = includes(this.supportedLanguages, language);
      // If no exact match is found, search without the region
      if (language && !isSupportedLanguage) {
        language = language.split('-')[0];
        language = this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(language)) || '';
        isSupportedLanguage = Boolean(language);
      }
      // Fallback if language is not supported
      if (!isSupportedLanguage) {
        language = this.defaultLanguage;
      }
      localStorage.setItem(languageKey, language);
      // this.service.Get(language).subscribe(res => {
      // this.translateService.setTranslation(language, res);
      //   });
      this.translateService.use(language);
    }
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }
  defaultLanguage: string;
  supportedLanguages: string[];
  languageKey = 'language';
  defaultKey = 'default';
  translationData: TranslationDataValues;
  ClientTranslation: Data[];
  // All default language key values
  allDefaultTranslations: Data[];
  // All current language key values
  allTranslations: Data[];
  filterTranslationData: Data;
  defaultTranslations: any;
  moduleName: string;
  tabName: string;
  widgetName: string;
  clientId: string;
  tranlslationLanguage: string;
  globalLanguage: any;
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private route: Router,
    private myStartupService: MyStartupService,
    private router: Router,
    private numberPipe: DecimalPipe,
    private datePipe: DatePipe,
    private store: Store<{ EventReducer: any }>
  ) {}

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(moduleName: string, defaultLanguage: string, supportedLanguages: string[], clientId: string) {
    this.moduleName = moduleName;
    if (this.route.url.includes('/composer')) {
      this.tabName = '';
    } else {
      this.tabName = 'sales';
    }
    this.clientId = clientId;
    const language = localStorage.getItem(this.languageKey);
    this.defaultLanguage = language === null || language === '' ? defaultLanguage : language;
    this.supportedLanguages = supportedLanguages;
    this.translateService.use(this.defaultLanguage);
    this.GetLanguageTranslation(this.defaultLanguage);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.GetLanguageTranslation(event.lang);
      this.FilterTranslation();
    });

    this.FilterTranslation();
  }

  ChangeTranslationByModuleAndTab(moduleName: string, tab: string) {
    this.moduleName = moduleName;
    if (this.route.url.includes('/composer')) {
      this.tabName = '';
    } else {
      this.tabName = tab.toLowerCase();
    }
    this.FilterTranslation();
  }

  GetByTab(tab: string) {
    return this.translationData.Data.find((x) => x.Module === this.moduleName && x.TabName === tab);
  }

  FilterTranslation(paramModuleName: string = null) {
    let url: any;
    let moduleName: any;
    if (paramModuleName == null) {
      url = this.router.url;

      if (this.route.url.includes('/' + environment.reflecxRoutePrefix) === false) {
        moduleName = url.substr(url.lastIndexOf('/') + 1, url.length - url.lastIndexOf('/'));
      } else {
        moduleName = this.router.url.split('/')[2];
      }
    } else {
      moduleName = paramModuleName;
    }
    let translation: any;
    if (this.translationData) {
      if (moduleName === 'insights') {
        moduleName = 'reporting';
      }
      this.filterTranslationData = this.translationData.Data.find((x) => x.Module === moduleName);
      this.defaultTranslations = this.translationData.Data.find((x) => x.Module === 'default');
      const commonTranslation = this.translationData.Data.find((x) => x.Module === 'common');
      if (this.filterTranslationData) {
        translation = this.filterTranslationData.Values;
        translation = translation.concat(this.defaultTranslations.Values);
      } else {
        translation = this.defaultTranslations.Values;
      }
      translation = translation.concat(commonTranslation.Values);
      if (moduleName === 'reporting') {
        const rgTranslation = this.translationData.Data.find((x) => x.Module === 'reportgenerator');
        translation = translation.concat(rgTranslation.Values);
      }
      if (translation != null) {
        const obj = Object.create(null);
        for (const item of translation) {
          if (item != null) {
            obj[item.Key] = item.Value.Value;
          }
        }
        // const json = JSON.parse(JSON.stringify(obj));
        const json = JSON.parse(JSON['makeStringify'](obj));
        if (this.tranlslationLanguage == null) {
          this.tranlslationLanguage = localStorage.getItem('language');
        }
        this.translateService.setTranslation(this.tranlslationLanguage, json);
      }
    }
  }

  SetDefaultAndClientTranslations(response: any, language: string) {
    if (response != null) {
      if (!response.TranslationFound) {
        this.toastService.error('Translation not found!');
      }
      // this.translationData = response;
      this.ClientTranslation = response.ClientTranslation;
      this.allDefaultTranslations = response.DefaultData;
      this.allTranslations = response.Data;
      this.tranlslationLanguage = language;
      this.defaultTranslations = response.Data.find((x: any) => x.Module === this.defaultKey).Values;
      // this.FilterTranslation();
      // localStorage.setItem(languageKey, language);
      // this.store.dispatch(new CVPActions.LanguageEventAction.LanguageEvent(language));
    }
  }

  GetLanguageTranslation(language: string, isGlobal: boolean = true) {
    const res: any = this.myStartupService.data || null;
    if (res != null) {
      if (!res.TranslationFound) {
        this.toastService.error('Translation not found!');
      }
      this.translationData = res;

      // this.allTranslations = res.Data;
      const obj = Object.create(null);
      res.Data.map((value: any) => {
        for (const item of value.Values) {
          obj[item.Key] = item.Value.Value;
        }
      });
      // this.translateService.setTranslation(language, json);

      if (isGlobal) {
        localStorage.setItem(languageKey, language);
      }
      this.store.dispatch(new CVPActions.LanguageEventAction.LanguageEvent(language));
      this.FilterTranslation();
    }
    //   return
    // this.service.Get(this.clientId, language).subscribe(res => {
    //   if (res != null) {
    //   if (!res.TranslationFound) {
    //     this.toastService.error('Translation not found!');
    //   }
    //   this.translationData = res;
    //   this.ClientTranslation =  res.ClientTranslation;

    //   this.allTranslations =  res.Data;
    //   this.tranlslationLanguage = language;
    //   this.defaultTranslations   =  this.translationData.Data.find(x => x.Module === this.defaultKey).Values;
    //   this.FilterTranslation();
    //   if(isGlobal) localStorage.setItem(languageKey, language);
    //   this.store.dispatch(new CVPActions.LanguageEventAction.LanguageEvent(language));
    //     }
    //   });
  }

  // tslint:disable-next-line: member-ordering
  public static getCurrentLocale(): string {
    // if (localStorage.getItem(languageKey) === null) {
    // return 'en-CA';
    // }
    return localStorage.getItem(languageKey) || 'en-CA';
  }

  public getTranslate(value: string) {
    return this.translateService.instant(value);
  }

  public transformNumber(value: number, isNonDecimal: boolean = true): string {
    if (value) {
      if (isNonDecimal === true) {
        return this.numberPipe.transform(value);
      } else {
        return this.numberPipe.transform(value, '1.1-1');
      }
    }
    return this.numberPipe.transform(0.0);
  }

  public transformStringNumber(value: string, format: string = '1.1-1'): string {
    if (value) {
      const val = parseFloat(value);
      return this.numberPipe.transform(val, format);
    }
    return this.numberPipe.transform(0.0);
  }
  public transformDate(value: Date, format: string): string {
    if (value) {
      return this.datePipe.transform(value, format);
    }
    return '';
  }

  public getTimeIntervalDetails(name: string, value: any) {
    if (name == 'ResponseTime') {
      var interval = value;
      var days = parseInt(interval);
      var hoursRaw = 24 * (interval % 1);
      var hours = parseInt(hoursRaw.toString());

      if (hours === 24) {
        days = days + 1;
        hours = 0;
      }

      var printDays = days + ' ' + this.translateService.instant('Day(s)');

      var printHours = hours + ' ' + this.translateService.instant('Hour(s)');

      if (days === 0 && hours === 0) {
        return 'N/A';
      } else if (days === 0) {
        return printHours;
      } else if (hours === 0) {
        return printDays;
      } else {
        return ' ' + printDays + ' ' + printHours + ' ';
      }
    } else {
      return '';
    }
  }
}
