import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  NgModule,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { Footer, Header, PrimeTemplate } from 'primeng/api';

@Component({
  selector: 'rf-header',
  template: '<ng-content></ng-content>',
})
export class RfHeader extends Header {}

@Component({
  selector: 'rf-footer',
  template: '<ng-content></ng-content>',
})
export class RfFooter extends Footer {}

@Directive({
  selector: '[rfTemplate]',
  host: {},
})
export class RfPrimeTemplate extends PrimeTemplate {
  @Input('rfTemplate') name: string;
}

@NgModule({
  imports: [CommonModule],
  exports: [RfHeader, RfFooter, RfPrimeTemplate],
  declarations: [RfHeader, RfFooter, RfPrimeTemplate],
})
export class RfSharedModule {}
