<div>
  <div
    orderable
    (reorder)="onColumnReordered($event)"
    (targetChanged)="onTargetChanged($event)"
    [style.width.px]="_columnGroupWidths.total"
    class="datatable-header-inner"
  >
    <div
      *ngFor="let colGroup of _columnsByPin; trackBy: trackByGroups"
      [class]="'datatable-row-' + colGroup.type"
      [ngStyle]="_styleByGroup[colGroup.type]"
    >
      <data-header-cell
        *ngFor="let column of colGroup.columns; trackBy: columnTrackingFn"
        resizeable
        [resizeEnabled]="column.resizeable"
        (resize)="onColumnResized($event, column)"
        long-press
        [longestContent]="longestContent"
        [pressModel]="column"
        [pressEnabled]="reorderable && column.draggable"
        (longPressStart)="onLongPressStart($event)"
        (longPressEnd)="onLongPressEnd($event)"
        draggable
        [dragX]="reorderable && column.draggable && column.dragging"
        [dragY]="false"
        [dragModel]="column"
        [dragEventTarget]="dragEventTarget"
        [headerHeight]="headerHeight"
        [isTarget]="column.isTarget"
        [targetMarkerTemplate]="targetMarkerTemplate"
        [targetMarkerContext]="column.targetMarkerContext"
        [column]="column"
        [sortType]="sortType"
        [sorts]="sorts"
        [selectionType]="selectionType"
        [sortAscendingIcon]="sortAscendingIcon"
        [sortDescendingIcon]="sortDescendingIcon"
        [allRowsSelected]="allRowsSelected"
        (sort)="onSort($event)"
        (select)="select.emit($event)"
        (columnContextmenu)="columnContextmenu.emit($event)"
      >
      </data-header-cell>
    </div>
  </div>
</div>
