import {
  Component,
  Output,
  EventEmitter,
  Input,
  HostBinding,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DataTableBodyComponent } from '@swimlane/ngx-datatable';
import { GridBodyListRowDirective } from './list-row.directive';

@Component({
  selector: 'rbody-list-datatable-body',
  templateUrl: './body.list.html',
})
export class BodyListComponent extends DataTableBodyComponent implements OnInit {
  @Input() rowTemplate: GridBodyListRowDirective;
  ngOnInit() {}
}
