import { CVPActions } from './actions';
import { Reducers } from './reducers';

const GState = {
  Reducers,
  CVPActions,
};

export function getInitialState() {
  return {};
}

export default GState;
