import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './types/loader-state.model';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  showMain = false;
  showTop = false;
  constructor() {}

  setLoaders(val = false) {
    this.showMain = val;
    this.showTop = val;
  }

  setMain(val = false) {
    this.showMain = val;
  }

  setTop(val = false) {
    this.showMain = val;
  }
}
