import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { Router } from '@angular/router';
import { ValidationProblemDetails } from '../types/validationProblemDetails';
// import { AuthenticationService } from '../authentication/authentication.service';
// import { ToastService } from '@app/shared/services/toast/toast.service';
// import { MatDialog } from '@angular/material';
// import { UnauthorizedComponent } from '@reflecxshared/components/unauthorized/unauthorized.component';
import { TranslateService } from '@ngx-translate/core';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    // public authService: AuthenticationService,
    public toastService: ToastService,
    private translateService: TranslateService,
    private router: Router // public dialog: MatDialog
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response: any) => {
        // if (response.body && response.body.Status === false && response.body.Message) {
        //   this.toasterError(response);
        // }
      }),
      catchError((error) => this.errorHandler(error))
    );
  }

  // Customize the default error handler here if needed
  // private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
  private errorHandler(response: any): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      if (response.status === 401) {
        // this.toastService.error('Something went wrong...');
        this.router.navigate(['/endsession']);
      }
      // this.toastService.error(response.body.Message);
      // log.error('Request error', response);
    }

    switch (response.status) {
      case 400:
        if (!(response.error.Message == undefined || response.error.Message == null)) {
          this.toastService.error(response.error.Message);
        } else if (response.error.title !== undefined && response.error.title !== '') {
          this.toastService.error(response.error.title);
        }
        break;
      case 401:
        localStorage.setItem('isUserReloggedIn', JSON.stringify(true));
        this.router.navigate(['/endsession']);
        break;
      case 404:
        this.toastService.error('Resource not found.');
        // this.router.navigate(['/login']);
        break;
      case 422:
        const details = response.error as ValidationProblemDetails;
        if (details.errors) {
          const errors = Object.keys(details.errors).map((key) => {
            if (key) {
              return `${[key]}: ${details.errors[key].join(',')}`;
            }
            return `${details.errors[key].join(',')}`;
          });
          this.toastService.error(errors.join('\n'));
        } else {
          this.toastService.error(response.error.title);
        }
        break;
      case 429:
        this.toastService.error('Sorry, your IP has been blocked due to limit exceeds.');
        break;
      case 500:
        this.toastService.error('Something went wrong...');
        break;
      case 501:
        this.toastService.error('Unable to process the request...');
        break;
      case 502:
        this.toastService.error('Service_Not_Available');
        break;
      case 503:
        this.toastService.error('Service_Not_Available');
        break;
      case 504:
        this.toastService.error('Service_Not_Available');
        break;
      case 505:
        this.toastService.error('Service_Not_Available');
        break;
      case 506:
      case 507:
      case 508:
      case 510:
      case 511:
      default:
        this.toastService.error('Service not available... please check you internet connection');
        break;
    }
    // if (response.status === 401) {
    //   this.authService.expired();
    //   this.toastService.info('Your session has been expired. Kindly re-login to continue.');
    //   this.dialog.open(UnauthorizedComponent, {
    //     data: {
    //     },
    //     panelClass: ['modal', 'modal--medium', 'modal--error']
    //   });
    // }
    return throwError(response);
    // throw response;
  }
}
