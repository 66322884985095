<br />
<br />
<br />
<br />
<br />
<div class="modal__content">
  <div class="error__content">
    <span class="error-icon"></span>
    <h2>Error 401 - Unauthorized</h2>
    <p>
      You are unauthorized to view this resource <br />
      Please contact system administrator
    </p>
    <p *ngIf="getAccess()"><a (click)="toHome()" class="btn">Go to Home</a></p>
    <p *ngIf="!getAccess()"><a (click)="onLogout()" class="btn">Logout</a></p>
  </div>
</div>
