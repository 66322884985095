import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppConfig } from 'src/global.config';

@Component({
  selector: 'app-notfound',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss'],
})
export class NotFound404Component implements OnInit {
  permissions: any;
  constructor(public router: Router, private ngxpermissions: NgxPermissionsService) {}
  ngOnInit() {}

  toHome() {
    this.permissions = this.ngxpermissions.getPermissions();
    (this.permissions['Reporting'] ? true : false)
      ? this.router.navigate(['/' + environment.reportingRoutePrefix])
      : this.router.navigate(['/' + environment.reflecxRoutePrefix]);
  }
}
