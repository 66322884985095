import { NavService } from '@reflecxshared/services/nav/nav.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from '../loader.service';
import { tap, catchError, finalize } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loaderService: LoaderService, private navService: NavService) {}

  public forceResetTotalRequest(): void {
    this.totalRequests = 0;
    if (environment.loaderStyle === 'main') {
      this.showMainLoader(true);
    } else if (environment.loaderStyle === 'top') {
      this.showTopLoader(true);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;
    this.totalRequests++;
    const isHeader = request.headers.get('hideLoader');
    if (environment.loaderStyle === 'main') {
      Boolean(isHeader) == true ? request.headers.delete('hideLoader') : this.showMainLoader(true);
    } else if (environment.loaderStyle === 'top') {
      this.showTopLoader(true);
    }
    return next.handle(request).pipe(
      tap((res) => {
        lastResponse = res;
        if (res instanceof HttpResponse) {
          this.decreaseRequests();
        }
      }),
      catchError((err) => {
        error = err;
        this.decreaseRequests();
        return throwError(err);
      }),
      finalize(() => {
        if (lastResponse.type === HttpEventType.Sent && !error) {
          this.decreaseRequests();
        }
      })
    );
  }

  private decreaseRequests() {
    this.totalRequests--;
    if (this.totalRequests <= 0) {
      this.showMainLoader(false);
      this.showTopLoader(false);
    }
  }

  private showMainLoader(show: boolean): void {
    this.loaderService.showMain = show;

    if (!show) {
      this.navService.removeOverlapClassFromMainLoader();
    }
  }
  private showTopLoader(show: boolean): void {
    this.loaderService.showTop = show;
  }
}
