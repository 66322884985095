import {
  Component,
  OnInit,
  Input,
  ContentChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
  Renderer2,
  AfterContentInit,
} from '@angular/core';
import { TableCellComponent } from '../table-cell/table-cell.component';

declare var $: any;

@Component({
  selector: 'cvp-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss'],
})
export class TableContainerComponent implements OnInit, AfterViewInit {
  @Input() get rows(): Array<any> {
    return this._rows;
  }
  set rows(_rows: Array<any>) {
    this._rows = _rows;
    this.initData();
  }

  @Input() striped: boolean = true;
  @Input() wider: boolean = false;
  @Input() tableClass: string = '';
  @Input() paginator: boolean = true;
  @Input() showSerialNo: boolean = false;
  @Input() extraOptions: boolean = false;
  @Input() highlightRows: number[] = [];
  @Input() cols: QueryList<TableCellComponent>;

  @Input() page: any = {
    currPage: 1,
    pageSize: 25,
    data: [],
    sortColumn: '',
    sortOrder: 'asc',
  };

  leftScroll: any;
  rightScroll: any;
  speed = 10;
  interval = 10;
  showFreezeHeader = false;
  topValue = 0;

  @ViewChild('scrollTable', { static: false }) scrollTable: ElementRef;
  @ViewChild('scrollTableHeader', { static: false })
  scrollTableHeader: ElementRef;
  @ViewChild('scrollLeft', { static: false }) scrollLeft: ElementRef;
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('freezeHeaderDiv', { static: false }) freezeHeaderDiv: ElementRef;

  @ContentChildren(TableCellComponent) columns: QueryList<TableCellComponent>;
  private _rows: any;
  public scrollbarOptionsX = {
    axis: 'x',
    autoHideScrollbar: true,
    advanced: { autoExpandHorizontalScroll: true, updateOnContentResize: true },
  };

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.initData();
  }

  ngAfterViewInit() {
    let width = 0;
    $('.main-table th.fixed-side').each((e: any, el: any) => {
      width += el.clientWidth;
    });
    if (this.extraOptions) {
      this.renderer.setStyle(this.scrollLeft.nativeElement, 'left', width + 'px');
    }
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (window.pageYOffset > 238) {
      this.showFreezeHeader = true;
      // this.topValue = window.pageYOffset - 238;
    } else {
      this.showFreezeHeader = false;
    }
  }

  mouseHoverLeft(event: any) {
    this.leftScroll = setInterval(() => {
      const fixHeaderLeft = this.freezeHeaderDiv.nativeElement.offsetLeft;
      const scrollLeft = this.scrollTable.nativeElement.scrollLeft;
      if (scrollLeft > 0) {
        this.scrollTable.nativeElement.scrollLeft = scrollLeft - this.speed;
        this.freezeHeaderDiv.nativeElement.style.left = fixHeaderLeft + this.speed + 'px';

        // this.scrollTableHeader.nativeElement.scrollLeft = scrollLeft - this.speed;
      }
    }, this.interval);
  }

  mouseHoverLeaveLeft() {
    clearInterval(this.leftScroll);
  }

  mouseHoverRight(event: any) {
    this.rightScroll = setInterval(() => {
      const fixHeaderLeft = this.freezeHeaderDiv.nativeElement.offsetLeft;
      const scrollLeft = this.scrollTable.nativeElement.scrollLeft;

      const maxX = this.scrollTable.nativeElement.scrollWidth - this.scrollTable.nativeElement.clientWidth;
      if (scrollLeft < maxX) {
        this.scrollTable.nativeElement.scrollLeft = scrollLeft + this.speed;
        this.freezeHeaderDiv.nativeElement.style.left = fixHeaderLeft - this.speed + 'px';
        // this.scrollTableHeader.nativeElement.scrollLeft = scrollLeft + this.speed;
      }
    }, this.interval);
  }

  mouseHoverLeaveRight() {
    clearInterval(this.rightScroll);
  }

  initData() {
    if (this.paginator) {
      this.page.data = this.rows.slice(
        (this.page.currPage - 1) * this.page.pageSize,
        this.page.pageSize * this.page.currPage
      );
    } else {
      this.page.data = [...this.rows];
    }
  }

  onChange(event: any) {
    this.page.pageSize = event;
    this.page.currPage = 1;
    this.initData();
  }

  onModelChange(event: any) {
    this.page.currPage = event.page;
    this.initData();
  }

  onScroll(event: any) {
    //  event.preventDefault();
  }
}

@Component({
  selector: 'cvp-table-container-admin',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss'],
})
export class TableContainerAdminComponent extends TableContainerComponent implements OnInit, AfterViewInit {
  @Input() cols: QueryList<TableCellComponent>;
  constructor(private _renderer: Renderer2) {
    super(_renderer);
  }
}
