import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { GridHeaderTopActionDirective } from '../header/header-top/search-action/grid-header-top-action.directive';
import { GridHeaderMenuDirective } from '../header/header-menu.directive';
import { GridBodyListRowDirective } from './body-list/list-row.directive';
import { GridHeaderTopDropdownDirective } from '../header/header-top/search-action/grid-header-top-search-dropdown.directive';
import { GridHeaderFilterDropdownDirective } from './header/header-filter/alphabetfilter.dropdown.directive';
import { DatatableFooterDirective } from './footer/footer.directive';
import { RDataTableBodyComponent } from './body/body.component';
import { RHeaderComponent } from './header/rheader.component';
import { BodyListComponent } from './body-list/body.list.component';
import { GridHeaderTitleDirective } from '../header/header-top/header-title.directive';
import { GridHeaderTitleTemplateDirective } from '../header/header-top/header-title-template.directive';

@Component({
  selector: 'ngx-grid-datatable',
  template: `
    <grid-header
      [view]="view"
      [ActionTemplate]="GridActionTemplate"
      [HeaderMenuTemplate]="GridHeaderMenuTemplate"
      [GridHeaderTopDropDownTemplate]="GridHeaderTopDropdownTemplate"
      [GridHeaderTitleTemplateDirective]="GridHeaderTitleTemplateDirective"
      [topBar]="topBar"
      (onSearch)="onSearchInner($event)"
      [searchtext]="searchtext"
      (onSearchDropDownChange)="onSearchDropDownChange.emit($event)"
      (onSettingClick)="onSettingClick.emit($event)"
      (onChangeViewHeader)="view = $event; onChangeView.emit($event)"
      (selectCustomFilter)="selectCustomFilterInner($event)"
    ></grid-header>

    <div>
      <div>
        <div class="datatable-mainbody-wrapper" *ngIf="view == 'gridView'">
          <p class="records-found" *ngIf="topBar.showRowLength">
            <span *ngIf="!allSelectBtn"
              >{{ rows.length }} {{ 'Records found' | translate }} {{ topBar.selectRow }} {{ 'Selected' | translate }}
            </span>
            <a style="color: blue;" *ngIf="rows.length < count && selectAll" (click)="onAllSelectFunc.emit($event)"
              >({{ !allSelectBtn ? 'Select all' : 'Deselect all' }} {{ count }} records )</a
            >
          </p>
          <rdatatable-header-filter
            [columns]="topBar.alphabetFilterColumns"
            [alphabetBar]="alphabetBar"
            [topBar]="topBar"
            [dropdownTemplate]="GridHeaderFilterDropdownTemplate"
            (onAlphabetAndColumnChange)="onAlphabetChangeInner($event)"
            [selectedAlphabet]="selectedAlphabet"
          >
          </rdatatable-header-filter>
          <ng-container *ngIf="rows.length > 0">
            <rgrid-header
              *ngIf="headerHeight"
              [sorts]="sorts"
              [longestContent]="longestContent"
              [sortType]="sortType"
              [scrollbarH]="scrollbarH"
              [innerWidth]="_innerWidth"
              [offsetX]="_offsetX | async"
              [dealsWithGroup]="groupedRows"
              [columns]="_internalColumns"
              [headerHeight]="headerHeight"
              [reorderable]="reorderable"
              [targetMarkerTemplate]="targetMarkerTemplate"
              [sortAscendingIcon]="cssClasses.sortAscending"
              [sortDescendingIcon]="cssClasses.sortDescending"
              [allRowsSelected]="allRowsSelected"
              [selectionType]="selectionType"
              (sort)="onColumnSort($event)"
              (resize)="onColumnResize($event)"
              (reorder)="onColumnReorder($event)"
              (select)="onHeaderSelect($event)"
              (columnContextmenu)="onColumnContextmenu($event)"
            >
            </rgrid-header>
          </ng-container>
          <rdatatable-body
            *ngIf="rows.length > 0"
            [groupRowsBy]="groupRowsBy"
            [longestContent]="longestContent"
            [groupedRows]="groupedRows"
            [rows]="_internalRows"
            [groupExpansionDefault]="groupExpansionDefault"
            [scrollbarV]="scrollbarV"
            [scrollbarH]="scrollbarH"
            [virtualization]="virtualization"
            [loadingIndicator]="loadingIndicator"
            [externalPaging]="externalPaging"
            [rowHeight]="rowHeight"
            [rowCount]="rowCount"
            [offset]="offset"
            [trackByProp]="trackByProp"
            [columns]="_internalColumns"
            [pageSize]="pageSize"
            [offsetX]="_offsetX | async"
            [rowDetail]="rowDetail"
            [groupHeader]="groupHeader"
            [selected]="selected"
            [innerWidth]="_innerWidth"
            [bodyHeight]="bodyHeight"
            [selectionType]="selectionType"
            [emptyMessage]="messages.emptyMessage"
            [rowIdentity]="rowIdentity"
            [rowClass]="rowClass"
            [selectCheck]="selectCheck"
            [displayCheck]="displayCheck"
            [summaryRow]="summaryRow"
            [summaryHeight]="summaryHeight"
            [summaryPosition]="summaryPosition"
            (page)="onBodyPage($event)"
            (activate)="activate.emit($event)"
            (rowContextmenu)="onRowContextmenu($event)"
            (select)="onBodySelect($event)"
            (scroll)="onBodyScroll($event)"
            (treeAction)="onTreeAction($event)"
          >
          </rdatatable-body>
        </div>
        <div *ngIf="view == 'listView'">
          <rbody-list-datatable-body
            *ngIf="rows.length > 0"
            [groupRowsBy]="groupRowsBy"
            [groupedRows]="groupedRows"
            [rows]="_internalRows"
            [groupExpansionDefault]="groupExpansionDefault"
            [scrollbarV]="scrollbarV"
            [rowTemplate]="GridBodyListRowTemplate"
            [scrollbarH]="false"
            [virtualization]="virtualization"
            [loadingIndicator]="loadingIndicator"
            [externalPaging]="externalPaging"
            [rowHeight]="rowHeight"
            [rowCount]="rowCount"
            [offset]="offset"
            [trackByProp]="trackByProp"
            [columns]="_internalColumns"
            [pageSize]="pageSize"
            [offsetX]="_offsetX | async"
            [rowDetail]="rowDetail"
            [groupHeader]="groupHeader"
            [selected]="selected"
            [innerWidth]="_innerWidth"
            [bodyHeight]="bodyHeight"
            [selectionType]="selectionType"
            [emptyMessage]="messages.emptyMessage"
            [rowIdentity]="rowIdentity"
            [rowClass]="rowClass"
            [selectCheck]="selectCheck"
            [displayCheck]="displayCheck"
            [summaryRow]="summaryRow"
            [summaryHeight]="summaryHeight"
            [summaryPosition]="summaryPosition"
            (page)="onBodyPage($event)"
            (activate)="activate.emit($event)"
            (rowContextmenu)="onRowContextmenu($event)"
            (select)="onBodySelect($event)"
            (scroll)="onBodyScroll($event)"
            (treeAction)="onTreeAction($event)"
          >
          </rbody-list-datatable-body>
        </div>
      </div>
    </div>
    <div *ngIf="rows.length == 0" class="norecords-block">{{ 'No records Found' | translate }}</div>

    <div *ngIf="rows.length > 0">
      <rdatatable-footer
        *ngIf="footerHeight"
        [rowCount]="rowCount"
        [pageSize]="pageSize"
        [offset]="offset"
        [footerHeight]="footerHeight"
        [footerTemplate]="footer"
        [totalMessage]="messages.totalMessage"
        [pagerLeftArrowIcon]="cssClasses.pagerLeftArrow"
        [pagerRightArrowIcon]="cssClasses.pagerRightArrow"
        [pagerPreviousIcon]="cssClasses.pagerPrevious"
        [selectedCount]="selected.length"
        [selectedMessage]="!!selectionType && messages.selectedMessage"
        [pagerNextIcon]="cssClasses.pagerNext"
        (page)="onFooterPage($event)"
      >
      </rdatatable-footer>
    </div>
  `,
  host: {
    class: 'ngx-datatable',
  },
})
// tslint:disable-next-line:component-class-suffix
export class Datatable extends DatatableComponent implements OnInit, OnChanges {
  longestContent = {};
  @Input() view = 'gridView';
  @Input() columnMapping: any;
  @Input() topBar: any = {};
  @Input() alphabetBar: boolean;
  @Input() searchtext: string;
  @Input() selectedAlphabet: string;
  @Output() onAlphabetAndColumnChange = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onPagination = new EventEmitter();
  @Output() selectCustomFilter = new EventEmitter();
  @Output() onChangeView = new EventEmitter<String>();

  @Output() onSearchDropDownChange = new EventEmitter();
  @Output() onSettingClick = new EventEmitter();
  @Output() onAllSelectFunc = new EventEmitter();
  @Input() selectAll: any;
  @Input() allSelectBtn: any;

  @Input() cssClasses: any = {
    sortAscending: 'fas fa-sort-up',
    sortDescending: 'fas fa-sort-down',
    pagerLeftArrow: 'datatable-icon-left',
    pagerRightArrow: 'datatable-icon-right',
    pagerPrevious: 'datatable-icon-prev',
    pagerNext: 'datatable-icon-skip',
  };

  @ContentChild(GridHeaderTopDropdownDirective)
  GridHeaderTopDropdownTemplate: GridHeaderTopDropdownDirective;

  @ContentChild(GridHeaderTopActionDirective)
  GridActionTemplate: GridHeaderTopActionDirective;

  @ContentChild(GridHeaderMenuDirective)
  GridHeaderMenuTemplate: GridHeaderMenuDirective;

  @ContentChild(GridBodyListRowDirective)
  GridBodyListRowTemplate: GridBodyListRowDirective;

  @ContentChild(GridHeaderFilterDropdownDirective)
  GridHeaderFilterDropdownTemplate: GridHeaderFilterDropdownDirective;

  @ContentChild(GridHeaderTitleDirective)
  GridHeaderTitleTemplateDirective: GridHeaderTitleTemplateDirective;

  @ViewChild(RDataTableBodyComponent)
  bodyComponent: RDataTableBodyComponent;

  @ViewChild(RHeaderComponent)
  headerComponent: RHeaderComponent;

  @ViewChild(BodyListComponent)
  bodyComponentList: BodyListComponent;

  // constructor(
  //   @SkipSelf() @Inject(ScrollbarHelper) scrollbar: ScrollbarHelper,
  //   @SkipSelf() @Inject(DimensionsHelper) dimensionsHelper: DimensionsHelper,
  //   @Inject(ChangeDetectorRef) cd: ChangeDetectorRef,
  //   @Inject(ElementRef) element: ElementRef,
  //   @Inject(KeyValueDiffers) differs: KeyValueDiffers,
  //   @Inject(ColumnChangesService) columnChangesService: ColumnChangesService
  // ) {
  //   super(scrollbar, dimensionsHelper, cd, element, differs, columnChangesService);
  // }

  ngOnInit(): void {
    // need to call this immediatly to size
    // if the table is hidden the visibility
    // listener will invoke this itself upon show
    super.ngOnInit();
    this.calcLongestContent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rows']) {
      this.calcLongestContent();
    }
  }

  calcLongestContent() {
    this.longestContent = {};
    this._rows.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (row[key] !== null) {
          if (typeof row[key] === 'object') {
            if (this.longestContent[key] === undefined) {
              this.longestContent[key] = {};
            }
            Object.keys(row[key]).forEach((nkey) => {
              if (row[key][nkey] !== null) {
                if (this.longestContent[key][nkey] === undefined) {
                  const d = this.getNameByValueMapping(nkey);
                  if (d) {
                    if (nkey.length < d.length) {
                      this.longestContent[key][nkey] = d + '';
                    } else {
                      this.longestContent[key][nkey] = nkey + '';
                    }
                  } else {
                    this.longestContent[key][nkey] = nkey + '';
                  }
                }
                if (this.longestContent[key][nkey].length < (row[key][nkey] + '').length) {
                  const d = this.getNameByValueMapping(nkey);
                  if (d) {
                    if (this.longestContent[key][nkey].length < d.length) {
                      this.longestContent[key][nkey] = d + '';
                    } else {
                      this.longestContent[key][nkey] = row[key][nkey] + '';
                    }
                  } else {
                    this.longestContent[key][nkey] = row[key][nkey] + '';
                  }
                }
              }
            });
          }
          if (this.longestContent[key] === undefined) {
            const d = this.getNameByValueMapping(key);
            if (d) {
              if (key.length < d.length) {
                this.longestContent[key] = d + '';
              } else {
                this.longestContent[key] = key + '';
              }
            } else {
              this.longestContent[key] = key + '';
            }
          }
          if (this.longestContent[key].length < (row[key] + '').length) {
            const d = this.getNameByValueMapping(key);
            if (d) {
              if ((row[key] + '').length < d.length) {
                this.longestContent[key] = d + '';
              } else {
                this.longestContent[key] = row[key] + '';
              }
            } else {
              this.longestContent[key] = row[key] + '';
            }
          }
        }
      });
    });
  }

  getNameByValueMapping(key: any) {
    if (this.columnMapping) {
      const val = this.columnMapping.filter((cm: any) => {
        return cm['value'] === key;
      });
      return val && val.length > 0 ? val[0]['name'] : '';
    } else {
      return '';
    }
  }

  onAlphabetChangeInner(data: any) {
    this.onAlphabetAndColumnChange.emit(data);
  }
  onSearchInner(data: any) {
    this.onSearch.emit(data);
  }
  selectCustomFilterInner(data: any) {
    this.selectCustomFilter.emit(data);
  }
  onFooterPage(page: any) {
    this.onPagination.emit(page);
  }
}
