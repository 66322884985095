/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'app/core/logger.service';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```
 * export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */

import { HttpClient } from '@angular/common/http';
import { Identifiers } from '@app/shared/services/app.config.type';
import { environment } from '@env/environment';
import { isDate } from 'lodash';
import { AuthenticationService } from './authentication/authentication.service';

/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */
export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}
const TypeVals = (key: string) => {
  const mapping = {
    boolean: 'bool',
    object: '@obj',
  };
  return mapping[key] || key;
};
const Types = (key: string) => {
  const mapping = {
    boolean: 'bool',
    object: 'object',
  };
  return mapping[key] || key;
};
export interface ServerLog {
  Level: number;
  Message: string;
  Data: Array<any>;
}

/**
 * Log output handler function.
 */
export type LogOutput = (source: string, level: LogLevel, ...objects: any[]) => void;

export class Logger {
  static previousErrors: string[] = [];
  static httpClient: HttpClient = null;
  static authService: any = null;
  static appConfig: any = null;
  static fire = false;
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static level = LogLevel.Debug;

  /**
   * Additional log outputs.
   */
  static outputs: LogOutput[] = [];

  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  static enableProductionMode() {
    Logger.level = LogLevel.Warning;
  }

  constructor(private source?: string) {}

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug(...objects: any[]) {
    this.log(console.log, LogLevel.Debug, objects);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info(...objects: any[]) {
    this.log(console.info, LogLevel.Info, objects);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn(...objects: any[]) {
    this.log(console.warn, LogLevel.Warning, objects);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error(...objects: any[]) {
    this.buildAndSend(objects);
    this.log(console.error, LogLevel.Error, objects);
  }

  private log(func: Function, level: LogLevel, objects: any[]) {
    if (level <= Logger.level) {
      const log = this.source ? ['[' + this.source + ']'].concat(objects) : objects;
      func.apply(console, log);
      Logger.outputs.forEach((output) => output.apply(output, [this.source, level].concat(objects)));
    }
  }
  private getTypeAndBinding(value: any) {
    let type: any = typeof value;
    let binding = '';
    if (isDate(value)) {
      type = 'date';
    } else if (Array.isArray(value)) {
      value = Object.assign({}, value);
    } else if (type === 'number') {
      type = 'string';
      value = String(value);
    }
    binding = `${Types(type)}:{${TypeVals(type)}}`;
    return { type, value, binding };
  }
  private buildAndSend(objects: any[], level = 4): any {
    if (!Logger.httpClient) {
      return;
    }
    const Data: any = [];
    const message = this.source ? '[' + this.source + '] ' : 'Logger';
    let MessageVariables = message;
    if (Array.isArray(objects)) {
      objects.forEach((obj, index) => {
        const end = index >= objects.length ? '' : ', ';
        const { type, value, binding } = this.getTypeAndBinding(obj);
        Data.push(value);
        MessageVariables += binding + end;
      });
      const payload: ServerLog = {
        Data: Data,
        Level: level,
        Message: MessageVariables,
      };
      this.send(payload);
      return payload;
    }
    return null;
  }
  private async send(payload: ServerLog) {
    if (!environment.production) {
      return;
    }
    const token = Logger?.authService?.getToken();
    const fire = Logger?.httpClient['appConfig']?.getConfig(Identifiers.FrontendLogger, 'fire');
    Logger.fire = fire;
    if (Logger.fire && token) {
      Logger.httpClient.disableLoader().skipErrorHandler().post('/log', payload).subscribe();
    }
  }
  static init() {
    const log = new Logger('Console');
    window.onerror = function (message, source, lineno, colno, error) {
      console.log(message, source, lineno, colno, error);
      if (error) message = error.stack;
      log.error(message, source, lineno, colno, error);
    };
  }
}
