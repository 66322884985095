import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
} from '@angular/core';
import { DataTablePagerComponent } from '@swimlane/ngx-datatable';
import { FooterPageSizeDropdownDirective } from './footer-pagesize-dropdown.directive';

@Component({
  selector: 'rdatatable-pager',
  templateUrl: './rdatatable-pager.html',
  host: {
    class: 'datatable-pager',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RDataTablePagerCompnent extends DataTablePagerComponent {
  @Input()
  set size(val: number) {
    this._size = val;
    this.pages = this.calcPages();
  }

  get size(): number {
    return this._size;
  }

  @Input()
  set count(val: number) {
    this._count = val;
    this.pages = this.calcPages();
  }

  get count(): number {
    return this._count;
  }

  @Input()
  set page(val: number) {
    this._page = val;
    this.pages = this.calcPages();
  }

  get page(): number {
    return this._page;
  }

  get totalPages(): number {
    const count = this.size < 1 ? 1 : Math.ceil(this.count / this.size);
    return Math.max(count || 0, 1);
  }

  @Input()
  set visiblePagesCount(val: number) {
    this._visiblePagesCount = val;
    this.pages = this.calcPages();
  }

  get visiblePagesCount(): number {
    return this._visiblePagesCount;
  }
  @Input() pagerLeftArrowIcon = 'icon-left';
  @Input() pagerRightArrowIcon = 'icon-right';
  @Input() pagerPreviousIcon = 'icon-prev';
  @Input() pagerNextIcon = 'icon-skip';
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() onPageSizeChange: EventEmitter<any> = new EventEmitter();

  @ContentChild(FooterPageSizeDropdownDirective, { read: TemplateRef })
  FooterPageSizeDropdownTemplate: FooterPageSizeDropdownDirective;

  public _visiblePagesCount = 3;
  public readonly pageLimitOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
  ];

  _count = 0;
  _page = 1;
  _size = 0;
  pages: any;

  onLimitChange(value: any) {
    this._size = value;
    this.onPageSizeChange.emit(value);
  }

  canPrevious(): boolean {
    return this.page > 1;
  }

  canNext(): boolean {
    return this.page < this.totalPages;
  }

  prevPage(): void {
    this.selectPage(this.page - 1);
  }

  nextPage(): void {
    this.selectPage(this.page + 1);
  }

  selectPage(page: number): void {
    if (page > 0 && page <= this.totalPages && page !== this.page) {
      this.page = page;

      this.modelChange.emit({
        page,
      });
    }
  }

  calcPages(page?: number): any[] {
    const pages = [];
    let startPage = 1;
    let endPage = this.totalPages;
    const maxSize = this.visiblePagesCount;
    const isMaxSized = maxSize < this.totalPages;

    page = page || this.page;

    if (isMaxSized) {
      startPage = page - Math.floor(maxSize / 2);
      endPage = page + Math.floor(maxSize / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + maxSize - 1, this.totalPages);
      } else if (endPage > this.totalPages) {
        startPage = Math.max(this.totalPages - maxSize + 1, 1);
        endPage = this.totalPages;
      }
    }

    for (let num = startPage; num <= endPage; num++) {
      pages.push({
        number: num,
        text: <string>(<any>num),
      });
    }

    return pages;
  }
}
