import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationHelperService {
  constructor(public translateService: TranslateService) {}
  public GetCategoryTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('voc_category_' + key.trim().toLowerCase());
  }

  public GetSentimentTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('voc_sentiment_' + key.trim().toLowerCase());
  }
  public GetSentimentTrendWidgetTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('sentiment_trend_' + key.trim().toLowerCase());
  }

  public GetSurveyTrendWidgetTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('survey_trend_' + key.trim().toLowerCase());
  }

  public GetAppealStatus(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('appeal_status_' + key.trim().toLowerCase());
  }
  public GetAppealRemovalReason(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('appeal_removal_reason_' + key.trim().toLowerCase());
  }

  public GetHotalertStatusTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('hotalert_status_' + key.trim().toLowerCase());
  }
  public GetResolutionStatusTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('hotalert_resolution_status_' + key.trim().toLowerCase());
  }
  public GetLifeCycleStatusTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('survey_status_' + key.trim().toLowerCase());
  }
  public GetCustomerSearchStatusTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('survey_status_' + key.trim().toLowerCase());
  }
  public GetHotalertResolutionTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('hotalert_resolution_' + key.trim().toLowerCase());
  }
  public GetHotalertCategoryTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('hotalert_category_' + key.trim().toLowerCase());
  }
  public GetHotalertEscalationTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('hotalert_escalation_' + key.trim().toLowerCase());
  }

  public GetSurveyKpiNameTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('survey_kpi_name_' + key.trim().toLowerCase());
  }

  public GetKpiNameWithDefaultTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
      //return this.translateService.instant('survey_kpi_name_default');
    }
    return this.translateService.instant('survey_kpi_name_' + key.trim().toLowerCase());
  }

  public GetSurveyKpiNameCJTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('survey_kpi_name_cj_' + key.trim().toLowerCase());
  }

  public GetCustomerJourneyLoyalitiesTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('customer_journey_loyalities_label_' + key.trim().toLowerCase());
  }

  public GetCustomerJourneyNPSTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('customer_journey_nps_label_' + key.trim().toLowerCase());
  }

  public GetActionPlanStatusTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('action_plan_status_' + key.trim().toLowerCase());
  }

  public GetActionPlanHistoryTypeTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('action_plan_history_type_' + key.trim().toLowerCase());
  }

  public GetActionPlanUserTypeTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant(key.trim().toLowerCase());
  }

  public GetEventTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }

    return this.translateService.instant('event_' + key.trim().toLowerCase() + '.title');
  }

  public GetClientEventTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }

    return this.translateService.instant('event_client' + key.trim().toLowerCase() + '.title');
  }

  public GetCustomerEmployeeTypesTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    return this.translateService.instant('customer_employee_types_' + key.trim().toLowerCase());
  }

  public GetContactReportStatusTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    if (key) {
      return this.translateService.instant('contact_report_status_' + key.trim().toLowerCase());
    }
  }

  public GetBounceBackReasonTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    if (key) {
      return this.translateService.instant('survey_bounceback_reason_' + key.trim().toLowerCase());
    }
  }

  public GetFrftRootCausesTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    // return this.translateService.instant('frft_root_causes_' + key.trim().toLowerCase());
    return this.translateService.instant(key.trim());
  }

  public GetFrftModelQ6noTranslation(key: string): string {
    if (key === undefined || key === null) {
      return key;
    }
    // return this.translateService.instant('frft_root_causes_' + key.trim().toLowerCase());
    return this.translateService.instant(key.trim());
  }

  public GetFormattedDate(value: any): string {
    if (value === undefined || value === null) {
      return value;
    }
    const date = new Date(value);
    const langugage = localStorage.getItem('language');
    return (
      date.getDate().toLocaleString(langugage) +
      ' ' +
      date.toLocaleString(langugage, { month: 'long' }) +
      ', ' +
      date.toLocaleString(langugage, { year: 'numeric' })
    );
  }
  public GetPrimeNgCalenderTranslation(dateFormat: string = 'dd/mm/yy'): any {
    const format = {
      firstDayOfWeek: 0,
      dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: 'Today',
      clear: 'Clear',
      dateFormat: dateFormat,
      weekHeader: 'Wk',
    };

    for (let i = 0; i < format.dayNames.length; i++) {
      format.dayNames[i] = this.translateService.instant(format.dayNames[i]);
    }
    for (let i = 0; i < format.dayNamesShort.length; i++) {
      format.dayNamesShort[i] = this.translateService.instant(format.dayNamesShort[i]);
    }
    for (let i = 0; i < format.dayNamesMin.length; i++) {
      format.dayNamesMin[i] = this.translateService.instant(format.dayNamesMin[i]);
    }
    for (let i = 0; i < format.monthNames.length; i++) {
      format.monthNames[i] = this.translateService.instant(format.monthNames[i]);
    }
    for (let i = 0; i < format.monthNamesShort.length; i++) {
      format.monthNamesShort[i] = this.translateService.instant(format.monthNamesShort[i]);
    }
    return format;
  }
}
