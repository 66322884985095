import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { environment } from '@env/environment';
import { interval, Observable, take } from 'rxjs';
import { Logger } from './logger.service';
const log = new Logger('GlobalErrorHandler');

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private previousErrors: string[] = [];
  private previousErrorsCount: any = {};

  interval: Observable<number> | null = null;
  constructor(private zone: NgZone, private injector: Injector) {
    this.interval = interval(50000);
    this.interval.subscribe((number: number) => {
      this.previousErrors = [];
    });
  }

  handleError(errorInfo: Error | any) {
    const currentError = errorInfo.toString();
    const { message, stack } = errorInfo;

    if (!environment.production) {
      log.error(message, stack);
      return;
    }

    if (this.previousErrorsCount[currentError] == undefined) {
      this.previousErrorsCount[currentError] = 0;
    }
    this.previousErrorsCount[currentError] += 1;

    if (this.previousErrors.indexOf(currentError) === -1) {
      this.previousErrors.push(currentError);
      const currentURL = `Current URL: ${location.href}`;
      const count = `Error Count: ${this.previousErrorsCount[currentError]}`;
      const time = `Time: ${new Date().toString()}`;

      log.error(message, stack, currentURL, count, time);

      this.zone.run(() => {
        console.log(errorInfo);
      });
    }

    // console.error(message, stack);
    // Check if it's an error from an HTTP response
    // if (!(errorInfo instanceof HttpErrorResponse)) {
    // if (errorInfo.name === 'TypeError') {
    //   console.log(errorInfo);
    // errorInfo = errorInfo.rejection; // get the error object
    // } else {
    //   log.error(message, stack);
    // }
    // console.error('Error from global error handler', error);
  }
}
