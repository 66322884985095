import { Injectable, OnChanges, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Identifiers } from '@app/shared/services/app.config.type';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { isEmpty } from 'lodash';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

interface Nav {
  text: string;
  icon: string;
  isActive: boolean;
  visible?: boolean;
  link: string;
  tooltip?: string;
  click?: Function;
  permission?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  showTopNav = false;
  showRightButtons = false;
  showLanguageDropdown = false;
  showSideNav = true;
  topNav: MenuItem[] = [];
  navnavBar: any = [];
  activeTab: MenuItem;
  sideNav: any = [];
  ReflecxAppName = 'Reflecx';
  ClearviewAppName = 'Reflecx';
  disable = false;
  questionEditState = false;
  disableLangDropDown = false;
  surveyVersionArray: any = [];
  currentSurveyId: any;
  selectCurrentVersion: any;
  surveyType: any = 1;
  IsDynamicServices: boolean = false;
  theme: string = 'default';
  coachReportPrintObj = {
    ID: '',
    Region: '',
    Dealer: '',
    ContactKPI: '',
    ContactFacilitator: '',
    ContactAssignee: '',
    ContactDate: '',
    ActionPlans: '',
    Status: '',
    isPrintClick: false,
  };
  coachActionPrintObj = {
    DisplayID: '',
    Region: '',
    Dealer: '',
    ContactKPI: '',
    EventType: '',
    AssignedTo: '',
    StartDate: '',
    ApprovalDate: '',
    StartScore: '',
    CurrentScore: '',
    TargetScore: '',
    Percentage: '',
    Status: '',
    TargetDate: '',
    isPrintClick: false,
  };
  landingPageForPlatform: Array<string> = ['apple', 'alder'];
  currentSurveyLanguages: any;
  currentSurveySelectedLanguage: any;
  public observeLangChange = new BehaviorSubject(false);
  // private themeDimension:Array<{type:string,Width:number,Height:number,aspectRatio:number}> =[]
  private themeDimension: any = {};
  private defaultDimension: any = [
    {
      type: 'LogoPath',
      Width: 100,
      Height: 50,
      aspectRatio: 2,
    },
    {
      type: 'HeaderPath',
      Width: 920,
      Height: 285,
      aspectRatio: 3.23,
    },
    {
      type: 'FaviconPath',
      Width: 16,
      Height: 16,
      aspectRatio: 1,
    },
    {
      type: 'OptionsPath',
      Width: 46,
      Height: 46,
      aspectRatio: 1,
    },
    {
      type: 'DefaultTemplate',
      Width: 650,
      Height: 352,
      aspectRatio: 1.85,
    },
  ];
  surveyStatus: string;
  constructor(
    private titleService: Title,
    private translateService: TranslateService,
    private appConfig: AppConfigurationService
  ) {}

  public setTitle(newTitle: string) {
    this.titleService.setTitle(`${this.ReflecxAppName} | ${newTitle}`);
  }

  public setV(val: boolean) {
    if (this.navnavBar.length === 2) {
      this.navnavBar[1].disable = val;
    }
  }

  public setTitleClearView(newTitle: string) {
    this.titleService.setTitle(`${this.ClearviewAppName} | ${newTitle}`);
  }
  // public setNavigation(type: string) {
  //   switch (type) {
  //     case 'Transmission': {
  //       this.topNav[0].isActive = false;
  //       this.topNav[1].isActive = false;
  //       this.topNav[2].isActive = false;
  //       this.topNav[3].isActive = true;
  //       break;
  //     }
  //     case 'TemplateEditor': {
  //       this.topNav[0].isActive = false;
  //       this.topNav[1].isActive = false;
  //       this.topNav[2].isActive = true;
  //       this.topNav[3].isActive = false;
  //       break;
  //     }
  //     case 'Designer': {
  //       this.topNav[0].isActive = false;
  //       this.topNav[1].isActive = true;
  //       this.topNav[2].isActive = false;
  //       this.topNav[3].isActive = false;
  //       break;
  //     }
  //   }
  // }
  public getThemeImageDimension(
    key: string,
    isTemplate: boolean = false
  ): { Width: number; Height: number; aspectRatio: number } {
    if (!isEmpty(this.themeDimension) && this.themeDimension[this.theme.toLowerCase()]) {
      if (isTemplate) {
        let isParticularTemplate = this.themeDimension[this.theme.toLowerCase()].find((res: any) => {
          return res.type == key.split('.')[1];
        });
        if (!isParticularTemplate) {
          isParticularTemplate = this.themeDimension[this.theme.toLowerCase()].find((res: any) => {
            return res.type == key.split('.')[0];
          });
        }
        return isParticularTemplate;
      }
      return this.themeDimension[this.theme.toLowerCase()].find((res: any) => {
        return res.type == key;
      });
    } else {
      // console.log('Client configuration of theme dimension is missing');
      return this.defaultDimension.find((res: any) => {
        return res.type == key;
      });
    }
  }
  public getConfiguration(): void {
    let dimension: any = this.appConfig.getByIdentifier(Identifiers.ThemeDimension);
    if (dimension) {
      this.themeDimension = dimension['Fields'];
    }
  }

  public addOverlapClassToMainLoader() {
    document.querySelectorAll('div.main-loader').forEach((x: Element) => {
      x.classList.add('loader--index');
    });
  }

  public removeOverlapClassFromMainLoader() {
    document.querySelectorAll('div.main-loader').forEach((x: Element) => {
      x.classList.remove('loader--index');
    });
  }

  public setCurSurLanguages(surveyId: string, languages: any[], user: any) {
    this.disableLangDropDown = false;
    if (languages && languages.length > 1) {
      this.showLanguageDropdown = true;
    } else {
      this.showLanguageDropdown = false;
    }
    const userDefaultLang = user.DefaultLanguage;
    // if (surveyId != this.currentSurveyId || !this.currentSurveySelectedLanguage) {
    this.currentSurveyLanguages = languages;
    if (!this.currentSurveySelectedLanguage) {
      const userLang = languages.find((x) => x.Code == userDefaultLang);
      if (userLang) {
        this.currentSurveySelectedLanguage = userLang.Code;
      } else {
        this.currentSurveySelectedLanguage = languages[0].Code;
      }
    }
    // }
  }
  public getCurSurSelLanguage(defaultValue: any) {
    let langCode = defaultValue;
    if (this.currentSurveySelectedLanguage) {
      langCode = this.currentSurveySelectedLanguage;
    }
    return langCode;
  }
  public getImageURL(path: string) {
    if (!path || path.includes('data:image') || path.includes('https://') || path.includes('http://')) {
      return path;
    }
    path = this.getUploadPath() + path;
    return path;
  }
  public getUploadPath() {
    let apiUrl = environment.apiUrl;
    if (environment.apiUrl == '') {
      apiUrl = 'http://localhost:32725';
    }
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    return apiUrl + '/' + authData.ClientInfo.BaseFolder + '/';
  }

  public setTextImageURL(input: string, addOrRemoveEnvUrl: string) {
    const container = document.createElement('div');
    container.innerHTML = input;
    container.querySelectorAll('a').forEach((link) => {
      let href = link.getAttribute('href');
      if (href) {
        if (addOrRemoveEnvUrl == 'add') {
          if (!href.startsWith('http')) {
            href = this.getUploadPath() + href;
          }
        }
        if (addOrRemoveEnvUrl == 'remove') {
          href = href.replace(this.getUploadPath(), '');
        }
        link.setAttribute('href', href);
      }
    });
    container.querySelectorAll('img').forEach((img) => {
      let src = img.getAttribute('src');
      if (addOrRemoveEnvUrl == 'add') {
        if (!src.startsWith('http')) {
          src = this.getUploadPath() + src;
        }
      }
      if (addOrRemoveEnvUrl == 'remove') {
        src = src.replace(this.getUploadPath(), '');
      }
      img.setAttribute('src', src);
    });
    return container.innerHTML;
  }

  public hideQuillActionButton(): void {
    const qlAction = document.getElementsByClassName('ql-action');
    if (qlAction.length !== 0) {
      for (let i = 0; i < qlAction.length; i++) {
        qlAction[i].classList.add('ql-tooltip-hidden');
      }
    }
  }
  public quillPreviewLink(event: any): void {
    const path: Array<any> = event.path || event.composedPath();
    const anchors = path.find((anchor: any) => {
      return anchor.tagName == 'A' && anchor.getAttribute('quill-link-listener');
    });
    if (anchors) {
      event.preventDefault();
      const qlPreview: any = document.getElementsByClassName('ql-preview');
      if (qlPreview.length !== 0) {
        const href = anchors.getAttribute('data-href') || anchors.getAttribute('href');
        for (let i = 0; i < qlPreview.length; i++) {
          qlPreview[i].innerHTML = href;
          qlPreview[i].href = href;
        }
      }
    }
  }
}
