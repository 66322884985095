import { Action } from '@ngrx/store';
import { CVPActions } from '../actions/';

const ActionTypes = CVPActions.MainDashBoardActions.ActionTypes;

const widgets = localStorage.getItem('widgets') ? localStorage.getItem('widgets') : '[]';
export const initialState: Array<any> = JSON.parse(widgets);
export function hotAlertWidgetReducer(state: Array<any> = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.ChangeState:
      return action['payload'];
    default:
      return state;
  }
}
