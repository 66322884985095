import { Injectable } from '@angular/core';
import { NotificationsService } from './notifications.service';
import { Router } from '@angular/router';
import { RealTimeNotificationService } from './realTimeNotification/real-time-notification.service';
import { SignalrService } from '@app/core/signalr.service';
import { NgxPermissionsService, NgxPermissionsObject } from 'ngx-permissions';
import { ToastService } from './toast/toast.service';
import { Notification } from '@app/core/types/notifications';
import { Identifiers } from './app.config.type';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core';
import { LoaderService } from '@app/core/loader.service';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceCleaner {
  permissions: NgxPermissionsObject;
  constructor(
    private notificationService: NotificationsService,
    private router: Router,
    public toastService: ToastService,
    public realTimeNotificationService: RealTimeNotificationService,
    private signalRService: SignalrService,
    public ngxPermission: NgxPermissionsService,
    private loaderService: LoaderService,
    private authService: AuthenticationService
  ) {
    this.permissions = ngxPermission.getPermissions();
  }
  public cleanDataAndLogout() {
    // load notifications data from index db to send it to server
    let notification: Notification[] = [];
    this.notificationService.getAllRecoredsAsync().subscribe((record) => {
      // tslint:disable-next-line: max-line-length
      const isPortalTrafficDataPushAllowed: Boolean = this.permissions[Identifiers.PortalTrafficDataPush]
        ? true
        : false;
      if (isPortalTrafficDataPushAllowed) {
        const appNotifyId: any = localStorage.getItem('appNotifyId');
        notification = record.filter((rec: any) => {
          return (
            rec.isRead !== true ||
            (rec.isRead === true &&
              rec.appNotifyId === appNotifyId &&
              rec.monitoringType === this.notificationService.routingType &&
              rec.duration === null)
          );
        });

        for (let i = 0; i < notification.length; i++) {
          if (this.notificationService.IsRoutingUpdateRecord(notification[i], true)) {
            notification[i] = this.notificationService.SetUpdateNotificationValues(notification[i]);
          }
        }

        // tslint:disable-next-line:max-line-length
        const notifyData = { message: 'Logout User', pageName: 'Logout', module: 'Logout', dateTimeExited: new Date() };
        notification.push(this.notificationService.GetNotificationForEvent(notifyData, true));
        // tslint:disable-next-line:max-line-length

        if (environment.signalRConfigured === false || localStorage.getItem('IsApiNotification') === 'true') {
          this.loaderService.showMain = true;
          this.realTimeNotificationService.SendApiNotifications(notification, false).subscribe(
            () => {
              this.SetLogoutEvent();
            },
            (error) => {
              this.SetLogoutEvent();
              this.loaderService.showMain = false;
            }
          );
        } else {
          this.signalRService.syncNotificationData(notification);
          this.loaderService.showMain = true;
          setTimeout(() => {
            this.SetLogoutEvent();
          }, 500);
        }
      } else {
        this.loaderService.showMain = true;
        this.SetLogoutEvent();
      }
    });
  }
  public SetLogoutEvent() {
    this.unsubNotificationAndSignalRService();
    var isIdleTimeout = JSON.parse(localStorage.getItem('isIdleTimeout'));
    if (isIdleTimeout != null && isIdleTimeout != undefined && isIdleTimeout == true) {
      this.authService.logout(true);
    } else {
      this.authService.logout();
    }
  }

  unsubNotificationAndSignalRService() {
    this.realTimeNotificationService.unsubscribeListeners();
    this.signalRService.disconnect();
  }
}
