import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpResponse,
  HttpInterceptor,
  HttpHandler,
  HttpBackend,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, from, empty } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { RequestCache } from './request.cache.service';
import { LoaderService } from '../loader.service';
import { Logger } from '../logger.service';
import { Store } from '@ngrx/store';
import { CVPActions } from '@gstate/actions';
const log = new Logger('CacheInterceptor');
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  enableCache = false;
  // private httpClient: HttpClient;

  constructor(
    private cache: RequestCache,
    private loader: LoaderService,
    private store: Store<any>,
    httpBackend: HttpBackend
  ) {
    // this.httpClient = new HttpClient(httpBackend);
    // this.enableCache = environment.enableCache;
    this.loader.showMain = false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    const headers = new HttpHeaders({
      hideLoader: 'true',
    });
    if (!this.enableCache || !this.cache.detectCachableRequest(req)) {
      return next.handle(req);
    }

    let maybeCachedResponse: any = empty();
    return from(this.cache.get(req)).pipe(
      switchMap((cacheItem: any) => {
        if (cacheItem && cacheItem['data']) {
          this.loader.showTop = true;
          const d = JSON.parse(cacheItem['data']) as HttpResponse<any>;
          const cacheResponse = new HttpResponse(d);
          this.store.dispatch(new CVPActions.CacheStateEvent.ChangeCacheState({ cache: cacheResponse }));

          next.handle(req.clone({ headers })).subscribe(
            (res: HttpResponse<any>) => {
              this.loader.showTop = true;

              if (res instanceof HttpResponse) {
                this.cache.put(req, res);
                this.store.dispatch(new CVPActions.CacheStateEvent.ChangeCacheState({ cache: res }));
                this.loader.showTop = false;
              }
            },
            (error) => {
              this.loader.showTop = false;
              // console.log('oops', error);
            }
          );
        } else {
          maybeCachedResponse = this.sendRequest(req, next, this.cache);
        }
        return maybeCachedResponse;
      })
    );
  }

  sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }

  configure(options?: { update?: boolean } | null): CachingInterceptor {
    // this.enableCache = options.update === false ? options.update : options.enableCache;
    this.enableCache = options.update;
    log.info('Caching enable : ' + this.enableCache);
    return this;
  }
}
