<div class="action__item">
  <div class="layout-icons">
    <a
      (click)="onSettingClick.emit()"
      tooltipPosition="top"
      class="btn btn--withicon"
      pTooltip="{{ 'Configuration' | translate }}"
      *ngIf="
        (view == 'gridView' || topBar.gridOnly) &&
        (topBar.hideColumnConfiguration == null || topBar.hideColumnConfiguration == false)
      "
    >
      <i class="fal fa-cog"></i>
    </a>
    <a
      *ngIf="!topBar.gridOnly"
      tooltipPosition="top"
      class="btn btn--withicon"
      pTooltip="{{ 'List View' | translate }}"
      (click)="onviewchange('listView')"
      [class.active]="view == 'listView'"
    >
      <i class="fal fa-list"></i>
    </a>
    <a
      *ngIf="!topBar.gridOnly"
      tooltipPosition="top"
      pTooltip="{{ 'Grid View' | translate }}"
      (click)="onviewchange('gridView')"
      class="btn btn--withicon"
      [class.active]="view == 'gridView'"
    >
      <i class="fal fa-pause fa-rotate-90"></i>
    </a>
  </div>
</div>
