import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { MustMatch } from '@app/core/helpers';
import { RestMessage } from '@app/core/types';
import { ToastService } from '@app/shared/services/toast/toast.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.sass'],
})
export class SetPasswordComponent implements OnInit {
  setPasswordDTO: any;
  error: string;
  submitted = false;
  response: any;

  isSubmit = false;
  isClearViewEnv = true;
  isReflecxEnv = false;
  isMBCEnv = false;

  setPassForm: FormGroup;
  strengthColor = '';
  isStrongStrength = false;
  passwordPolicy: any = null;
  isPasswordPolicyError = false;
  passwordPolicyError = '';
  imagePath: any = undefined;
  tenant: any = undefined;
  clientId: any = null;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private routeParam: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private cdf: ChangeDetectorRef
  ) {
    this.setPassForm = this.formBuilder.group(
      {
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }

  ngOnInit(): void {
    this.isSubmit = false;
    const id = this.route.snapshot.queryParamMap.get('id');
    const code = this.route.snapshot.queryParamMap.get('code');
    const path = this.route.snapshot.queryParamMap.get('imagepath');
    const tenant = this.route.snapshot.queryParamMap.get('tenant');

    this.setPasswordDTO = { Id: id, Code: code };
    if (this.setPasswordDTO.Id != null && this.setPasswordDTO.Id !== '') {
      this.checkPasswordPolicy(this.setPasswordDTO.Id);
    }
    if (path) {
      this.imagePath = path;
      if (this.imagePath) {
        this.imagePath = this.imagePath + '?' + this.getVersionNumber();
      }
      this.cdf.detectChanges();
    }
    if (tenant) {
      this.tenant = tenant;
      this.authService.getClientByName(this.tenant).subscribe((response: any) => {
        this.clientId = response.Id;
      });
    }
  }

  get f() {
    return this.setPassForm.controls;
  }

  getVersionNumber() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const hours = d.getHours();
    const mins = d.getMinutes();
    const sec = d.getSeconds();
    const randomNumber = Math.floor(Math.random() * 100000 + 1);

    const version = year + '' + month + '' + date + '' + hours + '' + mins + '' + sec + '' + randomNumber;
    return version;
  }

  setPassword() {
    this.submitted = true;

    if (this.passwordPolicy != null) {
      this.validatePasswordPolicy();
      if (this.isPasswordPolicyError) {
        return;
      }
    }

    if (!this.setPassForm.valid) {
      return;
    }

    this.setPasswordDTO.NewPassword = this.setPassForm.value.newPassword;
    this.isSubmit = true;

    this.authService.setPassword(this.setPasswordDTO).subscribe((response: RestMessage) => {
      this.toastService.success('Password has been set successfully');
      this.isSubmit = false;
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 5000);
    });
  }

  getUserClient(Id: any) {
    this.authService.getUserClient(Id).subscribe((res: any) => {
      if (res && res.Id != null) {
        this.checkPasswordPolicy(res.Id);
      }
    });
  }

  checkPasswordPolicy(id: string) {
    this.authService.getPasswordPolicy(id).subscribe((res: any) => {
      if (res) {
        this.passwordPolicy = res;
      }
    });
  }

  validatePasswordPolicy() {
    if (this.passwordPolicy !== null) {
      const newPassword = this.setPassForm.controls['newPassword'].value;
      if (!this.verifyBlankSpece(newPassword)) {
        return;
      }
      if (this.passwordPolicy.Length > 0) {
        this.passwordPolicyError = FormatString(
          'New password must have at least {0} characters and contain  following combination: ',
          this.passwordPolicy.Length
        );
        this.verifyLength(newPassword);
      } else {
        this.passwordPolicyError = 'New password must contain  following combination: ';
      }
      let errorString = '';
      if (this.passwordPolicy.IsUpperCase) {
        errorString += 'uppercase letters';
        this.verifyUppercase(newPassword);
      }
      if (this.passwordPolicy.IsLowerCase) {
        this.verifyLowercase(newPassword);
        if (errorString !== '') {
          errorString += ', ';
        }
        errorString += 'lowercase letters';
      }
      if (this.passwordPolicy.IsDigit) {
        if (errorString !== '') {
          errorString += ', ';
        }
        errorString += 'numbers';
        this.verifyDigit(newPassword);
      }
      if (this.passwordPolicy.IsSpecialCharacter) {
        if (errorString !== '') {
          errorString += ' ';
        }
        errorString += 'and symbols.';
        this.verifySpecialCharacter(newPassword);
      }

      this.passwordPolicyError += errorString;
      if (this.isPasswordPolicyError) {
        return;
      }
    }
  }

  verifyBlankSpece(newPassword: string) {
    if (newPassword.startsWith(' ') || newPassword.endsWith(' ')) {
      this.isPasswordPolicyError = true;
      this.passwordPolicyError = 'Please do not use blank space at start or end of password';
      return false;
    }
    return true;
  }

  verifyLength(newPassword: string) {
    if (newPassword.length < this.passwordPolicy.Length) {
      this.isPasswordPolicyError = true;
    }
  }

  verifySpecialCharacter(newPassword: string) {
    if (!(/^[a-zA-Z0-9- ]*$/.test(newPassword) === false)) {
      this.isPasswordPolicyError = true;
    }
  }
  verifyDigit(newPassword: string) {
    if (!this.hasDigit(newPassword)) {
      this.isPasswordPolicyError = true;
    }
  }
  verifyLowercase(newPassword: string) {
    if (!this.hasLowerCase(newPassword)) {
      this.isPasswordPolicyError = true;
    }
  }
  verifyUppercase(newPassword: string) {
    if (!this.hasUpperCase(newPassword)) {
      this.isPasswordPolicyError = true;
    }
  }

  hasLowerCase(str: string) {
    return /[a-z]/.test(str);
  }
  hasUpperCase(str: string) {
    return /[A-Z]/.test(str);
  }
  hasDigit(str: string) {
    return /[0-9]/.test(str);
  }

  onPaswordChange(val: string) {
    this.isSubmit = false;
    this.isPasswordPolicyError = false;
    this.passwordPolicyError = '';

    const color = this.testPasswordStrength(val);
    this.styleStrengthLine(color, val);
  }

  styleStrengthLine(color: string, value: any) {
    this.strengthColor = '';
    if (value) {
      this.strengthColor = color;
      this.cdf.detectChanges();
    }
  }

  testPasswordStrength(value: any) {
    this.isStrongStrength = false;
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=/()%ยง!@#$%^&*])(?=.{8,})'),
      mediumRegex = new RegExp(
        '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
      );

    if (strongRegex.test(value)) {
      this.isStrongStrength = true;
      return 'green';
    } else if (mediumRegex.test(value)) {
      return 'orange';
    } else {
      return 'red';
    }
    this.cdf.detectChanges();
  }
}
export function FormatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}
