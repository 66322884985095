<ngx-header-top
  [topBar]="topBar"
  [view]="view"
  [GridHeaderTopDropDownTemplate]="GridHeaderTopDropDownTemplate"
  [ActionTemplate]="ActionTemplate"
  [GridHeaderTitleTemplateDirective]="GridHeaderTitleTemplateDirective"
  [searchtext]="searchtext"
  (onChangeViewHeaderTop)="onChangeViewHeader.emit($event)"
  (onSearch)="onSearch.emit($event)"
  (onSearchDropDownChange)="onSearchDropDownChange.emit($event)"
  (onSettingClick)="onSettingClick.emit($event)"
>
</ngx-header-top>
<div class="contact-list-block" *ngIf="HeaderMenuTemplate">
  <ng-template [ngTemplateOutlet]="HeaderMenuTemplate.template" [ngTemplateOutletContext]="topBar"> </ng-template>
</div>
