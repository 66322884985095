<div class="login-page">
  <div class="col-left" *ngIf="imagePath" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
  <div class="col-left" *ngIf="imagePath == undefined">
    <app-swiper-login></app-swiper-login>
  </div>
  <div class="col-right">
    <form name="authForm" novalidate style="height: 100%">
      <div class="login-form">
        <div class="form">
          <div class="form__inner">
            <div class="form__header">
              <div class="login-logo">
                <img src="./assets/styles/portal/build/assets/images/reflecx-logo.svg" alt="" />
              </div>

              <h3>Forgot Password?</h3>
              <p>Enter your user ID below, and we'll send you the password reset link</p>
            </div>

            <fieldset class="form__group" [ngClass]="{ 'error--field': isError }">
              <label class="form__label">ENTER USER ID</label>
              <div class="form__filed">
                <input
                  class="form__input"
                  type="email"
                  name="email"
                  (keyup)="onChange($event.target.value)"
                  [(ngModel)]="email"
                  required
                />
                <div class="field__error" *ngIf="isError">
                  <p>
                    {{ 'User ID is required.' }}
                  </p>
                </div>
              </div>
            </fieldset>

            <fieldset class="form__group login--actions">
              <button class="login-btn" (click)="forgotPassword(email)">SEND</button>
            </fieldset>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
