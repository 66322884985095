import { CommonModule } from '@angular/common';
import { Component, ElementRef, NgModule, ViewChild, EventEmitter, Output } from '@angular/core';
import { ScrollPanel } from 'primeng/scrollpanel';
import { DomHandler } from '../common';

@Component({
  selector: 'cvp-scrollPanel-ul',
  templateUrl: './scrollpanel-ul.html',
})
export class CVPScrollPanelUl extends ScrollPanel {
  @ViewChild('container') containerViewChild: ElementRef;
  @ViewChild('content') contentViewChild: ElementRef;
  @ViewChild('xBar') xBarViewChild: ElementRef;
  @ViewChild('yBar') yBarViewChild: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;

  moveBar() {
    const container = this.containerViewChild.nativeElement;
    const content = this.contentViewChild.nativeElement;

    /* horizontal scroll */
    const xBar = this.xBarViewChild.nativeElement;
    const wrapper = this.wrapper.nativeElement;
    const totalWidth = content.scrollWidth;
    const ownWidth = content.clientWidth;
    const bottom = (container.clientHeight - xBar.clientHeight) * -1;

    this.scrollXRatio = ownWidth / totalWidth;

    /* vertical scroll */
    const yBar = this.yBarViewChild.nativeElement;
    const totalHeight = content.scrollHeight;
    const ownHeight = content.clientHeight;
    const right = (container.clientWidth - yBar.clientWidth) * -1;

    this.scrollYRatio = ownHeight / totalHeight;

    this.requestAnimationFrame(() => {
      if (this.scrollXRatio >= 1) {
        DomHandler.addClass(xBar, 'ui-scrollpanel-hidden');
        DomHandler.addClass(wrapper, 'ui-scrollpanel-wrapper-hidden');
      } else {
        DomHandler.removeClass(xBar, 'ui-scrollpanel-hidden');
        DomHandler.removeClass(wrapper, 'ui-scrollpanel-wrapper-hidden');
        xBar.style.cssText =
          'width:' +
          Math.max(this.scrollXRatio * 100, 10) +
          '%; left:' +
          (content.scrollLeft / totalWidth) * 100 +
          '%;bottom:' +
          bottom +
          'px;';
      }

      if (this.scrollYRatio >= 1) {
        DomHandler.addClass(wrapper, 'ui-scrollpanel-wrapper-hidden');
        DomHandler.addClass(yBar, 'ui-scrollpanel-hidden');
      } else {
        DomHandler.removeClass(yBar, 'ui-scrollpanel-hidden');
        DomHandler.removeClass(wrapper, 'ui-scrollpanel-wrapper-hidden');
        yBar.style.cssText =
          'height:' +
          Math.max(this.scrollYRatio * 100, 10) +
          '%; top: calc(' +
          (content.scrollTop / totalHeight) * 100 +
          '% - ' +
          xBar.clientHeight +
          'px);right:' +
          right +
          'px;';
      }
    });
  }
}

@Component({
  selector: 'cvp-scrollPanel',
  templateUrl: './scrollpanel.html',
})
export class CVPScrollPanel extends ScrollPanel {
  @ViewChild('container') containerViewChild: ElementRef;
  @ViewChild('content') contentViewChild: ElementRef;
  @ViewChild('xBar') xBarViewChild: ElementRef;
  @ViewChild('yBar') yBarViewChild: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;
  @Output() scrollEmitter: EventEmitter<any> = new EventEmitter<any>();

  onScroll(event: any) {
    this.scrollEmitter.emit(event);
  }

  moveBar() {
    const container = this.containerViewChild.nativeElement;
    const content = this.contentViewChild.nativeElement;

    /* horizontal scroll */
    const xBar = this.xBarViewChild.nativeElement;
    const wrapper = this.wrapper.nativeElement;
    const totalWidth = content.scrollWidth;
    const ownWidth = content.clientWidth;
    const bottom = (container.clientHeight - xBar.clientHeight) * -1;

    this.scrollXRatio = ownWidth / totalWidth;

    /* vertical scroll */
    const yBar = this.yBarViewChild.nativeElement;
    const totalHeight = content.scrollHeight;
    const ownHeight = content.clientHeight;
    const right = (container.clientWidth - yBar.clientWidth) * -1;

    this.scrollYRatio = ownHeight / totalHeight;

    this.requestAnimationFrame(() => {
      if (this.scrollXRatio >= 1) {
        DomHandler.addClass(xBar, 'ui-scrollpanel-hidden');
        DomHandler.addClass(wrapper, 'ui-scrollpanel-wrapper-hidden');
      } else {
        DomHandler.removeClass(xBar, 'ui-scrollpanel-hidden');
        DomHandler.removeClass(wrapper, 'ui-scrollpanel-wrapper-hidden');
        xBar.style.cssText =
          'width:' +
          Math.max(this.scrollXRatio * 100, 10) +
          '%; left:' +
          (content.scrollLeft / totalWidth) * 100 +
          '%;bottom:' +
          bottom +
          'px;';
      }

      if (this.scrollYRatio >= 1) {
        DomHandler.addClass(wrapper, 'ui-scrollpanel-wrapper-hidden');
        DomHandler.addClass(yBar, 'ui-scrollpanel-hidden');
      } else {
        DomHandler.removeClass(yBar, 'ui-scrollpanel-hidden');
        DomHandler.removeClass(wrapper, 'ui-scrollpanel-wrapper-hidden');
        yBar.style.cssText =
          'height:' +
          Math.max(this.scrollYRatio * 100, 10) +
          '%; top: calc(' +
          (content.scrollTop / totalHeight) * 100 +
          '% - ' +
          xBar.clientHeight +
          'px);right:' +
          right +
          'px;';
      }
    });
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [CVPScrollPanel, CVPScrollPanelUl],
  declarations: [CVPScrollPanel, CVPScrollPanelUl],
})
export class CVPScrollPanelModule {}
