import { Injectable, OnDestroy } from '@angular/core';
import {
  NavigationBehaviorOptions,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Route,
  Router,
  RoutesRecognized,
  UrlTree,
} from '@angular/router';
import { AuthenticationService, Logger } from '@app/core';
import { cloneDeep } from 'lodash';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { GlobalFilterHelperService } from './global-filters-helper.service';
import { GlobalFilterService } from './global-filters.service';
import { filter, pairwise } from 'rxjs/operators';
import { Location } from '@angular/common';

const log = new Logger('ReflecxRouter');

export enum FilterTypes {
  Global = 'Global',
  Dynamic = 'Dynamic',
}

export interface DynamicFilters {
  name: string;
  key?: string;
  value: any;
  displayValue?: any;
  type: FilterTypes;
  hidden?: Boolean;
}

@Injectable({ providedIn: 'root' })
export class GlobalFilterRouter {
  set_allowed_persistance_pages = ['/insights/reports/statistics-v2'];
  load_allowed_persistance_pages = [
    '/insights/reports/recorddisposition/disposition',
    '/insights/reports/surveylist',
    '/insights/alerts/list',
  ];
  filters: any = {};
  history: any = {};
  lastUrl = '';
  fromHere = false;
  reset = true;
  constructor(
    public router: Router,
    public gbfConfigHelper: GlobalFilterHelperService,
    public gbfConfig: GlobalFilterConfigService,
    public authService: AuthenticationService,
    public gbfService: GlobalFilterService,
    public location: Location
  ) {
    this.loadLocalStorageValues();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger == 'popstate') {
          const sfy = String(Object.keys(this.filters).length) || '1';
          this.restoreFilters(sfy, false);
          this.setInLocalStorage();
        } else if (this.reset) {
          if (!this.fromHere) {
            const sfy = this.makeSlugify('1');
            this.restoreFilters(sfy, false);
            this.setInLocalStorage();
          }
          this.fromHere = false;
        }
      }
      if (event instanceof NavigationEnd) {
        this.reset = true;
      }
    });
  }
  setUpBeforeRoute(commands: any[], filters: any[]) {
    let commandURL: any = commands;
    if (Array.isArray(commands)) {
      commandURL = commands[0];
    }
    let pureURL = commandURL.indexOf('?') > -1 ? commandURL.indexOf('?') : commandURL.length;
    commandURL = commandURL.substring(0, pureURL);
    const globalFilters = filters.filter((x: DynamicFilters) => x.type === FilterTypes.Global);
    globalFilters.forEach((filter: any) => {
      const config = this.gbfConfig.filterConfig.config.find((_config: any) => _config.name === filter.key);
      if (config && config?.type === 'list') {
      } else if (config && config.type === 'tree') {
        let records: any[] = [];
        if (config.name.indexOf('org') > -1) {
          records = this.gbfConfigHelper['multi_orgs'] || this.gbfConfigHelper['orgs'];
        }
        const value = Array.isArray(filter.value) && filter.value.length > 0 ? filter.value[0] : filter.value;
        let found = records.find((x: any) => x.id === value || x.mProps.NodeId === value);
        if (found) {
          if (config.settings.multi) {
            const futureTree: any[] = [found];
            for (let i = found.level - 1; i >= 1; i--) {
              found = records.find((x: any) => x.id === found.parent);
              futureTree.push({ ...found, ...found['mProps'] });
            }
            const tree = futureTree.reverse().map((x: any) => {
              return { ...x, ...x['mProps'] };
            });
            this.gbfService[config.name] = tree;
          } else {
            this.gbfService[config.name] = { ...found, ...found['mProps'] };
          }
        } else {
          throw new Error('GBFRouter:: Org not found.');
        }
      } else if (config && config.type === 'calendar') {
        const _dates = filter.value;
        if (Array.isArray(_dates) && _dates.length === 2) {
          this.gbfService.gbfChangeState.next({ value: _dates, config: config, for: 'calendar' });
        } else {
          throw new Error('dynamic dates should be array of two dates start and end');
        }
      }
    });
  }
  public navigate(
    commands: any[],
    extras?: NavigationExtras,
    params?: { key: string; beforeRoute?: boolean; filters: Array<DynamicFilters> },
    cb?: Function,
    fromUrl = '1'
  ) {
    this.fromHere = true;
    const filterState = Object.assign({}, this.getFilterCurrentState(params.filters));
    if (params.beforeRoute) {
      this.setUpBeforeRoute(commands, params.filters);
      this.router.navigate(commands, extras);
    }
    setTimeout(() => {
      const _fromUrl = fromUrl; // this.router.url;
      let _url = this.makeSlugify(params.key);
      this.lastUrl = _url;
      this.filters[_url] = params.filters;
      this.history[this.makeSlugify(_fromUrl)] = {
        url: _fromUrl,
        _url,
        filters: filterState,
      };
      this.setUpGlobalFilters(params.filters, () => {
        this.setInLocalStorage();
        if (!params.beforeRoute) {
          this.router.navigate(commands, extras);
        }
        cb && cb();
      });
    }, 0);
  }
  public cloneAndAddHistory(url: string, routeIndex: number) {
    this.reset = false;
    const newUrl = this.makeSlugify(url);
    if (this.lastUrl && newUrl) {
      let history = this.history[String(routeIndex - 1)];
      let filters = this.filters[this.lastUrl];
      if (history && filters) {
        history = Object.assign({}, history);
        history.url = newUrl;
        this.history[String(routeIndex)] = history;
        this.filters[newUrl] = filters;
      }
    }
  }
  public navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationBehaviorOptions,
    params?: { key: string; beforeRoute?: boolean; filters: Array<DynamicFilters> },
    cb?: Function,
    fromUrl = '1'
  ) {
    this.fromHere = true;
    const filterState = Object.assign({}, this.getFilterCurrentState(params.filters));
    const _fromUrl = fromUrl; //this.router.url;
    let _url = this.makeSlugify(params.key);
    this.lastUrl = _url;
    this.filters[_url] = params.filters;
    this.history[this.makeSlugify(_fromUrl)] = {
      url: _fromUrl,
      _url,
      filters: filterState,
    };
    this.setUpGlobalFilters(params.filters, () => {
      this.router.navigateByUrl(url, extras);
      this.setInLocalStorage();
      cb && cb();
    });
  }

  getFilterCurrentState(filters: Array<DynamicFilters>) {
    const globalFilters = filters.filter((x: DynamicFilters) => x.type === FilterTypes.Global);
    const _filters: any = {};
    globalFilters.forEach((filter) => {
      if (filter.key === 'calendar' && !this.gbfService[filter.key]) {
        this.gbfService[filter.key] = this.gbfService.getCalenderRange().join('|');
      }
      // else {
      //   this.gbfService[filter.key] = this.gbfService[filter.key];
      // }
      if (this.gbfService[filter.key]) {
        _filters[filter.key] = cloneDeep(this.gbfService[filter.key]);
      }
    });
    return _filters;
  }

  setUpGlobalFilters(filters: Array<DynamicFilters>, cb: Function) {
    const globalFilters = filters.filter((x: DynamicFilters) => x.type === FilterTypes.Global);
    globalFilters.forEach((filter) => {
      const config = this.gbfConfig.filterConfig.config.find((_config: any) => _config.name === filter.key);
      if (config && config?.type === 'list') {
        const values = !config.dynamic ? config.values : this.gbfConfigHelper[config.name];
        let found = null;
        if (!config.settings.multi) {
          found = this.mapForGlobalFilter(values.find((x: any) => x.value === filter.value));
        } else if (Array.isArray(filter.value) && filter.value.length > 0) {
          found = cloneDeep(values).map((x: any) => {
            if (filter.value.includes(x.value)) {
              x.IsChecked = true;
              x.active = true;
            } else {
              x.IsChecked = false;
              x.active = false;
            }
            return x;
          });
        }
        this.gbfService[config.name] = found;
        this.gbfService.gbfChangeState.next({ value: found, config: config, for: 'list' });
      } else if (config && config.type === 'tree') {
        if (config.settings.multi) {
          const value = Array.isArray(filter.value) && filter.value.length > 0 ? filter.value : [filter.value];
          this.gbfService.gbfChangeState.next({ value: value, config: config, for: 'tree' });
        } else {
          const value = Array.isArray(filter.value) && filter.value.length > 0 ? filter.value[0] : filter.value;
          this.gbfService.gbfChangeState.next({ value: value, config: config, for: 'tree' });
        }
      } else if (config && config.type === 'calendar') {
        const _dates = filter.value;
        if (Array.isArray(_dates) && _dates.length === 2) {
          this.gbfService.gbfChangeState.next({ value: _dates, config: config, for: 'calendar' });
        } else {
          throw new Error('dynamic dates should be array of two dates start and end');
        }
      }
    });
    setTimeout(() => {
      cb();
    }, 0);
  }

  mapForGlobalFilter(value: any) {
    if (!value) return false;
    return {
      IsChecked: true,
      active: true,
      label: value.label,
      value: value.value,
    };
  }

  loadLocalStorageValues() {
    let routerUrl = this.router.url.split('?');
    let url = routerUrl[0];
    if (this.load_allowed_persistance_pages.includes(url)) {
      const found = localStorage.getItem('dynamic_filters');
      if (found) {
        const parsed = JSON.parse(found);
        if (parsed.history && parsed.filters) {
          this.lastUrl = parsed.lastUrl;
          this.history = parsed.history;
          this.filters = parsed.filters;
        }
      }
    }
  }

  setInLocalStorage() {
    if (this.set_allowed_persistance_pages.includes(this.router.url)) {
      const value = JSON.stringify(
        Object.assign({}, { lastUrl: this.lastUrl, history: this.history, filters: this.filters })
      );
      localStorage.setItem('dynamic_filters', value);
    }
  }

  public onBack() {
    this.location.back();
  }

  restoreFilters(url: string, navigate = true) {
    const _previousState = this.history[url];
    if (_previousState?.filters && _previousState?.url) {
      Object.keys(_previousState.filters).forEach((filterKey) => {
        const config = this.gbfConfig.filterConfig.config.find((_config: any) => _config.name === filterKey);
        const filter = _previousState.filters[filterKey];
        if (config && config?.type === 'list') {
          this.gbfService[config.name] = filter;
          this.gbfService.gbfChangeState.next({ value: filter, config: config, for: 'list' });
        } else if (config && config?.type === 'tree') {
          this.gbfService[config.name] = filter;
          this.gbfService.gbfChangeState.next({ value: filter, config: config, for: 'tree' });
        } else if (config && config?.type === 'calendar') {
          this.gbfService[config.name] = filter;
          this.gbfService.gbfChangeState.next({ value: filter.split('|'), config: config, for: 'calendar' });
        }
      });
      delete this.history[url];
      delete this.filters[this.lastUrl];
      if (Object.keys(this.history).length > 0) {
        this.lastUrl = this.history[Object.keys(this.history)[0]]._url;
        console.log(this.lastUrl);
      } else {
        this.lastUrl = '';
      }
      if (navigate) {
        this.location.back();
      }
    }
  }

  private makeSlugify(str: string): string {
    return str.replace(/\//g, '');
  }

  canGoBack(): boolean {
    return this.length() > 0 || window.history.length > 1;
  }

  length(): number {
    const l = this.getFiltersByPage()?.length;
    return l;
  }

  getFiltersByPage(): Array<DynamicFilters> {
    const _url = this.makeSlugify(location.pathname.replace('/portal', ''));
    return this.filters[_url] || new Array<DynamicFilters>();
  }
}
