import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilerService {
  _action = new Subject();
  _alphabate = new Subject();
  _value = new Subject();
  _row = new Subject();
  value$ = this._value.asObservable();
  action$ = this._action.asObservable();
  alphabate$ = this._alphabate.asObservable();
  row$ = this._row.asObservable();

  passValue(value: any) {
    this._value.next(value);
  }

  girdAction(type: string, data: any) {
    this._action.next({ type, data });
  }

  alphabateAction(alphabate: string) {
    this._alphabate.next(alphabate);
  }
  rowAction(row: string) {
    this._row.next(row);
  }
}
