import { Input, Output, EventEmitter, Directive, TemplateRef, ContentChild } from '@angular/core';
import { GridBodyListRowTemplateDirective } from './list-row.template.directive';

@Directive({ selector: 'grid-body-list-row' })
export class GridBodyListRowDirective {
  @Input() rowIndex: number;
  @Input() row: any;

  // @Input()
  @ContentChild(GridBodyListRowTemplateDirective, { read: TemplateRef })
  template: TemplateRef<any>;
}
