import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChildren,
  ElementRef,
  NgModule,
  QueryList,
  ViewChild,
  TemplateRef,
  Input,
} from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { RfPrimeTemplate, RfSharedModule } from '../common/shared';

@Component({
  selector: 'cvp-carousel-header',
  templateUrl: './carousel.html',
})
export class CVPCarouselHeader extends Carousel {
  @ContentChildren(RfPrimeTemplate) templates: QueryList<any>;
  @ViewChild('container') containerViewChild: ElementRef;
  @ViewChild('viewport') viewportViewChild: ElementRef;
  @ViewChild('items') itemsViewChild: ElementRef;
  footerTemplate: TemplateRef<any>;
  headerTemplate: TemplateRef<any>;
  @Input() indicatorsContentClass: any;
}

@Component({
  selector: 'cvp-carousel',
  templateUrl: './carousel-no-header.html',
})
export class CVPCarousel extends Carousel {
  @ContentChildren(RfPrimeTemplate) templates: QueryList<any>;
  @ViewChild('container') containerViewChild: ElementRef;
  @ViewChild('viewport') viewportViewChild: ElementRef;
  @ViewChild('items') itemsViewChild: ElementRef;
  footerTemplate: TemplateRef<any>;
  headerTemplate: TemplateRef<any>;
  @Input() indicatorsContentClass: any;
}

@NgModule({
  imports: [CommonModule, RfSharedModule],
  exports: [CVPCarousel, CVPCarouselHeader, RfSharedModule],
  declarations: [CVPCarousel, CVPCarouselHeader],
})
export class CVPCarouselModule {}
