import { Action } from '@ngrx/store';

export enum ActionTypes {
  PermissionEvent = '[Permission Event]',
}

export class PermissionEvent implements Action {
  readonly type = ActionTypes.PermissionEvent;
  constructor(public data: string) {}
}
