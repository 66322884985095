import { Component, OnInit } from '@angular/core';
import { LoaderService } from './../../core/loader.service';

@Component({
  selector: 'app-logout-sso',
  templateUrl: './logout-sso.component.html',
  styleUrls: ['./logout-sso.component.sass'],
})
export class LogoutSsoComponent implements OnInit {
  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.showMain = false;
  }
}
