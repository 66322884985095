<div class="login-page">
  <div class="col-left">
    <div class="login-form">
      <div class="form">
        <div class="form__inner">
          <div class="form__header">
            <span>Redirecting, please wait...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-right"></div> -->
</div>
