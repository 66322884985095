import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BreadCrumb } from '@app/core/types/breadCrumb';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class BreadCrumbService {
  breadcrumbs$: BreadCrumb[];
  url: string;
  stack: Array<any>;
  breadCrumb: BehaviorSubject<BreadCrumb[]> = new BehaviorSubject<BreadCrumb[]>([]);

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.stack = [];
    this.init(this.router, this.activatedRoute);
  }

  init(router: Router, activatedRoute: ActivatedRoute) {
    const onNavigationEnd = router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      distinctUntilChanged()
    );
    onNavigationEnd.subscribe((event) => {
      //   this.breadcrumbs$ = this.buildBreadCrumb(activatedRoute.root);
      this.setCurrentUrl(event);
      // this.emitBreadCrumbs();
    });
  }

  setCurrentUrl(data: any) {
    this.url = data.urlAfterRedirects;
    // console.log(this.url)
  }

  addToStack(modulename: string, url?: string) {
    // this.reset(modulename);
    if (this.stack.findIndex((x) => x.label === modulename) < 0) {
      this.stack.push({
        label: modulename,
        command: () => this.updateBreadCrumb(modulename),
        routerLink: url ? decodeURIComponent(url) : decodeURIComponent(this.url),
      });
    }
    // console.log(this.stack)
    sessionStorage.setItem('breadcrumb', JSON.stringify(this.stack));
    this.breadCrumb.next(this.stack);

    //  return this.stack;
  }

  updateBreadCrumb(modulename: string, url?: string) {
    // debugger;
    // console.log(this.stack)
    this.stack = JSON.parse(sessionStorage.getItem('breadcrumb'));
    if (!this.stack) {
      this.stack = [];
    }
    modulename = decodeURIComponent(modulename);
    const index = this.stack.findIndex((x) => x.label === modulename);
    if (index >= 0) {
      let flag = this.stack.length;
      while (flag > index + 1) {
        this.stack.pop();
        flag--;
      }
      // console.log(this.stack)
      sessionStorage.setItem('breadcrumb', JSON.stringify(this.stack));
      this.breadCrumb.next(this.stack);
    } else {
      this.addToStack(modulename, url);
    }
  }

  resetModule() {
    this.stack = [];
    sessionStorage.setItem('breadcrumb', JSON.stringify(this.stack));
    this.breadCrumb.next(this.stack);
  }

  getBreadCrumbStack() {
    return this.stack;
  }

  setBreadCrumb(items: any, admin: boolean) {
    const breadCrumbs: BreadCrumb[] = [];
    if (admin) {
      breadCrumbs.push({
        label: 'Home',
        icon: 'pi pi-home',
        routerLink: '/' + environment.reflecxRoutePrefix,
        queryParm: {},
      });
    } else {
      breadCrumbs.push({
        label: 'Home',
        icon: 'pi pi-home',
        routerLink: '/' + environment.reportingRoutePrefix,
        queryParm: {},
      });
    }

    items.forEach((element: BreadCrumb) => {
      breadCrumbs.push(element);
    });
    localStorage.setItem('breadcrumb', JSON.stringify(breadCrumbs));
    this.breadCrumb.next(breadCrumbs);
  }

  public getBreadCrumbObservable() {
    return this.breadCrumb.asObservable();
  }

  public emitBreadCrumbs() {
    if (this.breadcrumbs$) {
      const breadCrumbs: BreadCrumb[] = [];
      const labels: string[] = [];

      this.breadcrumbs$.forEach((x) => {
        if (!labels.includes(x.label)) {
          labels.push(x.label);
          breadCrumbs.push(x);
        }
      });
      this.breadcrumbs$ = breadCrumbs;
      localStorage.setItem('breadcrumb', JSON.stringify(breadCrumbs));
      this.breadCrumb.next(breadCrumbs);
    } else {
      this.breadCrumb.next(null);
    }
    // return [...new Set(this.breadcrumbs$.map((item: BreadCrumb) => item.label))];
  }

  getCurrent() {
    return this.breadcrumbs$;
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    const label = route.routeConfig ? (route.routeConfig.data ? route.routeConfig.data.breadcrumb : 'Home') : 'Home';
    const path = route.routeConfig ? route.routeConfig.path : '';

    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label,
      routerLink: nextUrl.split('//').join('/'),
      queryParm: {},
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  getBreadCrumb(modulename: string, data: Array<any>) {
    switch (modulename) {
      case 'DatasourceList':
        return [
          {
            label: 'Home',
            icon: 'pi pi-home',
            routerLink: '/' + environment.reflecxRoutePrefix,
            queryParm: {},
          },
          {
            label: 'Data Sources',
            routerLink: '/' + environment.reflecxRoutePrefix + '/datasource/list',
            queryParm: {},
          },
        ];
    }
  }
}
