import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication/authentication.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { Logger } from '@app/core/logger.service';
import { Router } from '@angular/router';
const log = new Logger('SignalR');
// import * as signalR from '@microsoft/signalr';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  HttpTransportType,
  LogLevel,
  RetryContext,
} from '@microsoft/signalr';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  notificationReload = new BehaviorSubject<boolean>(false);

  SampleData = new BehaviorSubject<any>('');
  Sample = this.SampleData.asObservable();

  ResponseFromSparkPostData = new BehaviorSubject<any>('');
  ResponseFromSparkPost = this.ResponseFromSparkPostData.asObservable();

  TransmissionStartNotificationData = new BehaviorSubject<any>('');
  TransmissionStartNotification = this.TransmissionStartNotificationData.asObservable();

  TransmissionFailedNotificationData = new BehaviorSubject<any>('');
  TransmissionFailedNotification = this.TransmissionFailedNotificationData.asObservable();

  TransmissionCompletedNotificationData = new BehaviorSubject<any>('');
  TransmissionCompletedNotification = this.TransmissionCompletedNotificationData.asObservable();

  TransmissionTotalRecordsNotificationData = new BehaviorSubject<any>('');
  TransmissionTotalRecordsNotification = this.TransmissionTotalRecordsNotificationData.asObservable();

  SurveySubmitResponseData = new BehaviorSubject<any>('');
  SurveySubmitResponse = this.SurveySubmitResponseData.asObservable();

  NotificationResponseData = new BehaviorSubject<any>('');
  NotificationResponse = this.NotificationResponseData.asObservable();

  SingleRStateData = new BehaviorSubject<any>('');
  SingleRStateResponse = this.SingleRStateData.asObservable();

  GeneralNotificationResponseData = new BehaviorSubject<any>('');
  GeneralNotificationResponse = this.GeneralNotificationResponseData.asObservable();

  SurveyResponseStatusData = new BehaviorSubject<any>('');
  SurveyResponseStatus = this.SurveyResponseStatusData.asObservable();

  // IsFirstNotificationSync = true;
  // ConnectionCurrentState = 'pending';
  // ConnectionCurrentState: HubConnectionState = HubConnectionState.Disconnected;

  private token: string;
  private initialized = false;
  private isTokenExpired = false;
  private hubConnection: HubConnection;
  private canSendNewRequest = true;
  private user: any;
  private eventRegistered = false;

  constructor(private authService: AuthenticationService, public toastService: ToastService, private router: Router) {}

  public init() {
    const userInfo = this.authService.getUser();
    if (userInfo !== null && userInfo !== undefined) {
      if (
        !this.initialized
        // this.connectionCurrentState === 'disconnected' ||
        // this.connectionCurrentState === 'pending'
      ) {
        this.initialized = true;
        this.user = userInfo;
        this.buildConnection();
      } else {
        log.warn('SignalR already connected');
      }
    }
  }

  public disconnect() {
    if (this.eventRegistered) {
      this.hubConnection.off('onLogout');
      this.hubConnection.off('sample');
      this.hubConnection.off('onResponseFromSparkpost');
      this.hubConnection.off('onTransmissionStart');
      this.hubConnection.off('onTransmissionFailed');
      this.hubConnection.off('onTransmissionCompleted');
      this.hubConnection.off('onTransmissionTotalRecordsPublishing');
      this.hubConnection.off('onSurveySubmit');
      this.hubConnection.off('onCustomerStatusChange');
      this.hubConnection.off('onCustomerLogoutRequest');
      this.hubConnection.off('onGeneralNotification');
      this.hubConnection.off('onNotificationSync');
      this.hubConnection.off('onSurveyResponseStatusUpdate');
      this.hubConnection.off('sendMessageToClientUsers');
      this.hubConnection.off('sendMessageToUser');
      this.hubConnection.off('reloadApp');
      this.SampleData.next('');
      this.ResponseFromSparkPostData.next('');
      this.TransmissionStartNotificationData.next('');
      this.TransmissionFailedNotificationData.next('');
      this.TransmissionCompletedNotificationData.next('');
      this.TransmissionTotalRecordsNotificationData.next('');
      this.SurveySubmitResponseData.next('');
      this.GeneralNotificationResponseData.next('');
      this.NotificationResponseData.next('');
      this.SurveyResponseStatusData.next('');
      this.eventRegistered = false;
    }

    if (this.hubConnection && this.hubConnection.state !== HubConnectionState.Disconnected) {
      this.hubConnection.stop();
    }

    // this.ConnectionCurrentState = 'disconnected';
    this.SingleRStateData.next(false);
  }

  public syncNotificationData(notifications: any) {
    let loginNotificationId: string;
    let lastSyncDate: string = null;

    if (this.canSendNewRequest === false) {
      log.debug('SignalR --> syncNotificationData>> Skiping due to canSendNewRequest is false');
      return;
    }

    if (this.hubConnection.state !== HubConnectionState.Connected) {
      log.debug('SignalR --> syncNotificationData>> Skiping due to socket disconnection');
      return;
    }

    if (localStorage.getItem('loginNotificationId') != null) {
      loginNotificationId = localStorage.getItem('loginNotificationId');
    } else {
      loginNotificationId = '';
    }

    if (localStorage.getItem('lastSyncDateNotify') != null) {
      lastSyncDate = localStorage.getItem('lastSyncDateNotify');
    } else {
      lastSyncDate = '';
    }

    log.debug('SignalR --> syncNotificationData ');

    this.hubConnection.invoke('syncNotificationData', notifications, loginNotificationId, lastSyncDate).catch((err) => {
      log.debug(err);
      // console.log(err);
      if (this.canSendNewRequest === true) {
        this.canSendNewRequest = false;
        setTimeout(() => {
          this.canSendNewRequest = true;
        }, 15000);
      }
    });
  }

  public isConnected(): boolean {
    return this.hubConnection != null && this.hubConnection.state === HubConnectionState.Connected;
  }

  private sendTestNotification() {
    if (this.canSendNewRequest === false) {
      log.debug('SignalR --> Test syncNotificationData >> this.canSendNewRequest == false');
      setTimeout(() => {
        this.sendTestNotification();
      }, 10000);
      return;
    }

    if (this.hubConnection.state !== HubConnectionState.Connected) {
      log.debug('SignalR --> Test syncNotificationData >> Skiping due to socket disconnection');
      setTimeout(() => {
        this.sendTestNotification();
      }, 10000);
      return;
    }

    log.debug('SignalR --> Test syncNotificationData ');
    this.hubConnection.invoke('syncNotificationData', null, null, null).catch((err) => {
      log.debug(err);
    });
    setTimeout(() => {
      this.sendTestNotification();
    }, 10000);
  }

  private buildConnection() {
    if (localStorage.getItem('authorizationData') != null) {
      this.updateToken();
      this.authService.checkTokenExpiry();

      const logLevel = environment.production ? LogLevel.Error : LogLevel.Debug;

      // const data = JSON.parse(localStorage.getItem('authorizationData'));
      if (!this.hubConnection) {
        this.hubConnection = new HubConnectionBuilder()
          .withUrl(`${environment.signalRUrl}`, {
            skipNegotiation: true,
            // tslint:disable-next-line: no-bitwise
            transport: HttpTransportType.WebSockets, // | signalR.HttpTransportType.LongPolling,
            // accessTokenFactory: () =>  data.token
            // accessTokenFactory: () => this.getToken()
            accessTokenFactory: () => this.token,
          })
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (context: RetryContext) => {
              if (context.retryReason && context.retryReason.message.toLocaleLowerCase().indexOf('authoriz') !== -1) {
                log.warn('SignalR disconnected >> retryReason.message below');
                log.warn(context.retryReason);
                if (this.isTokenExpired) {
                  this.router.navigate(['/endsession']);
                }
                this.isTokenExpired = true;
              } else {
                this.updateToken();
                return 500;
              }
            },
          })
          .configureLogging(logLevel)
          .build();
      }

      this.startConnection();
    }
  }

  private getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      resolve(this.token);
    });
  }

  private startConnection() {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return;
    }

    this.hubConnection
      .start()
      .then(() => {
        log.info('SignalR --> Connection started');
        // this.ConnectionCurrentState = 'connected';
        // this.ConnectionCurrentState = HubConnectionState.Connected;

        this.registerListeners();

        if (!environment.production) {
          // for testing only
          setTimeout(() => {
            this.sendTestNotification();
          }, 5000);
        }
      })
      .catch((err: any) => {
        log.debug('SignalR --> Unable to connect >> Error Msg : ', err);
        this.updateToken();
        this.authService.checkTokenExpiry();
        // change connection state to reconnecting
        // call the start connection
        if (localStorage.getItem('SignalrConnectivityCounts') == null) {
          localStorage.setItem('SignalrConnectivityCounts', '0');
        } else {
          let trycount: number = parseInt(localStorage.getItem('SignalrConnectivityCounts'), 0);
          trycount = trycount + 1;
          localStorage.setItem('SignalrConnectivityCounts', trycount.toString());
          if (trycount === 9) {
            localStorage.setItem('IsApiNotification', 'true');
          }
        }

        // if you get error try to start connection again after 3 seconds.
        setTimeout(() => {
          this.startConnection();
        }, 5000);
      });

    this.hubConnection.onclose(() => {
      setTimeout(() => {
        if (this.hubConnection.state === HubConnectionState.Disconnected) {
          //  this.ConnectionCurrentState = 'pending';
          this.startConnection();
        }
      }, 15000);
    });

    this.hubConnection.onreconnecting(() => {
      setTimeout(() => {
        log.info('Reconnecting SignalR');
      }, 10);
    });

    this.hubConnection.onreconnected(() => {
      setTimeout(() => {
        this.canSendNewRequest = true;
        this.isTokenExpired = false;
        log.info('Reconnected SignalR');
      }, 10);
    });
  }

  private registerListeners() {
    if (this.eventRegistered) {
      return;
    }

    // consuming observables from SignalR
    this.sampleListener();
    this.onLogout();
    this.responseFromSparkPost();
    this.transmissionStartNotification();
    this.transmissionFailedNotification();
    this.transmissionCompletedNotification();
    this.transmissionTotalRecordsNotification();
    this.surveySubmit();
    this.customerStatusChange();
    this.customerLogoutRequest();
    this.onGeneralNotification();
    this.onNotificationSync();
    this.onSurveyResponseStatusUpdate();
    this.onSendMessageToClientUsers();
    this.onSendMessageToUser();
    this.reloadApp();
    this.eventRegistered = true;
    this.SingleRStateData.next(true);
    /*const notification: Notification[] = [];
    this.syncNotificationData(notification);*/
  }

  private updateToken() {
    const data = JSON.parse(localStorage.getItem('authorizationData'));
    this.token = this.authService.getToken();
  }

  private onSendMessageToClientUsers() {
    this.hubConnection.on('sendMessageToClientUsers', (data: any) => {
      // this.toastService.info(data.message);
      this.toastService.confirmDialog({
        header: 'Notification',
        message: data.message,
        rejectVisible: false,
      });
    });
  }

  private onSendMessageToUser() {
    this.hubConnection.on('sendMessageToUser', (data: any) => {
      // this.toastService.info(data.message);
      this.toastService.confirmDialog({
        header: 'Notification',
        message: data.message,
        rejectVisible: false,
      });
    });
  }

  private reloadApp() {
    this.hubConnection.on('reloadApp', (data: any) => {
      // this.notificationReload.next(true);
      this.toastService.strickMessage(
        'New version available',
        'Please click on below button to update to the new versio.'
      );
      // this.toastService.confirmDialog({
      //   header: 'New Version Notification', message: data.message, acceptLabel: 'Reload', acceptVisible: true,
      //   rejectVisible: true, rejectLabel: 'Cancel', accept: () => { window.location.reload(); }
      // });
    });
  }

  private onLogout() {
    this.hubConnection.on('onLogout', (data: any) => {
      if (this.user.Email === data) {
        this.authService.logout();
      }
    });
  }

  private sampleListener() {
    this.hubConnection.on('sample', (response: any) => {
      this.SampleData.next(response);
    });
  }

  private responseFromSparkPost() {
    this.hubConnection.on('onResponseFromSparkpost', (response: any) => {
      this.ResponseFromSparkPostData.next(response);
    });
  }

  private transmissionStartNotification() {
    this.hubConnection.on('onTransmissionStart', (data: any) => {
      this.TransmissionStartNotificationData.next(data);
    });
  }

  private transmissionFailedNotification() {
    this.hubConnection.on('onTransmissionFailed', (data: any) => {
      this.TransmissionFailedNotificationData.next(data);
    });
  }

  private transmissionCompletedNotification() {
    this.hubConnection.on('onTransmissionCompleted', (data: any) => {
      this.TransmissionCompletedNotificationData.next(data);
    });
  }

  private transmissionTotalRecordsNotification() {
    this.hubConnection.on('onTransmissionTotalRecordsPublishing', (data: any) => {
      this.TransmissionTotalRecordsNotificationData.next(data);
    });
  }

  private surveySubmit() {
    this.hubConnection.on('onSurveySubmit', (data: any) => {
      this.SurveySubmitResponseData.next(data);
    });
  }

  private customerStatusChange() {
    this.hubConnection.on('onCustomerStatusChange', (data: any) => {
      this.SurveySubmitResponseData.next(data);
    });
  }

  private customerLogoutRequest() {
    this.hubConnection.on('onCustomerLogoutRequest', (data: any) => {
      this.SurveySubmitResponseData.next(data);
    });
  }

  private onGeneralNotification() {
    this.hubConnection.on('onGeneralNotification', (data: any) => {
      this.GeneralNotificationResponseData.next(data);
    });
  }

  private onNotificationSync() {
    this.hubConnection.on('onNotificationSync', (data: any) => {
      this.NotificationResponseData.next(data);
    });
  }

  private onSurveyResponseStatusUpdate() {
    this.hubConnection.on('onSurveyResponseStatusUpdate', (data: any) => {
      this.SurveyResponseStatusData.next(data);
    });
  }
}
