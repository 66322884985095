<div [class]="styleClass" [ngStyle]="style">
  <ul class="breadcrumbs_list">
    <li class="ui-breadcrumb-home" *ngIf="home">
      <a
        *ngIf="!home.routerLink"
        [href]="home.url||'#'"
        class="ui-menuitem-link"
        (click)="itemClick($event, home)"
        [ngClass]="{'ui-state-disabled':home.disabled}"
        [attr.target]="home.target"
        [attr.title]="home.title"
        [attr.id]="home.id"
      >
        <span [ngClass]="home.icon||'pi pi-home'"></span>
      </a>
      <a
        *ngIf="home.routerLink"
        [routerLink]="home.routerLink"
        [queryParams]="home.queryParams"
        [routerLinkActive]="'ui-state-active'"
        [routerLinkActiveOptions]="home.routerLinkActiveOptions||{exact:false}"
        class="ui-menuitem-link"
        (click)="itemClick($event, home)"
        [ngClass]="{'ui-state-disabled':home.disabled}"
        [attr.target]="home.target"
        [attr.title]="home.title"
        [attr.id]="home.id"
      >
        <span [ngClass]="home.icon||'pi pi-home'"></span>
      </a>
    </li>
    <!-- <li class="ui-breadcrumb-chevron pi pi-chevron-right" *ngIf="model&&home"></li> -->
    <ng-template ngFor let-item let-end="last" [ngForOf]="model">
      <li role="menuitem" [class.active]="end">
        <a
          *ngIf="!item.routerLink"
          [href]="item.url||'#'"
          [class.active]="end"
          (click)="itemClick($event, item)"
          [ngClass]="{'ui-state-disabled':item.disabled}"
          [attr.target]="item.target"
          [attr.title]="item.title"
          [attr.id]="item.id"
        >
          <span *ngIf="item.icon" class="ui-menuitem-icon" [ngClass]="item.icon"></span>
          <span class="">{{item.label}}</span>
        </a>
        <a
          *ngIf="item.routerLink"
          [class.active]="end"
          [routerLink]="item.routerLink"
          [queryParams]="item.queryParm"
          [routerLinkActive]="'ui-state-active'"
          [routerLinkActiveOptions]="item.routerLinkActiveOptions||{exact:false}"
          class="ui-menuitem-link"
          (click)="itemClick($event, item)"
          [ngClass]="{'ui-state-disabled':item.disabled}"
          [attr.target]="item.target"
          [attr.title]="item.title"
          [attr.id]="item.id"
        >
          <span *ngIf="item.icon" class="n" [ngClass]="item.icon"></span>
          <span class="">{{item.label}}</span>
        </a>
      </li>
      <!-- <li class="ui-breadcrumb-chevron pi pi-chevron-right" *ngIf="!end"></li> -->
    </ng-template>
  </ul>
</div>
