<!--<div class="modal modal--medium modal--error modal--loading active">
  <div class="modal__background"></div>
  <div class="modal__outer">
    <div class="modal__inner">
      <div class="modal__content">
        <div class="error__content">
          <span class="error-icon"></span>
          <h3>Loading, please wait...</h3>
        </div>
      </div>
    </div>
  </div>
</div>-->
