import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, forwardRef, Inject, Input, NgModule, OnDestroy, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DomHandler } from 'primeng/dom';
import { Menu, MenuItemContent } from 'primeng/menu';
import { MenuItem } from '../common/menuitem';

@Component({
  selector: '[cvcMenuItemContent]',
  templateUrl: './menu-item-content.html',
})
export class CVCMenuItemContent extends MenuItemContent {
  @Input('cvcMenuItemContent') item: any;
  constructor(@Inject(forwardRef(() => CVCMenu)) public menu: CVCMenu) {
    super(menu);
  }
}

@Component({
  selector: 'cvc-menu',
  templateUrl: './menu.html',
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}')),
    ]),
  ],
})
export class CVCMenu extends Menu implements OnDestroy {
  @Input() containerClass: string;
  @Input() topPosition = 'none';
  @ViewChild('container') containerViewChild: ElementRef;
  itemClick(event: any, item: any) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }
    if (!item.url) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
      });
    }
    if (this.popup) {
      this.hide();
    }
  }
  onOverlayAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        if (this.popup) {
          this.container = event.element;
          this.moveOnTop();
          this.onShow.emit({});
          this.appendOverlay();
          DomHandler.absolutePosition(this.container, this.target);
          if (this.topPosition !== 'none' && this.appendTo !== 'body') {
            this.container.style.top = this.topPosition;
          }
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
        }
        break;

      case 'void':
        this.onOverlayHide();
        this.onHide.emit({});
        break;
    }
  }
}

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [CVCMenu, CVCMenuItemContent, RouterModule],
  declarations: [CVCMenu, CVCMenuItemContent],
})
export class CVCMenuModule {}
