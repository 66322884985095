import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  NgZone,
  Inject,
} from '@angular/core';
import { Calendar } from 'primeng/calendar';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService, PrimeNGConfig } from 'primeng/api';
import { difference } from 'lodash';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'cvc-inner-calendar',
  templateUrl: './inner-calendar.html',
})
export class CalendarInnerComponent extends Calendar implements OnInit, OnChanges {
  @Input() showRecursive = true;
  @Input() dateKey = 'key';
  @Input() dateRanges = [moment(new Date()).add(-2, 'month').toDate(), new Date()];
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() changeEndDate: EventEmitter<any> = new EventEmitter();
  outputFormat = { start: new Date(), end: new Date() };
  @Input() monthYear = {};
  @Input() endDate = new Date();
  @Input() startYears = '2010:' + moment().year() + 1;
  @Input() endYears = '2010:' + moment().year() + 1;
  @Input() currentCalendarDate: any;
  @Input() identifier = 'MTD';
  @Input() defaultSelectedDate: any;
  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cdr: ChangeDetectorRef,
    private _zone: NgZone,
    private _config: PrimeNGConfig,
    public overlayService: OverlayService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) document: Document
  ) {
    super(document, el, renderer, cdr, _zone, _config, overlayService);
    this._maxDate = new Date();
    if (this._locale) {
      this._locale.monthNamesShort = [
        this.translateService.instant('Calender_months.jan'),
        this.translateService.instant('Calender_months.feb'),
        this.translateService.instant('Calender_months.mar'),
        this.translateService.instant('Calender_months.apr'),
        this.translateService.instant('Calender_months.may'),
        this.translateService.instant('Calender_months.jun'),
        this.translateService.instant('Calender_months.jul'),
        this.translateService.instant('Calender_months.aug'),
        this.translateService.instant('Calender_months.sep'),
        this.translateService.instant('Calender_months.oct'),
        this.translateService.instant('Calender_months.nov'),
        this.translateService.instant('Calender_months.dec'),
      ];
      this._locale.monthNames = [
        this.translateService.instant('January'),
        this.translateService.instant('February'),
        this.translateService.instant('March'),
        this.translateService.instant('April'),
        this.translateService.instant('May'),
        this.translateService.instant('June'),
        this.translateService.instant('July'),
        this.translateService.instant('August'),
        this.translateService.instant('September'),
        this.translateService.instant('October'),
        this.translateService.instant('November'),
        this.translateService.instant('December'),
      ];
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultSelectedDate) {
      const val = changes.defaultSelectedDate.currentValue;
      let start: any = moment(new Date()).toDate();
      let end: any = moment(new Date()).toDate();
      if (val && typeof changes.defaultSelectedDate.currentValue === 'string') {
        const arr = val.split('to');
        if (typeof arr === 'object' && arr.length > 0) {
          start = moment(arr[0]).toDate();
          end = moment(arr[1]).toDate();
        }
      }
      if (this.dateKey === 'start') {
        this.value = start;
        this.change.emit({ [this.dateKey]: start });
        const year = start.getFullYear();
        const month = start.getMonth();
        this.onYearDropdownChange(String(year));
        this.onMonthDropdownChange(String(month));
        // this.currentMonth = 2;
      }

      if (this.dateKey === 'end') {
        this.value = end;
        this.change.emit({ [this.dateKey]: end });
        const year = end.getFullYear();
        const month = end.getMonth();
        this.onYearDropdownChange(String(year));
        this.onMonthDropdownChange(String(month));
      }
    }
    // if (changes['endDate'] && this.dateKey === 'start') {
    //   const d1 = changes['endDate'].currentValue || new Date().toISOString();
    //   this._maxDate = d1;
    //   if (this.value === undefined) {
    //     const d = moment(new Date()).add(-1, 'month').toDate();
    //     this.value = d;
    //     // this.change.emit({ [this.dateKey]: d });
    //   }
    //   if (moment(d1).diff(this.value) < 0) {
    //     const d = moment(d1).add(-1, 'day').toDate();
    //     this.value = d;
    //     // this.change.emit({ [this.dateKey]: d });
    //   }
    //   if (this.currentMonth !== undefined && this.currentMonth != null && this.currentYear) {
    //     this.createMonths(this.currentMonth, this.currentYear);
    //   }
    // }
  }
  ngOnInit() {
    super.ngOnInit();
    if (this.dateKey === 'start') {
      const pMonth = moment().month(-3).format('M');
      // this.onMonthDropdownChange(pMonth + '');
      this.onMonthDropdownChangeCustom();
    }
  }

  onMonthDropdownChangeCustom() {
    const currentMonth = moment().month();
    const diference = currentMonth - 1;

    if (diference != -1) {
      this.onMonthChange.emit({ month: difference, year: this.currentYear });
      this.createMonths(diference, this.currentYear);
    } else {
      this.onMonthChange.emit({ month: 11, year: this.currentYear - 1 });
      this.createMonths(11, this.currentYear - 1);
    }

    //this.currentMonth = parseInt(pMonth);
    //this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
    if (!this.showRecursive) {
      // const d = `${this.currentMonth + 1} 01 ${this.currentYear}`;
      // this.change.emit({ [this.dateKey]: new Date(d) });
    }
  }

  onMonthDropdownChange(m: string) {
    // tslint:disable-next-line:radix
    this.currentMonth = parseInt(m);
    this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
    this.createMonths(this.currentMonth, this.currentYear);
    if (!this.showRecursive) {
      // const d = `${this.currentMonth + 1} 01 ${this.currentYear}`;
      // this.change.emit({ [this.dateKey]: new Date(d) });
    }
  }

  onYearDropdownChange(y: string) {
    // tslint:disable-next-line:radix
    this.currentYear = parseInt(y);
    this.onYearChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
    this.createMonths(this.currentMonth, this.currentYear);
    if (!this.showRecursive) {
      // this.change.emit({ [this.dateKey]: new Date(`${this.currentMonth + 1} 01 ${this.currentYear}`) });
    }
  }

  onInnerCalendarChange(e: { start: Date; end: Date }) {
    this.outputFormat = { ...this.outputFormat, ...e };
    this.change.emit(this.outputFormat);
  }

  onDateSelect(event: any, dateMeta: any) {
    if (this.disabled || !dateMeta.selectable) {
      event.preventDefault();
      return;
    }

    if (this.isMultipleSelection() && this.isSelected(dateMeta)) {
      this.value = this.value.filter((date: any, i: any) => {
        return !this.isDateEquals(date, dateMeta);
      });
      this.updateModel(this.value);
    } else {
      if (this.shouldSelectDate(dateMeta)) {
        if (dateMeta.otherMonth) {
          this.currentMonth = dateMeta.month;
          this.currentYear = dateMeta.year;
          this.createMonths(this.currentMonth, this.currentYear);
          this.selectDate(dateMeta);
        } else {
          this.selectDate(dateMeta);
        }
      }
    }

    if (this.isSingleSelection() && (!this.showTime || this.hideOnDateTimeSelect)) {
      setTimeout(() => {
        event.preventDefault();
        this.hideOverlay();

        if (this.mask) {
          this.disableModality();
        }

        this.cd.markForCheck();
      }, 150);
    }

    this.updateInputfield();
    event.preventDefault();
    if (this.dateKey === 'end') {
      this.endDate = this.value;
    }
    this.change.emit({ [this.dateKey]: this.value });
  }
}
