import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { SignalrService } from '@app/core/signalr.service';
import { Transmission } from '@app/core/types/transmission';
import { ToastService } from '../toast/toast.service';
import { Logger } from '@app/core/logger.service';
import { Observable, Subscription } from 'rxjs';

const log = new Logger('App');

@Injectable({
  providedIn: 'root',
})
export class RealTimeNotificationService {
  pageNo = 1;
  pageSize = 1000;
  total = 1;
  transmissions: Transmission[] = [];
  notificationListing: any[] = [];
  user: any;
  notificationCount = 0;
  private subscriptions = new Subscription();

  constructor(
    private signalRService: SignalrService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private httpClient: HttpClient
  ) {
    // this.subscribeListeners();
  }

  init() {
    const userInfo = this.authService.getUser();
    if (userInfo !== null && userInfo !== undefined) {
      this.user = userInfo;
      this.pageNo = 1;
      this.pageSize = 1000;
      this.total = 1;
      this.notificationListing = [];
      this.notificationCount = 0;

      this.transmissions = [
        {
          title: 'Publishing',
          count: 0,
          classes: 'card card--plain',
          icon: 'fal fa-arrow-to-top',
        },
        {
          title: 'Successful',
          count: 0,
          classes: 'card card--plain card--successful',
          icon: 'fal fa-check-double',
        },
        {
          title: 'Failed',
          count: 0,
          classes: 'card card--plain card--failed',
          icon: 'fal fa-times',
        },
      ];
    }
  }

  subscribeListeners() {
    this.subscriptions.closed = false;
    //this.getTransmissionList();
    this.onSampleStart();
    this.onTransmissionStartNotification();
    this.ontransmissionFailedNotification();
    this.onTransmissionCompletedNotification();
    this.onTransmissionTotalRecordsNotification();
  }

  unsubscribeListeners() {
    this.subscriptions.unsubscribe();
    this.notificationCount = 0;
  }

  SendApiNotifications(data: any, isLoaderShow: boolean = false): Observable<Notification[]> {
    let loginNotificationId: string;
    let lastSyncDate: Date = null;
    if (localStorage.getItem('loginNotificationId') != null) {
      loginNotificationId = localStorage.getItem('loginNotificationId');
    } else {
      loginNotificationId = '';
    }

    if (localStorage.getItem('lastSyncDateNotify') != null) {
      lastSyncDate = new Date(localStorage.getItem('lastSyncDateNotify'));
    } else {
      lastSyncDate = new Date();
    }
    const jsonData = JSON.stringify(data);
    const requestData: any = {};
    requestData.loginNotificationId = loginNotificationId;
    requestData.lastSyncDate = lastSyncDate.toISOString();
    requestData.notficationString = jsonData;
    if (!isLoaderShow) {
      return this.httpClient.disableLoader().skipErrorHandler().put<Notification[]>('/Notification', requestData);
    } else {
      return this.httpClient.skipErrorHandler().put<Notification[]>('/Notification', requestData);
    }
  }

  private onSampleStart() {
    this.subscriptions.add(
      this.signalRService.Sample.subscribe((data) => {
        if (data !== '') {
        }
      })
    );
  }

  private onTransmissionStartNotification() {
    this.subscriptions.add(
      this.signalRService.TransmissionStartNotification.subscribe((data) => {
        if (data !== '') {
          this.notificationCount++;
          this.transmissions[0].count++;
          let isExists = false;
          for (let i = 0; i < this.notificationListing.length; i++) {
            if (this.notificationListing[i].id === data.id) {
              isExists = true;
              this.notificationListing[i].progress = 'In_Progress';
              break;
            }
          }
          if (!isExists) {
            this.notificationListing.unshift({
              id: data.id, // distribution Id
              heading: data.name, // 'Test demo build 1',// distribution Name
              report: { total: 0, success: 0, failed: 0 },
              date: new Date(),
              progress: 'In_Progress',
            });
          }
        }
      })
    );
  }

  private ontransmissionFailedNotification() {
    this.subscriptions.add(
      this.signalRService.TransmissionFailedNotification.subscribe((data) => {
        if (data !== '') {
          if (data.L !== 9) {
            // failed count increased
            this.transmissions[2].count++;
            // publishing count decrease.
            if (this.transmissions[0].count > 0) {
              this.transmissions[0].count--;
            }
            let isExists = false;
            for (let i = 0; i < this.notificationListing.length; i++) {
              if (this.notificationListing[i].id === data.id) {
                isExists = true;
                this.notificationListing[i].report.total = data.t;
                // this.notificationListing[i].report.success = data.s;
                this.notificationListing[i].report.failed = data.f;
                this.notificationListing[i].progress = 'Error';
                break;
              }
            }
            if (!isExists) {
              this.notificationListing.unshift({
                id: data.id, // distribution Id
                heading: data.name, // 'Test demo build 1',// distribution Name
                report: { total: data.t, success: data.s, failed: data.f },
                date: new Date(),
                progress: 'Error',
              });
            }
          } else {
            // level = 9 reperesent post reminder failed which always occured
            // after successfully submission of transmission
            this.onTransmissionCompleted(data);
            this.toastService.error(
              'Reminder schedule process has been failed on ' + data.Name + ' but transmistion successfully published.'
            );
          }
        }
      })
    );
  }

  private onTransmissionCompletedNotification() {
    this.subscriptions.add(
      this.signalRService.TransmissionCompletedNotification.subscribe((data) => {
        if (data !== '') {
          this.onTransmissionCompleted(data);
        }
      })
    );
  }

  private onTransmissionTotalRecordsNotification() {
    this.subscriptions.add(
      this.signalRService.TransmissionTotalRecordsNotification.subscribe((data) => {
        if (data !== '') {
          let isExists = false;
          for (let i = 0; i < this.notificationListing.length; i++) {
            if (this.notificationListing[i].id === data.id) {
              isExists = true;
              this.notificationListing[i].report.total = data.p;
              this.notificationListing[i].progress = 'In_Progress';
              break;
            }
          }
          if (!isExists) {
            this.notificationListing.unshift({
              id: data.id,
              heading: data.name,
              report: { total: data.p, success: 0, failed: 0 },
              date: new Date(),
              progress: 'In_Progress',
            });
          }
        }
      })
    );
  }

  private onTransmissionCompleted(data: any) {
    // completed count increased
    this.transmissions[1].count++;

    // publishing count decreased
    if (this.transmissions[0].count > 0) {
      this.transmissions[0].count--;
    }

    let isExists = false;
    for (let i = 0; i < this.notificationListing.length; i++) {
      if (this.notificationListing[i].id === data.id) {
        this.notificationListing[i].report.total = data.t;
        this.notificationListing[i].report.success = data.s;
        // this.notificationListing[i].report.failed = data.f;
        this.notificationListing[i].progress = 'Completed';
        isExists = true;
        break;
      }
    }
    if (!isExists) {
      this.notificationListing.unshift({
        id: data.id, // distribution Id
        heading: data.name, // 'Test demo build 1',// distribution Name
        report: { total: data.t, success: data.s, failed: data.f },
        date: new Date(),
        progress: 'Completed',
      });
    }
  }

  private getTransmissionNotificaitonsCount() {
    const state = 0;
    this.getTransmissionNofificaitonsCount(this.user.ClientId, this.user.MarketId).subscribe(
      (result: any) => {
        this.transmissions[0].count = this.notificationListing.filter(function (x: any) {
          return x.progress === 'Completed';
        }).length;
        this.transmissions[1].count = this.notificationListing.filter(function (x: any) {
          return x.progress === 'Error';
        }).length;
        this.transmissions[2].count = result.Publishing;
      },
      (err) => {
        log.debug(err);
      }
    );
  }

  private getTransmissionList() {
    const state = 0;
    this.getTransmissionsList(this.user.ClientId, this.user.MarketId, this.pageNo, this.pageSize, state).subscribe(
      (result: any) => {
        this.pageNo = result.Response.PageNo;
        this.pageSize = result.Response.PageSize;
        this.total = result.Response.Total;
        const logs = result.Response.Data;
        const rt: any = Object;
        rt.Total = logs.length;
        rt.Completed = logs.filter((x: any) => x.Status === 'Published').length;
        rt.Failed = logs.filter((x: any) => x.Status === 'Failed').length;
        if (logs.length != null && logs.length > 0) {
          for (let i = 0; i < logs.length; i++) {
            const id = logs[i].Id;
            const name = logs[i].Name;
            let isExists = false;
            for (let j = 0; j < this.notificationListing.length; j++) {
              if (this.notificationListing[j].id === id) {
                isExists = true;

                this.notificationListing[j].report.total = rt.Total;
                this.notificationListing[j].report.success = rt.Completed;
                this.notificationListing[j].report.failed = rt.Failed;
                switch (logs[i].Status) {
                  case 'Publishing':
                    // in-progress
                    this.notificationListing[j].progress = 'Pending';
                    break;
                  case 'Published':
                    // completed
                    this.notificationListing[j].progress = 'Completed';
                    break;
                  case 'Failed':
                    // error
                    this.notificationListing[j].progress = 'Error';
                    break;
                  case 0:
                  default:
                    // not-started
                    this.notificationListing[j].progress = 'Completed';
                    break;
                }
                break;
              }
            }
            if (!isExists) {
              const obj = {
                id: id, // distribution Id
                heading: name, // 'Test demo build 1',// distribution Name
                report: {
                  total: rt.Total,
                  success: rt.Completed,
                  failed: rt.Failed,
                },
                date: new Date(logs[i].CreatedOn),
                progress: 'true',
              };

              switch (logs[i].Status) {
                case 'Publishing':
                  // in-progress
                  obj.progress = 'Pending';
                  break;
                case 'Published':
                  // completed
                  obj.progress = 'Completed';
                  break;
                case 'Failed':
                  // error
                  obj.progress = 'Error';
                  break;
                case 0:
                default:
                  // not-started
                  obj.progress = 'Completed';
                  break;
              }
              this.notificationListing.push(obj);
            }
          }
        }

        this.getTransmissionNotificaitonsCount();
      },
      (err) => {
        log.debug(err);
      }
    );
  }

  private getTransmissionNofificaitonsCount(clientId: string, marketId: string) {
    const url = '/notice/stats/' + marketId;
    return this.httpClient.skipErrorHandler().get(url);
  }

  private getTransmissionsList(clientId: string, marketId: string, pageNo: number, pageSize: number, state: number) {
    const url = '/notice/' + marketId + '/trans/' + pageNo + '/' + pageSize + '/' + state;
    return this.httpClient.get(url);
  }
}
