<!-- <perfect-scrollbar
    (psScrollLeft)="onScrolled($event)"
    (psScrollRight)="onScrolled($event)"
    >
    <div (scroll)="onScrolled($event)"> -->
<cvp-scrollPanel #scrollBar (scrollEmitter)="onScrolled($event)" [style]="{width: '100%'}">
  <ng-content></ng-content>
</cvp-scrollPanel>
<!-- </div>

  </perfect-scrollbar> -->
