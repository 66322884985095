<div class="my-surveys" *ngIf="!rowTemplate">
  <div class="survey-block cursor-pointer" (click)="listViewAction(row)">
    <div class="name table-cell">
      <div class="number">{{pageSize * (offset) + rowIndex + 1}}</div>
      <div class="details"><h4>{{row.Name}}</h4></div>
    </div>
    <ng-container *ngFor="let column of columns">
      <div class="responses table-cell" *ngIf="column.name != 'Name' && column.name != 'Actions'">
        <p>{{column.name}}</p>
        <p class="color-blue">{{row[column.prop]}}</p>
      </div>
    </ng-container>
    <div class="actions table-cell"></div>
  </div>
</div>

<ng-template
  *ngIf="rowTemplate"
  [ngTemplateOutlet]="rowTemplate.template"
  [ngTemplateOutletContext]="{
   rowIndex: rowIndex,
   row: row
 }"
>
</ng-template>
