import { Table } from 'primeng/table';
import { Component, ViewChild, ElementRef, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CVPTableComponent } from './table.component';

const Table_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Table),
  multi: true,
};
@Component({
  selector: '[pScrollableView]',
  templateUrl: './templates/scrollableView.html',
  providers: [Table_ACCESSOR, Table],
})
export class CVPScrollableView {
  // @Input("cvpScrollableView") columns: RfColumn[];
  @ViewChild('scrollHeader') scrollHeaderViewChild: ElementRef;
  @ViewChild('scrollHeaderBox') scrollHeaderBoxViewChild: ElementRef;
  @ViewChild('scrollBody') scrollBodyViewChild: ElementRef;
  @ViewChild('scrollTable') scrollTableViewChild: ElementRef;
  @ViewChild('scrollFooter') scrollFooterViewChild: ElementRef;
  @ViewChild('scrollFooterBox') scrollFooterBoxViewChild: ElementRef;
  @ViewChild('virtualScroller') virtualScrollerViewChild: ElementRef;
}
