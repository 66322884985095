import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-idle-timeout',
  templateUrl: './idle-timeout.component.html',
  styleUrls: ['./idle-timeout.component.sass'],
})
export class IdleTimeoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
