import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Observable, of, Subscriber, Subject, BehaviorSubject, merge, from, empty, concat } from 'rxjs';
import { startWith, tap, switchMap, flatMap, take } from 'rxjs/operators';
import { RequestCache } from './request.cache.service';
import { LoaderService } from '../loader.service';
import { Logger } from '../logger.service';
import { PaginationHeader } from '../types/paginationData';
import { Store } from '@ngrx/store';
import { ChangeCacheState } from '@gstate/actions/cache.action';
import { CVPActions } from '@gstate/actions';
import { CachedItem } from 'ngforage';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { environment } from '@env/environment';
const log = new Logger('ActivityInterceptor');

@Injectable()
export class ActivityInterceptor implements HttpInterceptor {
  pageName: string = '';
  module: string = '';
  message: string = '';
  constructor(private notificationsService: NotificationsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    var request = req;
    if (environment.signalRConfigured == true) {
      this.notificationsService.ActivityRequestNotification(this.message, this.pageName, this.module, req);
    }
    return next.handle(req);
  }

  configure(data: any): ActivityInterceptor {
    this.pageName = data.pageName;
    this.module = data.module;
    this.message = data.message;
    return this;
  }
}
