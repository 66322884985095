import { NgForage } from 'ngforage';
import { Observable, Observer, from, empty, of } from 'rxjs';
import { OrderBy } from '@app/core/types/orderby';
import { Guid } from 'guid-typescript';

export class NgForageService<T> {
  constructor(protected storeName: string, private readonly ngf: NgForage) {
    this.ngf.name = 'ClearView';
    this.ngf.storeName = storeName;
  }

  addAsync(data: T): Observable<T> {
    const id: Guid = Guid.create();
    const key: string = id.toString();
    data['id'] = key;
    data['createdon'] = new Date();
    return from(this.ngf.setItem<T>(key, data));
  }

  // For adding notification from server
  addExisting(datas: T[]) {
    datas.forEach((data) => {
      const key = data['id'];
      this.ngf
        .setItem<T>(key, data)
        .then((res) => {})
        .catch((error) => {});
    });
  }

  addExist(data: T): Promise<T> {
    const key = data['id'];
    return this.ngf.setItem<T>(key, data);
  }

  // For adding notification from server
  async addIfNoExist(id: any, data: T) {
    if (id === null) {
      const id: Guid = Guid.create();
      const key: string = id.toString();
      data['id'] = key;
      data['createdon'] = new Date();
      this.ngf.setItem<T>(key, data);
    } else {
      const obj = await this.ngf.getItem(id);
      if (!obj) {
        this.ngf.setItem<T>(id, data);
      }
    }
  }

  removeAsync(key: string): Observable<void> {
    return from(this.ngf.removeItem(key));
  }

  clearAsync(): Observable<void> {
    return from(this.ngf.clear());
  }

  clearAsync2(): Observable<void> {
    this.ngf.name = 'MyApp';
    this.ngf.storeName = 'ng_forage';
    return from(this.ngf.clear());
  }

  getByCountAsync(count: number, sort?: string, orderBy?: OrderBy): Observable<T[]> {
    return new Observable((observer: Observer<T[]>) => {
      this.getAllAsync().subscribe(
        (data) => {
          observer.next(this.sort(data, sort, orderBy).slice(0, count));
        },
        (error) => observer.error(error),
        () => observer.complete()
      );
    });
  }

  CountAsync(): Observable<number> {
    return from(this.ngf.length());
  }

  protected getAllAsync(sort: string = 'date', orderBy: OrderBy = OrderBy.Desc): Observable<T[]> {
    return new Observable((observer: Observer<T[]>) => {
      const data: T[] = [];
      from(
        this.ngf.iterate((v: T): void => {
          data.push(v);
        })
      ).subscribe((v) => observer.next(this.sort(data, sort, orderBy)));
    });
  }

  setItem(data: T): Observable<T> {
    // const key: string = data.id;
    // data['id'] = data.id;
    // data['createdon'] =new Date;
    return from(this.ngf.setItem<T>(data['id'], data));
  }

  private sort(data: T[], key: string, orderBy: OrderBy): T[] {
    if (key !== 'date' && key !== 'createdon') {
      return data.sort((a, b) => {
        if (orderBy === OrderBy.Asc) {
          return a[key] - b[key];
        } else if (orderBy === OrderBy.Desc) {
          return b[key] - a[key];
        }
      });
    } else {
      return data.sort((a: any, b: any) => {
        if (orderBy === OrderBy.Asc) {
          return 0 - (new Date(a[key]) > new Date(b[key]) ? 1 : -1);
        } else if (orderBy === OrderBy.Desc) {
          return new Date(a[key]) > new Date(b[key]) ? 1 : -1;
        }
      });
    }
  }
}
