import { Injectable, Injector } from '@angular/core';
import { environment } from '@env/environment';
import 'whatwg-fetch';
import { Router } from '@angular/router';
import { AuthenticationService } from './core/authentication/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from './core';
import { AppConfigurationService } from './shared/services/app.configuration.service';
import { Identifiers } from './shared/services/app.config.type';

@Injectable()
export class MyStartupService {
  public data: any;
  private authService: AuthenticationService;
  private http: HttpClient;

  constructor(private injector: Injector) {
    if (this.authService === undefined) {
      this.authService = this.injector.get(AuthenticationService);
    }
    if (this.http === undefined) {
      this.http = this.injector.get(HttpClient);
    }
    const fire = this.http['appConfig'].getConfig(Identifiers.FrontendLogger, 'fire');
    Logger.fire = fire;
    Logger.httpClient = this.http;
    Logger.authService = this.authService;
  }

  isTokenExpired(expires_in: number): boolean {
    if (!expires_in) {
      return true;
    }

    const seconds = 1000;
    const d = new Date();
    const t = d.getTime();

    if (expires_in < Math.round(t / seconds)) {
      return true;
    }
    return false;
  }

  async load() {
    const user = await this.authService.initUser();
    const router = this.injector.get(Router);
    const iSSOLogin = this.authService.checkIsSSOLogin();

    const ls = JSON.parse(localStorage.getItem('authorizationData'));
    if (ls === undefined || !ls || (iSSOLogin === false && (user == null || this.isTokenExpired(user.expires_at)))) {
      return new Promise((resolve, reject) => {
        resolve(1);
      });
    } else if (ls) {
      const features = ls['Features'];
      const tempclientId = ls['ClientId'];
      if (features === null || features === undefined || tempclientId === null || tempclientId === undefined) {
        localStorage.clear();
        window.location.href = '/';
        return new Promise((resolve, reject) => {
          resolve(1);
        });
      }
    }

    const clientId = ls['ClientId'];
    if (clientId !== undefined && clientId) {
      // const filterCOnfiguration = await this.getConfiguration('gf','Global Filter');
      const languageCode = localStorage.getItem('language');
      //const languageCode = ls['DefaultLanguage'];
      const marketLanguage = ls['DefaultMarketLanguage'];
      const token = this.authService.getToken();

      // const token = "77992f494f407c21c21afa39524a057f6e736966c92c28de57faf422f31c0e33";
      const headers = { 'Content-Type': 'application/json' };

      const allowedPersmission = ls['Features'].find((x: any) => x.Name === 'allow_portal_translation');
      const language = allowedPersmission !== undefined ? languageCode : marketLanguage;
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }
      return new Promise((resolve, reject) => {
        let url: string;
        if (localStorage.getItem('translations')) {
          const translations = localStorage.getItem('translations');
          this.data = JSON.parse(translations);
          resolve(1);
        } else {
          if (environment.production) {
            url = `${environment.apiUrl + environment.serverUrl + environment.apiVersion}/multilingual/${language}`;
          } else {
            url = `${environment.apiUrl}/api/v1/multilingual/${language}`;
          }
          return fetch(url, { headers })
            .then((res) => {
              if (res.status === 200) {
                return res.json();
              } else if (res.status === 401) {
                router.navigate(['/endsession']);
              } else {
                window.location.href = 'error.html';
              }
            })
            .then((res) => {
              this.data = res;
              // localStorage.setItem('translations', JSON.stringify(res));
              localStorage.setItem('translations', JSON['makeStringify'](res));
              resolve(res);
            })
            .catch((error) => {
              window.location.href = 'error.html';
            });
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(1);
      });
    }
  }

  private getConfiguration(id: string, module: string) {
    return new Promise((resolve, reject) => {
      const user = JSON.parse(localStorage.getItem('authorizationData'));
      if (!user) {
        resolve(true);
      }
      const url = `/configuration?Id=${id}&UserId=${user.Id}&ClientId=${user.ClientId}&Module=${module}`;
      return this.http
        .disableLoader()
        .disableHttpCancel()
        .get<any>(url)
        .subscribe((configuration: any) => {
          if (configuration && configuration.Settings) {
            const object = configuration.Settings;
            for (const property in object) {
              localStorage.setItem(property, object[property]);
            }
            resolve(true);
          } else {
            resolve(true);
          }
        });
    });
  }
}
