import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-oops',
  templateUrl: './oops.component.html',
  styleUrls: ['./oops.component.sass'],
})
export class OopsComponent implements OnInit {
  permissions: any;
  constructor(public router: Router) {}
  ngOnInit() {}

  toHome() {
    // window.location.href = '/reset-login';
    this.router.navigate(['/reset-login']);
  }
}
