import { Directive, Input, HostListener } from '@angular/core';
import { RowToggler } from 'primeng/table';
import { CVPTableComponent } from '../table.component';

@Directive({
  selector: '[cvpRowToggler]',
})
export class CVPRowToggler extends RowToggler {
  @Input('cvpRowToggler') data: any;

  @Input() pRowTogglerDisabled: boolean;

  constructor(public dt: CVPTableComponent) {
    super(dt);
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.isEnabled()) {
      this.dt.toggleRow(this.data, event);
      event.preventDefault();
    }
  }

  isEnabled() {
    return this.pRowTogglerDisabled !== true;
  }
}
