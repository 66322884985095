import { Injectable } from '@angular/core';
import { Config } from './app.config.type';

@Injectable()
export class AppConfigurationService {
  config: Array<Config>;
  constructor() {
    this.config = this.getAuthorizationDataLocalStorage();
  }
  getByIdentifier(identifier: string) {
    this.config = this.getAuthorizationDataLocalStorage();
    if (this.config) {
      const conff = this.config.find((conf: Config) => {
        return conf.Identifier === identifier;
      });
      return conff ? conff : false;
    } else {
      return false;
    }
  }
  getByFeatureId(featureId: string) {
    this.config = this.getAuthorizationDataLocalStorage();
    if (this.config) {
      const conff = this.config.find((conf: Config) => {
        return conf.FeatureId === featureId;
      });
      return conff ? conff : false;
    } else {
      return false;
    }
  }
  getClientConfig(identifier: string) {
    if (this.config) {
      const conff = this.config.find((conf: Config) => {
        return conf.Identifier === identifier;
      });
      if (conff != null) {
        return conff?.ClientConfig ? conff?.ClientConfig : conff;
      } else {
        return {};
      }
    }
  }
  getUserEscalationConfig() {
    if (this.config) {
      const conff = this.getByIdentifier('user-escalation-level_permission');
      var clientConfig = conff['ClientConfig'];
      var fields: any[] = [];
      if (clientConfig != null && clientConfig != undefined) fields = clientConfig['Fields'];
      else fields = conff['Fields'];
      return fields;
    }
  }

  getHideColumns(cols: any[], fieldName: string, hideFieldName: string, reportName: string) {
    if (this.config) {
      const conff = this.getByIdentifier('DynamicColunmsHides');
      const clientConfig = conff['ClientConfig'];
      let fields: any[] = [];
      if (clientConfig != null && clientConfig !== undefined) {
        fields = clientConfig['Fields'];
      } else {
        fields = conff['Fields'];
      }

      let filter = {};
      let columns: any[] = [];

      if (fields !== undefined && fields != null) {
        filter = fields.filter((x) => x['key'] === reportName)[0];
      }

      if (filter !== undefined && filter != null) {
        columns = filter['value'];
      }

      cols.forEach((e) => {
        const colmns = (columns || []).filter((w) => w['name'] === e[fieldName])[0];
        if (colmns !== undefined && colmns != null) {
          e[hideFieldName] = colmns['visibility'];
          e.order = !e.order ? 1000 : columns['order'];
        } else {
          e[hideFieldName] = true;
          e.order = 1000;
        }
      });
    } else {
      // console.log('Error', 'Report Hide config not found');
    }
    cols.sort((a: any, b: any) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    return cols;
  }

  getHideColumnsV2(cols: any[], fieldName: string, hideFieldName: string, reportName: string) {
    if (this.config) {
      const conff = this.getByIdentifier('DynamicColunmsHides');
      const clientConfig = conff['ClientConfig'];
      let fields: any[] = [];
      if (clientConfig != null && clientConfig !== undefined) {
        fields = clientConfig['Fields'];
      } else {
        fields = conff['Fields'];
      }

      let filter = {};
      let columns: any[] = [];

      if (fields !== undefined && fields != null) {
        filter = fields.filter((x) => x['key'] === reportName)[0];
      }

      if (filter !== undefined && filter != null) {
        columns = filter['value'];
      }

      cols = columns.map((x: any) => {
        return {
          field: x.fieldname,
          header: x.label,
          name: x.name,
        };
      });

      cols.forEach((e) => {
        const colmns = (columns || []).filter((w) => w['name'] === e[fieldName])[0];
        if (colmns !== undefined && colmns != null) {
          e[hideFieldName] = colmns['visibility'];
          e.order = !e.order ? 1000 : columns['order'];
        } else {
          e[hideFieldName] = true;
          e.order = 1000;
        }
      });
    } else {
      // console.log('Error', 'Report Hide config not found');
    }
    cols.sort((a: any, b: any) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    return cols;
  }

  getReporColumns(reportName: string) {
    if (this.config) {
      const conf = this.getByIdentifier('reporting-columns-config');
      const clientConfig = conf['ClientConfig'];
      let fields: any[] = [];
      if (clientConfig != null && clientConfig !== undefined) {
        fields = clientConfig['Fields'];
      } else {
        fields = conf['Fields'];
      }

      let filter = {};
      let columns: any[] = [];

      if (fields !== undefined && fields != null) {
        filter = fields.filter((x) => x['key'] === reportName)[0];
      }

      if (filter !== undefined && filter != null) {
        columns = filter['value'];
      }

      columns = (columns || []).filter((x) => x['display'] === true);

      columns.sort((a: any, b: any) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));

      columns = columns.map((col: any) => {
        return { field: col.name, header: col.header, columnName: col.name };
      });

      return columns;
    }
  }
  getConfig(identifier: string, key = '') {
    const config = this.getByIdentifier(identifier);
    if (config && config.Fields && config.Fields[key]) {
      return config.Fields[key].value;
    }
    return false;
  }
  private getAuthorizationDataLocalStorage(): any {
    const authData = localStorage.getItem('authorizationData');
    return authData !== 'null' && authData !== null ? JSON.parse(authData)['Configurations'] : false;
  }

  getReportMenu() {
    const config = this.getByIdentifier('dynamic_rpt_menu_link');
    if (config && config.Fields) {
      return config.Fields;
    }
    return false;
  }
}
