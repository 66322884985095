import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { concatMap, retryWhen, delay, delayWhen } from 'rxjs/operators';
import { Logger } from '../logger.service';

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;
export const codesForRetry = [506, 507, 508, 510, 511, 0];
const log = new Logger('MonitorInterceptor');

@Injectable()
export class MonitorInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((error) =>
        error.pipe(
          concatMap((error, count) => {
            if (count < retryCount && codesForRetry.includes(error.status)) {
              error.count = count;
              log.error(error);
              return of(error).pipe(delay(retryWaitMilliSeconds * (count + 1)));
            }
            return throwError(() => {
              return error;
            });
          })
        )
      )
    );
  }
}
