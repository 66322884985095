import { Injectable } from '@angular/core';
import { GlobalFilterConfigService } from '@app/shared/module/global-filters/global-filters-config.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class RootService {
  currentMonth: any = moment();
  constructor(public gbfConfig: GlobalFilterConfigService, public translateService: TranslateService) {}

  getCalenderDropDownOptions() {
    let calendardropdown: any = [
      {
        label: 'R10',
        key: 'R10',
        value: () => [moment().subtract(10, 'days'), moment()],
      },
      {
        label: 'R30',
        key: 'R30',
        value: () => [moment().subtract(30, 'days'), moment()],
      },
      {
        label: 'R90',
        key: 'R90',
        value: () => [moment().subtract(90, 'days'), moment()],
      },
      {
        label: 'MTD',
        key: 'MTD',
        value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
      },
      {
        label: '3MTD',
        key: '3MTD',
        //value: (value: any = this.currentMonth) => [moment(value).startOf('month'), moment()],
        value: () => [moment().startOf('month').subtract(2, 'month'), moment()],
      },
      {
        label: '6MTD',
        key: '6MTD',
        value: () => [moment().startOf('month').subtract(5, 'month'), moment()],
      },
      {
        label: '12MTD',
        key: '12MTD',
        value: () => [moment().startOf('month').subtract(5, 'month'), moment()],
      },
      {
        label: 'QTD',
        key: 'QTD',
        value: (value: any = this.currentMonth) => [
          moment(value).quarter(moment(value).quarter()).startOf('quarter'),
          moment(value).get('month') === moment().get('month') ? moment() : moment(value).endOf('month'),
        ],
      },
      {
        label: 'YTD',
        key: 'YTD',
        value: (value: any = this.currentMonth) => [moment(value).startOf('year'), moment()],
      },
      {
        label: 'M36',
        key: 'M36',
        value: () => [moment().subtract(-36, 'month'), moment()],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_month.label'),
        key: 'PREVIOUS MONTH',
        value: () => [
          moment(new Date()).add(-1, 'month').startOf('month'),
          moment(new Date()).add(-1, 'month').endOf('month'),
        ],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_quarter.label'),
        key: 'PREVIOUS QUARTER',
        value: () => [
          moment().subtract(1, 'quarter').startOf('quarter'),
          moment().subtract(1, 'quarter').endOf('quarter'),
        ],
      },
      {
        // tslint:disable-next-line:max-line-length
        label: this.translateService.instant('previous_year.label'),
        key: 'PREVIOUS YEAR',
        value: () => [moment().add(-1, 'year').startOf('year'), moment().add(-1, 'year').endOf('year')],
      },
      // tslint:disable-next-line:max-line-length
      {
        label: this.translateService.instant('program_to_date.label'),
        key: 'PROGRAM TO DATE',
        value: () => [moment().add(1, 'month').startOf('month'), moment().add(2, 'month')],
      },
      {
        label: this.translateService.instant('custom_range.label'),
        key: 'CUSTOM RANGE',
        value: (): any => [],
      },
    ];
    const found = this.gbfConfig.filterConfig.config.find((x: any) => x.name === 'calendar');
    if (found && found.dateDropDown) {
      let dropdown = found.dateDropDown;
      dropdown.map((obj: any) => {
        calendardropdown.forEach((element: any) => {
          if (obj.key === element.key) {
            obj.label = element.label;
            obj.value = element.value;
          }
        });
      });
      return dropdown;
    }
    return calendardropdown;
  }
}
