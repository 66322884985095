import { Component, OnInit, Input, EventEmitter, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { alphabate } from '../../../constans';
import { Output } from '@angular/core';
import { TopBar } from '../../../types/topBar';
import { GridHeaderFilterDropdownDirective } from './alphabetfilter.dropdown.directive';

interface AlphabetFilter {
  alphabet: string;
  selectedColumn: String;
}

@Component({
  selector: 'rdatatable-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss'],
})
export class HeaderFilterComponent implements OnInit {
  @Input() columns: any;
  @Input() alphabetBar: boolean;
  @Input() topBar: TopBar;
  @Input() dropdownTemplate: GridHeaderFilterDropdownDirective;
  @Input() selectedAlphabet: string;
  @Output() onAlphabetAndColumnChange = new EventEmitter();
  alphabate: Array<string> = alphabate;

  @ViewChild('selectedColumn', { read: ViewContainerRef }) selectedColumn: ElementRef;

  selectedColumnAlphabet: String;

  ngOnInit() {
    if (this.topBar.alphabetFilterColumns && this.topBar.alphabetFilterColumns.length) {
      this.selectedColumnAlphabet = this.topBar.alphabetFilterColumns[0].value;
    }
  }

  selectAlphabet(alphabet: string) {
    this.selectedAlphabet = alphabet;
    const obj: AlphabetFilter = {
      alphabet,
      selectedColumn: this.selectedColumnAlphabet,
    };
    this.onAlphabetAndColumnChange.emit(obj);
  }

  reset() {}

  selectRow(e: any) {
    const val = e.value;
    const obj: AlphabetFilter = {
      alphabet: this.selectedAlphabet,
      selectedColumn: this.selectedColumnAlphabet,
    };
    this.onAlphabetAndColumnChange.emit(obj);
  }
}
