import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { TourModule } from '@ngx-tour-core';

import { TourAnchorNgxPopperDirective, TourAnchorNgxPopperPopoverDirective } from './tour-anchor.directive';
import { TourStepTemplateComponent } from './tour-step-template.component';
import { TourStepTemplateService } from './tour-step-template.service';
import { NgxpTourService } from './ngx-popper-tour.service';
import { PopperController } from './popper/popper-directive';
import { PopperContent } from './popper/popper-content';
import { PopperContentOptions } from './popper/popper-model';
export {
  TourAnchorNgxPopperDirective,
  TourAnchorNgxPopperPopoverDirective,
  TourStepTemplateComponent,
  NgxpTourService,
};

@NgModule({
  declarations: [
    PopperController,
    PopperContent,
    TourAnchorNgxPopperDirective,
    TourAnchorNgxPopperPopoverDirective,
    TourStepTemplateComponent,
  ],
  exports: [
    TourAnchorNgxPopperDirective,
    PopperController,
    PopperContent,
    TourAnchorNgxPopperPopoverDirective,
    TourStepTemplateComponent,
  ],
  imports: [CommonModule],
})
export class TourNgxPopperModule {
  public static forRoot(popperBaseOptions: PopperContentOptions = {}): ModuleWithProviders<TourNgxPopperModule> {
    return {
      ngModule: TourNgxPopperModule,
      providers: [
        TourStepTemplateService,
        ...TourModule.forRoot().providers,
        NgxpTourService,
        { provide: 'popperDefaults', useValue: popperBaseOptions },
      ],
    };
  }
}
