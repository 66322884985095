import {
  NgModule,
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomHandler } from '../dom/domhandler';
import { MenuItem } from '../common/menuitem';
import { RouterModule } from '@angular/router';
import { MenubarSub, Menubar } from 'primeng/menubar';

@Component({
  selector: 'cvc-menubarSub',
  templateUrl: './menubar-sub-custom.html',
})
export class CVCMenubarSub extends MenubarSub {
  @Input() rootClass: string;
  @Output() onItemSelect: EventEmitter<any> = new EventEmitter<any>();
  itemActive: any;
  onItemMenuClick(event: Event, item: HTMLLIElement, i: any): void {}
  listClick(a: any) {}
  itemClick(a: any, child: any) {}
  onItemClick(event: Event, item: HTMLLIElement): void {
    this.itemActive = item;
    this.onItemSelect.emit(item);
  }
}

@Component({
  selector: 'cvc-menubar',
  templateUrl: './menubar.html',
})
export class CVCMenubar extends Menubar {
  @Input() rootClass: string;
  @Output() onItemSelect: EventEmitter<any> = new EventEmitter<any>();
}

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [CVCMenubar, RouterModule],
  declarations: [CVCMenubar, CVCMenubarSub],
})
export class CVCMenubarModule {}
