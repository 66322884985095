import {
  NgModule,
  Component,
  OnInit,
  Inject,
  forwardRef,
  OnChanges,
  SimpleChange,
  AfterContentInit,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UITreeNode, Tree } from 'primeng/tree';
import { TreeNode } from './TreeNodeExtended';
import { ObjectUtils } from 'primeng/utils';
import { TooltipModule } from 'primeng/tooltip';
// import { TreeNode } from '@primeng/lib/common/treenode';

@Component({
  selector: 'r-treeNode',
  template: `
    <ng-template [ngIf]="node">
      <li
        *ngIf="tree.droppableNodes"
        class="ui-treenode-droppoint"
        [ngClass]="{ 'ui-treenode-droppoint-active ui-state-highlight': draghoverPrev }"
        (drop)="onDropPoint($event, -1)"
        (dragover)="onDropPointDragOver($event)"
        (dragenter)="onDropPointDragEnter($event, -1)"
        (dragleave)="onDropPointDragLeave($event)"
      ></li>
      <li
        *ngIf="!tree.horizontal"
        role="treeitem"
        [ngClass]="['ui-treenode', node.styleClass || '', isLeaf() ? 'ui-treenode-leaf' : '']"
      >
        <div
          class="ui-treenode-content"
          (contextmenu)="onNodeRightClick($event)"
          (touchend)="onNodeTouchEnd()"
          (drop)="onDropNode($event)"
          (dragover)="onDropNodeDragOver($event)"
          (dragenter)="onDropNodeDragEnter($event)"
          (dragleave)="onDropNodeDragLeave($event)"
          [draggable]="tree.draggableNodes"
          (dragstart)="onDragStart($event)"
          (dragend)="onDragStop($event)"
          tabIndex="0"
          [ngClass]="{
            'ui-treenode-selectable': tree.selectionMode && node.selectable !== false,
            'ui-treenode-dragover': draghoverNode,
            'ui-treenode-content-selected': isSelected()
          }"
          (keydown)="onKeyDown($event)"
          [attr.aria-posinset]="this.index + 1"
          [attr.aria-expanded]="this.node.expanded"
          [attr.aria-selected]="isSelected()"
        >
          <span
            class="ui-tree-toggler pi pi-fw ui-unselectable-text"
            [ngClass]="{ 'pi-caret-right': !node.expanded, 'pi-caret-down': node.expanded }"
            (click)="toggle($event)"
          ></span>
          <div class="ui-chkbox" *ngIf="tree.selectionMode == 'checkbox'">
            <div
              (click)="onNodeClick($event)"
              class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
              [ngClass]="{ 'ui-state-disabled': node.selectable === false }"
            >
              <span
                class="ui-chkbox-icon ui-clickable pi"
                [ngClass]="{ 'pi-check': isSelected(), 'pi-minus': node.partialSelected }"
              ></span>
            </div>
          </div>
          <span [class]="getIcon()" *ngIf="node.icon || node.expandedIcon || node.collapsedIcon"></span
          ><span class="ui-treenode-label ui-corner-all" [ngClass]="{ 'ui-state-highlight': isSelected() }">
            <span pTooltip="{{ node.Description }}" tooltipPosition="top" *ngIf="!tree.getTemplateForNode(node)">{{
              node.label
            }}</span>
            <span *ngIf="tree.getTemplateForNode(node)">
              <ng-container
                *ngTemplateOutlet="tree.getTemplateForNode(node); context: { $implicit: node }"
              ></ng-container>
            </span>
          </span>
        </div>
        <ul
          class="ui-treenode-children"
          style="display: none;"
          *ngIf="node.children && node.expanded"
          [style.display]="node.expanded ? 'block' : 'none'"
          role="group"
        >
          <r-treeNode
            *ngFor="
              let childNode of node.children;
              let firstChild = first;
              let lastChild = last;
              let index = index;
              trackBy: tree.trackBy
            "
            [node]="childNode"
            [parentNode]="node"
            [firstChild]="firstChild"
            [lastChild]="lastChild"
            [index]="index"
          ></r-treeNode>
        </ul>
      </li>
      <li
        *ngIf="tree.droppableNodes && lastChild"
        class="ui-treenode-droppoint"
        [ngClass]="{ 'ui-treenode-droppoint-active ui-state-highlight': draghoverNext }"
        (drop)="onDropPoint($event, 1)"
        (dragover)="onDropPointDragOver($event)"
        (dragenter)="onDropPointDragEnter($event, 1)"
        (dragleave)="onDropPointDragLeave($event)"
      ></li>
      <table *ngIf="tree.horizontal" [class]="node.styleClass">
        <tbody>
          <tr>
            <td class="ui-treenode-connector" *ngIf="!root">
              <table class="ui-treenode-connector-table">
                <tbody>
                  <tr>
                    <td [ngClass]="{ 'ui-treenode-connector-line': !firstChild }"></td>
                  </tr>
                  <tr>
                    <td [ngClass]="{ 'ui-treenode-connector-line': !lastChild }"></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="ui-treenode" [ngClass]="{ 'ui-treenode-collapsed': !node.expanded }">
              <div
                class="ui-treenode-content ui-state-default ui-corner-all"
                [ngClass]="{ 'ui-treenode-selectable': tree.selectionMode, 'ui-state-highlight': isSelected() }"
                (click)="onNodeClick($event)"
                (contextmenu)="onNodeRightClick($event)"
                (touchend)="onNodeTouchEnd()"
              >
                <span
                  class="ui-tree-toggler pi pi-fw ui-unselectable-text"
                  [ngClass]="{ 'pi-plus': !node.expanded, 'pi-minus': node.expanded }"
                  *ngIf="!isLeaf()"
                  (click)="toggle($event)"
                ></span
                ><span [class]="getIcon()" *ngIf="node.icon || node.expandedIcon || node.collapsedIcon"></span
                ><span class="ui-treenode-label ui-corner-all">
                  <span
                    pTooltip="{{ node.Description }}"
                    tooltipPosition="top"
                    *ngIf="!tree.getTemplateForNode(node)"
                    >{{ node.label }}</span
                  >
                  <span *ngIf="tree.getTemplateForNode(node)">
                    <ng-container
                      *ngTemplateOutlet="tree.getTemplateForNode(node); context: { $implicit: node }"
                    ></ng-container>
                  </span>
                </span>
              </div>
            </td>
            <td
              class="ui-treenode-children-container"
              *ngIf="node.children && node.expanded"
              [style.display]="node.expanded ? 'table-cell' : 'none'"
            >
              <div class="ui-treenode-children">
                <r-treeNode
                  *ngFor="
                    let childNode of node.children;
                    let firstChild = first;
                    let lastChild = last;
                    trackBy: tree.trackBy
                  "
                  [node]="childNode"
                  [firstChild]="firstChild"
                  [lastChild]="lastChild"
                ></r-treeNode>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  `,
})
export class UIRTreeNode implements OnInit {
  static ICON_CLASS: string = 'ui-treenode-icon ';

  @Input() node: TreeNode;

  @Input() parentNode: TreeNode;

  @Input() root: boolean;

  @Input() index: number;

  @Input() firstChild: boolean;

  @Input() lastChild: boolean;

  tree: RTree;

  constructor(@Inject(forwardRef(() => RTree)) public rtree: RTree) {
    this.tree = rtree as RTree;
  }

  draghoverPrev: boolean;

  draghoverNext: boolean;

  draghoverNode: boolean;

  ngOnInit() {
    this.node.parent = this.parentNode;

    if (this.parentNode) {
      this.tree.syncNodeOption(
        this.node,
        this.tree.value,
        'parent',
        this.tree.getNodeWithKey(this.parentNode.key, this.tree.value)
      );
    }
  }

  getIcon() {
    let icon: string;

    if (this.node.icon) icon = this.node.icon;
    else
      icon =
        this.node.expanded && this.node.children && this.node.children.length
          ? this.node.expandedIcon
          : this.node.collapsedIcon;

    return UITreeNode.ICON_CLASS + ' ' + icon;
  }

  isLeaf() {
    return this.tree.isNodeLeaf(this.node);
  }

  toggle(event: Event) {
    if (this.node.expanded) this.collapse(event);
    else this.expand(event);
  }

  expand(event: Event) {
    this.node.expanded = true;
    this.tree.onNodeExpand.emit({ originalEvent: event, node: this.node });
  }

  collapse(event: Event) {
    this.node.expanded = false;
    this.tree.onNodeCollapse.emit({ originalEvent: event, node: this.node });
  }

  onNodeClick(event: MouseEvent) {
    this.tree.onNodeClick(event, this.node);
  }

  onNodeTouchEnd() {
    this.tree.onNodeTouchEnd();
  }

  onNodeRightClick(event: MouseEvent) {
    this.tree.onNodeRightClick(event, this.node);
  }

  isSelected() {
    return this.tree.isSelected(this.node);
  }

  onDropPoint(event: Event, position: number) {
    event.preventDefault();
    let dragNode = this.tree.dragNode;
    let dragNodeIndex = this.tree.dragNodeIndex;
    let dragNodeScope = this.tree.dragNodeScope;
    let isValidDropPointIndex =
      this.tree.dragNodeTree === this.tree ? position === 1 || dragNodeIndex !== this.index - 1 : true;

    if (this.tree.allowDrop(dragNode, this.node, dragNodeScope) && isValidDropPointIndex) {
      if (this.tree.validateDrop) {
        this.tree.onNodeDrop.emit({
          originalEvent: event,
          dragNode: dragNode,
          dropNode: this.node,
          dropIndex: this.index,
          accept: () => {
            this.processPointDrop(dragNode, dragNodeIndex, position);
          },
        });
      } else {
        this.processPointDrop(dragNode, dragNodeIndex, position);
        this.tree.onNodeDrop.emit({
          originalEvent: event,
          dragNode: dragNode,
          dropNode: this.node,
          dropIndex: this.index,
        });
      }
    }

    this.draghoverPrev = false;
    this.draghoverNext = false;
  }

  processPointDrop(dragNode: any, dragNodeIndex: any, position: any) {
    let newNodeList = this.node.parent ? this.node.parent.children : this.tree.value;
    this.tree.dragNodeSubNodes.splice(dragNodeIndex, 1);
    let dropIndex = this.index;

    if (position < 0) {
      dropIndex =
        this.tree.dragNodeSubNodes === newNodeList
          ? this.tree.dragNodeIndex > this.index
            ? this.index
            : this.index - 1
          : this.index;
      newNodeList.splice(dropIndex, 0, dragNode);
    } else {
      dropIndex = newNodeList.length;
      newNodeList.push(dragNode);
    }

    this.tree.dragDropService.stopDrag({
      node: dragNode,
      subNodes: this.node.parent ? this.node.parent.children : this.tree.value,
      index: dragNodeIndex,
    });
  }

  onDropPointDragOver(event: any) {
    event.dataTransfer.dropEffect = 'move';
    event.preventDefault();
  }

  onDropPointDragEnter(event: Event, position: number) {
    if (this.tree.allowDrop(this.tree.dragNode, this.node, this.tree.dragNodeScope)) {
      if (position < 0) this.draghoverPrev = true;
      else this.draghoverNext = true;
    }
  }

  onDropPointDragLeave(event: Event) {
    this.draghoverPrev = false;
    this.draghoverNext = false;
  }

  onDragStart(event: any) {
    if (this.tree.draggableNodes && this.node.draggable !== false) {
      event.dataTransfer.setData('text', 'data');

      this.tree.dragDropService.startDrag({
        tree: this,
        node: this.node,
        subNodes: this.node.parent ? this.node.parent.children : this.tree.value,
        index: this.index,
        scope: this.tree.draggableScope,
      });
    } else {
      event.preventDefault();
    }
  }

  onDragStop(event: any) {
    this.tree.dragDropService.stopDrag({
      node: this.node,
      subNodes: this.node.parent ? this.node.parent.children : this.tree.value,
      index: this.index,
    });
  }

  onDropNodeDragOver(event: any) {
    event.dataTransfer.dropEffect = 'move';
    if (this.tree.droppableNodes) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onDropNode(event: any) {
    if (this.tree.droppableNodes && this.node.droppable !== false) {
      event.preventDefault();
      event.stopPropagation();
      let dragNode = this.tree.dragNode;
      if (this.tree.allowDrop(dragNode, this.node, this.tree.dragNodeScope)) {
        if (this.tree.validateDrop) {
          this.tree.onNodeDrop.emit({
            originalEvent: event,
            dragNode: dragNode,
            dropNode: this.node,
            index: this.index,
            accept: () => {
              this.processNodeDrop(dragNode);
            },
          });
        } else {
          this.processNodeDrop(dragNode);
          this.tree.onNodeDrop.emit({
            originalEvent: event,
            dragNode: dragNode,
            dropNode: this.node,
            index: this.index,
          });
        }
      }
    }

    this.draghoverNode = false;
  }

  processNodeDrop(dragNode: any) {
    let dragNodeIndex = this.tree.dragNodeIndex;
    this.tree.dragNodeSubNodes.splice(dragNodeIndex, 1);

    if (this.node.children) this.node.children.push(dragNode);
    else this.node.children = [dragNode];

    this.tree.dragDropService.stopDrag({
      node: dragNode,
      subNodes: this.node.parent ? this.node.parent.children : this.tree.value,
      index: this.tree.dragNodeIndex,
    });
  }

  onDropNodeDragEnter(event: any) {
    if (
      this.tree.droppableNodes &&
      this.node.droppable !== false &&
      this.tree.allowDrop(this.tree.dragNode, this.node, this.tree.dragNodeScope)
    ) {
      this.draghoverNode = true;
    }
  }

  onDropNodeDragLeave(event: any) {
    if (this.tree.droppableNodes) {
      let rect = event.currentTarget.getBoundingClientRect();
      if (
        event.x > rect.left + rect.width ||
        event.x < rect.left ||
        event.y >= Math.floor(rect.top + rect.height) ||
        event.y < rect.top
      ) {
        this.draghoverNode = false;
      }
    }
  }

  onKeyDown(event: KeyboardEvent) {
    const nodeElement = (<HTMLDivElement>event.target).parentElement.parentElement;

    if (nodeElement.nodeName !== 'P-TREENODE') {
      return;
    }

    switch (event.which) {
      //down arrow
      case 40:
        const listElement = this.tree.droppableNodes
          ? nodeElement.children[1].children[1]
          : nodeElement.children[0].children[1];
        if (listElement) {
          this.focusNode(listElement.children[0]);
        } else {
          const nextNodeElement = nodeElement.nextElementSibling;
          if (nextNodeElement) {
            this.focusNode(nextNodeElement);
          } else {
            let nextSiblingAncestor = this.findNextSiblingOfAncestor(nodeElement);
            if (nextSiblingAncestor) {
              this.focusNode(nextSiblingAncestor);
            }
          }
        }

        event.preventDefault();
        break;

      //up arrow
      case 38:
        if (nodeElement.previousElementSibling) {
          this.focusNode(this.findLastVisibleDescendant(nodeElement.previousElementSibling));
        } else {
          let parentNodeElement = this.getParentNodeElement(nodeElement);
          if (parentNodeElement) {
            this.focusNode(parentNodeElement);
          }
        }

        event.preventDefault();
        break;

      //right arrow
      case 39:
        if (!this.node.expanded) {
          this.expand(event);
        }

        event.preventDefault();
        break;

      //left arrow
      case 37:
        if (this.node.expanded) {
          this.collapse(event);
        } else {
          let parentNodeElement = this.getParentNodeElement(nodeElement);
          if (parentNodeElement) {
            this.focusNode(parentNodeElement);
          }
        }

        event.preventDefault();
        break;

      //enter
      case 13:
        this.tree.onNodeClick(event, this.node);
        event.preventDefault();
        break;

      default:
        //no op
        break;
    }
  }

  findNextSiblingOfAncestor(nodeElement: any): any {
    let parentNodeElement = this.getParentNodeElement(nodeElement);
    if (parentNodeElement) {
      if (parentNodeElement.nextElementSibling) return parentNodeElement.nextElementSibling;
      else return this.findNextSiblingOfAncestor(parentNodeElement);
    } else {
      return null;
    }
  }

  findLastVisibleDescendant(nodeElement: any): any {
    const childrenListElement = nodeElement.children[0].children[1];
    if (childrenListElement) {
      const lastChildElement = childrenListElement.children[childrenListElement.children.length - 1];

      return this.findLastVisibleDescendant(lastChildElement);
    } else {
      return nodeElement;
    }
  }

  getParentNodeElement(nodeElement: any) {
    const parentNodeElement = nodeElement.parentElement.parentElement.parentElement;

    return parentNodeElement.tagName === 'P-TREENODE' ? parentNodeElement : null;
  }

  focusNode(element: any) {
    if (this.tree.droppableNodes) element.children[1].children[0].focus();
    else element.children[0].children[0].focus();
  }
}

@Component({
  selector: 'r-tree',
  template: `
    <div
      [ngClass]="{
        'ui-tree ui-widget ui-widget-content ui-corner-all': true,
        'ui-tree-selectable': selectionMode,
        'ui-treenode-dragover': dragHover,
        'ui-tree-loading': loading
      }"
      [ngStyle]="style"
      [class]="styleClass"
      *ngIf="!horizontal"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragenter)="onDragEnter()"
      (dragleave)="onDragLeave($event)"
    >
      <div class="ui-tree-loading-mask ui-widget-overlay" *ngIf="loading"></div>
      <div class="ui-tree-loading-content" *ngIf="loading">
        <i [class]="'ui-tree-loading-icon pi-spin ' + loadingIcon"></i>
      </div>
      <div class="row">
        <div class="col-left"></div>
        <div class="col-right">
          <div *ngIf="filter" class="ui-tree-filter-container input-search">
            <input
              #filter
              type="text"
              autocomplete="off"
              class="ui-tree-filter ui-inputtext ui-widget ui-state-default ui-corner-all"
              [attr.placeholder]="filterPlaceholder"
              (keydown.enter)="$event.preventDefault()"
              (input)="onFilter10($event)"
            />
            <div class="ui-tree-empty-message" *ngIf="!filterhasvalue">{{ emptyMessage }}</div>
          </div>
        </div>
      </div>
      <ul
        class="ui-tree-container"
        *ngIf="getRootNode()"
        role="tree"
        [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledBy"
      >
        <ng-container *ngIf="tempValue.length === 0 && !loaded">
          {{ setTempValue(getRootNode()) }}
        </ng-container>
        <r-treeNode
          *ngFor="
            let node of getRootNode();
            let firstChild = first;
            let lastChild = last;
            let index = index;
            trackBy: trackBy
          "
          [node]="node"
          [firstChild]="firstChild"
          [lastChild]="lastChild"
          [index]="index"
        ></r-treeNode>
      </ul>
      <div class="ui-tree-empty-message" *ngIf="!loading && !value">{{ emptyMessage }}</div>
    </div>
    <div
      [ngClass]="{
        'ui-tree ui-tree-horizontal ui-widget ui-widget-content ui-corner-all': true,
        'ui-tree-selectable': selectionMode
      }"
      [ngStyle]="style"
      [class]="styleClass"
      *ngIf="horizontal"
    >
      <div class="ui-tree-loading ui-widget-overlay" *ngIf="loading"></div>
      <div class="ui-tree-loading-content" *ngIf="loading">
        <i [class]="'ui-tree-loading-icon pi-spin ' + loadingIcon"></i>
      </div>
      <table *ngIf="value && value[0]">
        <r-treeNode [node]="value[0]" [root]="true"></r-treeNode>
      </table>
      <div class="ui-tree-empty-message" *ngIf="!loading && !value">{{ emptyMessage }}</div>
    </div>
  `,
})
export class RTree extends Tree implements AfterContentInit {
  loaded = false;
  tempValue: any = [];
  dragNodeTree: RTree;
  isSelectedAll = false;
  filterhasvalue = true;
  setTempValue(tNode: any) {
    this.loaded = true;
    this.tempValue = tNode;
    tNode.forEach((node: any) => {
      this.initializePartial(node);
    });
  }

  // tslint:disable-next-line: typedef
  onFilter10(event: any) {
    let filterValue = event.target.value;
    if (filterValue === '') {
      this.filteredNodes = null;
      this.filterhasvalue = true;
    } else {
      this.filteredNodes = [];
      const searchFields: string[] = this.filterBy.split(',');
      const filterText = ObjectUtils.removeAccents(filterValue).toLowerCase();
      const isStrictMode = this.filterMode === 'strict';
      for (let node of this.value) {
        let copyNode = { ...node };
        let paramsWithoutNode = { searchFields, filterText, isStrictMode };
        if (
          (isStrictMode &&
            (this.findFilteredNodes(copyNode, paramsWithoutNode) ||
              this.isFilterMatched(copyNode, paramsWithoutNode))) ||
          (!isStrictMode &&
            (this.isFilterMatched(copyNode, paramsWithoutNode) || this.findFilteredNodes(copyNode, paramsWithoutNode)))
        ) {
          this.filteredNodes.push(copyNode);
        }
      }
      if (this.filteredNodes.length > 0) {
        this.filterhasvalue = true;
      } else {
        this.filterhasvalue = false;
      }
    }
  }

  findFilteredNodes(node: any, paramsWithoutNode: any) {
    if (node) {
      let matched = false;
      if (node.children) {
        let childNodes = [...node.children];
        node.children = [];
        for (let childNode of childNodes) {
          let copyChildNode = { ...childNode };
          if (this.isFilterMatched(copyChildNode, paramsWithoutNode)) {
            matched = true;
            node.children.push(copyChildNode);
          }
        }
      }

      if (matched) {
        return true;
      }
    }
  }

  isFilterMatched(node: any, { searchFields, filterText, isStrictMode }: any) {
    let matched = false;
    for (let field of searchFields) {
      let fieldValue = ObjectUtils.removeAccents(String(ObjectUtils.resolveFieldData(node, field))).toLowerCase();
      if (fieldValue.indexOf(filterText) > -1) {
        matched = true;
      }
    }

    if (!matched || (isStrictMode && !this.isNodeLeaf(node))) {
      matched = this.findFilteredNodes(node, { searchFields, filterText, isStrictMode }) || matched;
    }

    return matched;
  }

  initializePartial(node: any): boolean {
    if (node.children && node.children.length > 0) {
      let childSelected = 0;
      node.children.forEach((child: any) => {
        childSelected += this.initializePartial(child) ? 1 : 0;
      });
      //  any or no children selected and parent is selected then set partial true
      if (childSelected !== node.children.length && this.findIndexInSelection(node) !== -1) {
        node.partialSelected = true;
      }
    }

    const index = this.findIndexInSelection(node);
    return index !== -1;
  }

  onNodeClick(event: any, node: any) {
    const eventTarget = <Element>event.target;

    if (eventTarget.className && eventTarget.className.indexOf('ui-tree-toggler') === 0) {
      return;
    } else if (this.selectionMode) {
      if (node.selectable === false) {
        return;
      }

      if (this.hasFilteredNodes()) {
        node = this.getNodeWithKey(node.key, this.value);

        if (!node) {
          return;
        }
      }

      const index = this.findIndexInSelection(node);
      const selected = index >= 0;

      if (this.isCheckboxSelectionMode()) {
        if (selected) {
          // if checked
          if (this.propagateSelectionDown) {
            this.propagateDown(node, false);
          } else {
            this.selection = this.selection.filter((val: any, i: any) => i !== index);
          }

          if (this.propagateSelectionUp && node.parent) {
            this.propagateUp(node.parent, false);
          }

          this.selectionChange.emit(this.selection);
          this.onNodeUnselect.emit({
            originalEvent: event,
            node: node,
          });
        } else {
          // if unchecked
          if (this.propagateSelectionDown) {
            this.propagateDown(node, true);
          } else {
            this.selection = [...(this.selection || []), node];
          }

          if (this.propagateSelectionUp && node.parent) {
            this.propagateUp(node.parent, true);
          }

          this.selectionChange.emit(this.selection);
          this.onNodeSelect.emit({
            originalEvent: event,
            node: node,
          });
        }
      } else {
        const metaSelection = this.nodeTouched ? false : this.metaKeySelection;

        if (metaSelection) {
          const metaKey = event.metaKey || event.ctrlKey;

          if (selected && metaKey) {
            if (this.isSingleSelectionMode()) {
              this.selectionChange.emit(null);
            } else {
              this.selection = this.selection.filter((val: any, i: any) => i !== index);
              this.selectionChange.emit(this.selection);
            }

            this.onNodeUnselect.emit({
              originalEvent: event,
              node: node,
            });
          } else {
            if (this.isSingleSelectionMode()) {
              this.selectionChange.emit(node);
            } else if (this.isMultipleSelectionMode()) {
              this.selection = !metaKey ? [] : this.selection || [];
              this.selection = [...this.selection, node];
              this.selectionChange.emit(this.selection);
            }

            this.onNodeSelect.emit({
              originalEvent: event,
              node: node,
            });
          }
        } else {
          if (this.isSingleSelectionMode()) {
            if (selected) {
              this.selection = null;
              this.onNodeUnselect.emit({
                originalEvent: event,
                node: node,
              });
            } else {
              this.selection = node;
              this.onNodeSelect.emit({
                originalEvent: event,
                node: node,
              });
            }
          } else {
            if (selected) {
              this.selection = this.selection.filter((val: any, i: any) => i !== index);
              this.onNodeUnselect.emit({
                originalEvent: event,
                node: node,
              });
            } else {
              this.selection = [...(this.selection || []), node];
              this.onNodeSelect.emit({
                originalEvent: event,
                node: node,
              });
            }
          }

          this.selectionChange.emit(this.selection);
        }
      }
    }

    this.nodeTouched = false;
  }

  propagateDown(node: any, select: boolean, calledFromParent: boolean = false) {
    const index = this.findIndexInSelection(node);

    if (node.partialSelected && !calledFromParent) {
      select = true;
    }

    if (
      select &&
      index === -1 &&
      !node.partialSelected &&
      node.children &&
      node.children.length > 0 &&
      !calledFromParent
    ) {
      node.partialSelected = true;
      this.selection = [...(this.selection || []), node];
      select = false;
    } else if (select && (!node.children || node.children.length < 1 || node.partialSelected || calledFromParent)) {
      node.partialSelected = false;
      if (index === -1) {
        this.selection = [...(this.selection || []), node];
      }
    } else {
      this.selection = this.selection.filter((val: any, i: any) => i !== index);
    }

    this.syncNodeOption(node, this.filteredNodes, 'partialSelected');

    if (node.children && node.children.length) {
      for (const child of node.children) {
        this.propagateDown(child, select, true);
      }
    }
  }

  propagateUp(node: any, select: boolean, calledFromParent: boolean = false) {
    if (!calledFromParent) {
      select = true;
    }

    if (node.children && node.children.length) {
      let selectedCount = 0;
      let childPartialSelected = false;
      for (const child of node.children) {
        if (this.isSelected(child)) {
          selectedCount++;
        } else if (child.partialSelected) {
          childPartialSelected = true;
        }
      }
      // if all child selected
      if (select && selectedCount === node.children.length) {
        node.partialSelected = false;
      } else {
        const index = this.findIndexInSelection(node);
        if (!select) {
          if (index >= 0) {
            node.partialSelected = true;
          }
        }

        if (childPartialSelected || (selectedCount > 0 && selectedCount !== node.children.length)) {
          node.partialSelected = true;
          if (index === -1) {
            this.selection = [...(this.selection || []), node];
          }
        }
      }

      this.syncNodeOption(node, this.filteredNodes, 'partialSelected');
    }

    const parent = node.parent;
    if (parent) {
      this.propagateUp(parent, select);
    }
  }

  isSelected(node: any) {
    // if false on any level return false
    this.isSelectedAll = this.findIndexInSelection(node) !== -1;
    if (!this.isSelectedAll || node.partialSelected) {
      return false;
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach((child: any) => {
        this.isSelectedAll = this.isSelected(child) && !child.partialSelected;
        if (!this.isSelectedAll) {
          return false;
        }
      });
    }
    return true;
  }
}

@NgModule({
  imports: [CommonModule, TooltipModule],
  exports: [RTree],
  declarations: [RTree, UIRTreeNode],
})
export class RTreeModule {}
