<div class="datatable-footer-inner" [ngClass]="{ 'selected-count': selectedMessage }">
  <ng-template
    *ngIf="footerTemplate"
    [ngTemplateOutlet]="footerTemplate.template"
    [ngTemplateOutletContext]="{
      rowCount: rowCount,
      pageSize: pageSize,
      selectedCount: selectedCount,
      curPage: curPage,
      offset: offset
    }"
  >
  </ng-template>
  <div class="page-count" *ngIf="!footerTemplate">
    <span *ngIf="selectedMessage"> {{ selectedCount?.toLocaleString() }} {{ selectedMessage }} / </span>
    {{ rowCount?.toLocaleString() }} {{ totalMessage }}
  </div>
  <rdatatable-pager
    *ngIf="!footerTemplate"
    [pagerLeftArrowIcon]="pagerLeftArrowIcon"
    [pagerRightArrowIcon]="pagerRightArrowIcon"
    [pagerPreviousIcon]="pagerPreviousIcon"
    [pagerNextIcon]="pagerNextIcon"
    [page]="curPage"
    [size]="pageSize"
    [count]="rowCount"
    [hidden]="!isVisible"
    (change)="page.emit($event)"
  >
  </rdatatable-pager>
</div>
