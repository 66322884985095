import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridHeaderTopActionDirective } from './search-action/grid-header-top-action.directive';
import { GridHeaderTopDropdownDirective } from './search-action/grid-header-top-search-dropdown.directive';
import { GridHeaderTitleTemplateDirective } from './header-title-template.directive';

@Component({
  selector: 'ngx-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
})
export class HeaderTopComponent implements OnInit {
  @Input() topBar: any;

  @Input() view: String;

  @Input() ActionTemplate: GridHeaderTopActionDirective;
  @Input() GridHeaderTopDropDownTemplate: GridHeaderTopDropdownDirective;
  @Input() GridHeaderTitleTemplateDirective: GridHeaderTitleTemplateDirective;
  @Input() searchtext: string;

  @Output() onChangeViewHeaderTop = new EventEmitter<String>();
  @Output() onSearch = new EventEmitter<String>();
  @Output() onSearchDropDownChange = new EventEmitter();
  @Output() onSettingClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onCallback(event: any) {}
}
