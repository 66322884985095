import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { NgForageCache } from 'ngforage';
import { isBoolean, toLower } from 'lodash';
import { from, Observable } from 'rxjs';
import { Identifiers } from '@app/shared/services/app.config.type';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { environment } from '@env/environment';

const maxAge = 900000;
@Injectable()
export class RequestCache {
  constructor(private ngCache: NgForageCache, private appConfig: AppConfigurationService) {}

  /**
   *
   * @param req take HttpRequest instance
   * @returns updated cache if not expired
   */
  get(req: HttpRequest<any>) {
    if (this.detectCachableRequest(req)) {
      return this.ngCache.getCached(this.getUrl(req)).then((cache) => (cache.expired === false ? cache : null));
    }
  }

  /**
   *
   * @param req take HttpRequest instance
   * @param response take HttpResponse instance
   * Updadate cache with expiry time
   */
  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    if (this.detectCachableRequest(req)) {
      this.ngCache.setCached(this.getUrl(req, false), JSON.stringify(response), 30 * 60000);
      // this.ngCache.setCached(this.getUrl(req, false), JSON.stringify((response)), 10000); // testing expiry
    }
  }

  /**
   *
   * @param request take HttpRequest instance
   * @returns if request cached retrun true else false
   */
  detectCachableRequest(request: HttpRequest<any>): boolean {
    const method = request.method.toLowerCase();

    return (
      !/^(http|https):/i.test(request.url) &&
      (method === 'get' || (method === 'post' && this.detectCacheParams(request)))
    );
  }

  /**
   * check first level of object key if exists PageNo | PageSize
   * @param request take HttpRequest instance
   * @returns return boolean (true/false)
   */
  detectCacheParams(request: HttpRequest<any>): boolean {
    let status = false;
    if (request.body?.hasOwnProperty('PageNo') && request.body?.hasOwnProperty('PageSize')) {
      status = true;
    } else {
      for (let bodyProp in request.body) {
        request.body[bodyProp]?.hasOwnProperty('PageNo') && request.body[bodyProp]?.hasOwnProperty('PageSize')
          ? (status = true)
          : null;
      }
    }
    return status;
  }

  /**
   *
   * @param req take HttpRequest instance
   * @param isgetter default true, when cache getter code some unnecessary query param remove from url
   * @returns create and return unique key
   */
  private getUrl(req: HttpRequest<any>, isgetter = true): string {
    let url = '';
    if (isgetter) {
      const urlRmWhiteSpace = req.urlWithParams.replace('&observe=response', '').replace('?observe=response', '');
      url = toLower(req.method) === 'get' ? urlRmWhiteSpace : `${urlRmWhiteSpace}${JSON.stringify(req.body)}`;
    } else {
      url = toLower(req.method) === 'get' ? req.urlWithParams : `${req.urlWithParams}${JSON.stringify(req.body)}`;
    }
    return url;
  }

  /**
   *
   * @param applicationCache boolean (true | false)
   * @param forceUpdate boolean (true | false)
   * @returns base on condition return boolean case perioty is 'applicationCache' if false no more cache
   */
  clientLevelCache(): boolean {
    let status = false;
    const portalCache: any = this.appConfig.getByIdentifier(Identifiers.PortalCache);

    if (portalCache && portalCache.ClientConfig) {
      status = isBoolean(portalCache.ClientConfig.Fields.enabled) ? portalCache.ClientConfig.Fields.enabled : false;
    } else {
      status = isBoolean(portalCache.Fields.enabled) ? portalCache.Fields.enabled : false;
    }
    return status;
  }

  /**
   * remove local indexDB
   */
  clearCacheDb() {
    // this.ngForage.clear(); //this is ngForage function not clear IndexDb
    indexedDB.deleteDatabase(environment.cacheDb);
  }

  /**
   *
   * @param req take HttpRequest instance
   * @returns not tested
   */
  delete(req: HttpRequest<any>): Observable<any> {
    return from(this.ngCache.removeItem(this.getUrl(req)).then((res) => res));
  }
}
