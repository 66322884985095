<div class="error-wrapper">
    <div class="error">
      <div class="logo__error">
        <img src="./assets/styles/portal/build/assets/images/reflecx-logo.svg" alt="" />
      </div>
      <div class="error__inner">
        <i class="fal fa-exclamation-circle"></i>
        <h1>You are not signed in!</h1>
        <p>You are signed out from current browser. Please sign back in to continue.</p>
        <p><a routerLink="/login" class="error-btn">Sign In</a></p>
      </div>
    </div>
  </div>
  