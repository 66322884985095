import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable, BehaviorSubject, from, of } from 'rxjs';
import { User } from '@reflecxfeatures/administration/types/User';
import { CodeVerificationDTO, ForgetPasswordDTO, ResetPasswordDTO, SetPasswordDTO } from '../types/user';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { Store } from '@ngrx/store';
import { CVPActions } from '@gstate/actions';
import { identityServerAngularConfig, identityServerTCIConfig, identityServerSSOConfig } from '@env/environment';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { catchError, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { UserManager, WebStorageStateStore, User as OidcUser } from 'oidc-client-ts';
import { NgxPermissionsService } from 'ngx-permissions';
import { Identifiers } from '@app/shared/services/app.config.type';
import { IdleTimeoutService } from '@app/shared/services/idle-timeout.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoaderService } from '../loader.service';
import { throwError } from 'rxjs';
import { NavService } from '@reflecxshared/services/nav/nav.service';
import { RequestCache } from '../http/request.cache.service';
import LocalForageStateStore from '../LocalForageStateStore';
import * as localforage from 'localforage';
export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
}
export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

const credentialsKey = 'credentials';

@Injectable()
export class AuthenticationService {
  image = new BehaviorSubject(null);
  currentImage = this.image.asObservable();
  OnTourSettingChange: EventEmitter<boolean> = new EventEmitter();
  authData: any = null;

  isLoggedIn = false;
  isSetLoggedOut = false;
  permissions: any;
  mgr: UserManager;
  isSSO: boolean;
  tenantName = '';
  isTokenExpiring = false;
  private _myStore: LocalForage;
  private readonly _myStoreName = 'auth_storage';
  private _myKey: string;
  private user: OidcUser = null;
  public orgListData: Array<any> = [];
  sliderMeta = [
    {
      img: 'https://reflecx.io/accounts/desktop/images/slide-1.jpg',
      description: 'Pursue the perfect<br />customer experience.',
    },
    {
      img: 'https://reflecx.io/accounts/desktop/images/slide-2.jpg',
      description: 'Listen, aggregate and<br />act across every channel.',
    },
    {
      img: 'https://reflecx.io/accounts/desktop/images/slide-3.jpg',
      description: 'Say hello to RefleCX. Everything<br />you need for proactive CX.',
    },
  ];

  constructor(
    private notificationService: NotificationsService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public toastService: ToastService,
    private idleTimeoutService: IdleTimeoutService,
    private loaderService: LoaderService,
    private store: Store<{ EventReducer: any }>,
    public ngxPermission: NgxPermissionsService,
    private oauthService: OAuthService,
    private requestCache: RequestCache
  ) {
    this._myStore = localforage;
    this.initDb();
    this.initializedData();
  }

  async initDb() {
    this._myStore.config({
      name: this._myStoreName,
    });
  }
  initializedData() {
    if (!this.checkIsSSOUserFlow()) {
      this._myKey = 'oidc.user:' + identityServerAngularConfig.authority + ':' + identityServerAngularConfig.client_id;
      this.init();
    } else {
      const client = JSON.parse(localStorage.getItem('ssoConfig'));
      if (client != null) {
        this._myKey = 'oidc.user:' + client.Authority + ':' + client.IdentityClientId;
        this.initSSO(client);
      } else {
        this._myKey = 'oidc.user:' + identityServerSSOConfig.authority + ':' + identityServerSSOConfig.client_id;
        this.initSSO(client);
      }
    }
  }

  async initUser() {
    const user = await this.mgr.getUser();
    return user;
  }
  init() {
    const config = {
      authority: identityServerAngularConfig.authority,
      client_id: identityServerAngularConfig.client_id,
      redirect_uri: identityServerAngularConfig.redirect_uri,
      response_type: identityServerAngularConfig.response_type,
      scope: identityServerAngularConfig.scope,
      post_logout_redirect_uri: identityServerAngularConfig.post_logout_redirect_uri,
      silent_redirect_uri: identityServerAngularConfig.silent_redirect_uri,
      accessTokenExpiringNotificationTime: identityServerAngularConfig.accessTokenExpiringNotificationTime,
      automaticSilentRenew: identityServerAngularConfig.automaticSilentRenew,
      userStore: new LocalForageStateStore(),
      clockSkew: identityServerAngularConfig.clockSkew,
    };
    this.mgr = new UserManager(config);
    if (localStorage.getItem('authorizationData') != null) {
      this.authData = JSON.parse(localStorage.getItem('authorizationData'));
    }
    this.mgr.getUser().then((user) => {
      this.user = user;
    });
    this.onTokenExpiring();
  }

  public initSSO(client: any) {
    if (client == null) {
      const config = {
        authority: identityServerSSOConfig.authority,
        client_id: identityServerSSOConfig.client_id,
        redirect_uri: identityServerSSOConfig.redirect_uri,
        response_type: identityServerSSOConfig.response_type,
        scope: identityServerSSOConfig.scope,
        post_logout_redirect_uri: identityServerSSOConfig.post_logout_redirect_uri,
        silent_redirect_uri: identityServerSSOConfig.silent_redirect_uri,
        accessTokenExpiringNotificationTime: identityServerSSOConfig.accessTokenExpiringNotificationTime,
        automaticSilentRenew: identityServerSSOConfig.automaticSilentRenew,
        userStore: new LocalForageStateStore(),
        clockSkew: identityServerSSOConfig.clockSkew,
      };

      this.mgr = new UserManager(config);
      this.isSSO = true;

      if (localStorage.getItem('authorizationData') != null) {
        this.authData = JSON.parse(localStorage.getItem('authorizationData'));
      }
      this.mgr.getUser().then((user) => {
        this.user = user;
      });
      this.onTokenExpiring();
    } else {
      const config = {
        authority: client.Authority,
        client_id: client.IdentityClientId,
        redirect_uri: client.RedirectUri,
        response_type: client.ResponseType,
        scope: client.Scope,
        post_logout_redirect_uri: client.PostLogoutRedirectUri,
        silent_redirect_uri: client.SilentRedirectUri,
        accessTokenExpiringNotificationTime: client.AccessTokenExpiringNotificationTime,
        automaticSilentRenew: client.AutomaticSilentRenew,
        userStore: new LocalForageStateStore(),
        clockSkew: identityServerSSOConfig.clockSkew,
      };

      this.mgr = new UserManager(config);
      this.isSSO = true;

      if (localStorage.getItem('authorizationData') != null) {
        this.authData = JSON.parse(localStorage.getItem('authorizationData'));
      }
      this.mgr.getUser().then((user) => {
        this.user = user;
      });
      this.onTokenExpiring();
    }
  }

  public getToken(): string {
    if (this.checkIsSSOLogin() == true) {
      return this.getSSOToken();
    } else {
      if (this.user == null || this.user?.access_token == null) this.logout();
      else return this.user.access_token;
    }
  }

  private onTokenExpiring() {
    this.mgr.events.addAccessTokenExpiring(() => {
      this.isTokenExpiring = true;
      // console.log('token expiring...');
      this.setNewToken(this.user.access_token);
    });
  }

  checkIsSSOLogin(): boolean {
    return JSON.parse(localStorage.getItem('isSSOLogin'));
  }

  setNewToken(currToken: string) {
    // console.log('current token : ' + currToken);
    setTimeout(
      () => {
        console.info('getToken >> wait 500ms so that token get refreshed');
        this.mgr.getUser().then((user) => {
          this.isTokenExpiring = false;
          this.user = user;
          if (this.user.access_token == currToken) {
            // console.log('new token is still same: ' + this.user.access_token);
            this.setNewToken(currToken);
          } else {
            // console.log('new token after refreshed: ' + this.user.access_token);
          }
        });
      },
      500,
      currToken
    );
  }

  public async getIndexedDbAuthData(): Promise<string | null> {
    const item = await this._myStore.getItem(this._myKey);
    //console.log(`get key: ${this._myKey}, value: ${item}.`);
    if (item) return item.toString();
    return null;
  }
  public removeIndexedDbItem(): Promise<string | null> {
    this._myStore.removeItem(this._myKey);
    return Promise.resolve(null);
  }
  public removeSsoIndexedDbItem(ssoIndexDbkey: string): Promise<string | null> {
    this._myStore.removeItem(ssoIndexDbkey);
    return Promise.resolve(null);
  }

  changeCurrentImage(image: string) {
    this.image.next(image);
  }

  getClientId() {
    return this.authData ? this.authData.ClientId : null;
  }

  getUserId() {
    return this.authData ? this.authData.Id : null;
  }

  public getUserManager(): UserManager {
    return this.mgr;
  }

  public getCurrentUser(): Observable<OidcUser> {
    return from(this.mgr.getUser());
  }
  public completeAuthentication(): Observable<void> {
    return from(
      this.mgr.signinRedirectCallback().then((user) => {
        this.user = user;
      })
    );
  }

  login() {
    this.clearAuthData();
    localStorage.setItem('isSSOLogin', null);
    localStorage.setItem('ssoClient', null);
    localStorage.setItem('ssoToken', null);

    this.mgr
      .getUser()
      .then((user) => {
        if (user) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
          this.mgr.signinRedirect();
        }
      })
      .catch((e) => {
        this.loaderService.showMain = false;
        this.router.navigate(['/oops']);
      });
  }

  signinRedirect() {
    this.mgr.signinRedirect();
  }

  signinCallback() {
    return this.mgr.signinCallback();
  }

  signoutRedirect() {
    this.mgr.signoutRedirect();
  }

  logout(isIdleTimeout: boolean = false) {
    const isSSOLogin = JSON.parse(localStorage.getItem('isSSOLogin'));
    const isUserReloggedIn = JSON.parse(localStorage.getItem('isUserReloggedIn'));

    this.logUserLogout();
    this.clearAuthData();
    this.isSetLoggedOut = true;
    this.isLoggedIn = false;

    if (this.checkIsSSOUserFlow()) this.initializedData();

    if (!isSSOLogin) {
      this.mgr
        .getUser()
        .then((user) => {
          if (user) {
            this.removeIndexedDbItem().then(() => {
              window.localStorage.clear();
              if (isIdleTimeout) {
                localStorage.setItem('isIdleTimeout', JSON.stringify(true));
                this.idleTimeoutService.postIdleTimoutMessage();
              } else {
                if (isUserReloggedIn) {
                  localStorage.setItem('isUserReloggedIn', JSON.stringify(true));
                }
                this.idleTimeoutService.postSigningOutMessage();
              }
              const tenant = this.getTenantName();
              localStorage.setItem('tenant', tenant);
              this.mgr.signoutRedirect();
            });
          } else {
            if (isIdleTimeout) {
              localStorage.setItem('isIdleTimeout', JSON.stringify(true));
              this.idleTimeoutService.postIdleTimoutMessage();
            } else {
              if (isUserReloggedIn) {
                localStorage.setItem('isUserReloggedIn', JSON.stringify(true));
              }
              this.idleTimeoutService.postSigningOutMessage();
            }
            const tenant = this.getTenantName();
            localStorage.setItem('tenant', tenant);
            this.mgr.signoutRedirect();
          }
        })
        .catch((e) => {
          this.loaderService.showMain = false;
          this.router.navigate(['/oops']);
        });
    } else {
      this.oauthService.tokenEndpoint = identityServerTCIConfig.authority + '/connect/token';
      this.oauthService.clientId = identityServerTCIConfig.client_id;
      this.oauthService.scope = identityServerTCIConfig.scope;
      this.oauthService.dummyClientSecret = identityServerTCIConfig.secret;

      this.router.navigate(['/logoutsso']);
      this.oauthService.logOut();
    }
  }

  storeAuthData(response: any): Observable<any> {
    const data = response;
    this.checkTokenExpiry(data.expiry);

    return this.requireAuthentication();
  }

  getSSOToken() {
    let token = null;
    const authData = JSON.parse(localStorage.getItem('authorizationData'));
    if (authData) token = authData?.token;

    return token;
  }

  getExpiryDate(secs: number): Date {
    const t = new Date();
    t.setSeconds(t.getSeconds() + secs);
    return t;
  }

  isTokenExpired(expires_in: number) {
    if (!expires_in) {
      return true;
    }

    const seconds = 1000;
    const d = new Date();
    const t = d.getTime();

    if (expires_in < Math.round(t / seconds)) {
      return true;
    }
    return false;
  }

  checkTokenExpiry(expiry?: number) {
    if (expiry) {
      if (this.isTokenExpired(expiry)) {
        localStorage.clear();
        localStorage.setItem('isSSOLogin', null);
        localStorage.setItem('ssoClient', null);
        localStorage.setItem('ssoToken', null);
        this.router.navigate(['/endsession']);
      }
    } else {
      this.getIndexedDbAuthData().then((ls: any) => {
        ls = JSON.parse(ls);
        if (ls && this.isTokenExpired(ls.expires_at)) {
          localStorage.clear();
          localStorage.setItem('isSSOLogin', null);
          localStorage.setItem('ssoClient', null);
          localStorage.setItem('ssoToken', null);
          this.router.navigate(['/endsession']);
        }
      });
    }
  }

  saveSsoAuthToken(response: any) {
    const currentData = JSON.parse(localStorage.getItem('authorizationData'));
    if (currentData != null) {
      this.logUserLogout();
      this.clearAuthData();
    }

    const data = response;
    this.authData = { token: data.access_token, expiry: data.expiry };

    localStorage.setItem('authorizationData', JSON.stringify(this.authData));
    localStorage.setItem('isSSOLogin', JSON.stringify(true));
  }

  onSignIn(response: any): any {
    const user = response;
    this.authData = user;
    localStorage.setItem('authorizationData', JSON.stringify(this.mapData(this.authData)));
    this.store.dispatch(new CVPActions.PermissionEventAction.PermissionEvent(''));
    this.logLastUserLogout();
    return this.authData;
  }

  getTourGuide() {
    const permission = this.ngxPermission.getPermissions();
    const tourGuideAllowed = permission[Identifiers.TourGuide] ? true : false;

    if (this.authData && this.authData.UserSettings && this.IsInPermission && tourGuideAllowed) {
      return this.authData.UserSettings.TourGuide;
    } else {
      return false;
    }
    // return (this.authData && this.authData.UserSettings) ? this.authData.UserSettings.TourGuide : false;
  }

  updateMarket(user: User): Observable<any> {
    const url = '/user/market';
    return this.http.put(url, user);
  }

  getLanguage(): Observable<any> {
    const DefaultLanguage = localStorage.getItem('language') || 'en-CA';
    return this.http.get(`/multilingual/${DefaultLanguage}`);
  }

  clearAuthData() {
    this.notificationService.clearAsync().subscribe(() => {
      this.notificationService.clearAsync2().subscribe(() => {
        this.authData = null;
        localStorage.removeItem('authorizationData');
        localStorage.removeItem('configData');
      });
    });
  }

  public requireAuthentication(): Observable<any> {
    const url = '/user/userclaims';
    return this.http
      .skipErrorHandler()
      .get(url)
      .pipe(
        tap((response: any) => {
          return response;
        }),
        catchError((error) => this.errorHandler(error))
      );
  }

  public mapData(data: any) {
    delete data.FeatureIds;
    data.DefaultMarketLanguage = data.Markets[0].DefaultLanguage || environment.defaultMarketLanguage;

    data.Features = data.Features.map(function (x: any) {
      return {
        Id: x.Id,
        ClientId: x.ClientId,
        ParentId: x.ParentId,
        Name: x.Name,
        Icon: x.Icon,
      };
    });
    return data;
  }

  storeLastUserInfo() {
    this.init();
    if (this.authData) {
      localStorage.setItem('lastLoginUser', JSON.stringify(this.authData));
    }
  }

  getLastUserInfo() {
    if (localStorage.getItem('lastLoginUser') != null) {
      return localStorage.getItem('lastLoginUser');
    } else {
      return null;
    }
  }
  getUserLanguageByCode() {
    return this.authData.Markets[0].Languages.map((value: any) => {
      return value.Code;
    });
  }
  getMarketDefaultLanguage() {
    return this.authData.DefaultMarketLanguage;
  }
  getClientLogo() {
    return this.authData.ClientInfo.Logo['portal'] || '';
  }
  getClientFabIcon() {
    return this.authData.ClientInfo.Logo['fav_icon'] || '';
  }

  getClientLogoType(type: string) {
    return this.authData.ClientInfo.Logo[type];
  }
  getClientThemes() {
    return this.mapThemesUrls(this.authData.ClientInfo.Themes || []);
  }
  getClientName() {
    return this.authData.ClientName;
  }
  getClientThemeImage(theme: string, type: string) {
    const value = this.authData.ClientInfo.Themes.find((x: any) => x.Field === theme);
    if (value) {
      return value[type];
    }
    return '';
  }
  logLastUserLogout() {
    const user: any = this.getLastUserInfo();
    if (user) {
      const url = environment.apiUrl + environment.serverUrl + environment.apiVersion + '/activity/logs';
      const data = [
        {
          CreatedOn: new Date(), // moment().utc().format('DD MMM YYYY hh:mm a'),
          Type: 'EXPIRED',
          CreatedBy: user.Id,
          UserName: (user.FirstName || '') + ' ' + (user.LastName || ''),
          ClientId: user.ClientId === '' ? null : user.ClientId,
          MarketId: user.MarketId === '' ? null : user.MarketId,
        },
      ];
      this.http.post(url, data);
      localStorage.setItem('lastLoginUser', null);
    }
  }
  logUserLogout() {
    this.init();
    if (this.authData) {
      const url = environment.apiUrl + environment.serverUrl + environment.apiVersion + '/activity/logs';
    }
  }

  updateLanguage(language: any) {
    this.authData.DefaultLanguage = language.Code;
    this.authData.MarketId = language.MarketId;
    localStorage.setItem('authorizationData', JSON.stringify(this.authData));
    localStorage.setItem('language', language.Code);
  }

  sendVerificationCode(data: any): Observable<any> {
    const url = '/user/sendverificationcode';
    return this.http.post<any>(url, data);
  }

  verifyCode(data: CodeVerificationDTO): Observable<any> {
    const url = '/user/verifycode';
    return this.http.post<any>(url, data);
  }

  setPassword(data: SetPasswordDTO): Observable<any> {
    const url = '/user/setpassword/';
    return this.http.put<any>(url, data);
  }

  expired() {
    this.storeLastUserInfo();
    this.clearAuthData();
  }

  changePassword(id: any, data: any): Observable<any> {
    const url = '/user/changepassword';
    return this.http.put<any>(url, data);
  }

  forgotPassword(data: ForgetPasswordDTO): Observable<any> {
    const url = '/user/forgotpassword';
    return this.http.post<any>(url, data);
  }

  validateResetToken(guid: any) {
    const url = environment.apiUrl + environment.serverUrl + environment.apiVersion + '/user/link/' + guid;
    return this.http.get(url);
  }
  resetPassword(data: ResetPasswordDTO): Observable<any> {
    const url = '/user/resetpassword/';
    return this.http.put<any>(url, data);
  }

  getUserClient(id: any): Observable<any> {
    const url = '/user/userclient/';
    return this.http.get(url + id);
  }

  getClientByName(name: any): Observable<any> {
    const url = '/client/GetByName/';
    return this.http.get(url + name);
  }

  getPasswordPolicy(id: any): Observable<any> {
    const url = '/client/ispasswordpolicy/';
    return this.http.get(url + id);
  }

  IsInRole(roles: any) {
    return !!(roles.indexOf(this.authData.Role) === -1);
  }
  getTokenParams(data: any): Observable<any> {
    const url = '/user/extractparams';
    return this.http.post<any>(url, data);
  }
  isUserLoggedIn() {
    // const value = !!JSON.parse(
    //   localStorage.getItem(
    //     'oidc.user:' + identityServerAngularConfig.authority + ':' + identityServerAngularConfig.client_id
    //   )
    // );
    // return value;

    if (this.user != null && this.user !== undefined) return true;
  }

  isAuthenticated() {
    const value = !!this.authData;
    return value;
  }
  IsInPermission(feature: any, permission: any) {
    return !!(
      this.authData.Features.findIndex(function (x: any) {
        return x.Feature === feature && x.FeatureOption === permission;
      }) === -1
    );
  }

  getUser() {
    // this.init();
    if (this.authData) {
      return this.authData;
    }
    return null;
  }

  getAntiForgeryToken() {
    const url = environment.apiUrl + environment.serverUrl + environment.apiVersion + '/user/antiforgerytoken';
    return this.http.get(url);
  }

  getProgram() {
    return this.authData && this.authData.ClientInfo ? this.authData.ClientInfo.DealerEvent : 'NVS';
  }

  isUserExist(id: string) {
    const url = '/user/sso/' + id;
    return this.http.get(url);
  }

  getSSOClientInfo(name: string) {
    const url = '/user/ssoclientinfo?ClientName=' + name;
    return this.http.get(url);
  }

  ssoLogin(data: any) {
    const url = '/user/sso';
    return this.http.post(url, data);
  }

  ssoUserProvision(data: any) {
    const url = '/user/ssouserprovision';
    return this.http.post(url, data);
  }

  getSSOConfiguration(name: string) {
    const url = identityServerSSOConfig.authority + '/api/v1/configuration/ssoconfiguration/' + name;
    return this.http.get(url);
  }

  createUser(user: any): Observable<any> {
    return this.http.post<any>('/user', user);
  }

  checkIsSSOUserFlow() {
    if (JSON.parse(localStorage.getItem('ssoClient')) != null && JSON.parse(localStorage.getItem('ssoClient')) != '') {
      return true;
    }
    return false;
  }

  getTenantName() {
    const tenant = localStorage.getItem('tenant');
    if (tenant !== null && tenant !== '' && tenant) {
      return tenant;
    } else {
      return '';
    }
  }
  private mapThemesUrls(themes: any) {
    themes.forEach((theme: any) => {
      theme.PreviewImage = theme.PreviewImage;
    });
    return themes;
  }

  private errorHandler(response: any): Observable<HttpEvent<any>> {
    // console.log(response);
    if (!environment.production) {
      // Do something with the error
      if (response.status === 401) {
        this.router.navigate(['/unauthorized']);
      }
    }

    switch (response.status) {
      case 0:
        this.router.navigate(['/oops']);
        break;
      case 400:
        this.router.navigate(['/oops']);
        break;
      case 401:
        this.router.navigate(['/unauthorized']);
        break;
      case 404:
        this.router.navigate(['/oops']);
        break;
      case 422:
        this.router.navigate(['/oops']);
        break;
      case 429:
        this.router.navigate(['/oops']);
        break;
      case 500:
        this.router.navigate(['/oops']);
        break;
      case 501:
        this.router.navigate(['/oops']);
        break;
      case 502:
        this.router.navigate(['/oops']);
        break;
      case 503:
        this.router.navigate(['/oops']);
        break;
      case 504:
        this.router.navigate(['/oops']);
        break;
      case 505:
        this.router.navigate(['/oops']);
        break;
      case 511:
        this.router.navigate(['/oops']);
        break;
      default:
        this.router.navigate(['/oops']);
        break;
    }

    return throwError(response);
  }

  public getOrgList(isAllow: boolean) {
    if (isAllow) {
      let type = JSON.stringify(['Private', 'Corporate']);
      const orgIdentifier = 'Default';
      const url = `/reporting/orgList?orgIdentifier=${orgIdentifier}&Fields=${type}`;
      return this.http.get(url);
    } else {
      return of([]);
    }
  }
}
