import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-signin-callback',
  templateUrl: './signin-callback.component.html',
  styleUrls: ['./signin-callback.component.sass'],
})
export class SigninCallbackComponent implements OnInit {
  constructor(private readonly _router: Router, private _authService: AuthenticationService) {}

  async ngOnInit() {
    await this._authService.signinCallback();
    this._router.navigate(['/login']);
  }
}
