<div
  #container
  class="table table-scroll"
  [class.table--striped]="striped == true"
  [class.table--wider]="wider == true"
>
  <div class="arrow-wrap" *ngIf="extraOptions">
    <span
      #scrollLeft
      (mouseenter)="mouseHoverLeft($event)"
      (mouseleave)="mouseHoverLeaveLeft()"
      class="arrow__left"
      style="visibility: visible; top: 20%"
      ><i class="fal fa-angle-left"></i
    ></span>
    <span
      (mouseenter)="mouseHoverRight($event)"
      (mouseleave)="mouseHoverLeaveRight()"
      class="arrow__right"
      style="visibility: visible; top: 20%"
      ><i class="fal fa-angle-right"></i
    ></span>
  </div>
  <div class="header-content" *ngIf="extraOptions">
    <div
      #freezeHeaderDiv
      style="overflow: hidden; position: absolute; z-index: 0"
      [style.top.px]="topValue"
      [class.hide]="!showFreezeHeader"
    >
      <base-table
        [rows]="rows"
        [inputColumns]="cols ? cols : columns"
        typeClass="main-table"
        [paginator]="paginator"
        [page]="page"
        [showSerialNo]="showSerialNo"
        [tableClass]="tableClass"
        [visibility]="'collaspe'"
      ></base-table>
    </div>
    <div
      style="overflow: hidden; position: absolute; z-index: 1"
      [style.top.px]="topValue"
      [class.hide]="!showFreezeHeader"
    >
      <base-table
        [rows]="rows"
        [inputColumns]="cols ? cols : columns"
        typeClass="freaze"
        [visibility]="'collaspe'"
        [paginator]="paginator"
        [page]="page"
        [showSerialNo]="showSerialNo"
        [tableClass]="tableClass"
      ></base-table>
    </div>
  </div>
  <div
    #scrollTable
    class="table-wrap"
    (mousewheel)="onScroll($event)"
    [style.overflow]="extraOptions ? 'hidden' : 'auto'"
  >
    <base-table
      [rows]="rows"
      [inputColumns]="cols ? cols : columns"
      typeClass="main-table"
      [visibility]="'visible'"
      [paginator]="paginator"
      [page]="page"
      [showSerialNo]="showSerialNo"
      [tableClass]="tableClass"
    ></base-table>
  </div>
  <base-table
    [rows]="rows"
    [inputColumns]="cols ? cols : columns"
    typeClass="clone"
    [paginator]="paginator"
    [page]="page"
    [showSerialNo]="showSerialNo"
    [tableClass]="tableClass"
    [visibility]="'visible'"
  ></base-table>
</div>

<ng-container *ngIf="paginator == true">
  <cvp-paginator
    [page]="page.currPage"
    [size]="page.pageSize"
    [count]="rows.length"
    (modelChange)="onModelChange($event)"
    (onPageSizeChange)="onChange($event)"
  >
  </cvp-paginator>
</ng-container>
