import { CommonModule } from '@angular/common';
import { Component, forwardRef, NgModule, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputSwitch } from 'primeng/inputswitch';

export const INPUTSWITCH_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CVCInputSwitch),
  multi: true,
};

@Component({
  selector: 'cvc-inputSwitch',
  templateUrl: './input-switch.html',
  providers: [INPUTSWITCH_VALUE_ACCESSOR],
})
export class CVCInputSwitch extends InputSwitch {
  @Input() onLabel: string;
  @Input() offLabel: string;
  @Input() label: string;
  @Input() labelClass: string;
}

@NgModule({
  imports: [CommonModule],
  exports: [CVCInputSwitch],
  declarations: [CVCInputSwitch],
})
export class CVCInputSwitchModule {}
