import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-time-sync',
  templateUrl: './time-sync.component.html',
  styleUrls: ['./time-sync.component.sass'],
})
export class TimeSyncComponent implements OnInit {
  timeDiff = '';
  constructor(private http: HttpClient) {}

  ngOnInit() {
    var TimeDiffKey = 'Local-Server-TimeDiff';
    var diff = JSON.parse(localStorage.getItem(TimeDiffKey));
    if (!diff) {
      this.http
        .skipErrorHandler()
        .head('https://www.googleapis.com')
        .toPromise()
        .then((res: any) => {
          var serverTime = res.headers.get('date');

          var localTime = new Date().toUTCString();
          let TimeDiff = +new Date(serverTime) - +new Date(localTime);
          window.localStorage.setItem(TimeDiffKey, TimeDiff.toString());

          this.isTimeSync();
        })
        .catch((error) => {
          var serverTime = error.headers.get('date');
          var localTime = new Date().toUTCString();
          let TimeDiff = +new Date(serverTime) - +new Date(localTime);
          window.localStorage.setItem(TimeDiffKey, TimeDiff.toString());

          this.isTimeSync();
        });
    } else {
      this.isTimeSync();
    }
  }

  isTimeSync() {
    var TimeDiffKey = 'Local-Server-TimeDiff';
    var diff = JSON.parse(localStorage.getItem(TimeDiffKey));
    var hoursDiff = Math.round(Math.abs(Math.abs(diff) / 1000 / 3600));
    var minDiff = Math.round(Math.abs(diff / 1000 / 60));
    var secDiff = Math.round(Math.abs(diff / 1000));

    this.timeDiff = hoursDiff + ' Hours, ' + minDiff + ' Minutes, ' + secDiff + ' Seconds';
  }
}
