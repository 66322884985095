import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signout-callback',
  templateUrl: './signout-callback.component.html',
  styleUrls: ['./signout-callback.component.sass'],
})
export class SignoutCallbackComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
