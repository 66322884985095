import { Component, Input, Renderer2, QueryList, OnInit } from '@angular/core';
import { TableContainerComponent } from '../table-container/table-container.component';
import { TableCellComponent } from '../table-cell/table-cell.component';

@Component({
  selector: 'base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
})
export class BaseTableComponent implements OnInit {
  @Input() typeClass: string;
  @Input() visibility: string;
  @Input() inputColumns: QueryList<TableCellComponent>;
  @Input() rows: any;
  @Input() extraOptions: boolean = false;
  @Input() showSerialNo: boolean = false;
  @Input() tableClass: string = '';
  @Input() paginator: boolean = true;

  @Input() page: any = {
    currPage: 1,
    pageSize: 25,
    data: [],
    sortColumn: '',
    sortOrder: 'asc',
  };
  constructor(private _renderer: Renderer2) {}

  ngOnInit() {
    this.initData();
  }

  initData() {
    if (this.paginator) {
      this.page.data = this.rows.slice(
        (this.page.currPage - 1) * this.page.pageSize,
        this.page.pageSize * this.page.currPage
      );
    } else {
      this.page.data = [...this.rows];
    }
  }

  sortSerialNo() {}

  sort(c: any) {
    let { sortColumn, sortOrder } = this.page;

    if (sortColumn == c.field) {
      if (sortOrder == 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
      sortColumn = c.field;
    }

    this.page.sortColumn = sortColumn;
    this.page.sortOrder = sortOrder;

    const rows = this.sortRows(sortColumn, sortOrder);
    this.rows = [...rows];
    this.initData();
  }

  sortRows(sortColumn: string, sortOrder: string) {
    const rows = [...this.rows];
    rows.sort((a, b) => {
      if (a[sortColumn] > b[sortColumn]) {
        return sortOrder == 'asc' ? 1 : -1;
      } else if (a[sortColumn] < b[sortColumn]) {
        return sortOrder == 'asc' ? -1 : 1;
      } else {
        return 0;
      }
    });

    return rows;
  }

  parseDotNotation(data: any, field: string): any {
    if (data && field) {
      if (field.indexOf('.') == -1) {
        return data[field];
      } else {
        const fields: string[] = field.split('.');
        let value = data;
        for (let i = 0, len = fields.length; i < len; ++i) {
          if (value == null) {
            return null;
          }
          value = value[fields[i]];
        }
        return value;
      }
    } else {
      return null;
    }
  }
}
