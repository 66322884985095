import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-external-callback',
  templateUrl: './external-callback.component.html',
  styleUrls: ['./external-callback.component.sass'],
})
export class ExternalCallbackComponent implements OnInit {
  constructor(private readonly _router: Router, private _authService: AuthenticationService) {}

  async ngOnInit() {
    var clientName = JSON.parse(localStorage.getItem('ssoClient'));
    if (!(clientName != null && clientName != '')) this._router.navigate(['/unauthorized']);

    const ssoConfig = JSON.parse(localStorage.getItem('ssoConfig'));
    if (ssoConfig == null) this._router.navigate(['/unauthorized']);

    this._authService.initSSO(ssoConfig);
    await this._authService.signinCallback();
    this._router.navigate(['/sso']);
  }
}
