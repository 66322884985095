import { Component, OnInit, Input, ContentChild, TemplateRef, HostListener } from '@angular/core';

@Component({
  selector: 'cvp-table-cell',
  template: '',
})
export class TableCellComponent implements OnInit {
  @Input() header: string;
  @Input() field: string;
  @Input() type: string = 'string';
  @Input() headerClass: string = 'text-left nw';
  @Input() cellClass: string = 'text-left nw';
  @Input() colSpan: number = 1;

  @ContentChild('template', { read: TemplateRef, static: true }) template: TemplateRef<any>;
  @ContentChild('headerTemplate', { read: TemplateRef, static: true }) headerTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}

  getTemplateReference() {
    return this.template;
  }

  getHeaderTemplateReference() {
    return this.headerTemplate;
  }

  @HostListener('click') onClick() {}
}
