import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GridHeaderTopActionDirective } from './header-top/search-action/grid-header-top-action.directive';
import { GridHeaderMenuDirective } from './header-menu.directive';
import { GridHeaderTopDropdownDirective } from './header-top/search-action/grid-header-top-search-dropdown.directive';
import { GridHeaderTitleTemplateDirective } from './header-top/header-title-template.directive';

@Component({
  selector: 'grid-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() topBar: any;
  @Input() view: String;

  @Input() ActionTemplate: GridHeaderTopActionDirective;
  @Input() HeaderMenuTemplate: GridHeaderMenuDirective;
  @Input() GridHeaderTopDropDownTemplate: GridHeaderTopDropdownDirective;
  @Input() GridHeaderTitleTemplateDirective: GridHeaderTitleTemplateDirective;
  @Input() searchtext: string;

  @Output() onSearch = new EventEmitter();
  @Output() selectCustomFilter = new EventEmitter();
  @Output() onChangeViewHeader = new EventEmitter<String>();
  @Output() onSearchDropDownChange = new EventEmitter();
  @Output() onSettingClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  setLeftClass() {
    const classes = {
      [this.topBar.rightContainerClass]: true,
    };
    return classes;
  }

  setTopClass() {
    const classes = {
      [this.topBar.topContainerClass]: true,
    };
    return classes;
  }

  setRightClass() {
    const classes = {
      [this.topBar.rightContainerClass]: true,
    };
    return classes;
  }

  updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.onSearch.emit(val);
  }
  doSomething(event: any) {
    const val = event.target.value;
    this.selectCustomFilter.emit(val);
  }
}
