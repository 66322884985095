import { uniqBy, sortBy } from 'lodash';
// import { arrayExpression } from 'babel-types';
export const guid = () => {
  function s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + s4();
};
export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime,
  });
};

export const mapSelect = (label: string, value: string, options: Array<any>) => {
  if (options.length === 0) {
    return [];
  }
  return options.map((x: any) => {
    return {
      label: x[label],
      value: x[value],
    };
  });
};

export const titleCase = (str: string, splitter: string = ' ') => {
  const splitStr = str.toLowerCase().split(splitter);
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(splitter);
};

export const mapSelectSimple = (options: Array<any>, setTitleCase = true) => {
  const list = options.map((x: any) => {
    return {
      label: setTitleCase ? titleCase(x.toString()) : x,
      value: x,
    };
  });
  return list;
};

export const escapeSpecialChar = (stringToParse: string) => {
  if (typeof stringToParse === 'string') {
    const specialCharacterArray = ['\\', '$', '=', '/', '(', ')'];
    specialCharacterArray.forEach((item) => {
      if (stringToParse.includes(item)) {
        stringToParse = stringToParse.split(item).join(`\\${item}`);
      }
    });
  }
  return stringToParse;
};
export function genMonthArrFromCurrMonth(startMonth: any, year: number) {
  const monthsArray = [
    { name: 'Jan', key: 1 },
    { name: 'Feb', key: 2 },
    { name: 'Mar', key: 3 },
    { name: 'Apr', key: 4 },
    { name: 'May', key: 5 },
    { name: 'Jun', key: 6 },
    { name: 'Jul', key: 7 },
    { name: 'Aug', key: 8 },
    { name: 'Sep', key: 9 },
    { name: 'Oct', key: 10 },
    { name: 'Nov', key: 11 },
    { name: 'Dec', key: 12 },
  ];

  let revMonth = [];
  for (let i = 0; i < 12; i++) {
    let filteredMonth = monthsArray.filter((item) => item.key == startMonth)[0];
    filteredMonth['year'] = year;
    revMonth.push(filteredMonth);
    startMonth = startMonth - 1;
    if (startMonth == 0) {
      startMonth = 12;
      year = year - 1;
    }
  }
  return revMonth.reverse();
}
export const regexGenerator = (placeholder: string) => {
  let regex = new RegExp(`@\\b` + placeholder, 'g');
  if (typeof placeholder === 'string') {
    regex = new RegExp("[（,）,̸,#,&,',[,],­{,},*,-,%,!]+|@\\b" + escapeSpecialChar(placeholder), 'g');
  }
  return regex;
};
export const extractPlaceholders = (text: string, code: string, placeHolders: string[], regexp?: RegExp) => {
  const convertedText = text;
  let tempPlaceholders: any[] = [];
  if (placeHolders && placeHolders.length > 0) {
    placeHolders.forEach((place) => {
      let regex = new RegExp(`@\\b` + place + `\\b`, 'g');
      if (new RegExp('[!@#$%^&*(),.?":{}|<>]').test(place)) {
        regex = regexGenerator(place);
      }
      const newText = convertedText.replace(new RegExp(`@\\b` + place, 'g'), ' ' + ' @' + place + ' ');
      if (regex.test(newText)) {
        tempPlaceholders.push({
          Name: '{{' + place + '}}',
          Language: code,
        });
      }
    });
  }
  let placeholderCollection = text.match(regexp);

  if (placeholderCollection && placeholderCollection.length > 0) {
    for (let i = 0; i < placeholderCollection.length; i++) {
      const p = placeholderCollection[i].replace(/@/g, '').trim();
      tempPlaceholders.push({
        Name: '{{' + p + '}}',
        Language: code,
      });
    }
  }
  tempPlaceholders = uniqBy(tempPlaceholders, function (e: any) {
    return e.Name;
  });

  let commonPlaceholders = [];
  for (var i = 0; placeHolders && i < placeHolders.length; i++) {
    var out = tempPlaceholders.find(function (element) {
      return element.Name.replace('{{', '').replace('}}', '') === placeHolders[i];
    });
    if (out != undefined) {
      commonPlaceholders.push(out);
    }
  }
  tempPlaceholders = commonPlaceholders;
  return tempPlaceholders;
};

export const escapeString = (stringToParse: string) => {
  if (stringToParse.includes('&')) {
    stringToParse = stringToParse.replace(/&/g, encodeURIComponent('&'));
  }
  if (stringToParse.includes('#')) {
    stringToParse = stringToParse.replace(/#/g, encodeURIComponent('#'));
  }
  if (stringToParse.includes('%')) {
    stringToParse = stringToParse.replace(/%/g, encodeURIComponent('%'));
  }
  if (stringToParse.includes('<')) {
    stringToParse = stringToParse.replace(/</g, encodeURIComponent('%'));
  }
  if (stringToParse.includes('>')) {
    stringToParse = stringToParse.replace(/>/g, encodeURIComponent('%'));
  }
  if (typeof stringToParse === 'string') {
    const specialCharacterArray = ['\\', '$', '=', '/', '.', '<', '>', '?', ':', '(', ')', '*', '+'];
    stringToParse = stringToParse.replace(/[!@$^&*()+=\-[\]\\'`;,./{}|":?~_]/g, '\\$&');
  }
  return stringToParse;
};

export function escapeRegExp(string: any) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
export const replaceToPlaceholder = (placeholders: any, text: string, convertBack: boolean) => {
  // Convert back True = Remove @ with {{}}
  if (convertBack) {
    let convertedText = text;

    placeholders = placeholders
      .map((d: any) => d.Name.replace('{{', '').replace('}}', ''))
      .sort((a: any, b: any) => {
        return b.length - a.length;
      });

    placeholders.forEach((place: any) => {
      let regex = new RegExp('@' + place, 'g');
      if (new RegExp('[!@#$%^&*(),.?":{}|<>]').test(place)) {
        regex = regexGenerator(place);
      }
      convertedText = convertedText.replace(regex, '{{' + place + '}}');
    });
    return convertedText;
  } else {
    placeholders.forEach((placeholder: any) => {
      const place = placeholder.Name.replace('{{', '').replace('}}', '');
      let regex = new RegExp('{{' + place + '}}', 'g');
      if (new RegExp('[!@#$%^&*(),.?":{}|<>]').test(place)) {
        regex = new RegExp("[（,）,̸,#,&,',[,],­{,},*,-,%,!" + ']+|{{\\b' + escapeSpecialChar(place) + `}}`, 'g');
      }
      text = text.replace(regex, `@${place}`);
    });
    return text;
  }
};

export const convertPlaceHolders = (question: any, convertBack: boolean) => {
  // Question Replacing
  if (question.Placeholders && question.Placeholders.length > 0) {
    question.Question.Text = replaceToPlaceholder(question.Placeholders, question.Question.Text, convertBack);
  }

  // List or Vairable Replacing
  question.Question.Variables.forEach((listValue: any) => {
    if (listValue.Placeholders) {
      listValue.Name = replaceToPlaceholder(listValue.Placeholders, listValue.Name, convertBack);
    }

    // Option Replacing
    if (listValue.Options && listValue.Options.length > 0) {
      listValue.Options.forEach((option: any) => {
        if (option.Placeholders) {
          option.Name = replaceToPlaceholder(option.Placeholders, option.Name, convertBack);
        }
      });
    }
  });
};

export const SortingByField = (array: any[], sortByColumn: string) => {
  array.sort((a, b) => {
    if (a[sortByColumn] < b[sortByColumn]) {
      return -1;
    }
    if (a[sortByColumn] > b[sortByColumn]) {
      return 1;
    }
    return 0;
  });
  return array;
};

import { FormGroup } from '@angular/forms';
import { PaginationData } from './types/paginationData';
import { ColumnMeta, QueryGeneratorObject } from '@app/shared/directive/reflecx-column.directive';
import _ from 'lodash';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({
        mustMatch: true,
      });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export const extractLink = (text: string) => {
  let convertedText = text;
  const reg = /\[Link([^[]+)\]/g;
  let links: Array<any> = [];
  let ph: any;
  while ((ph = reg.exec(convertedText))) {
    if (ph[1] && ph[1].trim().length > 0) {
      const attributes = ph[1].match(/\b(\w+)="(.*?)"/g);
      const result = {};
      attributes.forEach((attr: string) => {
        attr.replace(/"/g, ''); // Remove quotes from attributes
        const htmlAttribute = attr.split('=')[0];
        const htmlValue = attr.split('=')[1];
        result[htmlAttribute] = htmlValue;
      });
      result['key1'] = ph[0];
      result['key2'] = ph[1];
      links.push(result);
    }
  }
  const tempLinks = {};
  links = links.filter((a: any) => {
    if (a.ref !== undefined && a.text !== undefined) {
      const re = new RegExp(qouteRegex(a['key1']), 'g');
      convertedText = convertedText.replace(re, a['text'].replace(/["']/g, '').trim());
      const key = a.ref + '|' + a.text;
      if (!tempLinks[key]) {
        tempLinks[key] = true;
        return true;
      }
    }
  }, {});
  return {
    convertedText: convertedText,
    Links: links,
  };
};

export const qouteRegex = (str: string) => {
  return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
};

export function spaceTonbsp(input: string) {
  /*
  const container = document.createElement('div');
  container.innerHTML = input;
  Array.from(container.children).forEach((child:Element, index:any) => {
    child.innerHTML = (<any>child.innerHTML).replaceAll("  ", ' &nbsp; &nbsp; ')
  });
  //Array.from(container.children).forEach((child:Node, index:any) => {
    //child.textContent = (<any>child.textContent).replaceAll("  ", ' &nbsp; &nbsp; ')
  //});
  */
  return (<any>input).replaceAll('  ', '&nbsp;&nbsp;');
}
export function removeMentioneDiv(input: string) {
  const container = document.createElement('div');
  container.innerHTML = input;
  container.querySelectorAll('span.mention').forEach((spanElmt) => {
    spanElmt.outerHTML = spanElmt.innerHTML;
  });

  container.querySelectorAll('span.ql-mention-denotation-char').forEach((spanElmt) => {
    spanElmt.outerHTML = spanElmt.innerHTML;
  });

  container.querySelectorAll('span[contenteditable="false"]').forEach((spanElmt) => {
    spanElmt.outerHTML = spanElmt.innerHTML;
  });
  return container.innerHTML;
}

export function processConfirmationMessage(input: Element) {
  input.querySelectorAll('a').forEach((a) => {
    let messageKey = '&&ConfirmationMessage=';
    let message = '';
    let href = '';
    let hrefWithMessage = a.getAttribute('href');
    if (hrefWithMessage && hrefWithMessage.indexOf(messageKey) > 0) {
      message = hrefWithMessage.slice(hrefWithMessage.indexOf(messageKey) + messageKey.length);
      href = hrefWithMessage.slice(0, hrefWithMessage.indexOf(messageKey));
      a.setAttribute('href', href);
      if (message) {
        a.setAttribute('confirmationMessage', message);
      }
    }
  });
}

export function replaceSelection(textField: any, value: string) {
  if (textField.selectionStart || textField.selectionStart == '0') {
    var startPos = textField.selectionStart;
    var endPos = textField.selectionEnd;
    textField.value =
      textField.value.substring(0, startPos) + value + textField.value.substring(endPos, textField.value.length);
  } else {
    textField.value += value;
  }
}

export function addPlaceHolder(placeHolders: any, value: string) {
  const alreadyExists = placeHolders.some((ph: any) => {
    return ph.value === value;
  });
  if (!alreadyExists) {
    placeHolders.push({ id: placeHolders.length + 1, value: value });
  }
}

export function removePlaceHolder(placeHolders: any, value: string): any {
  if (Array.isArray(placeHolders)) {
    placeHolders = placeHolders.filter((x: any) => x.value != value);
    return placeHolders;
  }
}

export function encodeStartSurveyPlaceHolders(text: string, languages: any): string {
  languages.forEach((l: any) => {
    text = (<any>text).replaceAll('{{StartSurvey' + l.DisplayName + '}}', '{{StartSurvey|' + l.Code + '}}');
    text = text;
  });
  return text;
}
export function decodeStartSurveyPlaceHolders(text: string, languages: any): string {
  languages.forEach((l: any) => {
    text = (<any>text).replaceAll('{{StartSurvey|' + l.Code + '}}', '{{StartSurvey' + l.DisplayName + '}}');
    text = text;
  });
  return text;
}

export function removeHtmlTags(text: string): string {
  text = text.replace(/<[^>]*>/g, '');
  return text;
}

export const SafeJSONParse = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return {};
  }
};

export const isNullOrUndefined = (value: any) => {
  try {
    if (value == null || value == undefined) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return e;
  }
};

export const mapDynamicColumns = (res: any) => {
  let meta: Array<ColumnMeta> = [];
  if (res?.Response?.Data?.ref_data) {
    meta = res.Response.Data.ref_data.map((x: ColumnMeta, index: number) => {
      return {
        order: index + 1,
        Key: x.Key,
        Type: !x.Type ? 'string' : x.Type,
        Align: !x.Align ? 'left' : x.Align,
        Formatter: !x.Formatter ? '' : x.Formatter,
        FormatterArgs: !x.FormatterArgs ? [] : x.FormatterArgs,
        ShowText: x.ShowText,
        Visibility: x.Visibility,
        Renderer: !x.Renderer ? [] : x.Renderer,
        Conditions: !x.Conditions ? [] : x.Conditions,
        Translate: !x.Translate ? false : x.Translate,
      };
    });
  }
  let data: any[] = res?.Response?.Data?.data || [];
  const page: PaginationData = {
    totalElements: res.Response.Total,
    pageNumber: res.Response.PageNo,
    size: res.Response.PageSize,
    data: data,
    extraData: meta,
  };
  return page;
};

export const responseTransformation = (res: any) => {
  const ref_data = res.ref_data;
  const data = res.data.map((x: any) => {
    const responses = {};
    if (x.questions) {
      x.questions = Object.entries(x.questions).map(([key, value]) => ({ [key]: value }));
      x.questions.forEach((d: any) => {
        Object.entries(d).forEach(([propName, propValue]) => {
          responses[propName] = propValue;
        });
      });
    }
    let record = {
      ...x,
      ...responses,
    };
    delete record.questions;
    return record;
  });

  const response = {
    Response: {
      Data: {
        ref_data,
        data,
      },
      Total: res.ref_data.TotalPages,
      PageNo: 1,
      PageSize: 25,
    },
  };
  console.log(response);
  return response;
};

export const filterQueryGenerator = (obj: QueryGeneratorObject, filtersServiceObject: any) => {
  let query = '';
  if (!obj.Text) {
    filtersServiceObject.setFilters('');
  }
  if (obj.Formatter === 'equalString' && obj.Text && obj.Value) {
    query = `'${obj.Value}':ObjectId('${obj.Text}')`;
  } else if (obj.Text && obj.Value) {
    if (obj.Text.search('/') !== -1) {
      let str = obj.Text.replace('/', '\\/');
      query = `'${obj.Value}':/${_.escapeRegExp(str)}/i`;
    } else {
      query = `'${obj.Value}':/${_.escapeRegExp(obj.Text)}/i`;
    }
  } else if (obj.Formatter === 'number' && obj.Value && obj.Text && !isNaN(obj.Text)) {
    query = `'${obj.Value}':/${parseInt(obj.Text, 0)}/i`;
  } else {
    query = '';
  }
  return query;
};

export function isDate(date: any) {
  return !isNaN(new Date(date).getDate());
}

export function getSafeLocalStorage(key: string, parse = true, defaultval: any = false) {
  try {
    const val = localStorage.getItem(key);
    if (!val) {
      return defaultval;
    }
    if (parse) {
      return JSON.parse(val);
    }
    return val;
  } catch (error) {
    return defaultval;
  }
}

export function isFloat(n: number) {
  return Number(n) === n && n % 1 !== 0;
}
