import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  LowerCasePipe,
  PercentPipe,
  UpperCasePipe,
} from '@angular/common';

// Components
import { LoaderComponent } from './loader/loader.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { HeaderTabsComponent } from './components/header-tabs/header-tabs.component';
import { RouterModule } from '@angular/router';
import { MyPrimengModule } from '@primeng';
import { CssLoaderService } from './services/cssloader.service';
import { BreadCrumbService } from './services/breadcrumb.service';
import { LayoutXlComponent } from './components/layout-xl/layout-xl.component';
import { WidgetBase } from './components/widgetBase';
import { LayoutMediumComponent } from './components/layout-medium/layout-medium.component';
import { LayoutSmallComponent } from './components/layout-small/layout-small.component';
import { WidgetXlComponent } from './components/widget-xl/widget-xl.component';
import { WidgetChildComponent } from './components/widget-child/widget-child.component';
import { WidgetMediumComponent } from './components/widget-medium/widget-medium.component';
import { WidgetDynamicComponent } from './components/widget-dynamic/widget-dynamic.component';
import { WidgetSmallComponent } from './components/widget-small/widget-small.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { FiltersComponent } from './components/filters/filters.component';
import { OrgHirerchyMultiFilterComponent } from './components/filters/filter/org-hirerchy-multi-filter/org-hirerchy.multi-filter.component';
import { MatchHeightDirective } from './directive/match-height.directive';
import { HighchartsChartModule } from 'highcharts-angular';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressBarModule } from 'primeng/progressbar';
import { CarouselModule } from 'primeng/carousel';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { CalendarModule } from 'primeng/calendar';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TreeTableModule } from 'primeng/treetable';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule, TableService } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@grid';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { FilterPipe } from './pipes/filterBy.pipe';
import { FormatFileSizePipe } from './pipes/byte.pipe';
import { NotificationsService } from './services/notifications.service';
import { ToastService } from './services/toast/toast.service';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService, AuthenticationService } from '@app/core';
import { RealTimeNotificationService } from './services/realTimeNotification/real-time-notification.service';
// import { NgForageModule } from 'ngforage';
import { StickyHeaderDirective } from './directive/sticky-header.driective';
import { ClickOutside } from './directive/menu.directive';
import { DocumentClickDirective } from './directive/document-click.directive';
import { PanelModule } from 'primeng/panel';
import { AccordionModule } from 'primeng/accordion';
import { DigitOnlyDirective } from './directive/digit-only.directive';
import { ReflecxColumnDirective } from './directive/reflecx-column.directive';
import { NotificationListComponent } from './components/notifications/notification-list/notification-list.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RealTimeNotificationComponent } from './components/real-time-notification/real-time-notification.component';
import { AccountSettingComponent } from './components/account-setting/account-setting.component';
import { ProfileComponent } from './components/account-setting/profile/profile.component';
import { BugReportComponent } from './components/account-setting/bug-report/bug-report.component';
import { ChangePasswordComponent } from './components/account-setting/change-password/change-password.component';
import { TimezoneComponent } from './components/account-setting/timezone/timezone.component';
import { ImageDialogComponent } from './components/account-setting/dialog/image.dialog.component';
import { ImageCropperModule } from './components/account-setting/dialog/image-cropper/image-cropper.module';
import { AccountSettingService } from './components/account-setting/account-setting.service';
import { FiltersService } from './services/filters.service';
import { TourNgxPopperModule } from '@tour-custom';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { NotFound404Component } from './components/404/404.component';
import { ReflecxEditorComponent } from './components/editor/r-editor.component';
import { CacheService } from './services/cache.service';
import { OopsComponent } from './components/oops/oops.component';
import { NoMobileComponent } from './components/nomobile/no-mobile.component';
import { AuthServiceCleaner } from './services/auth.service.cleaner';
import { VocFiltersComponent } from './components/voc-filters/voc-filters.component';
import { SmFiltersComponent } from './components/sm-filters/sm-filters.component';
import { RDatePipe } from './pipes/rdate.pipe';
import { CustomDateFormatPipe } from './pipes/customdateformat.pipe';
import { TruncateGridTextPipe } from './pipes/truncategridtext.pipe';
import { GlobalFilterModule } from './module/global-filters/global-filters.module';
import { CvpTextareaComponent } from './components/ui-elements/cvp-textarea/cvp-textarea/cvp-textarea.component';
import { WidgetDropdownComponent } from './components/ui-elements/widget-dropdown/widget-dropdown.component';
import { ResultComponent } from './components/ui-elements/result/result.component';
import {
  TableContainerAdminComponent,
  TableContainerComponent,
} from './components/ui-elements/table-container/table-container.component';
import { TableCellComponent } from './components/ui-elements/table-cell/table-cell.component';
import { BaseTableComponent } from './components/ui-elements/base-table/base-table.component';
import { TableRendererComponent } from './components/ui-elements/table-renderer/table-renderer.component';
import WidgetHosterHelperComponent, {
  WidgetHosterComponent,
} from 'projects/cvreporting/src/app/features/cvreporting/widgets/widget-hoster.component';
import { ChunkPipe } from './pipes/chunk.pipe';
import { ReplacerPipe } from './pipes/replacer.pipe';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    PerfectScrollbarModule,
    CommonModule,
    RouterModule,
    MyPrimengModule,
    InputTextModule,
    NgxDatatableModule,
    TableModule,
    GridModule,
    TreeTableModule,
    SliderModule,
    ButtonModule,
    ProgressSpinnerModule,
    SidebarModule,
    CalendarModule,
    MenubarModule,
    TabMenuModule,
    InputSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    CarouselModule,
    ProgressBarModule,
    CheckboxModule,
    TourNgxPopperModule.forRoot(),
    DropdownModule,
    HighchartsChartModule,
    ToastModule,
    AccordionModule,
    ImageCropperModule,
    ConfirmDialogModule,
    TooltipModule,
    PanelModule,
    // NgForageModule.forRoot(),
    TranslateModule,
    NgxPermissionsModule.forRoot(),
    GlobalFilterModule,
  ],
  declarations: [
    WidgetHosterHelperComponent,
    WidgetHosterComponent,
    TableContainerAdminComponent,
    TableRendererComponent,
    BaseTableComponent,
    TableCellComponent,
    ResultComponent,
    ClickOutside,
    DocumentClickDirective,
    DigitOnlyDirective,
    ReflecxColumnDirective,
    ImageDialogComponent,
    RealTimeNotificationComponent,
    LoaderComponent,
    PageLayoutComponent,
    WidgetSmallComponent,
    WidgetMediumComponent,
    WidgetDynamicComponent,
    WidgetChildComponent,
    WidgetXlComponent,
    LayoutMediumComponent,
    LayoutSmallComponent,
    AccountSettingComponent,
    ProfileComponent,
    BugReportComponent,
    ChangePasswordComponent,
    TimezoneComponent,
    FiltersComponent,
    OrgHirerchyMultiFilterComponent,
    FilterPipe,
    FormatFileSizePipe,
    ReplacerPipe,
    RDatePipe,
    ChunkPipe,
    CustomDateFormatPipe,
    TruncateGridTextPipe,
    WidgetBase,
    LayoutXlComponent,
    HeaderTabsComponent,
    MatchHeightDirective,
    NotificationsComponent,
    NotificationListComponent,
    PageTitleComponent,
    StickyHeaderDirective,
    DigitOnlyDirective,
    UnauthorizedComponent,
    NotFound404Component,
    ReflecxEditorComponent,
    OopsComponent,
    NoMobileComponent,
    VocFiltersComponent,
    SmFiltersComponent,
    CvpTextareaComponent,
    WidgetDropdownComponent,
    TableContainerComponent,
  ],
  exports: [
    LowerCasePipe,
    WidgetHosterHelperComponent,
    WidgetHosterComponent,
    TableRendererComponent,
    TableCellComponent,
    BaseTableComponent,
    TableContainerComponent,
    ResultComponent,
    WidgetDropdownComponent,
    CvpTextareaComponent,
    ClickOutside,
    DocumentClickDirective,
    DigitOnlyDirective,
    ReflecxColumnDirective,
    AccountSettingComponent,
    ImageDialogComponent,
    ProfileComponent,
    BugReportComponent,
    TourNgxPopperModule,
    ChangePasswordComponent,
    TimezoneComponent,
    RealTimeNotificationComponent,
    NotificationsComponent,
    NotificationListComponent,
    MatchHeightDirective,
    PageTitleComponent,
    LoaderComponent,
    FiltersComponent,
    OrgHirerchyMultiFilterComponent,
    InputTextModule,
    ButtonModule,
    HeaderTabsComponent,
    MyPrimengModule,
    PageLayoutComponent,
    WidgetSmallComponent,
    WidgetMediumComponent,
    WidgetDynamicComponent,
    WidgetChildComponent,
    WidgetXlComponent,
    LayoutMediumComponent,
    LayoutSmallComponent,
    WidgetBase,
    FilterPipe,
    FormatFileSizePipe,
    ReplacerPipe,
    RDatePipe,
    CustomDateFormatPipe,
    TruncateGridTextPipe,
    LayoutXlComponent,
    InputTextModule,
    TableModule,
    GridModule,
    TreeTableModule,
    ButtonModule,
    ProgressSpinnerModule,
    SidebarModule,
    CalendarModule,
    MenubarModule,
    TabMenuModule,
    ChunkPipe,
    SliderModule,
    InputSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    CarouselModule,
    ProgressBarModule,
    CheckboxModule,
    DropdownModule,
    NgxDatatableModule,
    HighchartsChartModule,
    ToastModule,
    ConfirmDialogModule,
    TranslateModule,
    PanelModule,
    DigitOnlyDirective,
    UnauthorizedComponent,
    NotFound404Component,
    ReflecxEditorComponent,
    TooltipModule,
    NoMobileComponent,
    AccordionModule,
  ],
})
export class SharedRootModule {
  static forRoot() {
    return {
      ngModule: SharedRootModule,
      providers: [
        ...GlobalFilterModule.forServices(),
        CssLoaderService,
        NotificationsService,
        ToastService,
        MessageService,
        ConfirmationService,
        AppConfigurationService,
        AuthServiceCleaner,
        AccountSettingService,
        FiltersService,
        I18nService,
        CustomDateFormatPipe,
        TruncateGridTextPipe,
        CacheService,
        RealTimeNotificationService,
        //  BreadCrumbService
      ],
    };
  }
}
