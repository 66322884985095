import {
  NgModule,
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ToggleButton } from 'primeng/togglebutton';

export const TOGGLEBUTTON_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CVPToggleButton),
  multi: true,
};

@Component({
  selector: 'cvp-toggleButton',
  templateUrl: './togglebutton.html',
  providers: [TOGGLEBUTTON_VALUE_ACCESSOR],
})
export class CVPToggleButton extends ToggleButton {
  @ViewChild('checkbox') checkboxViewChild: ElementRef;
  onFocus() {}
}

@NgModule({
  imports: [CommonModule],
  exports: [CVPToggleButton],
  declarations: [CVPToggleButton],
})
export class CVPToggleButtonModule {}
