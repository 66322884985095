import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { RestMessage } from '@app/core/types';
import { CodeVerificationDTO } from '@app/core/types/user';
import { ToastService } from '@app/shared/services/toast/toast.service';

@Component({
  selector: 'app-code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.sass'],
})
export class CodeVerificationComponent implements OnInit {
  user: any = {};
  code: string;
  error: string;
  isError: boolean = false;
  submitted: boolean;
  codeVerification: CodeVerificationDTO;
  response: any;

  isSubmit: boolean = false;
  imagePath: any = undefined;
  tenant: any = undefined;
  clientId: any = null;
  userId: any = null;
  isEmailSent: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private cdf: ChangeDetectorRef,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.isSubmit = false;
    this.isError = false;
    this.userId = this.route.snapshot.queryParamMap.get('id');
    // this.CodeVerificationDTO = { Id: id, Token: token };
    // if (this.resetPasswordDTO.Id != null && this.resetPasswordDTO.Id !== '') {
    //   this.checkPasswordPolicy(this.resetPasswordDTO.Id);
    // }
    if (this.userId) {
      this.sendCodeEmailWithVerification();
    } else {
      this.toastService.error(
        'It seems your invitation link has got expired. Please request new link from your admin.'
      );
    }
    const path = this.route.snapshot.queryParamMap.get('imagepath');
    const tenant = this.route.snapshot.queryParamMap.get('tenant');
    if (path) {
      this.imagePath = path;
      if (this.imagePath) {
        this.imagePath = this.imagePath + '?' + this.getVersionNumber();
      }
      this.cdf.detectChanges();
    }
    if (tenant) {
      this.tenant = tenant;
    }
  }

  sendCodeEmail() {
    this.isEmailSent = false;
    this.authService
      .sendVerificationCode({ UserId: this.userId, VerifyInvitationAccepted: false })
      .subscribe((response: RestMessage) => {
        this.isEmailSent = true;
        this.toastService.success('Please check email for cofirmation code.');
      });
  }

  sendCodeEmailWithVerification() {
    this.isEmailSent = false;
    this.authService.sendVerificationCode({ UserId: this.userId }).subscribe((response: RestMessage) => {
      this.isEmailSent = true;
      this.toastService.success('Please check email for cofirmation code.');
    });
  }

  submitCode(code: any) {
    if (code === undefined || code === '') {
      this.error = "Sorry, we don't recognize this confirmation code.";
      this.isSubmit = false;
      this.isError = true;
      return;
    } else {
      this.error = '';
      this.isSubmit = true;
      this.isError = false;
    }

    this.codeVerification = { Id: this.userId, Code: code, ClientId: this.clientId };

    this.authService.verifyCode(this.codeVerification).subscribe((response: any) => {
      if (response) {
        this.toastService.success('Code confirmation successfull.');
        this.isSubmit = false;
        this.isError = false;
        this.error = '';
        this.code = '';
        this.router.navigateByUrl('/set-password?id=' + this.userId + '&code=' + code);
      } else {
        this.toastService.error(
          'Invalid confirmation code. Either your code is expired or you have entered invalid confirmation code.'
        );
      }
    });
  }

  getVersionNumber() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const date = d.getDate();
    const hours = d.getHours();
    const mins = d.getMinutes();
    const sec = d.getSeconds();
    const randomNumber = Math.floor(Math.random() * 100000 + 1);
    const version = year + '' + month + '' + date + '' + hours + '' + mins + '' + sec + '' + randomNumber;
    return version;
  }
}
