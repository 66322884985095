<div
  #container
  [ngStyle]="style"
  [class]="styleClass"
  [ngClass]="{'ui-table ui-widget outerbox': true, 'ui-table-responsive': responsive, 'ui-table-resizable': resizableColumns,
                'ui-table-resizable-fit': (resizableColumns && columnResizeMode === 'fit'),
                'ui-table-hoverable-rows': (rowHover||selectionMode), 'ui-table-auto-layout': autoLayout}"
>
  <div class="ui-table-loading ui-widget-overlay" *ngIf="loading"></div>
  <div class="ui-table-loading-content" *ngIf="loading">
    <i [class]="'ui-table-loading-icon pi-spin ' + loadingIcon"></i>
  </div>
  <div *ngIf="captionTemplate" class="ui-table-caption ui-widget-header">
    <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
  </div>
  <div *ngIf="showDomesticScroller && showWithScroll" class="table-scroll">
    <span
      class="btn btn--left fas fa-angle-left"
      (mouseenter)="scrollLeft()"
      (mouseleave)="clearLeft()"
    >
    </span>
    <span
      class="btn btn--right fas fa-angle-right"
      (mouseenter)="scrollRight()"
      (mouseleave)="clearRight()"
    >
    </span>
  </div>
  <cvp-paginator
    [rows]="rows"
    [first]="first"
    [totalRecords]="totalRecords"
    [pageLinkSize]="pageLinks"
    styleClass="ui-paginator-top"
    [alwaysShow]="alwaysShowPaginator"
    (onPageChange)="onPageChange($event)"
    [rowsPerPageOptions]="rowsPerPageOptions"
    *ngIf="paginator && (paginatorPosition === 'top' || paginatorPosition =='both')"
    [templateLeft]="paginatorLeftTemplate"
    [templateRight]="paginatorRightTemplate"
    [dropdownAppendTo]="paginatorDropdownAppendTo"
  ></cvp-paginator>

  <div
    #wrapperSticky
    id="SurveyGridPanelHeader"
    class="ui-table-wrapper sticky-table sticky-headers sticky-ltr-cells"
    *ngIf="!scrollable && showSticky"
    style="overflow: hidden; position: sticky; z-index: 28"
  >
    <table class="bluefixedtable" [ngClass]="tableStyleClass" [ngStyle]="tableStyle">
      <ng-container *ngTemplateOutlet="colGroupTemplate; context {$implicit: columns}"></ng-container>
      <thead class="ui-table-thead" #theadO>
        <ng-container *ngTemplateOutlet="headerTemplate; context: {$implicit: columns}"></ng-container>
      </thead>
      <tfoot *ngIf="footerTemplate" class="ui-table-tfoot">
        <ng-container *ngTemplateOutlet="footerTemplate; context {$implicit: columns}"></ng-container>
      </tfoot>
      <!-- <tbody style="visibility: collapse;" *ngIf="!TableBodyDirective" class="ui-table-tbody" [pTableBody]="columns" [pTableBodyTemplate]="bodyTemplate"></tbody> -->
      <tbody style="visibility: collapse" *ngIf="bodyTemplate" class="ui-table-tbody">
        <ng-container *ngIf="!expandedRowTemplate">
          <ng-template
            ngFor
            let-rowData
            let-rowIndex="index"
            [ngForOf]="(paginator && !lazy) ? ((filteredValue||value) | slice:first:(first + rows)) : (filteredValue||value)"
            [ngForTrackBy]="rowTrackBy"
          >
            <ng-container
              *ngTemplateOutlet="bodyTemplate; context: {$implicit: rowData, rowIndex: paginator ? (first + rowIndex) : rowIndex, columns: columns, editing: (editMode === 'row' && isRowEditing(rowData))}"
            ></ng-container>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="expandedRowTemplate">
          <ng-template
            ngFor
            let-rowData
            let-rowIndex="index"
            [ngForOf]="(paginator && !lazy) ? ((filteredValue||value) | slice:first:(first + rows)) : (filteredValue||value)"
            [ngForTrackBy]="rowTrackBy"
          >
            <ng-container
              *ngTemplateOutlet="bodyTemplate; context: {$implicit: rowData, rowIndex: paginator ? (first + rowIndex) : rowIndex, columns: columns, expanded: isRowExpanded(rowData), editing: (editMode === 'row' && isRowEditing(rowData))}"
            ></ng-container>
            <ng-container *ngIf="isRowExpanded(rowData)">
              <ng-container
                *ngTemplateOutlet="expandedRowTemplate; context: {$implicit: rowData, rowIndex: paginator ? (first + rowIndex) : rowIndex, columns: columns}"
              ></ng-container>
            </ng-container>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="isEmpty()">
          <ng-container *ngTemplateOutlet="emptyMessageTemplate; context: {$implicit: columns}"></ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- [ngStyle]="{'overflow': showScrollIcons ? 'hidden' : 'auto'}" -->
  <div
    #wrapper
    (scroll)="onScrollDiv($event)"
    id="SurveyGridPanelHeader_Visible"
    class="ui-table-wrapper sticky-table sticky-headers sticky-ltr-cells"
    *ngIf="!scrollable"
    style="overflow: hidden; overflow-x: auto;"
  >
    <table #table [id]="id" class="bluefixedtable" [ngClass]="tableStyleClass" [ngStyle]="tableStyle">
      <ng-container *ngTemplateOutlet="colGroupTemplate; context {$implicit: columns}"></ng-container>
      <thead class="ui-table-thead" #theadO>
        <ng-container *ngTemplateOutlet="headerTemplate; context: {$implicit: columns}"></ng-container>
      </thead>
      <tfoot *ngIf="footerTemplate" class="ui-table-tfoot">
        <ng-container *ngTemplateOutlet="footerTemplate; context {$implicit: columns}"></ng-container>
      </tfoot>
      <!-- <tbody *ngIf="!TableBodyDirective" class="ui-table-tbody" [pTableBody]="columns" [pTableBodyTemplate]="bodyTemplate"></tbody> -->
      <tbody *ngIf="bodyTemplate" class="ui-table-tbody">
        <ng-container *ngIf="!expandedRowTemplate">
          <ng-template
            ngFor
            let-rowData
            let-rowIndex="index"
            [ngForOf]="(paginator && !lazy) ? ((filteredValue||value) | slice:first:(first + rows)) : (filteredValue||value)"
            [ngForTrackBy]="rowTrackBy"
          >
            <ng-container
              *ngTemplateOutlet="bodyTemplate; context: {$implicit: rowData, rowIndex: paginator ? (first + rowIndex) : rowIndex, columns: columns, editing: (editMode === 'row' && isRowEditing(rowData))}"
            ></ng-container>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="expandedRowTemplate">
          <ng-template
            ngFor
            let-rowData
            let-rowIndex="index"
            [ngForOf]="(paginator && !lazy) ? ((filteredValue||value) | slice:first:(first + rows)) : (filteredValue||value)"
            [ngForTrackBy]="rowTrackBy"
          >
            <ng-container
              *ngTemplateOutlet="bodyTemplate; context: {$implicit: rowData, rowIndex: paginator ? (first + rowIndex) : rowIndex, columns: columns, expanded: isRowExpanded(rowData), editing: (editMode === 'row' && isRowEditing(rowData))}"
            ></ng-container>
            <ng-container *ngIf="isRowExpanded(rowData)">
              <ng-container
                *ngTemplateOutlet="expandedRowTemplate; context: {$implicit: rowData, rowIndex: paginator ? (first + rowIndex) : rowIndex, columns: columns}"
              ></ng-container>
            </ng-container>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="isEmpty()">
          <ng-container *ngTemplateOutlet="emptyMessageTemplate; context: {$implicit: columns}"></ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- Sticky Header -->
  <!-- <div class="table table--bordered table--fixedheight">
    <div id="SurveyGridPanelHeader" class="noprint" style="background: rgb(240, 240, 240); position: fixed; width: 2000px; overflow: hidden; top: 80px; z-index: 14;" [ngStyle]="{'width': getWrapperWidth()}">
      <div #topColumnContainer id="SurveyGridPanelHeaderContent" style="background: rgb(255, 255, 255); overflow: hidden; z-index: 0;"> -->
  <!-- For FrozenColumn Upper Boundry -->
  <!-- <table id="SurveyGridCopyClone"
        style="display: none"
        class="table--striped ui-table-thead" class="bluefixedtable" [ngClass]="tableStyleClass" [ngStyle]="tableStyle" style="table-layout: unset;width: 0; z-index: 100; background: darkred; position: absolute;">
          <thead class="ui-table-thead">
            <tr class="thead bar" id="SurveyGridHeaderCopy"> -->
  <!-- <th>
                  <div class="GridCellDiv" [ngStyle]="{'width': getLongestContentLength(0)}"></div>
              </th> -->
  <!-- <ng-container *ngFor="let col of cols; let i = index;">
                <ng-container *ngIf="col.freez">
                    <th  class="UnFrozenHeader" [cvpSortableColumn]="col.field">
                      <div class="GridCellDiv" [ngStyle]="{'width': getLongestContentLength(i)}"></div>
                        {{ col.header }}
                      <cvp-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></cvp-sortIcon>
                    </th>
                </ng-container>
              </ng-container>
            </tr>
          </thead>
        </table> -->

  <!-- For UnFrozenColumn Lower Boundry -->
  <!-- <table id="SurveyGridCopy" class="table--striped ui-table-thead" class="bluefixedtable" [ngClass]="tableStyleClass" [ngStyle]="tableStyle" style="table-layout: unset; display:none">
          <thead class="ui-table-thead">
            <tr class="thead bar" id="SurveyGridHeaderCopy"> -->
  <!-- <th>
                  <div class="GridCellDiv" [ngStyle]="{'width': getLongestContentLength(0)}"></div>
              </th> -->
  <!-- <th *ngFor="let col of cols; let i = index;" class="UnFrozenHeader" [cvpSortableColumn]="col.field">
                <div class="GridCellDiv" [ngStyle]="{'width': getLongestContentLength(i)}"></div>
                {{ col.header }}
                <cvp-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></cvp-sortIcon>
              </th>
              <th>
                  <div class="GridCellDiv" [ngStyle]="{'width': getLastColumnWidth()}"></div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div> -->
  <!-- End Sticky Header -->

  <div class="ui-table-scrollable-wrapper" *ngIf="scrollable">
    <div
      class="ui-table-scrollable-view ui-table-frozen-view"
      *ngIf="frozenColumns||frozenBodyTemplate"
      [ngStyle]="{width: frozenWidth}"
    ></div>
    <div class="ui-table-scrollable-view" [ngStyle]="{left: frozenWidth, width: 'calc(100% - '+frozenWidth+')'}"></div>
  </div>

  <cvp-paginator
    [rows]="rows"
    [first]="first"
    [totalRecords]="totalRecords"
    [pageLinkSize]="pageLinks"
    styleClass="ui-paginator-bottom"
    [alwaysShow]="alwaysShowPaginator"
    (onPageChange)="onPageChange($event)"
    [rowsPerPageOptions]="rowsPerPageOptions"
    *ngIf="paginator && (paginatorPosition === 'bottom' || paginatorPosition =='both')"
    [templateLeft]="paginatorLeftTemplate"
    [templateRight]="paginatorRightTemplate"
    [dropdownAppendTo]="paginatorDropdownAppendTo"
  ></cvp-paginator>
  <div *ngIf="summaryTemplate" class="ui-table-summary ui-widget-header">
    <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
  </div>

  <div
    #resizeHelper
    class="ui-column-resizer-helper ui-state-highlight"
    style="display: none"
    *ngIf="resizableColumns"
  ></div>

  <span
    #reorderIndicatorUp
    class="pi pi-arrow-down ui-table-reorder-indicator-up"
    style="display: none"
    *ngIf="reorderableColumns"
  ></span>
  <span
    #reorderIndicatorDown
    class="pi pi-arrow-up ui-table-reorder-indicator-down"
    style="display: none"
    *ngIf="reorderableColumns"
  ></span>
</div>
