import { CommonModule } from '@angular/common';
import { Component, Directive, NgModule } from '@angular/core';
import { Button, ButtonDirective } from 'primeng/button';

@Directive({
  selector: '[rfButton]',
})
export class RfButtonDirective extends ButtonDirective {}

@Component({
  selector: 'rf-button',
  templateUrl: './button.html',
})
export class RfButtonComponent extends Button {}

@NgModule({
  imports: [CommonModule],
  exports: [RfButtonDirective, RfButtonComponent],
  declarations: [RfButtonDirective, RfButtonComponent],
})
export class RfButtonModule {}
