import { Injectable, OnInit } from '@angular/core';
import { forkJoin, of, Observable, BehaviorSubject } from 'rxjs';
import { tap, catchError, filter, find, subscribeOn } from 'rxjs/operators';

import { Notification } from '@app/core/types/notifications';
import { NgForageService } from './ng-forage.service';
import { NgForage } from 'ngforage';
import { OrderBy } from '@app/core/types/orderby';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpRequest } from '@angular/common/http';
import { Guid } from 'guid-typescript';
@Injectable()
export class NotificationsService extends NgForageService<Notification> {
  notificationTypes = [
    { name: 'Notification', icon: 'fas fa-exclamation-triangle', classes: 'alert notification' },
    { name: 'Success', icon: 'far fa-check', classes: 'alert complete' },
    { name: 'Info', icon: 'far fa-info', classes: 'alert updated' },
    { name: 'Warning', icon: 'far fa-exclamation', classes: 'alert canceled' },
    { name: 'Error', icon: 'fas fa-exclamation-triangle', classes: 'alert deleted' },
  ];

  public readonly routingType: number = 1;
  public readonly logoutType: number = 2;
  public readonly syncDateType: number = 3;
  public readonly updateRoutingType: number = 4;
  public readonly loginType: number = 5;
  public readonly sessionId: string;

  constructor(ngf: NgForage, private router: Router, private activatedRouter: ActivatedRoute) {
    super('notifications', ngf);
    this.sessionId = Guid.create().toString();
  }

  getAllRecoredsAsync(sort?: string, orderBy?: OrderBy): Observable<Notification[]> {
    return this.getAllAsync(sort, orderBy);
  }
  ActivityRoutingNotification(message?: string, pageName?: string, parammodule?: string) {
    const appSessionId: any = localStorage.getItem('appNotifyId');
    if (pageName != null) {
      message = message + ' ' + pageName;
    }

    let lastVisitNotification: Notification[];
    this.getAllRecoredsAsync().subscribe((record) => {
      lastVisitNotification = record.filter((rec: any) => {
        return (
          rec.dateTimeExited == null && rec.monitoringType === this.routingType && rec.tabSessionId === this.sessionId
        );
      });

      for (let i = 0; i < lastVisitNotification.length; i++) {
        lastVisitNotification[i].dateTimeExited = new Date();
        this.setItem(lastVisitNotification[i]);
      }

      const notification: Notification = {
        icon: 'far fa-info-circle',
        notificationType: 'Info',
        type: 'Visit',
        classes: 'alert complete',
        message,
        page: pageName,
        date: new Date(),
        pageUrl: window.location.href,
        module: parammodule,
        userAgent: window.navigator.userAgent,
        browser: window.navigator.appCodeName + ' ' + window.navigator.vendor,
        isRead: false,
        dateTimeAccessed: new Date(),
        dateTimeExited: null,
        monitoringType: this.routingType, // for routing set it equal to 1
        tabSessionId: this.sessionId,
        appNotifyId: appSessionId,
      };

      this.addAsync(notification);
    });
  }
  ActivityRequestNotification(message?: string, page?: string, paramModule?: string, request?: HttpRequest<any>) {
    const appSessionId: any = localStorage.getItem('appNotifyId');
    const notification: Notification = {
      icon: 'far fa-info-circle',
      notificationType: 'Info',
      type: 'Action',
      classes: 'alert complete',
      message,
      page: page,
      date: new Date(),
      pageUrl: request.url,
      module: paramModule,
      userAgent: window.navigator.userAgent,
      browser: window.navigator.appCodeName + ' ' + window.navigator.vendor,
      requestParameters: request.url,
      dateTimeAccessed: new Date(),
      dateTimeExited: null,
      isRead: false,
      httpMethod: request.method,
      tabSessionId: this.sessionId,
      appNotifyId: appSessionId,
    };

    this.addAsync(notification);
  }

  ActivityNonRequestNotification(data: any, isLogin: boolean) {
    const appSessionId: any = localStorage.getItem('appNotifyId');
    const notification: Notification = {
      icon: 'far fa-info-circle',
      notificationType: 'Info',
      type: 'Action',
      classes: 'alert complete',
      message: data.message,
      page: data.pageName,
      date: new Date(),
      // pageUrl: request.url,
      module: data.module,
      userAgent: window.navigator.userAgent,
      browser: window.navigator.appCodeName + ' ' + window.navigator.vendor,
      //  requestParameters: request.url,
      dateTimeAccessed: new Date(),
      dateTimeExited: null,
      isRead: false,
      tabSessionId: this.sessionId,
      appNotifyId: appSessionId,
      // httpMethod: request.method
    };

    if (isLogin === false) {
      this.addAsync(notification);
    } else {
      const id: Guid = Guid.create();
      const key: string = id.toString();
      notification.id = key;
      notification.createdon = new Date();
      notification.monitoringType = this.loginType;
      this.addIfNoExist(key, notification);
      localStorage.setItem('loginNotificationId', key);
    }
  }

  GetNotificationForEvent(data: any, isLogout: boolean): Notification {
    let monitringType = 0;
    if (isLogout) {
      monitringType = this.logoutType;
    }
    const appSessionId: any = localStorage.getItem('appNotifyId');
    const notification: Notification = {
      icon: 'far fa-info-circle',
      notificationType: 'Info',
      type: 'Action',
      classes: 'alert complete',
      message: data.message,
      page: data.pageName,
      date: new Date(),
      // pageUrl: request.url,
      module: data.module,
      userAgent: window.navigator.userAgent,
      browser: window.navigator.appCodeName + ' ' + window.navigator.vendor,
      //  requestParameters: request.url,
      dateTimeAccessed: new Date(),
      dateTimeExited: data.dateTimeExited,
      isRead: false,
      monitoringType: monitringType,
      tabSessionId: this.sessionId,
      appNotifyId: appSessionId,
      // httpMethod: request.method
    };
    return notification;
  }

  SetUpdateNotificationValues(notifications: any): Notification {
    const data: Notification = {
      icon: null,
      notificationType: null,
      classes: null,
      message: null,
      date: new Date(),
      dateTimeAccessed: new Date(),
      tabSessionId: this.sessionId,
      type: 'Message',
      monitoringType: this.updateRoutingType,
      dateTimeExited: notifications.dateTimeExited,
      createdon: new Date(),
      id: notifications.id,
    };
    return data;
  }

  IsRoutingUpdateRecord(record: any, isLogout: boolean = false): boolean {
    const appNotifyId: any = localStorage.getItem('appNotifyId');
    if (isLogout) {
      if (
        record.isRead === true &&
        record.monitoringType === this.routingType &&
        record.appNotifyId === appNotifyId &&
        record.duration === null
      ) {
        return true;
      }
    }
    if (
      record.isRead === true &&
      record.monitoringType === this.routingType &&
      record.duration === null &&
      record.appNotifyId === appNotifyId &&
      record.dateTimeExited !== null
    ) {
      return true;
    }
    return false;
  }
}
