export interface ClientConfig {
  Id: string;
  ClientId?: string;
  Identifier: string;
  FeatureId: string;
  Fields: any;
}

export interface Config {
  Id: string;
  Identifier: string;
  FeatureId: string;
  Fields: any;
  ClientConfig?: ClientConfig;
}

export class Identifiers {
  // Text for Displaying in Datasource Survey Filter
  public static LastSurveyDateName = 'Last Survey Date';
  public static ProgramDate = 'ProgramDate';

  // Configuration
  public static TemplatePage = 'template_page';
  public static AddTemplatePage = 'add_template_page';
  public static DeleteTemplatePage = 'delete_template_page';
  public static ConfigurationPage = 'configuration_page';
  public static QuestionTextLength = 'Question.TextLength';
  public static QuestionVariableLength = 'Question.Variable.TextLength';
  public static QuestionVariableOptionCommentLength = 'Question.Variable.Option.CommentLength';
  public static QuestionSetting = 'question_settings';
  public static QuestionCommentLength = 'Question.CommentLength';
  public static QuestionVariableOptionScoreLength = 'Question.Variable.Option.ScoreLength';
  public static TransmissionExpiry = 'Transmission.Expiry';
  public static QuestionAnchorLength = 'Question.AnchorLength';
  public static QuestionCategoryLength = 'Question.CategoryLength';
  public static DataSourceCustomerFilter = 'Datasource.Customer.Filter';
  public static PortalCache = 'portal_cache';
  public static SurveyThemes = 'survey-themes';
  public static customerProfile = 'customer_profile';

  public static QuestionClone = 'question_cloning';
  public static QuestionMove = 'survey_designer_can_move_question';
  public static QuestionEdit = 'question_edit';
  public static QuestionDelete = 'question_delete';
  public static SaveQuestion = 'question_save';
  public static VariableTextLength = 'variable_text_length';
  public static QuestionVariableOptionTextLength = 'Question.Variable.Option.TextLength';
  public static QuestionMaxOptionValuesFilter = 'Question.MaxOption.filter';
  public static QuestionModelSelection = 'ModelSelection';

  public static SurveyLIstExport = 'survey_list_export';
  public static TransmissionLIstExport = 'transmission_list_export';
  public static SurveyColumnBrand = 'survey_column_brand';
  public static SurveyFileUpload = 'FileUpload';
  public static UserLIstExport = 'user_list_export';
  public static DatasourceListExport = 'ds_list_import';
  public static DatasourceExport = 'ds_export';
  public static DatasourceImport = 'ds_import';

  public static CustomerDelete = 'customer_delete';
  public static CustomerEdit = 'customer_edit';

  public static ReadUser = 'user_read';
  public static ReadClient = 'user_client';
  public static ReadRole = 'user_role';
  public static AllowSSOLogout = 'allow_sso_logout';
  public static AccountSettings = 'account_settings';
  public static Weight = 'weight';
  public static EmailEdit = 'email_edit';
  public static TourGuide = 'tour_guide';
  public static Multilingual = 'allow_portal_translation';
  public static PortalTrafficDataPush = 'allow_portal_traffic_data_push';
  public static CustomizeDashboard = 'customize_dashboard';
  public static PortalTranslation = 'portal_translation';
  public static ExportDashboard = 'export_dashboard';
  public static PrintDashboard = 'print_dashboard';
  public static TrainingVideos = 'training_videos';
  public static NotificationTemplate = 'notification_template';
  public static Resources = 'resources';
  public static Help = 'web_help';
  public static Helpdesk = 'help_desk';
  public static NonDealerCommentList = 'reporting_textanalytics_commentlist_nondealer';
  public static NonDealerReviewList = 'reporting_sm_reviewlist_nondealer';
  public static ReviewReply = 'reporting_sm_review_reply';
  public static CommentListUpdate = 'reporting_textanalytics_commentlist_update';
  public static ReviewListUpdate = 'reporting_sm_edit_category';
  public static ZendeskTab = 'reporting_sm_zendesk';
  public static NotesTab = 'reporting_sm_notes';
  public static RegionalMapView = 'reporting_textanalytics_regionalmapview';
  public static FilterLogicOperator = 'filter_Logic_disable';
  public static ReviewDetailsPage = 'reporting_sm_reviewlist_reviewdetailspage';
  public static IncludeUnassigned = 'reporting_show_unassigned_comments_on_sm';

  public static TransmissionEdit = 'transmission_edit';
  public static SurveyHeaderEdit = 'survey_header_edit';

  public static ChiSquareTest = 'Chi-Square Test';
  public static SignificanceTest = 'Significance Test';
  public static HeaderListOrder = 'Header.Order';
  public static KPIOptions = 'VOC.KPIDropdownOptions';
  public static FilterBarScoreRange = 'VOC.FilterBarScoreRange';
  public static MapCircleScaleSize = 'VOC.MapCircleScaleSize';
  public static ShowUnassignedCommentsVOC = 'reporting_show_unassigned_comments';
  public static ShowUnassignedCommentsSocialMedia = 'reporting_show_unassigned_comments_on_sm';
  public static ThemeDimension = 'theme.dimension';
  public static FileValidation = 'file_validation';
  public static DynamicColunmsHides = 'DynamicColunmsHides';
  public static ConfigEventsKPI = 'configuration.events.kpis';

  // 2FA PERMISSIONS
  public static TWO_FACTOR_ACCOUNT_LEVEL_SETTINGS = 'two_factor_settings';
  public static TWO_FACTOR_CLIENT_LEVEL_SETTINGS = 'client_level_two_factor_settings';

  // Insight
  public static InsightDashboard = 'insight_dashboard';
  public static DashboardSummary = 'insight_dashboard_summary';
  public static DashboardTrackingStats = 'insight_dashboard_tracking_stats';
  public static DashboardPerformanceStats = 'insight_dashboard_performance_stats';

  public static InsightReport = 'insight_reports';
  public static InsightReportByRegion = 'insight_reports_region';
  public static InsightReportByDemoGraphic = 'insight_reports_demographic';
  public static InsightReportByVehicleAge = 'insight_reports_vehicle_age';
  public static InsightReportByGeneration = 'insight_reports_generation';
  public static InsightReportByVerbatim = 'insight_reports_verbatim';

  public static InsightAdhoc = 'insight_adhoc_report';
  public static InsightDownload = 'insight_downlad_reports';
  public static InsightAdhocByQuestion = 'insight_adhoc_report_question';
  public static InsightAdhocByTransimission = 'insight_adhoc_report_transmission';
  public static InsightAdhocByVerbatim = 'insight_adhoc_report_verbatim';
  public static InsightAdhocByFilterQuestion = 'insight_adhoc_report_filter_by_question';
  public static InsightAliasReport = 'reporting_alias_report';

  // Widgets
  public static WidgetsRefreshThreshold = 'Widgets.RefreshThreshold';

  // Permission
  // Library
  public static Library = 'Library';
  public static LibraryModule = 'LibraryModule';
  public static LibraryImages = 'Library.Images';
  public static LibraryDocuments = 'Library.Documents';
  public static LibraryTemplate = 'Library.Templates';
  public static LibraryNotificationTemplate = 'Library.NotificationTemplates';
  public static LibrarySurveyTemplate = 'Library.SurveyTemplates';
  public static LibraryImagesCopy = 'Library.Images.Copy';
  public static LibraryImagesMove = 'Library.Images.Move';
  public static LibraryImagesSearch = 'Library.Images.Search';
  public static LibraryImagesUpload = 'Library.Images.Upload';
  public static LibraryImagesManageCategory = 'Library.Images.ManageCategory';
  public static LibraryImagesRename = 'Library.Images.Rename';
  public static LibraryImagesDownload = 'Library.Images.Download';
  public static LibraryImagesDelete = 'Library.Images.Delete';
  public static LibraryImagesManageCategoryAdd = 'Library.Images.ManageCategory.Add';
  public static LibraryImagesManageCategoryRename = 'Library.Images.ManageCategory.Rename';
  public static LibraryImagesManageCategoryDelete = 'Library.Images.ManageCategory.Delete';

  public static LibraryDocumentsCopy = 'Library.Documents.Copy';
  public static LibraryDocumentsMove = 'Library.Documents.Move';
  public static LibraryDocumentsSearch = 'Library.Documents.Search';
  public static LibraryDocumentsUpload = 'Library.Documents.Upload';
  public static LibraryDocumentsManageCategory = 'Library.Documents.ManageCategory';
  public static LibraryDocumentsRename = 'Library.Documents.Rename';
  public static LibraryDocumentsDownload = 'Library.Documents.Download';
  public static LibraryDocumentsDelete = 'Library.Documents.Delete';
  public static LibraryDocumentsManageCategoryAdd = 'Library.Documents.ManageCategory.Add';
  public static LibraryDocumentsManageCategoryRename = 'Library.Documents.ManageCategory.Rename';
  public static LibraryDocumentsManageCategoryDelete = 'Library.Documents.ManageCategory.Delete';
  public static DataSourceMasterCustomerList = 'ds_mastercustomer';
  public static QuestionEditRandomizeList = 'question_edit_randomize_list';
  public static QuestionEditSettingsHasCommentQuestion = 'question_edit_settings_hasComment_Question';
  public static QuestionEditSettingsAnchorWord = 'question_edit_settings_Anchorword';
  public static QuestionEditSettingsMultipleAnchorWords = 'question_edit_settings_multiple_Anchorword';
  public static QuestionEditSettingsOptionValueDisable = 'question_edit_optionvalue_disable';
  public static SurveyCoreFilter = 'survey.core.filter';
  public static SurveyAdhocFilter = 'survey.adhoc.filter';
  public static SurveyFollowUpHotAlert = 'survey.followup.hotalerts';
  public static SurveyManualAdministration = 'survey_manual_administration';
  public static SurveyLanguageManagement = 'survey_language_management';
  public static AllowAdvanceEditor = 'allow_advance_editor';
  public static StatePersistence = 'state_persistence';

  public static AlertList = 'reporting_hotalerts_hotalertlist';
  public static ReportingHotAlerts = 'reporting_hotalerts';
  public static ReportingCoach = 'reporting_coach';
  public static ReportingCustomerHistory = 'reporting_customerhistory';
  public static ReportingCustomerLifecycle = 'reporting_customerlifecycle';

  // SM Widgets
  public static KpiAvgRatings = 'reporting_sm_kpi_average_ratings';
  public static KpiReviewResponseTime = 'reporting_sm_kpi_response_time';
  public static KpiReviewResponseRate = 'reporting_sm_kpi_response_rate';
  public static kpiTotalReviews = 'reporting_sm_kpi_total_reviews';

  public static AvgRatingsOverTime = 'reporting_sm_ratings_over_time';
  public static ReviewsOverTime = 'reporting_sm_reviews_over_time';
  public static RatingsDistribution = 'reporting_sm_ratings_distribution';
  public static ReviewSitesDistribution = 'reporting_sm_review_sites_distribution';
  public static Reviews = 'reporting_sm_kpi_total_reviews';
  public static Top_Bottom_Performers = 'reporting_sm_top_bottom_performers';

  // Global dashboad-> Sruvey Resend
  public static SurveyResendColumns = 'dashboard.survey_resend.columns';
  public static DealerPerformanceEvents = 'dashboard.widget.dealer_performance.events';
  public static GridPin = 'grid_pin';
  public static FrontendLogger = 'frontend.logger';
  public static RegionalMapScale = 'regional_map_scale';
  // public static ReportingHotAlerts = 'reporting_hotalerts';
  // public static ReportingCoach = 'reporting_coach';
  // public static ReportingCustomerHistory = 'reporting_customerhistory';
  // public static ReportingCustomerLifecycle = 'reporting_customerlifecycle';
}
