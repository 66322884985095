import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule, ScrollbarHelper, DimensionsHelper, ColumnChangesService } from '@swimlane/ngx-datatable';

import { Datatable } from './datatable/Datatable';
import { RHeaderComponent } from './datatable/header/rheader.component';
import { FooterComponent } from './footer/footer.component';
import { BodyMenuComponent } from './body/body-menu/body-menu.component';
import { BodyHeaderComponent } from './body/body-header/body-header.component';
import { HeaderCellComponent } from './datatable/header/data-header-cell';
import { HeaderComponent } from './header/header.component';
import { HeaderFilterComponent } from './datatable/header/header-filter/header-filter.component';

import { DataTableFooterTemplateDirective } from './datatable/footer/footer-template.directive';
import { DatatableFooterDirective } from './datatable/footer/footer.directive';
import { RDataTablePagerCompnent } from './datatable/footer/rdatatable-pager';
import { RFooterComponent } from './datatable/footer/rfooter.component';

import { RDataTableBodyCellComponent } from './datatable/body/body-cell.component';
import { RDataTableBodyComponent } from './datatable/body/body.component';
import { RDataTableBodyRowComponent } from './datatable/body/body-row.component';
import { RDataTableRowWrapperComponent } from './datatable/body/body-row-wrapper.component';
import { RDataTableSummaryRowComponent } from './datatable/body/summary/summary-row.component';
import { RDataTableSelectionComponent } from './datatable/body/selection.component';
import { RProgressBarComponent } from './datatable/body/progress-bar.component';
import { RScrollerComponent } from './datatable/body/scroller.component';
import { DatatableGroupHeaderDirective } from './datatable/body/body-group-header.directive';
import { DatatableGroupHeaderTemplateDirective } from './datatable/body/body-group-header-template.directive';

import { VisibilityDirective } from './datatable/directives/visibility.directive';
import { LongPressDirective } from './datatable/directives/long-press.directive';
import { OrderableDirective } from './datatable/directives/orderable.directive';
import { ResizeableDirective } from './datatable/directives/resizeable.directive';
import { DraggableDirective } from './datatable/directives/draggable.directive';

import { BodyListComponent } from './datatable/body-list/body.list.component';

import { RDataTableBodyListCellComponent } from './datatable/body-list/body-list-cell';

import { RDataTableBodyListRowComponent } from './datatable/body-list/body-list-row.component';
import { HeaderTopComponent } from './header/header-top/header-top.component';
import { SearchActionComponent } from './header/header-top/search-action/search-action.component';
import { ActionIconsComponent } from './header/header-top/action-icons/action-icons.component';
import { FormsModule } from '@angular/forms';
import { GridHeaderTopActionDirective } from './header/header-top/search-action/grid-header-top-action.directive';
import { GridHeadeTopActionTemplateDirective } from './header/header-top/search-action/grid-header-top-action.template.directive';
import { GridHeaderMenuDirective } from './header/header-menu.directive';
import { GridHeaderMenuTemplateDirective } from './header/header-menu.template.directive';
import { GridBodyListRowDirective } from './datatable/body-list/list-row.directive';
import { GridBodyListRowTemplateDirective } from './datatable/body-list/list-row.template.directive';
import { GridHeaderTopDropdownDirective } from './header/header-top/search-action/grid-header-top-search-dropdown.directive';
import { GridHeadeTopDropdownTemplateDirective } from './header/header-top/search-action/grid-header-top-search.template.directive';
import { GridHeaderFilterDropdownDirective } from './datatable/header/header-filter/alphabetfilter.dropdown.directive';
import { GridHeaderFilterDropdownTemplateDirective } from './datatable/header/header-filter/alphabetfilter.dropdown.template.directive';
import { FooterPageSizeDropDownTemplateDirective } from './datatable/footer/footer-pagesize-dropdown.template.directive';
import { FooterPageSizeDropdownDirective } from './datatable/footer/footer-pagesize-dropdown.directive';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { GridHeaderTitleDirective } from './header/header-top/header-title.directive';
import { GridHeaderTitleTemplateDirective } from './header/header-top/header-title-template.directive';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MyPrimengModule } from '@primeng';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  providers: [ScrollbarHelper, DimensionsHelper, ColumnChangesService],
  declarations: [
    // OrderableDirective,
    // RHeaderComponent,
    // FooterComponent,
    // BodyMenuComponent,
    // BodyHeaderComponent,
    // HeaderCellComponent,
    // RDataTableBodyCellComponent,
    // RDataTableBodyComponent,
    // RDataTableBodyRowComponent,
    // RDataTableRowWrapperComponent,
    // RDataTableSummaryRowComponent,
    // RDataTableSelectionComponent,
    // RProgressBarComponent,
    // RScrollerComponent,
    // VisibilityDirective,
    // LongPressDirective,
    // ResizeableDirective,
    // DraggableDirective,
    // HeaderFilterComponent,
    // HeaderComponent,
    // RFooterComponent,
    // DataTableFooterTemplateDirective,
    // DatatableFooterDirective,
    // RDataTablePagerCompnent,
    // DatatableGroupHeaderDirective,
    // DatatableGroupHeaderTemplateDirective,
    // Datatable
    OrderableDirective,
    RHeaderComponent,
    FooterComponent,
    BodyMenuComponent,
    BodyHeaderComponent,
    Datatable,
    HeaderCellComponent,
    RDataTableBodyCellComponent,
    RDataTableBodyComponent,
    RDataTableBodyRowComponent,
    RDataTableRowWrapperComponent,
    RDataTableSummaryRowComponent,
    RDataTableSelectionComponent,
    RProgressBarComponent,
    RScrollerComponent,
    VisibilityDirective,
    LongPressDirective,
    ResizeableDirective,
    DraggableDirective,
    HeaderComponent,
    HeaderFilterComponent,
    HeaderComponent,
    RFooterComponent,
    DataTableFooterTemplateDirective,
    DatatableFooterDirective,
    RDataTablePagerCompnent,
    DatatableGroupHeaderDirective,
    DatatableGroupHeaderTemplateDirective,
    BodyListComponent,
    RDataTableBodyListCellComponent,
    RDataTableBodyListRowComponent,
    HeaderTopComponent,
    SearchActionComponent,
    ActionIconsComponent,
    GridHeadeTopActionTemplateDirective,
    GridHeaderTopActionDirective,
    GridHeaderMenuDirective,
    GridHeaderMenuTemplateDirective,
    GridBodyListRowDirective,
    GridBodyListRowTemplateDirective,
    GridHeaderTopDropdownDirective,
    GridHeadeTopDropdownTemplateDirective,
    GridHeaderFilterDropdownDirective,
    GridHeaderFilterDropdownTemplateDirective,
    FooterPageSizeDropDownTemplateDirective,
    FooterPageSizeDropdownDirective,
    GridHeaderTitleDirective,
    GridHeaderTitleTemplateDirective,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    PerfectScrollbarModule,
    ScrollPanelModule,
    MyPrimengModule,
    TooltipModule,
    TranslateModule,
  ],
  exports: [
    Datatable,
    DatatableGroupHeaderDirective,
    DatatableGroupHeaderTemplateDirective,
    RDataTablePagerCompnent,
    GridHeadeTopActionTemplateDirective,
    GridHeaderTopActionDirective,
    GridHeaderMenuDirective,
    GridHeaderMenuTemplateDirective,
    GridBodyListRowDirective,
    GridBodyListRowTemplateDirective,
    GridHeaderTopDropdownDirective,
    GridHeadeTopDropdownTemplateDirective,
    GridHeaderFilterDropdownDirective,
    GridHeaderFilterDropdownTemplateDirective,
    FooterPageSizeDropDownTemplateDirective,
    FooterPageSizeDropdownDirective,
    GridHeaderTitleDirective,
    GridHeaderTitleTemplateDirective,
  ],
})
export class GridModule {}
