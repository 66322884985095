import { Action } from '@ngrx/store';

export enum ActionTypes {
  ChangeState = '[ChangeState Component] ChangeState',
}

export class ChangeState implements Action {
  readonly type = ActionTypes.ChangeState;
  constructor(public payload: { widgets: Array<any> }) {}
}
