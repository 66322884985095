import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { RequestCache } from '@app/core/http/request.cache.service';
import { Actions } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Injectable()
export class CacheService implements OnDestroy {
  private unsubscribe$ = new Subject();
  constructor(private actions$: Actions, private requestCache: RequestCache) {}

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getRequestCache() {
    return this.requestCache;
  }
  getResponseData(res: any) {
    if (res) {
      const ress = res.body || null;
      if (ress.Response) {
        return ress;
      }
    }
  }
  for(url: string) {
    const subject = new BehaviorSubject<any>(null);
    if (url) {
      this.actions$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
        if (data && data.type == '[ChangeCacheState Component] ChangeCacheState') {
          const payload = data['payload'] || null;
          if (payload) {
            const cache = payload.cache;
            if (cache.url.search(this.escapeRegExp(url)) !== -1) {
              const res = cache.body || null;
              if (res) {
                subject.next(cloneDeep(res));
              }
            }
          }
        }
      });
    }
    return subject.asObservable();
  }

  escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
}
