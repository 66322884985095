// http://codepen.io/Lewitje/pen/YybQEP original copy from Lewi Hussay updated to work with multiple divs
// Equal height - by Burfield www.burfield.co.uk
// 2016-30-03 - Changed - Refactored from jQuery to vanilla JS (@jacobwarduk http://www.jacobward.co.uk)
// Example usage use data-match-height="groupName" on anything!!!
Object.defineProperty(Array.prototype, 'chunk', {
  value: function(chunkSize) {
    var array = this;
    return [].concat.apply([],
      array.map(function(elem, i) {
        return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
      })
    );
  }
});
export const matchHeight = (function() {
    var matchHeight = {
      match: function(groupName, gName, cb) {
        var scrollY = window.scrollY;
        var groupHeights = {};
        for (var i = 0; i < groupName.length; i++) {
          var item = groupName[i];
          item.setAttribute('data-match-height', gName);
          var data = item.getAttribute('data-match-height');
          item.setAttribute('style', '');
          item.style.minHeight = 'auto';
          if (groupHeights.hasOwnProperty(data)) {
            Object.defineProperty(groupHeights, data, {
              value: Math.max(groupHeights[data], item.offsetHeight),
              configurable: true,
              writable: true,
              enumerable: true
            });
          } else {
            groupHeights[data] = item.offsetHeight;
          }
        }
        var groupHeightsMax = groupHeights;
        Object.getOwnPropertyNames(groupHeightsMax).forEach(function(value) {
          var elementsToChange = document.querySelectorAll(
              "[data-match-height='" + value + "']"
            );
          var  elementsLength = elementsToChange.length;
          for (var i = 0; i < elementsLength; i++) {
            // elementsToChange[i].style.height = 0 + 'px';
            elementsToChange[i].style.height =
              Object.getOwnPropertyDescriptor(groupHeightsMax, value).value + 'px';
          }
        });
        cb();
      },
      init: function() {
        var groupName = Array.prototype.slice.call(
          document.querySelectorAll('[data-match-height]')
        );
        var chunks = groupName.chunk(2);
        for (var i = 0; i < chunks.length; i++) {
          var d = (Math.random() * 155789) + 100;
          if(chunks[i].length > 0) {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            this.match(chunks[i], d, function() {
              window.scrollTo(window.scrollX, scrollTop);
            });
          }
        }
      }
    };
  
    // Initialising on DOM load
    (document.readyState === 'complete' && matchHeight.init()) ||
      document.addEventListener('DOMContentLoaded', matchHeight.init);
  
    return matchHeight;
  });
