import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { identityServerAngularConfig } from '@env/environment';

import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { LoaderService } from './../../core/loader.service';
import { AuthServiceCleaner } from './../../shared/services/auth.service.cleaner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-end-session',
  templateUrl: './end-session.component.html',
  styleUrls: ['./end-session.component.sass'],
})
export class EndSessionComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private authClearService: AuthServiceCleaner,
    private router: Router
  ) {}

  ngOnInit() {
    this.loaderService.showMain = false;
    const ssoClient = JSON.parse(localStorage.getItem('ssoClient'));
    console.log(localStorage.getItem('ssoClient'));
    if (ssoClient != null && ssoClient != '') {
      this.authService.getSSOClientInfo(ssoClient).subscribe((client: any) => {
        if (client != null) {
          const indexDbKey = 'oidc.user:' + client.Authority + ':' + client.IdentityClientId;
          this.authService.removeSsoIndexedDbItem(indexDbKey).then(() => {
            window.localStorage.clear();
            window.location.href = '/sso?c=' + ssoClient;
          });
        }
      });
    } else {
      this.authService.getCurrentUser().subscribe({
        next: (user) => {
          if (user) {
            this.authClearService.SetLogoutEvent();
          } else {
            this.authService.removeIndexedDbItem().then(() => {
              window.localStorage.clear();
              this.authService.signoutRedirect();
            });
          }
        },
        error: (e) => {
          console.log(e);
          this.loaderService.showMain = false;
          this.router.navigate(['/oops']);
        },
      });
    }
  }
}
