import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  Input,
  EmbeddedViewRef,
  TemplateRef,
  HostBinding,
  Output,
  EventEmitter,
  HostListener,
  Renderer2,
  ElementRef,
} from '@angular/core';

export interface ITemplateProvider {
  getTemplateReference: () => TemplateRef<any>;
  getHeaderTemplateReference: () => TemplateRef<any>;
}

@Component({
  selector: 'cvp-table-renderer',
  template: '',
})
export class TableRendererComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() index: number;
  @Input() templateProvider: ITemplateProvider;
  @Input() type: string = 'cell';
  view: EmbeddedViewRef<any>;

  constructor(private viewContainer: ViewContainerRef) {}

  ngOnInit(): void {
    this.view = this.viewContainer.createEmbeddedView(
      this.type == 'cell'
        ? this.templateProvider.getTemplateReference()
        : this.templateProvider.getHeaderTemplateReference(),
      {
        $implicit: this.data,
        rowIndex: this.index,
      }
    );
  }

  ngOnDestroy(): void {
    this.view.destroy();
  }
}
