<div [hidden]="!this.loaderService.showMain" class="main-loader" [class.active]="this.loaderService.showMain">
  <div class="loader-icon">
    <img src="img/loader.gif" alt="" />
  </div>
</div>

<div
  [hidden]="!this.loaderService.showTop || this.loaderService.showMain"
  [class.active]="this.loaderService.showTop && !this.loaderService.showMain"
  class="top-loader"
>
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>
