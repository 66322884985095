import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class NoAuthGuard implements CanActivate {
  params: any;
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.isAuthenticated()) {
      const returnUrl = route.queryParams['returnUrl'] || '/';
      // checking for first login --
      this.router.navigate([returnUrl]);
    } else {
      return true;
    }
  }
}
