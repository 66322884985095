import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { CanActivateAdminModuleGuard } from './authentication/admin-module.guard';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthenticationService } from './authentication/authentication.service';
import { CanActivateReportingModuleGuard } from './authentication/reporting-module.guard';
import { ActivityInterceptor } from './http/ActivityInterceptor.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { CachingInterceptor } from './http/caching-interceptor.service';
import { MonitorInterceptor } from './http/monitor.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { HttpCacheService } from './http/http-cache.service';
import { HttpResponseInterceptor } from './http/http-response.interceptor';
// import { CacheInterceptor } from './http/cache.interceptor';
import { HttpTokenInterceptor } from './http/http-token.interceptor';
import { HttpService } from './http/http.service';
import { HttpCancelService } from './http/httpcancel.service';
import { LoaderInterceptor } from './http/loader.interceptor';
import { ManageHttpInterceptor } from './http/old/managehttp.interceptor';
import { LoaderService } from './loader.service';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { SignalrService } from './signalr.service';
import { NgForageCache } from 'ngforage';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { GlobalErrorHandler } from './global-error-handler';
import { SmartLoaderInterceptor } from './http/smart.loader.interceptor';
import { ApiVersionInterceptor } from './http/api-version.interceptor';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    NgForageCache,
    AuthenticationService,
    AuthenticationGuard,
    CanActivateAdminModuleGuard,
    CanActivateReportingModuleGuard,
    HttpCacheService,
    ApiPrefixInterceptor,
    SmartLoaderInterceptor,
    ErrorHandlerInterceptor,
    // CacheInterceptor,
    LoaderService,
    SignalrService,
    ApiVersionInterceptor,
    // { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    LoaderInterceptor,
    MonitorInterceptor,
    CachingInterceptor,
    ActivityInterceptor,
    HttpResponseInterceptor,
    HttpCancelService,
    ManageHttpInterceptor,
    HttpTokenInterceptor,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // {
    //   provide: HttpClient,
    //   useClass: HttpService,
    // },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MonitorInterceptor,
      multi: true,
    },
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
