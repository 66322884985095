import { Observable, Subject } from 'rxjs';

export class DynamicDialogRef {
  readonly _onClose = new Subject<any>();
  onClose: Observable<any> = this._onClose.asObservable();

  constructor() {}

  close(result?: any) {
    this._onClose.next(result);
  }
}
