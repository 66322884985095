import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
import moment from 'moment';
@Pipe({
  name: 'customdateformat',
})
export class CustomDateFormatPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string, public appConfig: AppConfigurationService) {}
  transform(date: string | Date, option: string = 'date') {
    const FieldNames: any = this.appConfig.getByIdentifier('custom-date-formats');
    const timeZone: any = this.appConfig.getByIdentifier('portal-timezone');
    if (typeof date === 'string') {
      date = this.getDate(date);
    }
    if (date) {
      switch (option) {
        case 'date':
          return formatDate(
            moment.utc(date).toString(),
            FieldNames.Fields.date,
            this.locale,
            timeZone?.Fields?.equal?.value
          );
        case 'time':
          return formatDate(date, FieldNames.Fields.time, this.locale, timeZone?.Fields?.equal?.value);
        case 'time-est':
          return formatDate(date, FieldNames.Fields.time, this.locale, 'EST');
        case 'date-est':
          return formatDate(date, FieldNames.Fields.datetime, this.locale, 'EST');
        case 'datetime':
          return formatDate(date, FieldNames.Fields.datetime, this.locale, timeZone?.Fields?.equal?.value);
        case 'day':
          return formatDate(
            moment.utc(date).toString(),
            FieldNames.Fields.day,
            this.locale,
            timeZone?.Fields?.equal?.value
          );
        case 'datemonthyear':
          return formatDate(date, FieldNames.Fields.datemonthyear, this.locale, timeZone?.Fields?.equal?.value);
        case 'duedate':
          return formatDate(date, FieldNames.Fields.date, this.locale, timeZone?.Fields?.equal?.value);
        case 'admindate':
          if (date && FieldNames && FieldNames.Fields && FieldNames.Fields.date && this.locale) {
            return formatDate(date, FieldNames.Fields.date, this.locale);
          } else {
            return date;
          }
      }
    }
    return date;
  }

  private getDate(utcDate: string): Date {
    let mtime = Date.parse(utcDate);
    let d = new Date(mtime);
    return d;
  }

  getFormat(option: string = 'date') {
    const FieldNames: any = this.appConfig.getByIdentifier('custom-date-formats');
    switch (option) {
      case 'date':
        return FieldNames.Fields.date;
      case 'time':
        FieldNames.Fields.time;
      case 'datetime':
        return FieldNames.Fields.datetime;
    }
    return FieldNames.Fields.date;
  }
  getFormatForCalendarControlDateFormat(option: string = 'date'): string {
    return (this.getFormat(option) as string).toLowerCase().replace('yyyy', 'yy');
  }
}
