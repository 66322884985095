import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Paginator } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { RfSharedModule } from '../common/shared';

@Component({
  selector: 'cvp-paginator',
  templateUrl: './paginator.html',
})
export class CVPPaginator extends Paginator {}

@NgModule({
  imports: [CommonModule, DropdownModule, FormsModule, RfSharedModule],
  exports: [CVPPaginator, DropdownModule, FormsModule, RfSharedModule],
  declarations: [CVPPaginator],
})
export class CVPPaginatorModule {}
