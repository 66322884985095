import {
  AfterViewInit,
  AfterContentInit,
  Component,
  ContentChild,
  Input,
  ViewChild,
  ViewEncapsulation,
  TemplateRef,
} from '@angular/core';
import { IStepOption, TourHotkeyListenerComponent } from '@ngx-tour-core';
import { NgxPopperModule } from './popper/popper.module';
import { PopperContent } from './popper/popper-content';
import { NgxpTourService } from './ngx-popper-tour.service';
import { TourStepTemplateService } from './tour-step-template.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'tour-step-template',
  template: `
    <popper-content #popper class="popper-content">
      <ng-container *ngTemplateOutlet="stepTemplate || defaultTemplate; context: { step: step }"></ng-container>
    </popper-content>
    <ng-template #defaultTemplate let-step="step">
      <h2 class="ngxp-title">{{ step?.title }}</h2>
      <p class="ngxp-content">{{ step?.content }}</p>
      <div class="tour-step-navigation">
        <div class="tour__pagination" *ngIf="tourService.isOn()">
          <a [class.disabled]="!tourService.hasPrev(step)" class="ngxp-btn btn-prev" (click)="tourService.prev()"
            ><i class="fal fa-angle-left"></i
          ></a>
          <span class="step__count" *ngIf="tourService.isOn()"
            >{{ tourService.getCurrentStep() }}/{{ tourService.getTotal() }}</span
          >
          <a [class.disabled]="!tourService.hasNext(step)" class="ngxp-btn btn-next" (click)="tourService.next()"
            ><i class="fal fa-angle-right"></i
          ></a>
        </div>

        <button class="ngxp-btn btn-end" (click)="tourService.MyEnd()">{{ step?.endBtnTitle }}</button>
      </div>
    </ng-template>
  `,
})
export class TourStepTemplateComponent extends TourHotkeyListenerComponent implements AfterViewInit, AfterContentInit {
  @ViewChild('popper') public popperContent: PopperContent;

  @ContentChild(TemplateRef)
  public stepTemplate: TemplateRef<{ step: IStepOption }>;

  public step: IStepOption = {};

  constructor(private tourStepTemplateService: TourStepTemplateService, public tourService: NgxpTourService) {
    super(tourService);
  }

  public ngAfterViewInit(): void {
    this.tourStepTemplateService.templateComponent = this;
  }

  public ngAfterContentInit(): void {
    this.tourStepTemplateService.template = this.popperContent;
  }
}
