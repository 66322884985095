<!-- <rdatatable-selection
#selector
[selected]="selected"
[rows]="rows"
[selectCheck]="selectCheck"
[selectEnabled]="selectEnabled"
[selectionType]="selectionType"
[rowIdentity]="rowIdentity"
(select)="select.emit($event)"
(activate)="activate.emit($event)"
> -->
<rdatatable-progress *ngIf="loadingIndicator"> </rdatatable-progress>
<!-- <rdatatable-scroller
  *ngIf="rows?.length"
  [scrollbarV]="scrollbarV"
  [scrollbarH]="scrollbarH"
  [scrollHeight]="scrollHeight"
  [scrollWidth]="columnGroupWidths?.total"
  (scroll)="onBodyScroll($event)"
> -->
<rdatatable-summary-row
  *ngIf="summaryRow && summaryPosition === 'top'"
  [rowHeight]="summaryHeight"
  [offsetX]="offsetX"
  [innerWidth]="innerWidth"
  [rows]="rows"
  [columns]="columns"
>
</rdatatable-summary-row>
<div *ngFor="let group of temp; let i = index; trackBy: rowTrackingFn">
  <rdatatable-body-list-row
    tabindex="-1"
    [innerWidth]="innerWidth"
    [offsetX]="offsetX"
    [columns]="columns"
    [rowTemplate]="rowTemplate"
    rowHeight="100px"
    [row]="group"
    [rowIndex]="getRowIndex(group)"
    [expanded]="getRowExpanded(group)"
    [rowClass]="rowClass"
    [pageSize]="pageSize"
    [offset]="offset"
    [displayCheck]="displayCheck"
    [treeStatus]="group.treeStatus"
    (treeAction)="onTreeAction(group)"
  >
  </rdatatable-body-list-row>
</div>
<rdatatable-summary-row
  *ngIf="summaryRow && summaryPosition === 'bottom'"
  [ngStyle]="getBottomSummaryRowStyles()"
  [rowHeight]="summaryHeight"
  [offsetX]="offsetX"
  [innerWidth]="innerWidth"
  [rows]="rows"
  [columns]="columns"
>
</rdatatable-summary-row>
<!-- </rdatatable-scroller> -->
<div class="empty-row norecords-block" *ngIf="!rows?.length && !loadingIndicator" [innerHTML]="emptyMessage"></div>
<!-- </rdatatable-selection> -->
