import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { AppConfigurationService } from '@app/shared/services/app.configuration.service';
@Pipe({
  name: 'truncategridtext',
})
export class TruncateGridTextPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string, public appConfig: AppConfigurationService) {}
  transform(text: string, textLength: number = 30) {
    if (text == null || text == undefined) {
      return text;
    }
    var type = typeof text;
    if (type == 'string') {
      var length = text.length;
      if (length < textLength) {
        return text;
      } else {
        var str = text.substring(0, 40);
        str += '..';
        return str;
      }
    } else {
      return text;
    }
  }
}
