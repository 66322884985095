import { environment } from '@env/environment';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { Logger } from '../logger.service';

@Injectable()
export class CanActivateAdminModuleGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router) {}

  canActivate() {
    if (environment.Apps.ReflecxApp) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }

  canActivateChild() {
    if (environment.Apps.ReflecxApp) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
