import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from '@app/core';
import { RealTimeNotificationService } from '@app/shared/services/realTimeNotification/real-time-notification.service';
import { SignalrService } from '@app/core/signalr.service';
import { AppConfig } from 'src/global.config';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  permissions: any;
  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private signalRService: SignalrService,
    public realTimeNotificationService: RealTimeNotificationService,
    private ngxpermissions: NgxPermissionsService
  ) {}
  ngOnInit() {}

  getAccess() {
    let authData = this.authService.authData;
    if (authData) {
      authData = authData.Features;
      const Admin = authData.find((x: any) => x.Name === 'Admin');
      const Reporting = authData.find((x: any) => x.Name === 'Reporting');
      if (!Admin && !Reporting) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  toHome() {
    this.permissions = this.ngxpermissions.getPermissions();
    (this.permissions['Reporting'] ? true : false)
      ? this.router.navigate(['/' + environment.reportingRoutePrefix])
      : this.router.navigate(['/' + environment.reflecxRoutePrefix]);
  }

  onLogout() {
    this.authService.logout();
    this.realTimeNotificationService.unsubscribeListeners();
    this.signalRService.disconnect();
    this.router.navigate(['/login']);
  }
}
