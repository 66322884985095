<div class="page_header">
  <ng-template
    *ngIf="GridHeaderTitleTemplateDirective"
    [ngTemplateOutlet]="GridHeaderTitleTemplateDirective.template"
    [ngTemplateOutletContext]="topBar"
  >
  </ng-template>
  <div class="page_header_item header-actions">
    <ngx-action-icons
      [view]="view"
      [topBar]="topBar"
      (onSettingClick)="onSettingClick.emit($event)"
      (onChangeView)="onChangeViewHeaderTop.emit($event)"
    >
    </ngx-action-icons>
    <ngx-search-action
      [topBar]="topBar"
      [GridHeaderTopDropDownTemplate]="GridHeaderTopDropDownTemplate"
      [searchtext]="searchtext"
      [ActionTemplate]="ActionTemplate"
      (onSearchDropDownChange)="onSearchDropDownChange.emit($event)"
      (onSearchTextChange)="onSearch.emit($event)"
    >
    </ngx-search-action>
  </div>
</div>
