import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { identityServerAngularConfig } from '@env/environment';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SsoComponent } from './sso/sso.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { WebStorageStateStore, Log } from 'oidc-client-ts';
import { from } from 'rxjs';

import { OAuthModule } from 'angular-oauth2-oidc';
import { LogoutSsoComponent } from './logout-sso/logout-sso.component';
import { EndSessionComponent } from './end-session/end-session.component';
import { ResetLoginComponent } from './reset-login/reset-login.component';
import { TimeSyncComponent } from './time-sync/time-sync.component';
import { IdleTimeoutComponent } from './idle-timeout/idle-timeout.component';
import { SignoutMessageComponent } from './signout-message/signout-message.component';
import { CarouselModule } from 'primeng/carousel';
import { SwiperLoginComponent } from './swiper/swiper.login.component';
import { CodeVerificationComponent } from './code-verification/code-verification.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SigninCallbackComponent } from './signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './signout-callback/signout-callback.component';
import { PostLogoutComponent } from './post-logout/post-logout.component';
import { ExternalCallbackComponent } from './external-callback/external-callback.component';
import { ExtSignoutCallbackComponent } from './ext-signout-callback/ext-signout-callback.component';
export interface State {
  router: RouterReducerState;
}

export const rootStore: ActionReducerMap<State> = {
  router: routerReducer,
};

export function getWebStorageStateStore() {
  return new WebStorageStateStore({ store: window.localStorage });
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    FormsModule,
    OAuthModule.forRoot(),
    CarouselModule,
    StoreModule.forRoot(rootStore),
    EffectsModule.forRoot([]),
  ],
  declarations: [
    SwiperLoginComponent,
    LoginComponent,
    SsoComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    LogoutSsoComponent,
    EndSessionComponent,
    ResetLoginComponent,
    TimeSyncComponent,
    IdleTimeoutComponent,
    SignoutMessageComponent,
    CodeVerificationComponent,
    SetPasswordComponent,
    SigninCallbackComponent,
    SignoutCallbackComponent,
    PostLogoutComponent,
    ExternalCallbackComponent,
    ExtSignoutCallbackComponent,
  ],
})
export class LoginModule {}
