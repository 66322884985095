import { NgModule, Component, ElementRef, OnDestroy, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { MenuItem } from '../common/menuitem';
import { RouterModule } from '@angular/router';
import { PanelMenu, PanelMenuSub } from 'primeng/panelmenu';

export class BasePanelMenuItem {
  handleClick(event: any, item: any) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    item.expanded = !item.expanded;

    if (!item.url) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
      });
    }
  }
}

@Component({
  selector: 'cvp-panelMenuSub',
  template: `
    <ul
      class="ui-submenu-list"
      [@submenu]="
        expanded
          ? { value: 'visible', params: { transitionParams: transitionOptions } }
          : { value: 'hidden', params: { transitionParams: transitionOptions } }
      "
    >
      <ng-template ngFor let-child [ngForOf]="item.items">
        <li *ngIf="child.separator" class="ui-menu-separator ui-widget-content"></li>
        <li
          *ngIf="!child.separator"
          class="ui-menuitem ui-corner-all"
          [ngClass]="child.styleClass"
          [class.ui-helper-hidden]="child.visible === false"
          [ngStyle]="child.style"
        >
          <a
            *ngIf="!child.routerLink"
            [href]="child.url || '#'"
            class="ui-menuitem-link ui-corner-all"
            [attr.tabindex]="item.expanded ? null : '-1'"
            [attr.id]="child.id"
            [ngClass]="{ 'ui-state-disabled': child.disabled }"
            (click)="handleClick($event, child)"
            [attr.target]="child.target"
            [attr.title]="child.title"
          >
            <span
              class="ui-panelmenu-icon pi pi-fw"
              [ngClass]="{ 'pi-caret-right': !child.expanded, 'pi-caret-down': child.expanded }"
              *ngIf="child.items && child.items.length > 0"
            ></span
            ><span class="ui-menuitem-icon" [ngClass]="child.icon" *ngIf="child.icon"></span
            ><span
              [ngClass]="{ 'active-menu': activeItem?.id == child?.id }"
              class="ui-menuitem-text"
              [innerHTML]="child.label"
            ></span>
          </a>
          <a
            *ngIf="child.routerLink"
            [routerLink]="child.routerLink"
            [queryParams]="child.queryParams"
            [routerLinkActive]="'ui-state-active'"
            [routerLinkActiveOptions]="child.routerLinkActiveOptions || { exact: false }"
            class="ui-menuitem-link ui-corner-all"
            [ngClass]="{ 'ui-state-disabled': child.disabled }"
            [attr.tabindex]="item.expanded ? null : '-1'"
            [attr.id]="child.id"
            (click)="handleClick($event, child)"
            [attr.target]="child.target"
            [attr.title]="child.title"
          >
            <span
              class="ui-panelmenu-icon pi pi-fw"
              [ngClass]="{ 'pi-caret-right': !child.expanded, 'pi-caret-down': child.expanded }"
              *ngIf="child.items && child.items.length > 0"
            ></span
            ><span class="ui-menuitem-icon" [ngClass]="child.icon" *ngIf="child.icon"></span
            ><span
              [ngClass]="{ 'active-menu': activeItem?.id == child?.id }"
              class="ui-menuitem-text"
              [innerHTML]="child.label"
            ></span>
          </a>
          <cvp-panelMenuSub
            [item]="child"
            [activeItem]="activeItem"
            [expanded]="child.expanded"
            [transitionOptions]="transitionOptions"
            *ngIf="child.items"
          ></cvp-panelMenuSub>
        </li>
      </ng-template>
    </ul>
  `,
  animations: [
    trigger('submenu', [
      state(
        'hidden',
        style({
          height: '0px',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition('visible => hidden', animate('{{transitionParams}}')),
      transition('hidden => visible', animate('{{transitionParams}}')),
    ]),
  ],
})
export class CVPPanelMenuSub extends PanelMenuSub {
  @Input() activeItem: MenuItem;
}

@Component({
  selector: 'cvp-panelMenu',
  template: `
    <div [class]="styleClass" [ngStyle]="style" [ngClass]="'ui-panelmenu ui-widget'">
      <ng-container *ngFor="let item of model; let f = first; let l = last">
        <div class="ui-panelmenu-panel" [ngClass]="{ 'ui-helper-hidden': item.visible === false }">
          <div
            [ngClass]="{
              'ui-widget ui-panelmenu-header ui-state-default': true,
              'ui-corner-top': f,
              'ui-corner-bottom': l && !item.expanded,
              'ui-state-active': item.expanded,
              'ui-state-disabled': item.disabled
            }"
            [class]="item.styleClass"
            [ngStyle]="item.style"
          >
            <a
              *ngIf="!item.routerLink"
              [href]="item.url || '#'"
              (click)="handleClick($event, item)"
              [attr.target]="item.target"
              [attr.title]="item.title"
              class="ui-panelmenu-header-link"
            >
              <span
                *ngIf="item.items && item.items.length > 0"
                class="ui-panelmenu-icon pi pi-fw"
                [ngClass]="{ 'pi-chevron-right': !item.expanded, 'pi-chevron-down': item.expanded }"
              ></span
              ><span class="ui-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon"></span
              ><span class="ui-menuitem-text" [innerHTML]="item.label"></span>
            </a>
            <a
              *ngIf="item.routerLink"
              [routerLink]="item.routerLink"
              [queryParams]="item.queryParams"
              [routerLinkActive]="'ui-state-active'"
              [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
              (click)="handleClick($event, item)"
              [attr.target]="item.target"
              [attr.title]="item.title"
              class="ui-panelmenu-header-link"
            >
              <span
                *ngIf="item.items && item.items.length > 0"
                class="ui-panelmenu-icon pi pi-fw"
                [ngClass]="{ 'pi-chevron-right': !item.expanded, 'pi-chevron-down': item.expanded }"
              ></span
              ><span class="ui-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon"></span
              ><span class="ui-menuitem-text" [innerHTML]="item.label"></span>
            </a>
          </div>
          <div
            *ngIf="item.items"
            class="ui-panelmenu-content-wrapper"
            [@rootItem]="
              item.expanded
                ? { value: 'visible', params: { transitionParams: transitionOptions } }
                : { value: 'hidden', params: { transitionParams: transitionOptions } }
            "
            (@rootItem.done)="onToggleDone()"
            [ngClass]="{ 'ui-panelmenu-content-wrapper-overflown': !item.expanded || animating }"
          >
            <div class="ui-panelmenu-content ui-widget-content">
              <cvp-panelMenuSub
                [item]="item"
                [activeItem]="activeItem"
                [expanded]="true"
                [transitionOptions]="transitionOptions"
                class="ui-panelmenu-root-submenu"
              ></cvp-panelMenuSub>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  animations: [
    trigger('rootItem', [
      state(
        'hidden',
        style({
          height: '0px',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition('visible => hidden', animate('{{transitionParams}}')),
      transition('hidden => visible', animate('{{transitionParams}}')),
    ]),
  ],
})
export class CVPPanelMenu extends PanelMenu {
  @Input() activeItem: MenuItem;
}

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [CVPPanelMenu, RouterModule],
  declarations: [CVPPanelMenu, CVPPanelMenuSub],
})
export class CVPPanelMenuModule {}
