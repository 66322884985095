import { WebStorageStateStore } from 'oidc-client-ts';
import * as localforage from 'localforage'; // this works!!!

export default class LocalForageStateStore extends WebStorageStateStore {
  private _myStore: LocalForage;
  private readonly _myStoreName = 'auth_storage';
  private readonly _myPrefix: string;

  public constructor({ prefix = 'oidc.', store = localStorage } = {}) {
    super(({ prefix = 'oidc.', store = localStorage } = {}));
    this._myPrefix = prefix;
    this._myStore = localforage;
    this.initDb();
  }

  async initDb() {
    this._myStore.config({
      name: this._myStoreName,
    });
  }

  public async set(key: string, value: string): Promise<void> {
    key = this._myPrefix + key;
    // console.log(`save key: ${key}, value: ${value}.`);

    var item = await this._myStore.setItem(key, value);

    return Promise.resolve();
  }

  public async get(key: string): Promise<string | null> {
    key = this._myPrefix + key;
    const item = await this._myStore.getItem(key);
    // console.log(`get key: ${key}, value: ${item}.`);
    if (item) return item.toString();
    return null;
  }

  public remove(key: string): Promise<string | null> {
    key = this._myPrefix + key;
    this._myStore.removeItem(key);
    return Promise.resolve(null);
  }

  public async getAllKeys(): Promise<string[]> {
    const keys = [];
    var length = await this._myStore.length();
    for (let index = 0; index < length; index++) {
      const key = await this._myStore.key(index);
      if (key && key.indexOf(this._myPrefix) === 0) {
        keys.push(key.substr(this._myPrefix.length));
      }
    }
    return Promise.resolve(keys);
  }
}
