import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { User } from 'oidc-client-ts';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  private authenticationService: AuthenticationService;

  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authenticationService === undefined) {
      this.authenticationService = this.injector.get(AuthenticationService);
    }
    let language = localStorage.getItem('language');
    if (!language) {
      language = 'en-ca';
    }
    if (this.authenticationService.checkIsSSOLogin()) {
      let token = this.authenticationService.getSSOToken();
      return next.handle(
        req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
            'Accept-Language': language,
            'App-Version': environment.appVersion,
          },
        })
      );
    } else {
      return this.authenticationService.getCurrentUser().pipe(
        switchMap((authData: User) => {
          // if(authData == null){
          //   debugger
          //   this.authenticationService.logout();
          //   return;
          // }
          return next.handle(
            req.clone({
              setHeaders: {
                Authorization: 'Bearer ' + authData?.access_token,
                'Accept-Language': language,
                'App-Version': environment.appVersion,
              },
            })
          );
        })
      );
    }
  }
}
