import { Action } from '@ngrx/store';

export enum ActionTypes {
  LanguageEvent = '[Language Event]',
}

export class LanguageEvent implements Action {
  readonly type = ActionTypes.LanguageEvent;
  constructor(public languageCode: string) {}
}
