<div class="login-page">
  <div class="col-left">
    <div class="login-form">
      <div class="form">
        <div class="form__inner">
          <div class="form__header">
            <h3>Logout</h3>
            <br />
            <span>You have been logged out. Please use provided link to login again.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-right"></div> -->
</div>
