<div #container style="width: 100%" class="filter-field filter--calendar" [class.active]="showDatePicker">
  <div class="filter__trigger" (click)="showDateRange_($event);">
    <i class="fal fa-calendar-alt"></i>
    <!-- <label class="field__label">{{format(params.value[0]._d)}}
      <span class="to">To</span>
      {{format(params.value[1]._d)}}</label> -->
    <label
      class="field__label"
      *ngIf="!customResult && rangeLabel != 'Custom
      Range'"
      >{{setCalenderTranslation(defaultSelectedDate.value)}}</label
    >
    <label
      class="field__label"
      *ngIf="customResult && rangeLabel != 'Custom
      Range'"
      >{{customResult || inputFieldValue}}
    </label>
    <label class="field__label" *ngIf="rangeLabel == 'Custom Range'"
      >{{setCalenderTranslation(defaultSelectedDate.value)}}</label
    >
    <div class="field__icons">
      <i class="far fa-angle-down"></i>
      <span *ngIf="showDatePicker">
        <i class="fal fa-times" (click)="close($event)"></i>
      </span>
    </div>
  </div>
  <div
    class="filter-dropdown dropdown--calendar"
    [class.active]="showDatePicker"
    [class.calendar--custom]="customRange &&
    customRange.label == 'Custom Range'"
  >
    <div class="date-filters">
      <ul>
        <li
          *ngFor="let date of dateDropDown; let i= index"
          [class.active]="identifier?.toLowerCase() == date.key?.toLowerCase()"
          [id]="date.label"
          (click)="selectCustomDate(date,i)"
        >
          <a>{{date.label}}</a>
        </li>
      </ul>
    </div>
    <div class="date__calendar">
      <div style="overflow: hidden">
        <div class="form-group">
          <div class="row">
            <div class="col-md-12" style="width: 100%">
              <div id="datetimepicker12">
                <span
                  [ngClass]="{'ui-calendar':true, 'ui-calendar-w-btn':
                  showIcon, 'ui-calendar-timeonly': timeOnly}"
                  [ngStyle]="style"
                  [class]="styleClass"
                >
                  <ng-template [ngIf]="!inline">
                    <div>
                      <input
                        #inputfield
                        type="text"
                        [attr.id]="inputId"
                        [attr.name]="name"
                        [attr.required]="required"
                        [value]="inputFieldValue"
                        (focus)="onInputFocus($event)"
                        (keydown)="onInputKeydown($event)"
                        (click)="onInputClick()"
                        (blur)="onInputBlur($event)"
                        [readonly]="readonlyInput"
                        (input)="onUserInput($event)"
                        [ngStyle]="inputStyle"
                        [class]="inputStyleClass"
                        [placeholder]="placeholder||''"
                        [disabled]="disabled"
                        [attr.tabindex]="tabindex"
                        [ngClass]="'ui-inputtext ui-widget ui-state-default
                        ui-corner-all'"
                        autocomplete="off"
                      /><button
                        type="button"
                        [icon]="icon"
                        rfButton
                        *ngIf="showIcon"
                        (click)="onButtonClick($event,inputfield)"
                        class="ui-datepicker-trigger ui-calendar-button"
                        [ngClass]="{'ui-state-disabled':disabled}"
                        [disabled]="disabled"
                        tabindex="-1"
                      ></button>
                    </div>
                  </ng-template>

                  <div
                    [class]="panelStyleClass"
                    [ngStyle]="panelStyle"
                    #contentWrapper
                    [ngClass]="{'ui-datepicker ui-widget ui-widget-content
                    ui-helper-clearfix ui-corner-all': true,
                    'ui-datepicker-inline':inline,'ui-shadow':!inline,
                    'ui-state-disabled':disabled,'ui-datepicker-timeonly':timeOnly,'ui-datepicker-multiple-month':
                    this.numberOfMonths> 1, 'ui-datepicker-monthpicker': (view
                    === 'month'), 'ui-datepicker-touch-ui': touchUI}"
                    (click)="onDatePickerClick($event)"
                    [@overlayAnimation]="touchUI ? {value: 'visibleTouchUI',
                    params: {showTransitionParams: showTransitionOptions,
                    hideTransitionParams: hideTransitionOptions}}:
                    {value: 'visible', params: {showTransitionParams:
                    showTransitionOptions, hideTransitionParams:
                    hideTransitionOptions}}"
                    [@.disabled]="inline === true"
                    (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                    *ngIf="inline || showDatePicker"
                  >
                    <ng-container *ngIf="!timeOnly">
                      <ng-container *ngIf="isCustomRange()">
                        <cvc-inner-calendar
                          [currentCalendarDate]="currentCalendarDate"
                          (change)="onInnerCalendarChange($event)"
                          showRecursive="true"
                          [(monthYear)]="monthYear"
                          [(endYears)]="endYears"
                          [defaultSelectedDate]="defaultSelectedDate.value"
                          [(endYears)]="endYears"
                          [startYears]="startYears"
                          [yearRange]="yearRange"
                          [monthNavigator]="monthNavigator"
                          [yearNavigator]="yearNavigator"
                          numberOfMonths="1"
                        >
                        </cvc-inner-calendar>
                      </ng-container>
                      <ng-container *ngIf="numberOfMonths === 1 && !isCustomRange()">
                        <div class="ui-datepicker-group ui-widget-content" *ngFor="let month of months; let i= index;">
                          <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
                            <ng-content select="rf-header"></ng-content>
                            <a
                              class="ui-datepicker-prev ui-corner-all"
                              tabindex="0"
                              (click)="navBackward($event)"
                              *ngIf="(i === 0 && numberOfMonths === 1) &&
                              (!showYearChangeBar())"
                            >
                              <span class="pi pi-chevron-left"></span>
                            </a>
                            <a
                              class="ui-datepicker-next ui-corner-all"
                              tabindex="0"
                              (click)="navForward($event)"
                              *ngIf="(numberOfMonths === 1 ? true : (i ===
                              numberOfMonths -1)) &&
                              (!showYearChangeBar())"
                            >
                              <span class="pi pi-chevron-right"></span>
                            </a>
                            <div class="ui-datepicker-title">
                              <!-- <span class="ui-datepicker-month"
                                *ngIf="numberOfMonths === 1 && (view !==
                                'month')">{{locale.monthNames[month.month]}}</span> -->
                              <span
                                class="p-datepicker-month ui-datepicker-month"
                                *ngIf="!monthNavigator && (view !== 'month')"
                                >{{getTranslation('monthNames')[month.month]}}</span
                              >
                              <select
                                class="ui-datepicker-month"
                                *ngIf="false && monthNavigator && (view !==
                                'month') && (numberOfMonths === 1 ||
                                numberOfMonths === 2)"
                                (change)="onMonthDropdownChange($event.target.value)"
                              >
                                <option
                                  [value]="i"
                                  *ngFor="let monthName of
                                  locale.monthNames;let i= index"
                                  [selected]="i === month.month"
                                >
                                  {{monthName}}
                                </option>
                              </select>
                              <select
                                class="ui-datepicker-year"
                                *ngIf="false &&
                                yearNavigator && (numberOfMonths === 1 ||
                                numberOfMonths === 2)"
                                (change)="onYearDropdownChange($event.target.value)"
                              >
                                <option
                                  [value]="year"
                                  *ngFor="let year of
                                  yearOptions"
                                  [selected]="year ===
                                  currentYear"
                                >
                                  {{year}}
                                </option>
                              </select>
                              <span class="ui-datepicker-year" *ngIf="numberOfMonths === 1"
                                >{{view === 'month' ? currentYear : month.year}}</span
                              >
                            </div>
                          </div>
                          <div class="ui-datepicker-calendar-container" *ngIf="view ==='date'">
                            <table class="ui-datepicker-calendar">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    *ngFor="let weekDay of
                                    weekDays;let begin= first; let end= last"
                                  >
                                    <span>{{weekDay}}</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let week of month.dates">
                                  <td
                                    *ngFor="let date of week"
                                    [ngClass]="{'ui-datepicker-other-month':
                                    date.otherMonth,
                                    'ui-datepicker-current-day':isSelected(date),'ui-datepicker-today':date.today}"
                                  >
                                    <ng-container
                                      *ngIf="date.otherMonth ?
                                      showOtherMonths : true"
                                    >
                                      <a
                                        class="ui-state-default"
                                        *ngIf="date.selectable"
                                        [ngClass]="{'ui-state-active':isSelected(date),
                                        'ui-state-highlight':date.today}"
                                        (click)="onDateSelect($event,date)"
                                        draggable="false"
                                      >
                                        <ng-container *ngIf="!dateTemplate"> {{date.day}} </ng-container>
                                        <ng-container
                                          *ngTemplateOutlet="dateTemplate;
                                          context: {$implicit: date}"
                                        >
                                        </ng-container>
                                      </a>
                                      <span class="ui-state-default ui-state-disabled" *ngIf="!date.selectable">
                                        {{date.day}}
                                      </span>
                                    </ng-container>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </ng-container>
                      <div class="ui-monthpicker" *ngIf="view === 'month' && !isCustomRange()">
                        <ng-container
                          *ngFor="let m of monthPickerValues(); let i=
                          index"
                        >
                          <a
                            tabindex="0"
                            *ngIf="monthDisabledState[i].disabled"
                            class="ui-monthpicker-month disabled"
                            (click)="onMonthSelect($event, i)"
                            [ngClass]="{
                            'ui-state-active': isMonthSelected(i),
                            'disabled': monthDisabledState[i].disabled
                            }"
                          >
                            {{m | translate}}
                          </a>
                          <a
                            tabindex="0"
                            *ngIf="!monthDisabledState[i].disabled"
                            (click)="onMonthSelect($event, i)"
                            class="ui-monthpicker-month"
                            [ngClass]="{'ui-state-active': isMonthSelected(i)}"
                          >
                            {{m | translate}}
                          </a>
                        </ng-container>
                      </div>
                    </ng-container>
                    <div class="ui-timepicker ui-widget-header ui-corner-all" *ngIf="showTime||timeOnly">
                      <div class="ui-hour-picker">
                        <a
                          tabindex="0"
                          (mousedown)="onTimePickerElementMouseDown($event, 0,
                          1)"
                          (mouseup)="onTimePickerElementMouseUp($event)"
                        >
                          <span class="pi pi-chevron-up"></span>
                        </a>
                        <span
                          [ngStyle]="{'display': currentHour <10 ? 'inline':
                          'none'}"
                          >0</span
                        ><span>{{currentHour}}</span>
                        <a
                          tabindex="0"
                          (mousedown)="onTimePickerElementMouseDown($event, 0,
                          -1)"
                          (mouseup)="onTimePickerElementMouseUp($event)"
                        >
                          <span class="pi pi-chevron-down"></span>
                        </a>
                      </div>
                      <div class="ui-separator">
                        <a tabindex="0">
                          <span class="pi pi-chevron-up"></span>
                        </a>
                        <span>{{timeSeparator}}</span>
                        <a tabindex="0">
                          <span class="pi pi-chevron-down"></span>
                        </a>
                      </div>
                      <div class="ui-minute-picker">
                        <a
                          tabindex="0"
                          (mousedown)="onTimePickerElementMouseDown($event, 1,
                          1)"
                          (mouseup)="onTimePickerElementMouseUp($event)"
                        >
                          <span class="pi pi-chevron-up"></span>
                        </a>
                        <span
                          [ngStyle]="{'display': currentMinute <10 ? 'inline':
                          'none'}"
                          >0</span
                        ><span>{{currentMinute}}</span>
                        <a
                          tabindex="0"
                          (mousedown)="onTimePickerElementMouseDown($event, 1,
                          -1)"
                          (mouseup)="onTimePickerElementMouseUp($event)"
                        >
                          <span class="pi pi-chevron-down"></span>
                        </a>
                      </div>
                      <div class="ui-separator" *ngIf="showSeconds">
                        <a tabindex="0">
                          <span class="pi pi-chevron-up"></span>
                        </a>
                        <span>{{timeSeparator}}</span>
                        <a tabindex="0">
                          <span class="pi pi-chevron-down"></span>
                        </a>
                      </div>
                      <div class="ui-second-picker" *ngIf="showSeconds">
                        <a
                          tabindex="0"
                          (mousedown)="onTimePickerElementMouseDown($event, 2,
                          1)"
                          (mouseup)="onTimePickerElementMouseUp($event)"
                        >
                          <span class="pi pi-chevron-up"></span>
                        </a>
                        <span
                          [ngStyle]="{'display': currentSecond <10 ? 'inline':
                          'none'}"
                          >0</span
                        ><span>{{currentSecond}}</span>
                        <a
                          tabindex="0"
                          (mousedown)="onTimePickerElementMouseDown($event, 2,
                          -1)"
                          (mouseup)="onTimePickerElementMouseUp($event)"
                        >
                          <span class="pi pi-chevron-down"></span>
                        </a>
                      </div>
                      <div class="ui-ampm-picker" *ngIf="hourFormat=='12'">
                        <a tabindex="0" (click)="toggleAMPM($event)">
                          <span class="pi pi-chevron-up"></span>
                        </a>
                        <span>{{pm ? 'PM' : 'AM'}}</span>
                        <a tabindex="0" (click)="toggleAMPM($event)">
                          <span class="pi pi-chevron-down"></span>
                        </a>
                      </div>
                    </div>

                    <div class="ui-datepicker-buttonbar ui-widget-header" *ngIf="showButtonBar">
                      <div class="ui-g">
                        <div class="ui-g-6">
                          <button
                            type="button"
                            [label]="_locale.today"
                            (click)="onTodayButtonClick($event)"
                            rfButton
                            [ngClass]="[todayButtonStyleClass]"
                          ></button>
                        </div>
                        <div class="ui-g-6">
                          <button
                            type="button"
                            [label]="_locale.clear"
                            (click)="onClearButtonClick($event)"
                            rfButton
                            [ngClass]="[clearButtonStyleClass]"
                          ></button>
                        </div>
                      </div>
                    </div>
                    <ng-content select="rf-footer"></ng-content>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dropdown__footer" *ngIf="showDonebutton">
      <a #dateDone class="btn" (click)="onDone($event)"> {{doneButton}} </a>
    </div>
  </div>
</div>
