<div class="login-page">
  <div class="col-left" *ngIf="imagePath" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
  <div class="col-left" *ngIf="imagePath == undefined">
    <app-swiper-login></app-swiper-login>
  </div>
  <div class="col-right">
    <form [formGroup]="resetform" style="height: 100%">
      <div class="login-form">
        <div class="form">
          <div class="form__inner">
            <div class="form__header">
              <div class="login-logo">
                <img src="./assets/styles/portal/build/assets/images/reflecx-logo.svg" alt="" />
              </div>

              <h3>Reset Password</h3>
              <p>Enter your new password</p>
            </div>

            <fieldset
              class="form__group"
              [ngClass]="{
                'error--field':
                  isPasswordPolicyError || (submitted && f.newPassword.errors && f.newPassword.errors?.required)
              }"
            >
              <label class="form__label">NEW PASSWORD</label>
              <div class="form__field">
                <div class="password-strength">
                  <input
                    type="Password"
                    formControlName="newPassword"
                    (keyup)="onPaswordChange($event.target.value)"
                    class="form__input"
                    autocomplete="new-form"
                  />
                  <div class="password-strength-bar">
                    <div *ngIf="strengthColor == 'red'" class="password-strength-block weak"></div>

                    <div *ngIf="strengthColor == 'orange'" class="password-strength-block moderate"></div>
                    <div *ngIf="strengthColor == 'orange'" class="password-strength-block moderate"></div>

                    <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                    <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                    <div *ngIf="strengthColor == 'green'" class="password-strength-block strong"></div>
                  </div>
                </div>
              </div>

              <div class="field__error" *ngIf="submitted">
                <p *ngIf="f.newPassword.errors && f.newPassword.errors?.required">
                  {{ 'New password is required.' }}
                </p>
                <p *ngIf="!f.newPassword.errors?.required && passwordPolicy != null && isPasswordPolicyError">
                  {{ passwordPolicyError }}
                </p>
              </div>
            </fieldset>

            <fieldset
              class="form__group"
              [ngClass]="{
                'error--field':
                  submitted &&
                  ((f.confirmPassword.errors && f.confirmPassword.errors.required) ||
                    (f.confirmPassword.errors && f.confirmPassword.errors.mustMatch))
              }"
            >
              <label class="form__label">RETYPE PASSWORD</label>
              <div class="form__field">
                <input type="Password" formControlName="confirmPassword" class="form__input" autocomplete="new-form" />

                <div class="field__error" *ngIf="submitted">
                  <p *ngIf="f.confirmPassword.errors && f.confirmPassword.errors.required">
                    {{ 'Confirm password is required.' }}
                  </p>
                  <p *ngIf="f.confirmPassword.errors && f.confirmPassword.errors.mustMatch">
                    {{ 'Password must Match.' }}
                  </p>
                </div>
              </div>
            </fieldset>

            <fieldset class="form__group">
              <div *ngIf="passwordPolicy != null">
                <p class="password-hints-title">PASSWORD HINT</p>
                <div class="password-hints">
                  <ul class="">
                    <li *ngIf="passwordPolicy.IsUpperCase">New Password must have at least one upper case character</li>
                    <li *ngIf="passwordPolicy.IsLowerCase">Must have at least one lower case character</li>
                    <li *ngIf="passwordPolicy.IsSpecialCharacter">Must have at least one special character</li>
                    <li *ngIf="passwordPolicy.IsDigit">Must have at least one digit</li>
                    <li *ngIf="passwordPolicy.Length > 0">
                      Password must be minimum {{ passwordPolicy.Length }} characters long
                    </li>
                    <li *ngIf="passwordPolicy.LastUsedPassword > 0">
                      Shouldn’t be the last {{ passwordPolicy.LastUsedPassword }} used passwords
                    </li>
                  </ul>
                </div>
              </div>
            </fieldset>

            <fieldset class="form__group login--actions">
              <div class="auth-button">
                <button class="login-btn" type="button" [disabled]="isSubmit" (click)="resetPassword()">CHANGE</button>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
