<table [ngClass]="tableClass + ' ' + typeClass">
  <thead>
    <tr class="no--border">
      <th class="text-left nw" [class.fixed-side]="extraOptions" *ngIf="showSerialNo" (click)="sortSerialNo()">
        <a href="javascript:void(0)">No</a>
      </th>
      <ng-container *ngFor="let c of inputColumns">
        <ng-template [ngIf]="!c.headerTemplate">
          <th [colSpan]="c.colSpan ? c.colSpan : 1" [ngClass]="c.headerClass + ' '" (click)="sort(c)">
            <a href="javacript:void(0)"> {{ c.header }}</a>
          </th>
        </ng-template>
        <ng-template [ngIf]="c.headerTemplate">
          <th [colSpan]="c.colSpan ? c.colSpan : 1" [ngClass]="c.headerClass + ' '" (click)="sort(c)">
            <cvp-table-renderer type="header" [data]="c" [templateProvider]="c"></cvp-table-renderer>
          </th>
        </ng-template>
      </ng-container>
    </tr>
  </thead>
  <tbody [style.visibility]="visibility">
    <tr *ngFor="let r of page.data; let i = index">
      <td class="text-left nw" [class.fixed-side]="extraOptions" *ngIf="showSerialNo">
        {{ '00' + (i < 10 ? '0' + i : i) }}
      </td>
      <ng-container *ngFor="let c of inputColumns">
        <ng-template [ngIf]="c.template">
          <td [ngClass]="c.cellClass">
            <cvp-table-renderer [data]="r" [index]="i" [templateProvider]="c"></cvp-table-renderer>
          </td>
        </ng-template>
        <ng-template [ngIf]="!c.template">
          <td [ngClass]="c.cellClass">
            {{ parseDotNotation(r, c.field) }}
          </td>
        </ng-template>
      </ng-container>
    </tr>
  </tbody>
</table>
