<!-- <div class="pagination-list">
  <ul class="pager pagination__list">
    <li *ngIf="page * size <= count">
      Showing {{ size * (page - 1) + 1 }} - {{ page * size }} of {{ count }}
    </li>
    <li *ngIf="page * size > count">
      Showing {{ size * (page - 1) + 1 }} - {{ count }}
    </li>

    <li>
      <span class="info">Per page</span>
      <ng-template *ngIf="FooterPageSizeDropdownTemplate" [ngTemplateOutlet]="FooterPageSizeDropdownTemplate.template"
        [ngTemplateOutletContext]="{
          pageLimitOptions: pageLimitOptions
        }">
      </ng-template>
      <select *ngIf="!FooterPageSizeDropdownTemplate" id="per-page" (change)="onLimitChange($event.target.value)">
        <option *ngFor="let option of pageLimitOptions" [value]="option.value" [selected]="option.value == _size">
          {{ option.value }}
        </option>
      </select>
    </li>
    <li class="last--child">
      <nav aria-label="Page navigation example">
        <ul class="pagination" *ngIf="1 <= pages.length">
          <li [class.disabled]="!canPrevious()">
            <a href="javascript:void(0)" (click)="selectPage(1)"> « </a>
          </li>
          <li [class.disabled]="!canPrevious()">
            <a href="javascript:void(0)" (click)="prevPage()"> ‹ </a>
          </li>
          <li class="pages" *ngFor="let pg of pages" [class.active]="pg.number === page">
            <a href="javascript:void(0)" (click)="selectPage(pg.number)">
              {{ pg.text }}
            </a>
          </li>
          <li [class.disabled]="!canNext()">
            <a href="javascript:void(0)" (click)="nextPage()"> › </a>
          </li>
          <li [class.disabled]="!canNext()">
            <a href="javascript:void(0)" (click)="selectPage(totalPages)">
              »
            </a>
          </li>
        </ul>
      </nav>
    </li>
  </ul>
</div> -->

<div
  [ngClass]="'ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix'"
  *ngIf="1 <= pages.length"
>
  <div class="ui-paginator-left-content">
    <li *ngIf="page * size <= count">
      {{ 'Showing' | translate }} {{ size * (page - 1) + 1 }} - {{ page * size }} {{ 'of' | translate }} {{ count }}
    </li>
    <li *ngIf="page * size > count">
      {{ 'Showing' | translate }} {{ size * (page - 1) + 1 }} - {{ count }} {{ 'of' | translate }} {{ count }}
    </li>
  </div>
  <a
    [attr.tabindex]="!canPrevious() ? null : '0'"
    class="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all"
    (click)="selectPage(1)"
    (keydown.enter)="selectPage(1)"
    [ngClass]="{'ui-state-disabled':!canPrevious()}"
    [tabindex]="!canPrevious() ? -1 : null"
  >
    <span class="ui-paginator-icon pi pi-step-backward"></span>
  </a>
  <a
    tabindex="0"
    [attr.tabindex]="!canPrevious() ? null : '0'"
    class="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all"
    (click)="prevPage()"
    (keydown.enter)="prevPage()"
    [ngClass]="{'ui-state-disabled':!canPrevious()}"
    [tabindex]="!canPrevious() ? -1 : null"
  >
    <span class="ui-paginator-icon pi pi-caret-left"></span>
  </a>
  <span class="ui-paginator-pages">
    <a
      tabindex="0"
      *ngFor="let pg of pages"
      class="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all"
      (click)="selectPage(pg.number)"
      (keydown.enter)="selectPage(pg.number)"
      [ngClass]="{'ui-state-active': pg.number === page}"
      >{{ pg.text }}</a
    >
  </span>
  <a
    [attr.tabindex]="!canNext() ? null : '0'"
    class="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all"
    (click)="nextPage()"
    (keydown.enter)="nextPage()"
    [ngClass]="{'ui-state-disabled':!canNext()}"
    [tabindex]="!canNext() ? -1 : null"
  >
    <span class="ui-paginator-icon pi pi-caret-right"></span>
  </a>
  <a
    [attr.tabindex]="!canNext() ? null : '0'"
    class="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all"
    (click)="selectPage(totalPages)"
    (keydown.enter)="selectPage(totalPages)"
    [ngClass]="{'ui-state-disabled':!canNext()}"
    [tabindex]="!canNext() ? -1 : null"
  >
    <span class="ui-paginator-icon pi pi-step-forward"></span>
  </a>
  <div class="ui-paginator-right-content">
    <span class="info">{{ 'Per page' | translate }}</span>
    <ng-content></ng-content>
    <!-- <ng-template *ngIf="FooterPageSizeDropdownTemplate" [ngTemplateOutlet]="FooterPageSizeDropdownTemplate.template"
        [ngTemplateOutletContext]="{
          pageLimitOptions: pageLimitOptions
        }">
      </ng-template>
      <select *ngIf="!FooterPageSizeDropdownTemplate" id="per-page" (change)="onLimitChange($event.target.value)">
        <option *ngFor="let option of pageLimitOptions" [value]="option.value" [selected]="option.value == _size">
          {{ option.value }}
        </option>
      </select> -->
  </div>
</div>
