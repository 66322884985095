<div class="error-wrapper">
  <div class="error">
    <div class="logo__error">
      <img src="./assets/styles/portal/build/assets/images/reflecx-logo.svg" alt="" />
    </div>

    <div class="error__inner">
      <i class="fal fa-exclamation-circle"></i>
      <h1>OOPS!</h1>
      <p>Looks like you have been inactive for some time now.</p>
      <p><a routerLink="/login" class="error-btn">Please login again</a></p>
    </div>
  </div>
</div>
