import { Component, ChangeDetectionStrategy, OnInit, Input, HostBinding } from '@angular/core';
import { DataTableBodyRowComponent } from '@swimlane/ngx-datatable';
import { GridBodyListRowDirective } from './list-row.directive';

@Component({
  selector: 'rdatatable-body-list-row',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './body-list-row.html',
})
export class RDataTableBodyListRowComponent extends DataTableBodyRowComponent implements OnInit {
  @Input() pageSize: number;
  @Input() offset: number;
  @Input() rowTemplate: GridBodyListRowDirective;

  @HostBinding('style.width.px')
  get columnsTotalWidths(): string {
    return '100%';
  }
  ngOnInit() {}
  listViewAction(row: any) {}
}
