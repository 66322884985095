import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  ContentChildren,
  QueryList,
  OnInit,
  ContentChild,
  AfterViewInit,
  AfterViewChecked,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { RfPrimeTemplate } from '../common';
import { Table } from 'primeng/table';
import { TableBodyDirective } from './directives/tablecustombody.directive';
import { GridViewScroll, GridViewScrollOptions } from './gridviewscroll';

@Component({
  selector: 'cvp-table',
  templateUrl: './templates/table.html',
  styles: [
    `
      .th {
        padding: 1.25em;
        border: 1px solid #ebebeb;
        font-weight: 500;
        color: #a9b4bd;
        background-color: #ffffff;
        text-transform: uppercase;
        text-align: left;
        border-width: 1px 0;
      }
    `,
  ],
})
export class CVPTableComponent extends Table implements AfterViewInit {
  @Input() id: string;
  @Input() showScrollIcons = true;
  @Input() showSticky = true;
  @ViewChild('container') containerViewChild: ElementRef;
  @ViewChild('resizeHelper') resizeHelperViewChild: ElementRef;
  @ViewChild('reorderIndicatorUp') reorderIndicatorUpViewChild: ElementRef;
  @ViewChild('reorderIndicatorDown') reorderIndicatorDownViewChild: ElementRef;
  @ViewChild('topColumnContainer') topColumnContainer: ElementRef;
  @ViewChild('table') tableViewChild: ElementRef;
  @ViewChild('wrapper') wrapperDiv: ElementRef;
  @ViewChild('wrapperSticky') wrapperSticky: ElementRef;
  @ViewChild('stickyTable') stickyTable: ElementRef;
  @Input() scrollHeight: string;
  @ViewChild('theadO') theadO: ElementRef;
  @ContentChild(TableBodyDirective)
  TableBodyDirective: TableBodyDirective;
  gridViewScroll: any = null;
  leftScrollInterval: any;
  rightScrollInterval: any;
  showWithScroll: boolean = false;
  stopStickyWidth: number = 0;
  scrollTimer: any;

  @Input() gridViewScrollOptions: GridViewScrollOptions;
  // @ContentChildren(RfPrimeTemplate) templates: QueryList<any>;
  @Input() cols: any;
  // Scroller
  @Input() showDomesticScroller = false;
  @Input() hideRightScroller = true;
  @Input() hideLeftScroller = false;
  righScrollerWidth = -1;
  private observer: ResizeObserver = new ResizeObserver(this.handleResize.bind(this));

  ngAfterViewInit() {
    this.observer.observe(this.wrapperDiv.nativeElement);
    this.wrapperSticky.nativeElement.style.width = getComputedStyle(this.wrapperDiv.nativeElement).width;
    this.wrapperSticky.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollLeft;
    window.addEventListener('resize', (event: any) => {
      this.wrapperSticky.nativeElement.style.width = getComputedStyle(this.wrapperDiv.nativeElement).width;
      this.wrapperSticky.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollLeft;
    });
    // this.freeze();
  }

  @HostListener('window:scroll', ['$event'])
  WindowScrollEvent(event: KeyboardEvent) {
    if (window.scrollY > 270 && this.stopStickyWidth < 5) {
      this.stopStickyWidth++;
      this.wrapperSticky.nativeElement.style.width = getComputedStyle(this.wrapperDiv.nativeElement).width;
    }
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
    }
    this.scrollTimer = setTimeout(() => {
      this.showDomesticScrollbar();
    }, 250);
  }

  handleResize(entries: ResizeObserverEntry[]) {
    this.showDomesticScrollbar();
  }

  showDomesticScrollbar() {
    const overflowWidth = this.wrapperDiv.nativeElement.scrollWidth;
    const offsetWidth = this.wrapperDiv.nativeElement.offsetWidth;
    this.showWithScroll = overflowWidth > offsetWidth;
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   debugger
  //   if (changes['columns'] || changes['value']) {
  //     this.showDomesticScrollbar();
  //   }
  // }

  public reset() {
    this._sortField = null;
    this._sortOrder = this.defaultSortOrder;
    this._multiSortMeta = null;
    this.tableService.onSort(null);

    this.filteredValue = null;
    this.filters = {};

    this.first = 0;
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    } else {
      this.totalRecords = this._value ? this._value.length : 0;
    }
  }

  checkIsScrollEnable() {
    if (this.wrapperDiv.nativeElement.scrollWidth > this.wrapperDiv.nativeElement.clientWidth) {
      this.showScrollIcons = true;
    } else {
      this.showScrollIcons = false;
    }
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;

    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    }

    // this.tableService.onValueChange(this.value);

    if (this.isStateful()) {
      this.saveState();
    }

    this.anchorRowIndex = null;

    this.onPage.emit({
      first: this.first,
      rows: this.rows,
    });

    this.freezeHeaderAndColumnTimeOut();
  }

  onScroll(event: any) {
    this.freezeHeaderAndColumn();
  }

  ApplyGridViewScroll() {
    if (this.gridViewScroll._initialized) {
      this.gridViewScroll.undo();
    }
    setTimeout(() => {
      this.gridViewScroll = new GridViewScroll(this.gridViewScrollOptions);
      this.gridViewScroll.enhance();
    });
  }

  getScrollPosition() {
    const position = this.gridViewScroll.scrollPosition;
    alert('scrollTop: ' + position.scrollTop + ', scrollLeft: ' + position.scrollLeft);
  }
  setScrollPosition() {
    const scrollPosition = { scrollTop: 50, scrollLeft: 50 };
    this.gridViewScroll.scrollPosition = scrollPosition;
  }

  freezeHeaderAndColumnTimeOut() {
    setTimeout(() => {
      this.freeze();
    });
  }

  freezeHeaderAndColumn() {
    this.freeze();
  }

  scrollToRight() {
    setTimeout(() => {
      this.wrapperDiv.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollWidth;
      this.topColumnContainer.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollWidth;
    });
  }

  freeze() {
    // Array.prototype.slice.call(document.querySelectorAll('nav a'), 0);
    // Array.prototype.slice.call(document.querySelectorAll('table thead tr.sticky-row th'), 0).forEach((x: any) => {
    //   x.setAttribute('style', `top:${this.wrapperDiv.nativeElement.scrollTop}px`);
    // });
    // Array.prototype.slice.call(document.querySelectorAll('table tbody tr.sticky-row td'), 0).forEach((x: any) => {
    //   x.setAttribute('style', `top:${this.wrapperDiv.nativeElement.scrollTop}px`);
    // });
    // Array.prototype.slice.call(document.querySelectorAll('table th.sticky-cell'), 0).forEach((x: any) => {
    //   x.setAttribute('style', `left:${this.wrapperDiv.nativeElement.scrollLeft}px`);
    // });
    // Array.prototype.slice.call(document.querySelectorAll('table td.sticky-cell'), 0).forEach((x: any) => {
    //   x.setAttribute('style', `left:${this.wrapperDiv.nativeElement.scrollLeft}px`);
    // });
    // let width = 0;
    // // tslint:disable-next-line:max-line-length
    // Array.prototype.slice.call(document.querySelectorAll('table thead tr.sticky-row th.sticky-cell'), 0).forEach((x: any) => {
    //   width += x.offsetWidth;
    // });
    // this.leftButton.nativeElement.style.left = (width + 10) + 'px';
    // this.checkIsScrollEnable();
  }

  sort(event: any) {
    const originalEvent = event.originalEvent;

    if (this.sortMode === 'single') {
      this._sortOrder = this.sortField === event.field ? this.sortOrder * -1 : this.defaultSortOrder;
      this._sortField = event.field;
      this.sortSingle();
    }
    if (this.sortMode === 'multiple') {
      const metaKey = originalEvent.metaKey || originalEvent.ctrlKey;
      const sortMeta = this.getSortMeta(event.field);

      if (sortMeta) {
        if (!metaKey) {
          this._multiSortMeta = [{ field: event.field, order: sortMeta.order * -1 }];
        } else {
          sortMeta.order = sortMeta.order * -1;
        }
      } else {
        if (!metaKey || !this.multiSortMeta) {
          this._multiSortMeta = [];
        }
        this.multiSortMeta.push({ field: event.field, order: this.defaultSortOrder });
      }
      this.sortMultiple();
    }

    if (this.isStateful()) {
      this.saveState();
    }

    this.anchorRowIndex = null;
    this.freezeHeaderAndColumnTimeOut();
  }

  onScrollDiv(e: any) {
    this.wrapperSticky.nativeElement.scrollLeft = e.target.scrollLeft;
  }

  getLongestContentLength(index: number) {
    let width = '0px';
    if (this.theadO && this.theadO.nativeElement) {
      if (this.theadO.nativeElement.children[0]) {
        if (this.theadO.nativeElement.children[0].children[index]) {
          width = window.getComputedStyle(this.theadO.nativeElement.children[0].children[index]).width;
          width = parseFloat(width) + 'px';
        }
      }
    }
    return width;
  }

  getLastColumnWidth() {
    let width = '0px';
    if (this.theadO && this.theadO.nativeElement) {
      if (this.theadO.nativeElement.children[0]) {
        // tslint:disable-next-line:max-line-length
        if (this.theadO.nativeElement.children[0].children[this.theadO.nativeElement.children[0].children.length - 1]) {
          width = window.getComputedStyle(
            this.theadO.nativeElement.children[0].children[this.theadO.nativeElement.children[0].children.length - 1]
          ).width;
          width = parseFloat(width) - 30 + 'px';
        }
      }
    }
    return width;
  }

  getWrapperWidth() {
    let width = '0px';
    if (this.wrapperDiv !== undefined) {
      width = window.getComputedStyle(this.wrapperDiv.nativeElement).width;
    }
    return width;
  }

  scrollLeft() {
    this.leftScrollInterval = setInterval(() => {
      this.wrapperDiv.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollLeft - 30;
      this.topColumnContainer.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollLeft;
      this.freezeHeaderAndColumn();
      this.hideRightScroller = true;
      this.righScrollerWidth = -1;
      if (this.wrapperDiv.nativeElement.scrollLeft === 0) {
        this.hideLeftScroller = false;
      }
    });
  }
  clearLeft() {
    clearInterval(this.leftScrollInterval);
  }
  clearRight() {
    clearInterval(this.rightScrollInterval);
  }

  scrollRight() {
    this.rightScrollInterval = setInterval(() => {
      this.wrapperDiv.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollLeft + 30;
      this.topColumnContainer.nativeElement.scrollLeft = this.wrapperDiv.nativeElement.scrollLeft;
      this.hideLeftScroller = true;
      if (this.righScrollerWidth === this.wrapperDiv.nativeElement.scrollLeft) {
        this.hideRightScroller = false;
      }
      this.righScrollerWidth = this.wrapperDiv.nativeElement.scrollLeft;
      this.freezeHeaderAndColumn();
    });
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
