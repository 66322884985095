<div class="error-wrapper">
  <div class="error">
    <div class="logo__error">
      <img src="./assets/styles/portal/build/assets/images/reflecx-logo.svg" alt="" />
    </div>

    <div class="error__inner">
      <i class="fal fa-exclamation-circle"></i>
      <h1>Attention! Time Synchronization Required.</h1>
      <p>
        Please sync your local system time with standard time. You can't proceed further if your local time is not sync
        with your standard time.
      </p>
      <p>
        Current Time Difference:<b> {{ timeDiff }}</b>
      </p>
    </div>
  </div>
</div>
