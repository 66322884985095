import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GlobalFilterComponent } from './global-filters.component';
import { GlobalFilterService } from './global-filters.service';
import { GlobalFilterConfigService } from './global-filters-config.service';
import { GlobalFilterHelperService } from './global-filters-helper.service';
import { GlobalFilterListComponent } from './shared/list/global-filter-list.component';
import { GlobalFilterTreeComponent } from './shared/tree/global-filter-tree.component';
import { gfClickOutSide } from './clickoutside.directive';
import { GPFilterPipe } from './gpFilterBy.pipe';
import { GBFSortPipe } from './gbf-sort.pipe';
import { KeysPipe } from './gp-keys.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MyPrimengModule } from '@primeng';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SliderModule } from 'primeng/slider';
import { GlobalFilterCalendarComponent } from './shared/calendar/global-filter-calendar.component';
import { GlobalFilterMapService } from './global-filters.map.service';
import { GlobalFilterApiService } from './global-filters.api.service';
import { GlobalFilterRouter } from './global-filter-router';
import { GlobalFilterStraightTreeComponent } from './shared/straightree/global-filter-straight-tree.component';
import { GlobalFilterSliderComponent } from './shared/slider/global-filter-slider.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalDynamicFilterComponent } from './global-dynamic-filter-component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { GPListItemPipe } from './gbf-list-item.pipe';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TabMenuModule,
    CheckboxModule,
    RadioButtonModule,
    MyPrimengModule,
    ProgressSpinnerModule,
    SliderModule,
    TranslateModule,
    ScrollingModule,
  ],
  declarations: [
    GPListItemPipe,
    GPFilterPipe,
    GBFSortPipe,
    KeysPipe,
    GlobalFilterComponent,
    GlobalFilterListComponent,
    GlobalFilterTreeComponent,
    GlobalFilterCalendarComponent,
    GlobalFilterStraightTreeComponent,
    GlobalFilterSliderComponent,
    GlobalDynamicFilterComponent,
    gfClickOutSide,
  ],
  exports: [GlobalDynamicFilterComponent, GlobalFilterComponent, GlobalFilterListComponent, GlobalFilterTreeComponent],
  providers: [
    // GlobalFilterService,
    // GlobalFilterMapService,
    // GlobalFilterHelperService,
    // GlobalFilterApiService,
  ],
})
export class GlobalFilterModule {
  static forRoot() {
    return {
      ngModule: GlobalFilterModule,
      providers: [GlobalFilterService],
    };
  }
  static forServices() {
    return [
      GlobalFilterRouter,
      GlobalFilterService,
      GlobalFilterMapService,
      GlobalFilterHelperService,
      GlobalFilterApiService,
      GlobalFilterConfigService,
    ];
  }
}
