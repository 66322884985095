import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, of, EMPTY } from 'rxjs';
import { RestMessage } from '@app/core/types';
import { FileUploader } from 'ng2-file-upload';
import { Logger } from '@app/core';
import { MongoStageBuilder } from '@reflecxshared/services/filter/filter';
import { catchError } from 'rxjs/operators';

const log = new Logger('AccountSettingService');

@Injectable()
export class AccountSettingService {
  constructor(private httpClient: HttpClient) {}

  addProfilePic(user: any): Observable<RestMessage> {
    const url = '/user/profilePic';
    return this.httpClient.post<RestMessage>(url, user);
  }

  updateUserSettings(user: any): Observable<RestMessage> {
    const url = '/user/userSettings';
    return this.httpClient.post<RestMessage>(url, user);
  }

  getPasswordPolicy(id: any): Observable<RestMessage> {
    const url = '/client/ispasswordpolicy/';
    return this.httpClient.get<RestMessage>(url + id);
  }

  getTwoFactorStatus(id: any): Observable<RestMessage> {
    const url = '/user/gettwofactorstatus/';
    return this.httpClient.get<RestMessage>(url + id);
  }
}
