import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ext-signout-callback',
  templateUrl: './ext-signout-callback.component.html',
  styleUrls: ['./ext-signout-callback.component.sass'],
})
export class ExtSignoutCallbackComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    localStorage.clear();
  }
}
