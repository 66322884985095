<br />
<br />
<br />
<br />
<br />
<div class="modal__content">
  <div class="error__content">
    <span class="error-icon"></span>
    <h2>Error 404 - Not Found</h2>
    <p>Page not found</p>
    <p><a (click)="toHome()" class="btn">Go to Home</a></p>
  </div>
</div>
