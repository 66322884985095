import { SortIcon } from 'primeng/table';
import { ChangeDetectorRef, Component } from '@angular/core';
import { CVPTableComponent } from './table.component';

@Component({
  selector: 'cvp-sortIcon',
  templateUrl: './templates/sorticon.html',
  // providers: [CVPTableComponent]
})
export class CVPSortIcon extends SortIcon {
  constructor(public dt: CVPTableComponent, public cd: ChangeDetectorRef) {
    super(dt, cd);
    this.subscription = this.dt.tableService.sortSource$.subscribe((sortMeta) => {
      this.updateSortState();
    });
  }
}
