import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-logout',
  templateUrl: './post-logout.component.html',
  styleUrls: ['./post-logout.component.sass'],
})
export class PostLogoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
