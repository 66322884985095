<div *ngIf="alphabetBar" class="alphabetic-filters">
  <ul>
    <li *ngFor="let a of alphabate" [ngClass]="{ 'ng-scope': true }" (click)="selectAlphabet(a)">
      <a [ngClass]="{ active: a === selectedAlphabet, 'ng-binding': true }">{{ a }}</a>
    </li>
    <li *ngIf="selectedAlphabet !== '' && selectedAlphabet !== 'null'">
      <a
        pTooltip="{{ 'grid.tooltip.reset' | translate }}"
        tooltipPosition="top"
        class="ng-scope"
        (click)="selectAlphabet('null')"
      >
        <i class="far fa-redo-alt"></i>
      </a>
    </li>
    <li *ngIf="topBar.showAlphabetDropDown">
      <!-- <p-dropdown [autoZIndex]='false' #selectedColumn [(ngModel)]="selectedColumnAlphabet" [options]="columns" (onChange)="selectRow($event)" [filter]="true" filterBy="label,value.name"></p-dropdown> -->
      <ng-template
        *ngIf="dropdownTemplate"
        [ngTemplateOutlet]="dropdownTemplate.template"
        [ngTemplateOutletContext]="topBar"
      >
      </ng-template>
      <select
        *ngIf="!dropdownTemplate"
        #selectedColumn
        (change)="selectRow($event)"
        [(ngModel)]="selectedColumnAlphabet"
      >
        <option *ngFor="let c of topBar.alphabetFilterColumns" [value]="c.value">{{ c.label }}</option>
      </select>
    </li>
  </ul>
</div>
