import { mainWidgetReducer } from './main.widget.reducer';
import { globalWidgetReducer } from './global.widget.reducer';
import { hotAlertWidgetReducer } from './hotalert.widget.reducer';
import { EventReducer } from './event.reducer';
import { CacheReducer } from './cache.reducer';
import { smWidgetReducer } from './sm.widget.reducer';
import { vocWidgetReducer } from './voc.widget.reducer';

export const Reducers = {
  MAIN_WIDGETS: mainWidgetReducer,
  GLOBAL_WIDGETS: globalWidgetReducer,
  SM_WIDGETS: smWidgetReducer,
  VOC_WIDGETS: vocWidgetReducer,
  HOTALERT_WIDGETS: hotAlertWidgetReducer,
  CACHE_REDUCER: CacheReducer,
};
