import { NgModule } from '@angular/core';
import { PrimengComponent } from './primeng.component';
import { RfBreadcrumbModule } from './breadcrumb/breadcrumb';
import { CVCMenuModule } from './menu/menu';
import { CVCInputSwitchModule } from './inputswitch/inputswitch';
import { CVCMenubarModule } from './menubar/menubar';
import { CVPScrollPanelModule } from './scrollpanel/scrollpanel';
import { RfCalendarModule } from './calendar/calendar';
import { DynamicDialogModule } from './dynamicdialog/dynamicdialog';
import { CVPCarouselModule } from './carousel/carousel';
import { CVPTableModule } from './table/table';
import { TableService } from './table/services/table.service';
import { RfRadioButtonModule } from './radiobutton/radiobutton';
import { RfCheckboxModule } from './checkbox/checkbox.component';
import { CVPPanelMenuModule } from './panelmenu/panelmenu';
import { CVPToggleButtonModule } from './togglebutton/togglebutton';
import { RTreeModule } from './tree/tree';
import { OrgTreeModule } from './orgtree/org-tree';
import { MentionModule } from './mention/mention.module';
import { RMultiSelectModule } from './multiselect/multiselect';
import { MultiSelectItem, MultiSelectModule } from 'primeng/multiselect';
import { RSlideMenuModule } from './slidemenu/slidemenu';
import { RfAutoCompleteModule } from './autocomplete/autocomplete';
import { CTreeTableModule } from './treetable/treetable';
import { AdvanceEditorModule } from './editor/editor';

@NgModule({
  declarations: [PrimengComponent],
  imports: [
    MentionModule,
    RfBreadcrumbModule,
    DynamicDialogModule,
    CVCMenuModule,
    RfCalendarModule,
    CVCInputSwitchModule,
    CVPCarouselModule,
    CVPTableModule,
    CVCMenubarModule,
    RfRadioButtonModule,
    RfCheckboxModule,
    CVPScrollPanelModule,
    CVPPanelMenuModule,
    CVPToggleButtonModule,
    RTreeModule,
    OrgTreeModule,
    MultiSelectModule,
    RMultiSelectModule,
    RSlideMenuModule,
    RfAutoCompleteModule,
    CTreeTableModule,
    AdvanceEditorModule,
  ],
  providers: [TableService],
  exports: [
    PrimengComponent,
    RfRadioButtonModule,
    DynamicDialogModule,
    RfBreadcrumbModule,
    CVCMenuModule,
    CVPTableModule,
    RfCalendarModule,
    CVCMenubarModule,
    CVPCarouselModule,
    CVCInputSwitchModule,
    CVPScrollPanelModule,
    RfCheckboxModule,
    CVPPanelMenuModule,
    CVPToggleButtonModule,
    RTreeModule,
    OrgTreeModule,
    RMultiSelectModule,
    MultiSelectModule,
    RSlideMenuModule,
    RfAutoCompleteModule,
    CTreeTableModule,
    AdvanceEditorModule,
  ],
})
export class MyPrimengModule {}
