import { Injectable } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationsService } from '../notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Confirmation } from '@primeng/lib/common/confirmation';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  options = {
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight',
  };
  confirmSubscription = new Subject();
  constructor(
    private notificationService: NotificationsService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {}

  replaceAll(string: string, search: string, replace: string) {
    return string.split(search).join(replace);
  }

  addNonTranslationString(value: string) {
    return '&&&//' + value + '//&&&';
  }
  decorateMessgaeTranslation(message: string, translate: boolean = true): string {
    if (message === null) {
      return message;
    }
    const words = message.split('&&&');

    for (const idx in words) {
      if (words[idx].trim() === '') {
        continue;
      }
      if (words[idx].includes('//') === false) {
        if (translate === true) {
          words[idx] = this.translateService.instant(words[idx].trim());
        } else {
          words[idx] = words[idx].trim();
        }
      } else {
        words[idx] = this.replaceAll(words[idx], '//', '');
      }
    }
    return words.join(' ');
  }
  success(message: string, removePrevious: Boolean = false) {
    this.notificationService
      .addAsync({
        icon: 'far fa-check',
        notificationType: 'Success',
        classes: 'alert complete',
        message,
        date: new Date(),
        dateTimeAccessed: new Date(),
        tabSessionId: this.notificationService.sessionId,
        type: 'Message',
      })
      .subscribe((x) => {
        if (removePrevious) {
          this.messageService.clear();
        }
        message = this.decorateMessgaeTranslation(message);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('Success Message'),
          detail: this.translateService.instant(message),
        });
      });
  }
  info(message: string, removePrevious: Boolean = false) {
    this.notificationService
      .addAsync({
        icon: 'far fa-info-circle',
        notificationType: 'Info',
        classes: 'alert updated',
        message,
        date: new Date(),
        dateTimeAccessed: new Date(),
        tabSessionId: this.notificationService.sessionId,
        type: 'Message',
      })
      .subscribe((x) => {
        if (removePrevious) {
          this.messageService.clear();
        }
        message = this.decorateMessgaeTranslation(message);
        this.messageService.add({
          severity: 'info',
          summary: this.translateService.instant('Info Message'),
          detail: this.translateService.instant(message),
        });
      });
  }
  strickMessage(summary: string, message: string) {
    this.messageService.add({
      key: 'sticky',
      sticky: true,
      severity: 'info',
      closable: false,
      summary: this.translateService.instant(summary),
      detail: this.translateService.instant(message),
    });
  }
  strickMessage2(message: string) {
    this.messageService.add({
      sticky: true,
      severity: 'info',
      summary: this.translateService.instant('Info Message'),
      detail: this.translateService.instant(message),
    });
  }
  // infoBulk(messages: Array<any>) {
  //   this.notificationService
  //     .addAsync({
  //       icon: 'info',
  //       type: 'Info',
  //       classes: 'alert updated',
  //       message: messages,
  //       date: new Date()
  //     })
  //     .subscribe(x => {
  //       this.messageService.addAll([
  //         { severity: 'info', summary: 'Message 1', detail: 'PrimeNG rocks' },
  //         { severity: 'info', summary: 'Message 2', detail: 'PrimeUI rocks' },
  //         { severity: 'info', summary: 'Message 3', detail: 'PrimeFaces rocks' }
  //       ]);
  //     });
  // }
  warning(message: string, removePrevious: Boolean = false, replace: any[] = null) {
    this.notificationService
      .addAsync({
        icon: 'fal fa-exclamation',
        notificationType: 'Warning',
        classes: 'alert canceled',
        message,
        date: new Date(),
        dateTimeAccessed: new Date(),
        tabSessionId: this.notificationService.sessionId,
        type: 'Message',
      })
      .subscribe((x) => {
        if (removePrevious) {
          this.messageService.clear();
        }
        message = this.decorateMessgaeTranslation(message);
        if (replace && replace.length > 0) {
          replace.forEach((x: any) => {
            message = message.replaceAll(x.replaceText, x.replaceValue);
          });
        }
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('Warning Message'),
          detail: this.translateService.instant(message),
        });
      });
  }
  error(message: string, removePrevious: Boolean = false) {
    message = message === `` || message === undefined ? `Something went wrong` : message;

    this.notificationService
      .addAsync({
        icon: 'fal fa-exclamation-triangle',
        notificationType: 'Error',
        classes: 'alert deleted',
        message,
        date: new Date(),
        dateTimeAccessed: new Date(),
        tabSessionId: this.notificationService.sessionId,
        type: 'Message',
      })
      .subscribe((x) => {
        if (removePrevious) {
          this.messageService.clear();
        }
        message = this.decorateMessgaeTranslation(message);
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('Error Message'),
          detail: this.translateService.instant(message),
        });
      });
  }

  customInfoToast(notification: any, removePrevious: Boolean = false) {
    if (removePrevious) {
      this.messageService.clear();
    }
    this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant('Info Message'),
      detail: this.translateService.instant(notification.message),
    });
  }

  // confirmationDialog(message: string, header: string) {
  //   this.confirmationService.confirm({
  //     message: message,
  //     header: header,
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       this.confirmSubscription.next(true);
  //     },
  //     reject: () => {
  //       this.confirmSubscription.next(false);
  //     }
  //   });
  //   return this.confirmSubscription;
  // }

  confirmationDialog(
    message: string,
    header: string,
    onSuccess: any,
    onReject: any,
    icon: string = 'far fa-info-circle clr-red'
  ) {
    this.confirmationService.confirm({
      message,
      header,
      icon: icon,
      accept: () => {
        onSuccess();
      },
      reject: () => {
        onReject();
      },
    });
  }
  confirmDialog(confirmation: Confirmation) {
    if (confirmation.icon === null || confirmation.icon === undefined) {
      confirmation.icon = 'far fa-info-circle clr-red';
    }
    this.confirmationService.confirm(confirmation);
  }
}
