import { SortableColumn, Table } from 'primeng/table';
import { Directive, Input, forwardRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CVPTableComponent } from '../table.component';
import { DomHandler } from '../../common';
const Table_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Table),
  multi: true,
};
@Directive({
  selector: '[cvpSortableColumn]',
  host: {
    '[class.ui-sortable-column]': 'isEnabled()',
    '[class.ui-state-highlight]': 'sorted',
    '[attr.tabindex]': 'isEnabled() ? "0" : null',
    '[attr.role]': '"columnheader"',
    '[attr.aria-sort]': 'sortOrder',
  },
  // providers: [CVPTableComponent, Table_ACCESSOR]
})
export class CVPSortableColumn extends SortableColumn {
  @Input('cvpSortableColumn') field: string;

  constructor(public dt: CVPTableComponent) {
    super(dt);
  }

  // @HostListener('click', ['$event'])
  // onClick(event: MouseEvent) {
  //   if (this.isEnabled()) {
  //     this.updateSortState();
  //     this.dt.sort({
  //       originalEvent: event,
  //       field: this.field,
  //     });
  //     DomHandler.clearSelection();
  //   }
  // }
}
