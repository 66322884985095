import {
  NgModule,
  Component,
  Input,
  TemplateRef,
  ElementRef,
  NgZone,
  Directive,
  AfterViewInit,
  Output,
  EventEmitter,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TreeTableService,
  TreeTable,
  TTScrollableView,
  TreeTableModule,
  TreeTableToggler,
  TTRow,
} from 'primeng/treetable';
import { PaginatorModule } from 'primeng/paginator';
import { DomHandler } from 'primeng/dom';
import { style } from '@angular/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
@Component({
  selector: 'c-treeTable',
  template: `
    <div
      #container
      [ngStyle]="style"
      [class]="styleClass"
      [ngClass]="{
        'ui-treetable ui-widget': true,
        'ui-treetable-auto-layout': autoLayout,
        'ui-treetable-hoverable-rows': rowHover || selectionMode === 'single' || selectionMode === 'multiple',
        'ui-treetable-resizable': resizableColumns,
        'ui-treetable-resizable-fit': resizableColumns && columnResizeMode === 'fit'
      }"
    >
      <div class="ui-treetable-loading ui-widget-overlay" *ngIf="loading && showLoader"></div>
      <div class="ui-treetable-loading-content" *ngIf="loading && showLoader">
        <i [class]="'ui-treetable-loading-icon pi-spin ' + loadingIcon"></i>
      </div>
      <div *ngIf="captionTemplate" class="ui-treetable-caption ui-widget-header">
        <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
      </div>
      <p-paginator
        [rows]="rows"
        [first]="first"
        [totalRecords]="totalRecords"
        [pageLinkSize]="pageLinks"
        styleClass="ui-paginator-top"
        [alwaysShow]="alwaysShowPaginator"
        (onPageChange)="onPageChange($event)"
        [rowsPerPageOptions]="rowsPerPageOptions"
        *ngIf="paginator && (paginatorPosition === 'top' || paginatorPosition == 'both')"
        [templateLeft]="paginatorLeftTemplate"
        [templateRight]="paginatorRightTemplate"
        [dropdownAppendTo]="paginatorDropdownAppendTo"
      ></p-paginator>
      <div class="ui-treetable-wrapper" *ngIf="!scrollable">
        <table #table class="ui-treetable-table">
          <ng-container *ngTemplateOutlet="colGroupTemplate; context: { $implicit: columns }"></ng-container>
          <thead class="ui-treetable-thead">
            <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: columns }"></ng-container>
          </thead>
          <tfoot class="ui-treetable-tfoot">
            <ng-container *ngTemplateOutlet="footerTemplate; context: { $implicit: columns }"></ng-container>
          </tfoot>
          <tbody class="ui-treetable-tbody" [pTreeTableBody]="columns" [pTreeTableBodyTemplate]="bodyTemplate"></tbody>
        </table>
      </div>

      <div
        class="ui-treetable-scrollable-wrapper"
        *ngIf="scrollable"
        [ngStyle]="{ 'overflow-x': isSmallDevice ? 'auto' : 'none' }"
      >
        <div
          id="r-frozen"
          #rfrozen
          class="ui-treetable-scrollable-view ui-treetable-frozen-view"
          *ngIf="frozenColumns || frozenBodyTemplate"
          [ccScrollableView]="frozenColumns"
          [isfixTablewidth]="true"
          (scroll)="_onScroll($event)"
          [frozen]="true"
          [ngStyle]="{ width: frozenWidth }"
          [scrollHeight]="scrollHeight"
        ></div>
        <div
          id="l-unfrozen"
          #lfrozen
          class="ui-treetable-scrollable-view"
          [ccScrollableView]="columns"
          [isSmallDevice]="isSmallDevice"
          [isfixTablewidth]="isfixTablewidth"
          (scroll)="_onScroll($event)"
          [frozen]="false"
          [scrollHeight]="leftScrollHeight"
        ></div>
      </div>

      <p-paginator
        [rows]="rows"
        [first]="first"
        [totalRecords]="totalRecords"
        [pageLinkSize]="pageLinks"
        styleClass="ui-paginator-bottom"
        [alwaysShow]="alwaysShowPaginator"
        (onPageChange)="onPageChange($event)"
        [rowsPerPageOptions]="rowsPerPageOptions"
        *ngIf="paginator && (paginatorPosition === 'bottom' || paginatorPosition == 'both')"
        [templateLeft]="paginatorLeftTemplate"
        [templateRight]="paginatorRightTemplate"
        [dropdownAppendTo]="paginatorDropdownAppendTo"
      ></p-paginator>
      <div *ngIf="summaryTemplate" class="ui-treetable-summary ui-widget-header">
        <ng-container *ngTemplateOutlet="summaryTemplate"></ng-container>
      </div>

      <div
        #resizeHelper
        class="ui-column-resizer-helper ui-state-highlight"
        style="display:none"
        *ngIf="resizableColumns"
      ></div>

      <span
        #reorderIndicatorUp
        class="pi pi-arrow-down ui-table-reorder-indicator-up"
        *ngIf="reorderableColumns"
      ></span>
      <span
        #reorderIndicatorDown
        class="pi pi-arrow-up ui-table-reorder-indicator-down"
        *ngIf="reorderableColumns"
      ></span>
    </div>
  `,
  styles: [
    `
      #r-frozen .ui-treetable-scrollable-body {
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
      #r-frozen .ui-treetable-scrollable-body::-webkit-scrollbar {
        display: none;
      }
    `,
  ],
  providers: [TreeTableService],
})
export class CTreeTableComponent extends TreeTable {
  @Input() isSmallDevice = false;
  @Input() isfixTablewidth = true;
  @ViewChild('lfrozen') lfrozen: any;
  @ViewChild('rfrozen') rfrozen: any;
  @Input() minBufferPx: number;
  @Input() maxBufferPx: number;
  @Input() leftScrollHeight: string = '517px';
  _onScroll(positions: { top: number; left: number }) {
    this.lfrozen.scrollBody.nativeElement.scrollTop = positions.top;
    this.rfrozen.scrollBody.nativeElement.scrollTop = positions.top;
    this.lfrozen.scrollHeaderViewChild.nativeElement.scrollLeft = positions.left;

    // this.scrollHeight = e + '';
  }
}

@Component({
  selector: '[ccScrollableView]',
  template: `
    <div #scrollHeader class="ui-treetable-scrollable-header" [ngClass]="{ 'mobile-unfreez': isSmallDevice }">
      <div #scrollHeaderBox class="ui-treetable-scrollable-header-box">
        <table
          class="ui-treetable-scrollable-header-table"
          [style]="{ width: isfixTablewidth ? '100%' : '' }"
          [ngClass]="tt.tableStyleClass"
          [ngStyle]="tt.tableStyle"
        >
          <ng-container
            *ngTemplateOutlet="
              frozen ? tt.frozenColGroupTemplate || tt.colGroupTemplate : tt.colGroupTemplate;
              context: { $implicit: columns }
            "
          ></ng-container>
          <thead class="ui-treetable-thead">
            <ng-container
              *ngTemplateOutlet="
                frozen ? tt.frozenHeaderTemplate || tt.headerTemplate : tt.headerTemplate;
                context: { $implicit: columns }
              "
            ></ng-container>
          </thead>
        </table>
      </div>
    </div>
    <ng-container *ngIf="!tt.virtualScroll; else virtualScrollTemplate">
      <div
        #scrollBody
        (scroll)="onFrozenScroll($event)"
        class="ui-treetable-scrollable-body"
        [ngStyle]="{ 'max-height': tt.scrollHeight !== 'flex' ? scrollHeight : undefined }"
      >
        <table
          #scrollTable
          [class]="tt.tableStyleClass"
          [style]="{ width: isfixTablewidth ? '100%' : '' }"
          [ngStyle]="tt.tableStyle"
        >
          <ng-container
            *ngTemplateOutlet="
              frozen ? tt.frozenColGroupTemplate || tt.colGroupTemplate : tt.colGroupTemplate;
              context: { $implicit: columns }
            "
          ></ng-container>
          <tbody
            class="ui-treetable-tbody"
            [pTreeTableBody]="columns"
            [pTreeTableBodyTemplate]="frozen ? tt.frozenBodyTemplate || tt.bodyTemplate : tt.bodyTemplate"
            [frozen]="frozen"
          ></tbody>
        </table>
        <div #scrollableAligner style="background-color:transparent" *ngIf="frozen"></div>
      </div>
    </ng-container>
    <ng-template #virtualScrollTemplate>
      <cdk-virtual-scroll-viewport
        [itemSize]="tt.virtualRowHeight"
        [style.height]="tt.scrollHeight !== 'flex' ? scrollHeight : undefined"
        [minBufferPx]="tt.minBufferPx"
        [maxBufferPx]="tt.maxBufferPx"
        class="ui-treetable-virtual-scrollable-body"
      >
        <table #scrollTable [class]="tt.tableStyleClass" [ngStyle]="tt.tableStyle">
          <ng-container
            *ngTemplateOutlet="
              frozen ? tt.frozenColGroupTemplate || tt.colGroupTemplate : tt.colGroupTemplate;
              context: { $implicit: columns }
            "
          ></ng-container>
          <tbody
            class="ui-treetable-tbody"
            [pTreeTableBody]="columns"
            [pTreeTableBodyTemplate]="frozen ? tt.frozenBodyTemplate || tt.bodyTemplate : tt.bodyTemplate"
            [frozen]="frozen"
          ></tbody>
        </table>
        <div #scrollableAligner style="background-color:transparent" *ngIf="frozen"></div>
      </cdk-virtual-scroll-viewport>
    </ng-template>
    <div #scrollFooter *ngIf="tt.footerTemplate" class="ui-treetable-scrollable-footer">
      <div #scrollFooterBox class="ui-treetable-scrollable-footer-box">
        <table class="ui-treetable-scrollable-footer-table" [ngClass]="tt.tableStyleClass" [ngStyle]="tt.tableStyle">
          <ng-container
            *ngTemplateOutlet="
              frozen ? tt.frozenColGroupTemplate || tt.colGroupTemplate : tt.colGroupTemplate;
              context: { $implicit: columns }
            "
          ></ng-container>
          <tfoot class="ui-treetable-tfoot">
            <ng-container
              *ngTemplateOutlet="
                frozen ? tt.frozenFooterTemplate || tt.footerTemplate : tt.footerTemplate;
                context: { $implicit: columns }
              "
            ></ng-container>
          </tfoot>
        </table>
      </div>
    </div>
  `,
})
export class CCScrollableViewComponent extends TTScrollableView implements AfterViewInit {
  @Input('ccScrollableView') columns: any[];
  @Input('isSmallDevice') isSmallDevice = false;
  @Input('isfixTablewidth') isfixTablewidth = true;
  @ViewChild('scrollBody', { static: false }) scrollBody: any;
  @Output('scroll') scroll: EventEmitter<any> = new EventEmitter();

  constructor(renderer: Renderer2, public tt: CTreeTableComponent, public el: ElementRef, public zone: NgZone) {
    super(tt, renderer, tt, el, zone);
  }
  onFrozenScroll(e: any) {
    this.scroll.emit({
      top: this.scrollBodyViewChild.nativeElement.scrollTop,
      left: this.scrollBodyViewChild.nativeElement.scrollLeft,
    });
  }
  ngAfterViewInit() {
    this.bindEvents();
    if (!this.frozen) {
      if (this.tt.frozenColumns || this.tt.frozenBodyTemplate) {
        DomHandler.addClass(this.el.nativeElement, 'ui-treetable-unfrozen-view');
      }
      if (this.tt.frozenWidth) {
        this.el.nativeElement.style.left = this.tt.frozenWidth;
        if (this.isSmallDevice) {
          this.el.nativeElement.style.width = '100%';
        } else {
          this.el.nativeElement.style.width = 'calc(100% - ' + this.tt.frozenWidth + ')';
        }
      }
      const frozenView = this.el.nativeElement.previousElementSibling;
      if (frozenView) {
        this.frozenSiblingBody = DomHandler.findSingle(frozenView, '.ui-treetable-scrollable-body');
      }
    } else {
      // this.scrollBodyViewChild.nativeElement.style.paddingBottom = DomHandler.calculateScrollbarWidth() + 'px';
    }
    if (this.tt.virtualScroll) {
      // this.setVirtualScrollerHeight();
      if (this.scrollLoadingTableViewChild && this.scrollLoadingTableViewChild.nativeElement) {
        this.scrollLoadingTableViewChild.nativeElement.style.display = 'table';
      }
    }
  }
}
@Component({
  selector: '[pTreeTableBody]',
  template: `
    <ng-container *ngIf="!tt.virtualScroll">
      <ng-template
        ngFor
        let-serializedNode
        let-rowIndex="index"
        [ngForOf]="tt.serializedValue"
        [ngForTrackBy]="tt.rowTrackBy"
      >
        <ng-container *ngIf="serializedNode.visible">
          <ng-container
            *ngTemplateOutlet="
              template;
              context: {
                $implicit: serializedNode,
                node: serializedNode.node,
                rowData: serializedNode.node.data,
                columns: columns
              }
            "
          ></ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="tt.virtualScroll">
      <ng-template
        cdkVirtualFor
        let-serializedNode
        let-rowIndex="index"
        [cdkVirtualForOf]="tt.serializedValue"
        [cdkVirtualForTrackBy]="tt.rowTrackBy"
      >
        <ng-container *ngIf="serializedNode.visible">
          <ng-container
            *ngTemplateOutlet="
              template;
              context: {
                $implicit: serializedNode,
                node: serializedNode.node,
                rowData: serializedNode.node.data,
                columns: columns
              }
            "
          ></ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="tt.isEmpty()">
      <ng-container *ngTemplateOutlet="tt.emptyMessageTemplate; context: { $implicit: columns }"></ng-container>
    </ng-container>
  `,
})
export class CTTBody {
  @Input('pTreeTableBody') columns: any[];
  @Input('pTreeTableBodyTemplate') template: TemplateRef<any>;
  @Input() frozen: boolean;
  constructor(public tt: CTreeTableComponent) {}
}

@Component({
  selector: 'c-treeTableToggler',
  template: `
    <a
      class="ui-treetable-toggler ui-unselectable-text"
      (click)="onClick($event); onnodeclick($event)"
      [style.visibility]="
        rowNode.node.leaf === false || (rowNode.node.children && rowNode.node.children.length) ? 'visible' : 'hidden'
      "
      [style.marginLeft]="rowNode.level * 16 + 'px'"
    >
      <i [ngClass]="rowNode.node.expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'"></i>
    </a>
  `,
})
export class CTreeTableToggler extends TreeTableToggler {
  constructor(public tt: CTreeTableComponent) {
    super(tt);
  }
  @Output() nodeclick = new EventEmitter();
  onnodeclick(e: any) {
    this.nodeclick.emit(e);
  }
}

@Directive({
  selector: '[cttRow]',
  host: {
    '[attr.tabindex]': '"0"',
  },
})
export class CTTRow extends TTRow {
  @Input('cttRow') rowNode: any;
  constructor(public tt: CTreeTableComponent, public el: ElementRef, public zone: NgZone) {
    super(tt, el, zone);
  }
}

@NgModule({
  imports: [CommonModule, ScrollingModule, TreeTableModule, PaginatorModule],
  exports: [CTreeTableComponent, CTreeTableToggler, CCScrollableViewComponent, CTTRow],
  declarations: [CTreeTableComponent, CTreeTableToggler, CTTBody, CCScrollableViewComponent, CTTRow],
})
export class CTreeTableModule {}
