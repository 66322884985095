<span [class]="labelClass" *ngIf="label">{{label}}</span>
<div
  [ngClass]="{'ui-inputswitch p-inputswitch p-component ui-widget': true, 'p-inputswitch-checked ui-inputswitch-checked': checked(), 'ui-state-disabled p-disabled': disabled, 'ui-inputswitch-readonly': readonly, 
  'ui-inputswitch-with-label': onLabel && offLabel,
  'ui-inputswitch-focus p-focus': focused}"
  [ngStyle]="style"
  [class]="styleClass"
  (click)="onClick($event, cb)"
  role="checkbox"
  [attr.aria-checked]="checked"
>
  <div class="ui-helper-hidden-accessible">
    <input
      #cb
      type="checkbox"
      [attr.id]="inputId"
      [attr.name]="name"
      [attr.tabindex]="tabindex"
      [checked]="checked"
      (change)="onInputChange($event)"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      [disabled]="disabled"
    />
  </div>

  <div class="toggle">
    <label *ngIf="onLabel" [class.active]="!checked">{{onLabel}}</label>
    <div class="switch">
      <span class="ui-inputswitch-slider"></span>
    </div>
    <label *ngIf="offLabel" [class.active]="checked">{{offLabel}}</label>
  </div>
</div>
