import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subscriber, of, concat, empty } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

import { HttpCacheService } from './http-cache.service';
import { Logger } from '../logger.service';
import { environment } from '@env/environment';

const log = new Logger('CacheInterceptor');
/**
 * Caches HTTP requests.
 * Use ExtendedHttpClient fluent API to configure caching for each request.
 */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private forceUpdate = false;
  private enableCache = false;
  constructor(private httpCacheService: HttpCacheService) {}

  /**
   * Configures interceptor options
   * @param options If update option is enabled, forces request to be made and updates cache entry.
   * @return The configured instance.
   */
  configure(options?: { update?: boolean; enableCache?: boolean } | null): CacheInterceptor {
    const instance = new CacheInterceptor(this.httpCacheService);
    if (options && options.update) {
      instance.forceUpdate = true;
    }
    if (options && options.enableCache) {
      instance.enableCache = true;
    }
    log.info('Caching enable : ' + instance.enableCache);
    return instance;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.enableCache || request.method !== 'GET') {
      return next.handle(request);
    }

    // // This will be an Observable of the cached value if there is one,
    // // or an empty Observable otherwise. It starts out empty.
    // let maybeCachedResponse: Observable<HttpEvent<any>> = empty();

    // const cacheKey = request.urlWithParams;
    // // Check the cache.
    // const cachedResponse = this.httpCacheService.getCacheData(cacheKey);
    // if (cachedResponse) {

    //   maybeCachedResponse = of(cachedResponse);
    //   request = request.clone({ params: request.params.append('cached', 'true') });
    // }

    // // Create an Observable (but don't subscribe) that represents making
    // // the network request and caching the value.
    // const networkResponse = next.handle(request)
    //   .pipe(
    //     tap(event => {
    //       // Just like before, check for the HttpResponse event and cache it.
    //       if (event instanceof HttpResponse) {
    //         this.httpCacheService.setCacheData(cacheKey, event);
    //       }
    //     })
    //   );

    // // Now, combine the two and send the cached response first (if there is
    // // one), and the network response second.
    // return concat(maybeCachedResponse, networkResponse);

    return new Observable((subscriber: Subscriber<HttpEvent<any>>) => {
      const cachedData = this.forceUpdate ? null : this.httpCacheService.getCacheData(request.urlWithParams);
      if (cachedData !== null) {
        // Create new response to avoid side-effects
        environment.loaderStyle = 'top';
        subscriber.next(cachedData);
        next
          .handle(request)
          // .pipe(
          //   tap(() => log.info('Delay start for 5 secs')),
          //   delay(5000),
          //   tap(() => log.info('Delay end')),
          // )
          .subscribe(
            (event) => {
              if (event instanceof HttpResponse) {
                this.httpCacheService.setCacheData(request.urlWithParams, event);
                subscriber.next(event);
              }
            },
            (error) => subscriber.error(error),
            () => subscriber.complete()
          );
        // subscriber.complete();
      } else {
        environment.loaderStyle = 'main';
        next.handle(request).subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              this.httpCacheService.setCacheData(request.urlWithParams, event);
            }
            subscriber.next(event);
          },
          (error) => subscriber.error(error),
          () => subscriber.complete()
        );
      }
    });
  }
}
