import {
  Directive,
  Input,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Renderer2,
} from '@angular/core';
import { Observable, SubscriptionLike, debounceTime, fromEvent } from 'rxjs';

const platformExpression = /Mac|iPhone|iPod|iPad/i;
const rejectedExpression = /Chrome|Android|CriOS|FxiOS|EdgiOS/i;
const expectedExpression = /Safari/i;

const isAppleSafari = () => {
  const agent = navigator.userAgent;
  if (rejectedExpression.test(agent)) {
    return false;
  }
  return platformExpression.test(agent) && expectedExpression.test(agent);
};

@Directive({
  selector: '[stickyHeader]',
})
export class StickyHeaderDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() stickyClass = '';
  @Input() stickyTop = 0;

  private windowScrollSubscription: SubscriptionLike = null;
  private windowResizeSubscription: SubscriptionLike = null;
  private header: any = null;
  private topWrapper: any = null;
  private header2: any = null;
  private offsetTop = 0;
  private lastScroll = 0;
  private isSticky = false;
  private hasHeader = false;
  private headerTop = 0;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  offset(el: any) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!isAppleSafari()) {
        this.windowScrollSubscription = fromEvent(window, 'scroll')
          .pipe(debounceTime(20))
          .subscribe(() => this.manageScrollEvent());
        this.windowResizeSubscription = fromEvent(window, 'resize')
          .pipe(debounceTime(20))
          .subscribe(() => this.updateHeaderSize());
      }
      const topHeader = document.getElementById('SurveyGridPanelHeader');
      const topWrapper = document.getElementById('SurveyGridPanelHeader_Visible');
      if (topHeader) {
        this.header = topHeader;
        // const isMobile = window.innerWidth < 1024;
        // if (isMobile) {
        //   this.unsetSticky();
        //   return;
        // }
        this.header.style.display = 'none';
        // this.headerTop = this.header.getBoundingClientRect()['top'];
        this.topWrapper = topWrapper;
        // this._calcPosition();
      }
    }, 0);
  }

  ngOnDestroy(): void {
    if (this.windowScrollSubscription) {
      this.windowScrollSubscription.unsubscribe();
      this.windowScrollSubscription = null;
    }
    if (this.windowResizeSubscription) {
      this.windowResizeSubscription.unsubscribe();
      this.windowResizeSubscription = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stickyTop && !isAppleSafari()) {
      this._calcPosition();
    }
  }

  private _calcPosition() {
    if (this.hasHeader) {
      const scroll = window.pageYOffset;
      if (this.isSticky && scroll >= this.headerTop) {
        this.header.style.top = this.stickyTop + 'px';
      }
    }
  }

  private manageScrollEvent(): void {
    const isMobile = window.innerWidth < 1024;
    if (isMobile) {
      this.unsetSticky();
      return;
    }
    const top = this.offset(this.topWrapper).top;
    const sticky = parseInt(top, 0);
    const scroll = window.pageYOffset - sticky + 40;
    if (scroll > this.lastScroll && !this.isSticky && scroll >= this.offsetTop) {
      this.setSticky();
    } else if (scroll < this.lastScroll && this.isSticky && scroll <= this.offsetTop) {
      this.unsetSticky();
    }
    this.lastScroll = scroll;
  }

  private setSticky(): void {
    this.isSticky = true;
    // this.header.style.position = 'fixed';
    this.header.style.top = this.stickyTop + 'px';
    this.header.style.display = 'block';
    this.header.scrollLeft = this.topWrapper.scrollLeft;
    // this.header2.style.display = 'table-row';;
    // this.updateHeaderSize();
    // this.setClass(true);
  }

  private updateHeaderSize() {
    if (this.isSticky) {
      // tslint:disable-next-line:max-line-length
      // const tableWidth = this.elementRef.nativeElement.getBoundingClientRect()['right'] - this.elementRef.nativeElement.getBoundingClientRect()['left'];
      // this.header.style.width = tableWidth + 'px';
      // // update size of TH elements
      // const thArray = this.elementRef.nativeElement.getElementsByTagName('TH');
      // for (let i = 0; i < thArray.length; i++) {
      //     thArray[i].style.width = tableWidth / thArray.length + 'px';
      // }
    }
  }

  private unsetSticky(): void {
    this.isSticky = false;
    // this.header.style.position = 'static';
    // this.header.style.width = 'auto';
    // this.header.style.display = 'table-row';
    if (this?.header?.style) {
      this.header.style.display = 'none';
    }
    // this.setClass(false);
  }

  private setStyle(key: string, value: string): void {
    this.renderer.setStyle(this.header, key, value);
  }

  private setClass(add: boolean): void {
    if (this.stickyClass) {
      add
        ? this.renderer.addClass(this.header, this.stickyClass)
        : this.renderer.removeClass(this.header, this.stickyClass);
    }
  }
}
