import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Inject,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DataTableHeaderCellComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'data-header-cell',
  template: `
    <div class="datatable-header-cell-template-wrap">
      <ng-template
        *ngIf="isTarget"
        [ngTemplateOutlet]="targetMarkerTemplate"
        [ngTemplateOutletContext]="targetMarkerContext"
      >
      </ng-template>
      <label *ngIf="isCheckboxable" class="datatable-checkbox">
        <input type="checkbox" [checked]="allRowsSelected" (change)="select.emit(!allRowsSelected)" />
      </label>
      <span *ngIf="!column.headerTemplate" class="datatable-header-cell-wrapper">
        <span class="datatable-header-cell-label draggable" (click)="onSort()" [innerHTML]="name"> </span>
      </span>
      <ng-template
        *ngIf="column.headerTemplate"
        [ngTemplateOutlet]="column.headerTemplate"
        [ngTemplateOutletContext]="cellContext"
      >
      </ng-template>
      <span (click)="onSort()" [class]="sortClass"> </span>
    </div>
  `,
})
export class HeaderCellComponent extends DataTableHeaderCellComponent implements OnInit {
  @Input() longestContent: any;
  constructor(@Inject(ChangeDetectorRef) ref: ChangeDetectorRef) {
    super(ref);
  }
  ngOnInit() {
    this.sortClass = this.calcSortClass(this.sortDir);
  }

  calcSortDir(sorts: any[]): any {
    if (sorts && this.column) {
      const sort = sorts.find((s: any) => {
        return s.prop === this.column.prop;
      });

      if (sort) return sort.dir;
    }
  }

  @HostBinding('style.width.px')
  get width(): number {
    if (this.column['prop'] !== null && this.longestContent !== undefined && this.column['name'] !== '') {
      const content = this.byString(this.longestContent, this.column['prop']);
      if (content !== undefined) {
        return content.length * 8 + 25;
      } else {
        return this.column.width;
      }
    } else {
      return this.column.width;
    }
  }

  byString(o: any, s: any) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
}
