import { CommonModule } from '@angular/common';
import { Component, NgModule, Output, EventEmitter } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Breadcrumb } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'rf-breadcrumb',
  templateUrl: './breadcrumb-header.html',
})
export class RfBreadcrumbComponent extends Breadcrumb {
  @Output() onItemClicked = new EventEmitter<any>();
  @Output() onHomeClicked = new EventEmitter<any>();
  itemClick(event: any, item: MenuItem) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }
    if (!item.url) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
      });
    }
    this.onItemClicked.emit(item);
  }
  onHomeClick(event: any) {
    if (this.home) {
      this.itemClick(event, this.home);
      this.onItemClicked.emit(this.home);
    }
  }
}

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [RfBreadcrumbComponent, RouterModule],
  declarations: [RfBreadcrumbComponent],
})
export class RfBreadcrumbModule {}
