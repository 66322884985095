<div *ngIf="!isCloseDemoExpiryMessage">
  <div class="floating-notification" *ngIf="isShowDemoExpiry && remainingInterval != 'account_expires_in'">
    <i class="far fa-info-circle"></i> {{ remainingInterval
    }}<span><i style="cursor: pointer" (click)="hideDemoMessage()" class="close far fa-times"></i></span>
  </div>
</div>

<router-outlet></router-outlet>
<app-loader></app-loader>
<p-confirmDialog
  [closable]="false"
  rejectLabel="No"
  acceptLabel="Yes"
  styleClass="ui-dialog--small"
  acceptIcon=""
  rejectIcon=""
  id="cd"
  #cd
>
  <p-footer>
    <button type="button" class="btn btn--cancel" label="No" (click)="cd.reject()">
      {{ cd.rejectButtonLabel | translate }}
    </button>
    <button type="button" class="btn" label="Yes" (click)="cd.accept()">{{ cd.acceptButtonLabel | translate }}</button>
  </p-footer>
</p-confirmDialog>
<p-toast position="bottom-left">
  <ng-template let-message pTemplate="message">
    <i
      class="ui-toast-icon pi"
      [ngClass]="{
        'pi-exclamation-triangle': message.severity == 'error',
        'pi-check-circle': message.severity == 'success',
        'pi-exclamation-circle': message.severity == 'warn',
        'pi-info-circle': message.severity == 'info'
      }"
    ></i>
    <h3>{{ message.summary }}</h3>
    <p>{{ message.detail }}</p>
  </ng-template>
</p-toast>
<p-toast key="sticky" styleClass="sticky--notification" position="bottom-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <i
      class="p-toast-icon pi"
      [ngClass]="{
        'pi-exclamation-triangle': message.severity == 'error',
        'pi-check-circle': message.severity == 'success',
        'pi-exclamation-circle': message.severity == 'warn',
        'pi-info-circle': message.severity == 'info'
      }"
    ></i>
    <h3>{{ message.summary }}</h3>
    <p>{{ message.detail }}</p>
    <button type="button" (click)="onReload()" class="btn btn--secondary btn--small">{{ 'Reload' | translate }}</button>
  </ng-template>
</p-toast>
