import { Component, Input, OnChanges, PipeTransform, TemplateRef } from '@angular/core';
import { DataTableSummaryRowComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'rdatatable-summary-row',
  template: `
    <rdatatable-body-row
      *ngIf="summaryRow && _internalColumns"
      tabindex="-1"
      [innerWidth]="innerWidth"
      [offsetX]="offsetX"
      [columns]="_internalColumns"
      [rowHeight]="rowHeight"
      [row]="summaryRow"
      [rowIndex]="-1"
    >
    </rdatatable-body-row>
  `,
  host: {
    class: 'datatable-summary-row',
  },
})
export class RDataTableSummaryRowComponent extends DataTableSummaryRowComponent {}
