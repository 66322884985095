import { Component } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-swiper-login',
  templateUrl: './swiper.login.component.html',
  styleUrls: ['./swiper.login.component.scss'],
})
export class SwiperLoginComponent {
  constructor(public authService: AuthenticationService) {}
}
