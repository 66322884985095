import * as MainWidgetsAction from './main.widget.actions';
import * as GlobalWidgetsAction from './global.widget.action';
import * as HotAlertWidgetsAction from './hotalert.widget.action';
import * as VOCWidgetsAction from './voc.widget.action';
import * as SMWidgetsAction from './sm.widget.action';
import * as LanguageEvent from './language.event.action';
import * as PermissionEvent from './userpermission.action';
import * as ChangeCacheEvent from './cache.action';

export const CVPActions = {
  MainDashBoardActions: MainWidgetsAction,
  GlobalDashBoardActions: GlobalWidgetsAction,
  HotAlertWidgetsAction: HotAlertWidgetsAction,
  SMWidgetsAction: SMWidgetsAction,
  VOCWidgetsAction: VOCWidgetsAction,
  LanguageEventAction: LanguageEvent,
  PermissionEventAction: PermissionEvent,
  CacheStateEvent: ChangeCacheEvent,
};
