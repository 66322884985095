<a
  *ngIf="!item.routerLink"
  [href]="item.url||'#'"
  class="ui-menuitem-link ui-corner-all"
  [attr.data-automationid]="item.automationId"
  [attr.target]="item.target"
  [attr.title]="item.title"
  [attr.id]="item.id"
  [ngClass]="{'ui-state-disabled':item.disabled}"
  (click)="menu.itemClick($event, item)"
>
  <span class="ui-menuitem-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
  <span class="ui-menuitem-text">{{item.label}}</span>
</a>
<a
  *ngIf="item.routerLink"
  [routerLink]="item.routerLink"
  [attr.data-automationid]="item.automationId"
  [queryParams]="item.queryParams"
  [routerLinkActive]="'ui-state-active'"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions||{exact:false}"
  class="ui-menuitem-link ui-corner-all"
  [attr.target]="item.target"
  [attr.id]="item.id"
  [attr.title]="item.title"
  [ngClass]="{'ui-state-disabled':item.disabled}"
  (click)="menu.itemClick($event, item)"
>
  <span class="ui-menuitem-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
  <span class="ui-menuitem-text">{{item.label}}</span>
</a>
