<!-- <div class="angular-editor" #angularEditor [style.width]="config.width"
     [style.minWidth]="config.minWidth">
  <advance-editor-tool-bar *ngIf="config.toolbarPosition === 'top'" #editorToolbar
                          [id]="id"
                          [uploadUrl]="config.uploadUrl"
                          [showToolbar]="config.showToolbar !== undefined ? config.showToolbar : true"
                          [fonts]="getFonts()"
                          [customClasses]="config.customClasses"
                          [defaultFontName]="config.defaultFontName"
                          [defaultFontSize]="config.defaultFontSize"
                          [hiddenButtons]="config.toolbarHiddenButtons"
                          (execute)="executeCommand($event)"></advance-editor-tool-bar>
 
  <div class="angular-editor-wrapper" #editorWrapper>
    <div #editor class="angular-editor-textarea"
    [mentionConfig]="mentionConfig"
         [attr.contenteditable]="config.editable"
         [attr.tabindex]="disabled ? -1 : tabIndex"
         [attr.translate]="config.translate"
         [attr.spellcheck]="config.spellcheck"
         [style.height]="config.height"
         [style.minHeight]="config.minHeight"
         [style.maxHeight]="config.maxHeight"
         [style.outline]="config.outline === false ? 'none': undefined"
         (input)="onContentChange($event.target)"
         (focus)="onTextAreaFocus($event)"
         (blur)="onTextAreaBlur($event)"
         (click)="exec()"
         (keyup)="exec()"
         (mouseout)="onTextAreaMouseOut($event)"
    >
    </div>
    <span class="angular-editor-placeholder">{{ placeholder || config['placeholder'] }}</span>
  </div>
  <advance-editor-tool-bar *ngIf="config.toolbarPosition === 'bottom'" #editorToolbar
                          [id]="id"
                          [uploadUrl]="config.uploadUrl"
                          [showToolbar]="config.showToolbar !== undefined ? config.showToolbar : true"
                          [fonts]="getFonts()"
                          [customClasses]="config.customClasses"
                          [defaultFontName]="config.defaultFontName"
                          [defaultFontSize]="config.defaultFontSize"
                          [hiddenButtons]="config.toolbarHiddenButtons"
                          (execute)="executeCommand($event)"></advance-editor-tool-bar>
</div> -->

<div class="angular-editor" #angularEditor [style.width]="config.width" [style.minWidth]="config.minWidth">
  <ng-container *ngIf="!hideToolBar">
    <angular-editor-toolbar
      *ngIf="config.toolbarPosition === 'top'"
      #editorToolbar
      [id]="id"
      [uploadUrl]="config.uploadUrl"
      [showToolbar]="config.showToolbar !== undefined ? config.showToolbar : true"
      [fonts]="getFonts()"
      [customClasses]="config.customClasses"
      [defaultFontName]="config.defaultFontName"
      [defaultFontSize]="config.defaultFontSize"
      [hiddenButtons]="config.toolbarHiddenButtons"
      (execute)="executeCommand($event)"
    ></angular-editor-toolbar>
  </ng-container>

  <div class="angular-editor-wrapper" #editorWrapper>
    <div
      #editor
      class="angular-editor-textarea"
      [mentionConfig]="mentionConfig"
      [attr.contenteditable]="config.editable"
      [attr.tabindex]="disabled ? -1 : tabIndex"
      [attr.translate]="config.translate"
      [attr.spellcheck]="config.spellcheck"
      [style.height]="config.height"
      [style.minHeight]="config.minHeight"
      [style.maxHeight]="config.maxHeight"
      [style.outline]="config.outline === false ? 'none': undefined"
      (input)="onContentChange($event.target)"
      (focus)="onTextAreaFocus($event)"
      (blur)="onTextAreaBlur($event)"
      (mouseout)="onTextAreaMouseOut($event)"
    >
      <!--          (click)="exec()"
         (keyup)="exec()" -->
    </div>
    <span class="angular-editor-placeholder">{{ placeholder || config['placeholder'] }}</span>
  </div>
  <angular-editor-toolbar
    *ngIf="config.toolbarPosition === 'bottom'"
    #editorToolbar
    [id]="id"
    [uploadUrl]="config.uploadUrl"
    [showToolbar]="config.showToolbar !== undefined ? config.showToolbar : true"
    [fonts]="getFonts()"
    [customClasses]="config.customClasses"
    [defaultFontName]="config.defaultFontName"
    [defaultFontSize]="config.defaultFontSize"
    [hiddenButtons]="config.toolbarHiddenButtons"
    (execute)="executeCommand($event)"
  ></angular-editor-toolbar>
</div>
