import { Input, Output, EventEmitter, Directive, TemplateRef, ContentChild } from '@angular/core';
import { FooterPageSizeDropDownTemplateDirective } from './footer-pagesize-dropdown.template.directive';

@Directive({ selector: 'footer-page-size-dropdown' })
export class FooterPageSizeDropdownDirective {
  @Input() pageLimitOptions = [{ value: 5 }, { value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }];

  // @Input()
  @ContentChild(FooterPageSizeDropDownTemplateDirective, { read: TemplateRef })
  template: TemplateRef<any>;
}
