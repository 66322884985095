<div
  #container
  [ngClass]="'ui-scrollpanel ui-widget ui-widget-content ui-corner-all'"
  [ngStyle]="style"
  [class]="styleClass"
>
  <div #wrapper class="ui-scrollpanel-wrapper">
    <div #content (scroll)="onScroll($event)" class="ui-scrollpanel-content">
      <ng-content></ng-content>
    </div>
  </div>
  <div #xBar class="ui-scrollpanel-bar ui-scrollpanel-bar-x"></div>
  <div #yBar class="ui-scrollpanel-bar ui-scrollpanel-bar-y"></div>
</div>
