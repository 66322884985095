export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const dispList = [
  { Description: 'In-Queue', Code: 'In-Queue' },
  { Description: 'In-Progress', Code: 'In-Progress' },
  { Description: 'Sent', Code: 'Sent' },
  { Description: 'Delivered', Code: 'Delivered' },
  { Description: 'Admin Bounced', Code: 'Admin Bounced' },
  { Description: 'Blocked Bounced', Code: 'Blocked Bounced' },
  { Description: 'Hard Bounced', Code: 'Hard Bounced' },
  { Description: 'Soft Bounced', Code: 'Soft Bounced' },
  { Description: 'Viewed', Code: 'Viewed' },
  { Description: 'Opened', Code: 'Opened' },
  { Description: 'Expired', Code: 'Expired' },
  { Description: 'Completed', Code: 'Completed', Disposition: 'Completed' },
  { Description: 'Abandoned', Code: 'Abandoned' },
  { Description: 'Filtered', Code: 'Filtered' },
  { Description: 'Bounced', Code: 'Bounced' },
  { Description: 'Cancelled', Code: 'Cancelled' },
];

export class FilterTypes {
  static Program = 'Program';
  static Org = 'Org';
  static Brand = 'Brand';
  static Model = 'Model';
  static ModelYear = 'ModelYear';
  static Disposition = 'Disposition';
  static Version = 'Version';
  static SelectedPeriods = 'SelectedPeriods';
  static OrgSugestions = 'OrgSugestions';
  static OrgsSelectedToInclude = 'OrgsToInclude';
  static Month = 'Month';
  static Year = 'Year';
}

export class DefaultColumnsKeys {
  static Program = 'Program';
  static OrgLevel = '{{ORGLEVELS}}';
  static Brand = 'Brand';
  static Model = 'Model';
  static ModelYear = 'Model Year';
  static Version = 'Version';
  static DatePeriod = 'Date Period';
  static DealerCode = 'Dealer Code';
}
