import { Input, Component, TemplateRef, forwardRef } from '@angular/core';
import { Table, TableBody } from 'primeng/table';

// import { TableBody, Table } from 'primeng/table';
// import { Component, TemplateRef, Input, forwardRef, OnInit } from '@angular/core';
// import { RfColumn } from '../common';
// import { CVPTableComponent } from './table.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CVPTableComponent } from './table.component';

const Table_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Table),
  multi: true,
};
// @Component({
//   selector: '[pTableBody]',
//   templateUrl: './templates/tablebody.html',
//   providers: [Table_ACCESSOR, Table]
// })
// export class CVPTableBody extends TableBody implements OnInit {

//   ngOnInit() {
//   }
// }

@Component({
  selector: '[pTableBody]',
  template: `
    <ng-container *ngIf="!dt.expandedRowTemplate">
      <ng-template
        ngFor
        let-rowData
        let-rowIndex="index"
        [ngForOf]="
          dt.paginator && !dt.lazy
            ? (dt.filteredValue || dt.value | slice : dt.first : dt.first + dt.rows)
            : dt.filteredValue || dt.value
        "
        [ngForTrackBy]="dt.rowTrackBy"
      >
        <ng-container
          *ngTemplateOutlet="
            template;
            context: {
              $implicit: rowData,
              rowIndex: dt.paginator ? dt.first + rowIndex : rowIndex,
              columns: columns,
              editing: dt.editMode === 'row' && dt.isRowEditing(rowData)
            }
          "
        ></ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="dt.expandedRowTemplate">
      <ng-template
        ngFor
        let-rowData
        let-rowIndex="index"
        [ngForOf]="
          dt.paginator && !dt.lazy
            ? (dt.filteredValue || dt.value | slice : dt.first : dt.first + dt.rows)
            : dt.filteredValue || dt.value
        "
        [ngForTrackBy]="dt.rowTrackBy"
      >
        <ng-container
          *ngTemplateOutlet="
            template;
            context: {
              $implicit: rowData,
              rowIndex: dt.paginator ? dt.first + rowIndex : rowIndex,
              columns: columns,
              expanded: dt.isRowExpanded(rowData),
              editing: dt.editMode === 'row' && dt.isRowEditing(rowData)
            }
          "
        ></ng-container>
        <ng-container *ngIf="dt.isRowExpanded(rowData)">
          <ng-container
            *ngTemplateOutlet="
              dt.expandedRowTemplate;
              context: { $implicit: rowData, rowIndex: dt.paginator ? dt.first + rowIndex : rowIndex, columns: columns }
            "
          ></ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="dt.isEmpty()">
      <ng-container *ngTemplateOutlet="dt.emptyMessageTemplate; context: { $implicit: columns }"></ng-container>
    </ng-container>
  `,
  // providers: [Table, Table_ACCESSOR]
})
export class CVPTableBody extends TableBody {
  @Input('pTableBody') columns: any[];
  @Input('pTableBodyTemplate') template: TemplateRef<any>;
}
