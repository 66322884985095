import { Action } from '@ngrx/store';
import { CVPActions } from '../actions/';

const ActionTypes = CVPActions.CacheStateEvent.ActionTypes;
export const initialState = '';
export function CacheReducer(state: string = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.ChangeCacheState:
      return action;
    default:
      return state;
  }
}
