<div class="action__item" *ngIf="topBar.showSearch">
  <div class="input-search" *ngIf="topBar.showSearch">
    <input
      type="text"
      class="p-inputtext"
      [placeholder]="topBar.placeholder || 'Search ' + topBar.title"
      [(ngModel)]="searchtext"
      (keyup)="onChange()"
    />
  </div>
</div>

<!-- <select>
    <option *ngFor="let column topBar"></option>
  </select> -->
<div class="action__item" *ngIf="!GridHeaderTopDropDownTemplate && topBar.hasSearchDropdown">
  <select
    class="{{ topBar.searchDropDownClass }}"
    *ngIf="!GridHeaderTopDropDownTemplate && topBar.hasSearchDropdown"
    #selectedColumn
    (change)="selectRow($event)"
  >
    <option *ngFor="let c of topBar.alphabetFilterColumns" [value]="c.value">{{ c.label }}</option>
  </select>
</div>
<div class="action__item" *ngIf="GridHeaderTopDropDownTemplate && topBar.hasSearchDropdown">
  <ng-template
    *ngIf="GridHeaderTopDropDownTemplate && topBar.hasSearchDropdown"
    [ngTemplateOutlet]="GridHeaderTopDropDownTemplate.template"
    [ngTemplateOutletContext]="topBar"
  >
  </ng-template>
</div>
<ng-template *ngIf="ActionTemplate" [ngTemplateOutlet]="ActionTemplate.template" [ngTemplateOutletContext]="topBar">
</ng-template>
