import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RfSharedModule } from '../common/shared';
import { CVPPaginatorModule } from '../paginator/paginator';
import { CVPCellEditor } from './celleditor';
import { CVPContextMenuRow } from './directives/contextmenu.directive';
import { CVPReorderableRow } from './directives/draggablerow.directive';
import { CVPReorderableRowHandle } from './directives/draggablerowhandler.directive';
import { CVPEditableColumn } from './directives/editablecolumn.directive';
import { CVPReorderableColumn } from './directives/reorderablecolumn.directive';
import { CVPResizableColumn } from './directives/resizablerow.directive';
import { CVPRowToggler } from './directives/rowtoggler.directive';
import { CVPSelectableRow } from './directives/selectablerow.directive';
import { CVPSelectableRowDblClick } from './directives/selectablerowdblclick.directive';
import { CVPSortableColumn } from './directives/sortableColumn.directive';
import { TableBodyDirective } from './directives/tablecustombody.directive';
import { BodyTemplateDirective } from './directives/tablecustombody.template.directive';
import { CVPEditableRow } from './directives/editableRow.directive';
import { CVPSaveEditableRow } from './directives/saveEditableRow.directive';
import { CVPInitEditableRow } from './directives/initEditableRow.directive';
import { CVPCancelEditableRow } from './directives/cancelEditableRow.directive';
import { StickyHeaderDirective } from './directives/sticky-header.directive';
import { CVPScrollableView } from './scrollableview';
import { CVPSortIcon } from './sorticon';
import { CVPTableComponent } from './table.component';
import { CVPTableBody } from './tablebody';
import { CVPTableCheckbox } from './tablecheckbox';
import { CVPTableHeaderCheckbox } from './tableheadercheckbox';
import { CVPTableRadioButton } from './tableradiobutton';
import { TableModule, TableService } from 'primeng/table';

@NgModule({
  imports: [CommonModule, CVPPaginatorModule],
  exports: [
    CVPTableComponent,
    RfSharedModule,
    CVPSortableColumn,
    CVPSelectableRow,
    CVPRowToggler,
    CVPContextMenuRow,
    CVPEditableRow,
    CVPSaveEditableRow,
    CVPInitEditableRow,
    CVPCancelEditableRow,
    CVPResizableColumn,
    CVPReorderableColumn,
    CVPEditableColumn,
    CVPTableBody,
    CVPScrollableView,
    StickyHeaderDirective,
    //  TableBody,
    //   ScrollableView,
    TableBodyDirective,
    BodyTemplateDirective,
    CVPCellEditor,
    CVPSortIcon,
    CVPTableRadioButton,
    CVPTableCheckbox,
    CVPTableHeaderCheckbox,
    CVPReorderableRowHandle,
    CVPReorderableRow,
    CVPSelectableRowDblClick,
    TableModule,
  ],
  declarations: [
    StickyHeaderDirective,
    CVPTableComponent,
    CVPSortableColumn,
    TableBodyDirective,
    BodyTemplateDirective,
    CVPSelectableRow,
    CVPEditableRow,
    CVPSaveEditableRow,
    CVPInitEditableRow,
    CVPCancelEditableRow,
    CVPRowToggler,
    // TableBody,
    // ScrollableView,
    CVPContextMenuRow,
    CVPResizableColumn,
    CVPReorderableColumn,
    CVPEditableColumn,
    CVPCellEditor,
    CVPTableBody,
    CVPScrollableView,
    CVPSortIcon,
    CVPTableRadioButton,
    CVPTableCheckbox,
    CVPTableHeaderCheckbox,
    CVPReorderableRowHandle,
    CVPReorderableRow,
    CVPSelectableRowDblClick,
  ],
  providers: [TableService],
})
export class CVPTableModule {}
