import { Injectable } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  channel: BroadcastChannel<any>;
  IDLE_TIMEOUT = 0; //seconds

  constructor() {
    this.channel = new BroadcastChannel('broadcast-channel');
  }

  setIdletimeout(time: number) {
    this.IDLE_TIMEOUT = time;
  }

  getIdletimeout() {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    return authData ? (authData.IdleTimeoutConfig ? authData.IdleTimeoutConfig.TimeoutSeconds : 0) : 0;
  }

  resetIdletimers() {
    this.channel.postMessage({ isClearInterval: true });
    this.channel.postMessage({ isClearInterval: true });
  }

  postIdleTimoutMessage() {
    this.channel.postMessage({ isIdleTimeout: true });
  }

  postSigningOutMessage() {
    this.channel.postMessage({ isSigningOut: true });
  }
}
