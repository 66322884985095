import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ProgressBarComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'rdatatable-progress',
  template: `
    <div class="progress-linear" role="progressbar">
      <div class="container">
        <div class="bar"></div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RProgressBarComponent extends ProgressBarComponent {}
