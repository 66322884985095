<div [ngStyle]="style" [ngClass]="'ui-radiobutton ui-widget'" [class]="styleClass">
  <div class="ui-helper-hidden-accessible">
    <input
      #rb
      type="radio"
      [attr.id]="inputId"
      [attr.name]="name"
      [attr.value]="value"
      [attr.tabindex]="tabindex"
      [checked]="checked"
      (change)="onChange($event)"
      (focus)="onInputFocus($event)"
      (blur)="onInputBlur($event)"
      [disabled]="disabled"
    />
  </div>
  <div
    (click)="handleClick($event, rb, true)"
    [ngClass]="{'ui-radiobutton-box ui-widget ui-state-default':true,
      'ui-state-active':rb.checked,'ui-state-disabled':disabled,'ui-state-focus':focused}"
  >
    <span class="ui-radiobutton-icon ui-clickable" [ngClass]="{'pi pi-circle-on':rb.checked}"></span>
  </div>
</div>
<label
  (click)="select($event)"
  [class]="labelStyleClass"
  [ngClass]="{'ui-radiobutton-label':true, 'ui-label-active':rb.checked, 'ui-label-disabled':disabled, 'ui-label-focus':focused}"
  [attr.for]="inputId"
  ><ng-content></ng-content
></label>
