import {
  NgModule,
  Component,
  OnInit,
  Inject,
  forwardRef,
  OnChanges,
  SimpleChange,
  AfterContentInit,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UITreeNode, Tree, TreeModule } from 'primeng/tree';
import { TreeNode } from './TreeNodeOrgExtended';
import { ObjectUtils } from 'primeng/utils';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RippleModule } from 'primeng/ripple';
import { DomHandler } from 'primeng/dom';
// import { TreeNode } from '@primeng/lib/common/treenode';

@Component({
  selector: 'org-treeNode',
  template: `
    <ng-template [ngIf]="node">
      <li
        *ngIf="tree.droppableNodes"
        class="p-treenode-droppoint"
        [ngClass]="{ 'p-treenode-droppoint-active': draghoverPrev }"
        (drop)="onDropPoint($event, -1)"
        (dragover)="onDropPointDragOver($event)"
        (dragenter)="onDropPointDragEnter($event, -1)"
        (dragleave)="onDropPointDragLeave($event)"
      ></li>
      <li *ngIf="!tree.horizontal" [ngClass]="['p-treenode', node.styleClass || '', isLeaf() ? 'p-treenode-leaf' : '']">
        <div
          class="p-treenode-content"
          [style.paddingLeft]="level * indentation + 'rem'"
          (click)="onNodeClick($event)"
          (contextmenu)="onNodeRightClick($event)"
          (touchend)="onNodeTouchEnd()"
          (drop)="onDropNode($event)"
          (dragover)="onDropNodeDragOver($event)"
          (dragenter)="onDropNodeDragEnter($event)"
          (dragleave)="onDropNodeDragLeave($event)"
          [draggable]="tree.draggableNodes"
          (dragstart)="onDragStart($event)"
          (dragend)="onDragStop($event)"
          [attr.tabindex]="0"
          [ngClass]="{
            'p-treenode-selectable': tree.selectionMode && node.selectable !== false,
            'p-treenode-dragover': draghoverNode,
            'p-highlight': isSelected()
          }"
          role="treeitem"
          (keydown)="onKeyDown($event)"
          [attr.aria-posinset]="this.index + 1"
          [attr.aria-expanded]="this.node.expanded"
          [attr.aria-selected]="isSelected()"
          [attr.aria-label]="node.label"
        >
          <button type="button" class="p-tree-toggler p-link" (click)="toggle($event)" pRipple tabindex="-1">
            <span
              class="p-tree-toggler-icon pi pi-fw"
              [ngClass]="{ 'pi-chevron-right': !node.expanded, 'pi-chevron-down': node.expanded }"
            ></span>
          </button>
          <div
            class="p-checkbox p-component"
            (click)="OnNodeFire()"
            [ngClass]="{ 'p-checkbox-disabled': node.selectable === false }"
            *ngIf="tree.selectionMode == 'checkbox'"
            [attr.aria-checked]="isSelected()"
          >
            <div
              class="p-checkbox-box"
              [ngClass]="{ 'p-highlight': isSelected(), 'p-indeterminate': node.partialSelected }"
            >
              <span
                class="p-checkbox-icon pi"
                [ngClass]="{ 'pi-check': isSelected(), 'pi-minus': node.partialSelected }"
              ></span>
            </div>
          </div>
          <span [class]="getIcon()" *ngIf="node.icon || node.expandedIcon || node.collapsedIcon"></span>
          <span class="p-treenode-label">
            <span *ngIf="!tree.getTemplateForNode(node)">{{ node.label }}</span>
            <span *ngIf="tree.getTemplateForNode(node)">
              <ng-container
                *ngTemplateOutlet="tree.getTemplateForNode(node); context: { $implicit: node }"
              ></ng-container>
            </span>
          </span>
        </div>
        <ul
          class="p-treenode-children"
          style="display: none;"
          *ngIf="!tree.virtualScroll && node.children && node.expanded"
          [style.display]="node.expanded ? 'block' : 'none'"
          role="group"
        >
          <org-treeNode
            (onNodeClickEventFire)="OnNodeFire()"
            *ngFor="
              let childNode of node.children;
              let firstChild = first;
              let lastChild = last;
              let index = index;
              trackBy: tree.trackBy
            "
            [node]="childNode"
            [parentNode]="node"
            [firstChild]="firstChild"
            [lastChild]="lastChild"
            [index]="index"
            [style.height.px]="tree.virtualNodeHeight"
            [level]="level + 1"
          ></org-treeNode>
        </ul>
      </li>
      <li
        *ngIf="tree.droppableNodes && lastChild"
        class="p-treenode-droppoint"
        [ngClass]="{ 'p-treenode-droppoint-active': draghoverNext }"
        (drop)="onDropPoint($event, 1)"
        (dragover)="onDropPointDragOver($event)"
        (dragenter)="onDropPointDragEnter($event, 1)"
        (dragleave)="onDropPointDragLeave($event)"
      ></li>
      <table *ngIf="tree.horizontal" [class]="node.styleClass">
        <tbody>
          <tr>
            <td class="p-treenode-connector" *ngIf="!root">
              <table class="p-treenode-connector-table">
                <tbody>
                  <tr>
                    <td [ngClass]="{ 'p-treenode-connector-line': !firstChild }"></td>
                  </tr>
                  <tr>
                    <td [ngClass]="{ 'p-treenode-connector-line': !lastChild }"></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="p-treenode" [ngClass]="{ 'p-treenode-collapsed': !node.expanded }">
              <div
                class="p-treenode-content"
                tabindex="0"
                [ngClass]="{ 'p-treenode-selectable': tree.selectionMode, 'p-highlight': isSelected() }"
                (click)="onNodeClick($event)"
                (contextmenu)="onNodeRightClick($event)"
                (touchend)="onNodeTouchEnd()"
                (keydown)="onNodeKeydown($event)"
              >
                <span
                  class="p-tree-toggler pi pi-fw"
                  [ngClass]="{ 'pi-plus': !node.expanded, 'pi-minus': node.expanded }"
                  *ngIf="!isLeaf()"
                  (click)="toggle($event)"
                ></span>
                <span [class]="getIcon()" *ngIf="node.icon || node.expandedIcon || node.collapsedIcon"></span>
                <span class="p-treenode-label">
                  <span *ngIf="!tree.getTemplateForNode(node)">{{ node.label }}</span>
                  <span *ngIf="tree.getTemplateForNode(node)">
                    <ng-container
                      *ngTemplateOutlet="tree.getTemplateForNode(node); context: { $implicit: node }"
                    ></ng-container>
                  </span>
                </span>
              </div>
            </td>
            <td
              class="p-treenode-children-container"
              *ngIf="node.children && node.expanded"
              [style.display]="node.expanded ? 'table-cell' : 'none'"
            >
              <div class="p-treenode-children">
                <org-treeNode
                  (onNodeClickEventFire)="OnNodeFire()"
                  *ngFor="
                    let childNode of node.children;
                    let firstChild = first;
                    let lastChild = last;
                    trackBy: tree.trackBy
                  "
                  [node]="childNode"
                  [firstChild]="firstChild"
                  [lastChild]="lastChild"
                ></org-treeNode>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  `,
})
export class UIOrgTreeNode extends UITreeNode {
  @Output() onNodeClickEventFire: EventEmitter<any> = new EventEmitter();
  constructor(@Inject(forwardRef(() => OrgTree)) tree: any) {
    super(tree);
  }
  OnNodeFire() {
    // console.log(type)
    this.onNodeClickEventFire.emit(true);
  }
}

@Component({
  selector: 'org-tree',
  template: `
    <div
      [ngClass]="{
        'p-tree p-component': true,
        'p-tree-selectable': selectionMode,
        'p-treenode-dragover': dragHover,
        'p-tree-loading': loading,
        'p-tree-flex-scrollable': scrollHeight === 'flex'
      }"
      [ngStyle]="style"
      [class]="styleClass"
      *ngIf="!horizontal"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragenter)="onDragEnter()"
      (dragleave)="onDragLeave($event)"
    >
      <div class="p-tree-loading-overlay p-component-overlay" *ngIf="loading">
        <i [class]="'p-tree-loading-icon pi-spin ' + loadingIcon"></i>
      </div>
      <div *ngIf="filter" class="p-tree-filter-container">
        <input
          #filter
          type="text"
          autocomplete="off"
          class="p-tree-filter p-inputtext p-component"
          [attr.placeholder]="filterPlaceholder"
          (keydown.enter)="$event.preventDefault()"
          (input)="_filter($event)"
        />
        <span class="p-tree-filter-icon pi pi-search"></span>
      </div>
      <ng-container *ngIf="!virtualScroll; else virtualScrollList">
        <div class="p-tree-wrapper" [style.max-height]="scrollHeight">
          <ul
            class="p-tree-container"
            *ngIf="getRootNode()"
            role="tree"
            [attr.aria-label]="ariaLabel"
            [attr.aria-labelledby]="ariaLabelledBy"
          >
            <org-treeNode
              (onNodeClickEventFire)="onNodeClickEventFire($event)"
              *ngFor="
                let node of getRootNode();
                let firstChild = first;
                let lastChild = last;
                let index = index;
                trackBy: trackBy
              "
              [node]="node"
              [firstChild]="firstChild"
              [lastChild]="lastChild"
              [index]="index"
              [level]="0"
            ></org-treeNode>
          </ul>
        </div>
      </ng-container>
      <ng-template #virtualScrollList>
        <cdk-virtual-scroll-viewport
          class="p-tree-wrapper"
          [style.height]="scrollHeight"
          [itemSize]="virtualNodeHeight"
          [minBufferPx]="minBufferPx"
          [maxBufferPx]="maxBufferPx"
        >
          <ul
            class="p-tree-container"
            *ngIf="getRootNode()"
            role="tree"
            [attr.aria-label]="ariaLabel"
            [attr.aria-labelledby]="ariaLabelledBy"
          >
            <org-treeNode
              (onNodeClickEventFire)="onNodeClickEventFire($event)"
              *cdkVirtualFor="
                let rowNode of serializedValue;
                let firstChild = first;
                let lastChild = last;
                let index = index;
                trackBy: trackBy;
                templateCacheSize: 0
              "
              [level]="rowNode.level"
              [rowNode]="rowNode"
              [node]="rowNode.node"
              [firstChild]="firstChild"
              [lastChild]="lastChild"
              [index]="index"
              [style.height.px]="virtualNodeHeight"
              [indentation]="indentation"
            ></org-treeNode>
          </ul>
        </cdk-virtual-scroll-viewport>
      </ng-template>
      <div class="p-tree-empty-message" *ngIf="!loading && (value == null || value.length === 0)">
        {{ emptyMessage }}
      </div>
    </div>
    <div
      [ngClass]="{ 'p-tree p-tree-horizontal p-component': true, 'p-tree-selectable': selectionMode }"
      [ngStyle]="style"
      [class]="styleClass"
      *ngIf="horizontal"
    >
      <div class="p-tree-loading-mask p-component-overlay" *ngIf="loading">
        <i [class]="'p-tree-loading-icon pi-spin ' + loadingIcon"></i>
      </div>
      <table *ngIf="value && value[0]">
        <org-treeNode
          (onNodeClickEventFire)="onNodeClickEventFire($event)"
          [node]="value[0]"
          [root]="true"
        ></org-treeNode>
      </table>
      <div class="p-tree-empty-message" *ngIf="!loading && (value == null || value.length === 0)">
        {{ emptyMessage }}
      </div>
    </div>
  `,
})
export class OrgTree extends Tree {
  @Input() minBufferPx: number;
  @Input() maxBufferPx: number;

  @Output() onNodeClickEventFireByParent: EventEmitter<any> = new EventEmitter();
  onNodeClickEventFire(value: any) {
    this.onNodeClickEventFireByParent.emit(true);
  }
  propagateDown(node: TreeNode, select: boolean) {
    let index = this.findIndexInSelection(node);

    if (select && index == -1) {
      this.selection = [...(this.selection || []), node];
    } else if (!select && index > -1) {
      this.selection = this.selection.filter((val: any, i: any) => i != index);
    }

    node.partialSelected = false;

    this.syncNodeOption(node, this.filteredNodes, 'partialSelected');

    if (node.children && node.children.length) {
      for (let child of node.children) {
        this.propagateDown(child, select);
      }
    }
  }
}

@NgModule({
  imports: [CommonModule, TooltipModule, TreeModule, ScrollingModule, RippleModule],
  exports: [OrgTree, ScrollingModule, RippleModule],
  declarations: [OrgTree, UIOrgTreeNode],
})
export class OrgTreeModule {}
