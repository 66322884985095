import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { LoginComponent } from './login.component';
import { LogoutSsoComponent } from './logout-sso/logout-sso.component';
import { SsoComponent } from './sso/sso.component';
import { EndSessionComponent } from './end-session/end-session.component';
import { IdleTimeoutComponent } from './idle-timeout/idle-timeout.component';
import { SignoutMessageComponent } from './signout-message/signout-message.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { CodeVerificationComponent } from './code-verification/code-verification.component';
import { SigninCallbackComponent } from './signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './signout-callback/signout-callback.component';
import { PostLogoutComponent } from './post-logout/post-logout.component';
import { ExternalCallbackComponent } from './external-callback/external-callback.component';
import { ExtSignoutCallbackComponent } from './ext-signout-callback/ext-signout-callback.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: extract('Login') },
  },
  {
    path: 'login/:id',
    component: LoginComponent,
    data: { title: extract('Login') },
  },
  {
    path: 'sso',
    component: SsoComponent,
    data: { title: extract('sso') },
  },
  {
    path: 'logoutsso',
    component: LogoutSsoComponent,
    data: { title: extract('Logout') },
  },
  {
    path: 'endsession',
    component: EndSessionComponent,
    data: { title: extract('End Session') },
  },
  {
    path: 'idle-timeout',
    component: IdleTimeoutComponent,
    data: { title: extract('Idle Timeout') },
  },
  {
    path: 'signout-message',
    component: SignoutMessageComponent,
    data: {
      breadcrumb: 'signout-message',
    },
  },
  {
    path: 'code-verification',
    component: CodeVerificationComponent,
    data: {
      breadcrumb: 'code-verification',
    },
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    data: {
      breadcrumb: 'set-password',
    },
  },
  {
    path: 'signin-callback',
    component: SigninCallbackComponent,
    data: {
      breadcrumb: 'signin-callback',
    },
  },
  {
    path: 'external-callback',
    component: ExternalCallbackComponent,
    data: {
      breadcrumb: 'external-callback',
    },
  },
  {
    path: 'signout-callback',
    component: SignoutCallbackComponent,
    data: {
      breadcrumb: 'sigout-callback',
    },
  },
  {
    path: 'ext-signout-callback',
    component: ExtSignoutCallbackComponent,
    data: {
      breadcrumb: 'ext-sigout-callback',
    },
  },
  { path: 'post-logout', component: PostLogoutComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LoginRoutingModule {}
